define("cb-ui/components/tcb-datepicker", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //action params
    tagName: '',
    source: null,
    target: null,
    alias: null,
    sync: null,
    // end action params
    startDate: null,
    endDate: null,
    value: null,
    readonly: false,
    disabled: false,
    placeholder: 'DD/MM/YYYY',
    isStartDate: true,
    timelineDatePicker: false,
    syncDatePickerValue: true,
    dateFormat: 'dd.mm.yyyy',
    dateLtStart: Ember.computed('value', 'startDate', function () {
      return this.get('value') < this.get('startDate');
    }),
    dateGtStart: Ember.computed('value', 'startDate', function () {
      return this.get('value') > this.get('endDate');
    }),
    datePickerValue: Ember.computed('value', function () {
      var value = this.get('value');

      if (!this.get('syncDatePickerValue')) {
        return value;
      }

      if (value) {
        var datePickerTime = ' 17:00:00';
        var datePickerMonth = ("0" + (value.getMonth() + 1)).slice(-2);
        var datePickerDay = ("0" + value.getDate()).slice(-2);

        var newDate = _moment.default.tz(value.getFullYear() + '-' + datePickerMonth + '-' + datePickerDay + datePickerTime, 'Europe/Berlin');

        return newDate.toDate();
      }

      return new Date();
    }),
    convertLocalTimeToCET: function convertLocalTimeToCET(value) {
      var datePickerTime = ' 17:00:00';
      var datePickerMonth = ("0" + (value.getMonth() + 1)).slice(-2);
      var datePickerDay = ("0" + value.getDate()).slice(-2);

      var newDate = _moment.default.tz(value.getFullYear() + '-' + datePickerMonth + '-' + datePickerDay + datePickerTime, 'Europe/Berlin');

      return newDate.toDate();
    },
    actions: {
      changeTimelineDate: function changeTimelineDate(value) {
        var source = this.get('source');
        var target = this.get('target');
        var alias = this.get('alias');
        var sync = this.get('sync');

        if (source && target && alias && sync && value) {
          var newValue = this.convertLocalTimeToCET(value);
          this.sendAction('changeTimelineDate', source, target, alias, sync, newValue);
          this.set('syncDatePickerValue', true);
        } else {
          this.set('syncDatePickerValue', false);
          this.set('value', this.convertLocalTimeToCET(value));
          this.set('syncDatePickerValue', true);
        }
      }
    }
  });

  _exports.default = _default;
});