define("cb-ui/routes/app/project/proposal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var radioOptions = {
    confirmSubmitProposal: 'confirmSubmitProposal',
    stepBackProposal: 'stepBackProposal'
  };

  var _default = Ember.Route.extend({
    notify: Ember.inject.service('notify'),
    controller: null,
    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super(transition);

      this.get('sessionContext.currentUser').then(function (user) {
        if (!user.get('isConsultant')) {
          _this.transitionTo('app.dashboard');
        }
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('proposal', model);
      controller.set('quote', this.modelFor('app.project'));
      controller.set('showSubmitProposalModal', false);
      controller.set('showConfirmSubmitProposalModal', false);
      controller.set('showCancelParticipationModal', false);
      controller.set('showIncompleteProposalModal', false);
      controller.set('showSubmittedInfoModal', false);
      controller.set('radioButtonsOptions', radioOptions);
      this.get('sessionContext.currentUser').then(function (user) {
        controller.set('appLanguage', user.get('language'));
        controller.set('user', user);
        controller.set('userPhone', user.get('mobilePhone') ? user.get('mobilePhone') : user.get('phone') ? user.get('phone') : null);
        controller.set('userFullName', user.get('fullName'));
        controller.set('userEmail', user.get('email'));
        user.get('company').then(function (company) {
          controller.set('userCompany', company.get('companyName'));
        }); //this.store.query('user-proposal-role', {proposal_id: model.get('id'), user_id: user.get('id')}).then(upr => {
        //  controller.set('userProposalRole', upr);
        //})
      });
      this.set('controller', controller);
    },
    actions: {
      openSubmitProposalModal: function openSubmitProposalModal() {
        var _this2 = this;

        var controller = this.controllerFor('app.project.proposal');
        var proposal = controller.get('proposal');
        var quote = controller.get('quote');

        if (proposal.get('status') === 'accepted') {
          this.store.createRecord('proposal-perm', {
            proposal: proposal.get('id')
          }).save().then(function (perms) {
            if (perms.get('allowConsultantToSubmit') || proposal.get('ignoreTimelineValidation')) {
              var financialQuotationTotal = proposal.get('financialQuotationTotal');

              if (financialQuotationTotal && !isNaN(financialQuotationTotal)) {
                controller.set('showSubmitProposalModal', true);
              } else {
                _this2.get('notify').error(_this2.get('intl').t('notifyMessages.financialQuotationNotSubmitted').toString(), {
                  closeAfter: 10000
                });
              }
            } else {
              var submitStartDate = (0, _moment.default)(quote.get('rfpReleaseDueDate')).tz('Europe/Berlin').add(1, 'days').format('DD.MM.YYYY');
              var proposalDue = (0, _moment.default)(quote.get('proposalDueDate')).tz('Europe/Berlin').format('DD.MM.YYYY');

              _this2.get('notify').error(_this2.get('intl').t('notifyMessages.proposalSubmitPeriod').toString() + submitStartDate + _this2.get('intl').t('notifyMessages.and').toString() + proposalDue + ' (CET)');
            }
          }).catch(function () {
            _this2.get('notify').error(_this2.get('intl').t('notifyMessages.checkTimeline'));
          });
        }
      },
      openIncompleteProposalModal: function openIncompleteProposalModal() {
        this.controller.set('showIncompleteProposalModal', true);
      },
      openConfirmSubmitProposalModal: function openConfirmSubmitProposalModal() {
        this.controllerFor('app.project.proposal').set('showSubmitProposalModal', false);
        this.controllerFor('app.project.proposal').set('showConfirmSubmitProposalModal', true);
      },
      openCancelParticipationModal: function openCancelParticipationModal() {
        if (confirm(this.get('intl').t('confirmMessages.cancelParticipation').toString())) {
          var controller = this.controllerFor('app.project.proposal');
          var proposal = controller.get('proposal');

          if (proposal.get('status') === 'accepted') {
            this.controllerFor('app.project.proposal').set('showCancelParticipationModal', true);
          }
        }
      },
      closeConfirmSubmitProposalModal: function closeConfirmSubmitProposalModal() {
        this.controllerFor('app.project.proposal').set('showConfirmSubmitProposalModal', false);
      },
      closeSubmitProposalModal: function closeSubmitProposalModal() {
        this.controllerFor('app.project.proposal').set('showSubmitProposalModal', false);
      },
      closeCancelParticipationModal: function closeCancelParticipationModal() {
        this.controllerFor('app.project.proposal').set('showCancelParticipationModal', false);
      },
      closeIncompleteProposalModal: function closeIncompleteProposalModal() {
        this.controller.set('showIncompleteProposalModal', false);
      },
      submitProposalWithConditions: function submitProposalWithConditions() {
        var _this3 = this;

        var controller = this.controllerFor('app.project.proposal');
        var proposal = controller.get('proposal');
        proposal.set('status', 'submitted');
        proposal.save().then(function (savedProposal) {
          controller.set('showConfirmSubmitProposalModal', false);

          if (savedProposal.get('isSubmitted')) {
            _this3.controller.set('showSubmittedInfoModal', true);
          } else {
            _this3.transitionTo('app.project.proposal.result', _this3.modelFor('app.project'), proposal);
          }
        }).catch(function (err) {
          // debugger
          if (err.errors.length) {
            err.errors.forEach(function (e) {
              _this3.get('notify').error(e.detail);
            });
          }

          _this3.get('notify').error(_this3.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
        });
      },
      goToDashboard: function goToDashboard() {
        this.controller.set('showSubmittedInfoModal', false);
        this.transitionTo('app.dashboard');
      },
      closeSubmittedInfoModal: function closeSubmittedInfoModal() {
        this.controller.set('showSubmittedInfoModal', false);
      },
      confirmCancelParticipation: function confirmCancelParticipation() {
        var _this4 = this;

        var controller = this.controllerFor('app.project.proposal');
        var proposal = controller.get('proposal');
        proposal.set('status', 'declined');
        proposal.save().then(function () {
          controller.set('showCancelParticipationModal', false);

          _this4.transitionTo('app.project.proposal.result', _this4.modelFor('app.project'), proposal);
        });
      },
      openCallBackClientModal: function openCallBackClientModal() {
        this.controllerFor('app.project.proposal').set('showCallBackFromClientModal', true);
      },
      closeCallBackClientModal: function closeCallBackClientModal() {
        this.controllerFor('app.project.proposal').set('showCallBackFromClientModal', false);
      },
      newCallBackClient: function newCallBackClient() {
        var _this5 = this;

        var proposal = this.controller.get('proposal');
        this.controller.set('callBackButtonDisabled', true);
        var ctrl = this.controller;

        if (!ctrl.get('userFullName') || !ctrl.get('userCompany') || !ctrl.get('userEmail') || !ctrl.get('userPhone')) {
          this.get('notify').error(this.get('intl').t('notifyMessages.fillInFields').toString());
          this.controller.set('callBackButtonDisabled', false);
          return;
        }

        this.store.createRecord('client-question', {
          proposal: proposal,
          question: this.controller.get('question'),
          userCompany: this.controller.get('userCompany'),
          userEmail: this.controller.get('userEmail'),
          userFullName: this.controller.get('userFullName'),
          userPhone: this.controller.get('userPhone')
        }).save().then(function () {
          _this5.get('notify').success(_this5.get('intl').t('notifyMessages.callBackRequestSent').toString());

          _this5.controller.set("showCallBackFromClientModal", false);

          _this5.controller.set("question", null);

          _this5.controller.set('callBackButtonDisabled', false);
        }).catch(function () {
          _this5.get('notify').error(_this5.get('intl').t('notifyMessages.errorOccurredTryAgain'));

          _this5.controller.set('callBackButtonDisabled', false);
        });
      }
    }
  });

  _exports.default = _default;
});