define("cb-ui/components/document-toc-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['document-toc-editor', 'p-m'],
    sections: null,
    rootSections: null,
    list: null,
    actions: {
      addSection: function addSection() {
        this.attrs.addSection();
      },
      'delete': function _delete(section) {
        this.attrs.delete(section);
      }
    },
    sendSerializedList: function sendSerializedList() {
      this.attrs.changeList(this.get('list').nestable('serialize'));
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var list = this.$().find('#nestable');
      this.set('list', list);
      var nestable = list.nestable();
      nestable.on('change', function () {
        _this.sendSerializedList();
      });
      this.bindScroll();
    },
    bindScroll: function bindScroll() {
      Ember.$(window).on('mousemove.toc', function (e) {
        var draggedElement = Ember.$('.dd-dragel');
        var htmlBody = Ember.$('html, body');

        if (draggedElement && draggedElement.length > 0 && !htmlBody.is(':animated')) {
          var bottom = Ember.$(window).height() - 50,
              top = 50;

          if (e.clientY > bottom && Ember.$(window).scrollTop() + Ember.$(window).height() < Ember.$(document).height() - 100) {
            htmlBody.animate({
              scrollTop: Ember.$(window).scrollTop() + 300
            }, 600);
          } else if (e.clientY < top && Ember.$(window).scrollTop() > 0) {
            htmlBody.animate({
              scrollTop: Ember.$(window).scrollTop() - 300
            }, 600);
          } else {
            htmlBody.finish();
          }
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off('mousemove.toc');
    }
  });

  _exports.default = _default;
});