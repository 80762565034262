define("cb-ui/routes/app/project/client/evaluation", ["exports", "cb-ui/mixins/scrollbar-reset"], function (_exports, _scrollbarReset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollbarReset.default, {
    notify: Ember.inject.service(),
    model: function model() {
      var quote = this.modelFor('app.project');
      return Ember.RSVP.hash({
        criteriaEvaluations: this.store.findAll('evaluation'),
        quote: quote,
        proposals: this.store.query('proposal', {
          quote_id: quote.get('id')
        }),
        beautyItems: quote.get('beautyItems'),
        currentUser: this.get('sessionContext.currentUser'),
        companies: quote.get('companies'),
        //all and technical/pitch response data structure is different
        quoteEvaluationResults: quote.getEvaluationResults({
          evaluation_type: 'all'
        }),
        quoteTechnicalEvaluationResults: quote.getEvaluationResults({
          evaluation_type: 'technical'
        }),
        quotePitchEvaluationResults: quote.getEvaluationResults({
          evaluation_type: 'pitch'
        }),
        criteriaEvaluationGroups: this.store.query('criteria-evaluation-group', {
          quote_id: quote.get('id'),
          include: 'evaluations'
        }),
        userQuoteRoleList: this.store.query('user-quote-role', {
          quote_id: quote.get('id')
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var quote = model.quote;
      var technicalQuoteCriteriaGroups = new Ember.A();
      var pitchQuoteCriteriaGroups = new Ember.A();
      controller.set('quote', model.quote);
      controller.set('userQuoteRole', model.userQuoteRoleList.get('firstObject'));
      controller.set('criteriaEvaluations', model.criteriaEvaluations);
      controller.set('proposals', model.proposals);
      controller.set('beautyContestItems', model.beautyItems);
      controller.set('activeEvaluation', null);
      controller.set('activeEvaluationCategory', null);
      controller.set('awardedProposal', null);
      controller.set('activeProposal', null);
      controller.set('showEvaluationModal', null);
      controller.set('showAwardProjectModal', null);
      controller.set('showFinancialDetailsModal', null);
      controller.set('showEvaluationsIncompleteModal', null);
      controller.set('notYetRatedClass', '');
      controller.set('currentUser', model.currentUser);
      controller.set("quoteEvaluationResults", model.quoteEvaluationResults.data);
      controller.set("quoteTechnicalEvaluationResults", model.quoteTechnicalEvaluationResults.data);
      controller.set("quotePitchEvaluationResults", model.quotePitchEvaluationResults.data);
      controller.set('showPitchEvaluationDetails', false);
      controller.set('showTechnicalEvaluationDetails', false);
      model.criteriaEvaluationGroups.forEach(function (ceg) {
        var groupType = ceg.get('type');

        if (groupType === 'technical_proposal') {
          technicalQuoteCriteriaGroups.addObject(ceg);
        } else if (groupType === 'pitch') {
          pitchQuoteCriteriaGroups.addObject(ceg);
        }
      });
      controller.set('technicalCriteriaGroups', technicalQuoteCriteriaGroups.sortBy('order'));
      controller.set('pitchCriteriaGroups', pitchQuoteCriteriaGroups.sortBy('order'));
      quote.get('owner').then(function (owner) {
        owner.get('company').then(function (company) {
          controller.set('quoteCompany', company);
        });
      });
    },
    actions: {
      restoreProjectVolume: function restoreProjectVolume(proposal) {
        var _this = this;

        proposal.set('finalProposalTotal', null);
        proposal.set('finalProposalTotalHistory', null);
        proposal.save().then(function () {
          _this.get('notify').success(_this.get('intl').t('app.projectClient.evaluation.label.financialProposals.changeVolumeComponent.restoreSuccess').toString());

          _this.refresh();
        }).catch(function () {
          _this.get('notify').error(_this.get('intl').t('app.projectClient.evaluation.label.financialProposals.changeVolumeComponent.error').toString());
        });
      },
      saveProposalVolume: function saveProposalVolume(proposal, component) {
        var _this2 = this;

        proposal.save().then(function () {
          component.set('showVolumeModal', false);

          _this2.get('notify').success(_this2.get('intl').t('app.projectClient.evaluation.label.financialProposals.changeVolumeComponent.changeSuccess').toString());

          _this2.refresh();
        }).catch(function () {
          _this2.get('notify').error(_this2.get('intl').t('app.projectClient.evaluation.label.financialProposals.changeVolumeComponent.error').toString());
        });
      }
    }
  });

  _exports.default = _default;
});