define("cb-ui/components/em-text", ["exports", "ember-rapid-forms/components/em-text"], function (_exports, _emText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import Ember from 'ember';
  var _default = _emText.default;
  _exports.default = _default;
});