define("cb-ui/templates/components/models-table/consultants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sobh2LRK",
    "block": "{\"symbols\":[\"name\"],\"statements\":[[4,\"each\",[[20,[\"record\",\"consultantsNames\",\"names\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"label consulting-firms-label\"],[7],[1,[19,1,[]],false],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/models-table/consultants.hbs"
    }
  });

  _exports.default = _default;
});