define("cb-ui/templates/components/models-table/edit-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vhwd6wQZ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"button\"],[9,\"class\",\"btn btn-primary btn-mini btn-table\"],[3,\"action\",[[19,0,[]],\"sendAction\",\"selectProject\",[20,[\"record\"]]]],[7],[6,\"i\"],[9,\"class\",\"glyphicon glyphicon-log-in\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"app.templates.index.label.editTemplate\"],null],false],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/models-table/edit-template.hbs"
    }
  });

  _exports.default = _default;
});