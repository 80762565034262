define("cb-ui/templates/components/ember-table/graphical-representation-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z06KvThL",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"chart-outer-layer\"],[7],[0,\"\\n\"],[1,[25,\"nvd3-chart\",null,[[\"type\",\"datum\",\"beforeSetup\",\"afterSetup\",\"dispatchEvents\"],[\"multiBarHorizontalChart\",[20,[\"cellValue\",\"datum\"]],[20,[\"beforeGraphSetup\"]],[20,[\"afterChartSetup\"]],[20,[\"dispatchChartEvents\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/ember-table/graphical-representation-title.hbs"
    }
  });

  _exports.default = _default;
});