define("cb-ui/components/question-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    renderedAnswer: Ember.computed('question.answer', function () {
      var answer = Ember.Handlebars.Utils.escapeExpression(this.get('question.answer'));
      return answer.replace(/\n/g, '<br>');
    })
  });

  _exports.default = _default;
});