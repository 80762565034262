define("cb-ui/models/company-setting", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    font: _emberData.default.attr('string'),
    custom_css: _emberData.default.attr('string'),
    nav: _emberData.default.attr('string'),
    navType: _emberData.default.attr('string'),
    primary: _emberData.default.attr('string'),
    primaryHover: _emberData.default.attr('string'),
    default: _emberData.default.attr('string'),
    success: _emberData.default.attr('string'),
    danger: _emberData.default.attr('string'),
    coverLetterLogo: attr(),
    coverLetterFooterLogo: attr(),
    coverLetterText: _emberData.default.attr('string'),
    //Cover Letter Logo care e FileField
    company: _emberData.default.belongsTo('company', {
      async: true,
      inverse: 'companySettings'
    })
  });

  _exports.default = _default;
});