define("cb-ui/models/language", ["exports", "cb-ui/utils/translate-property", "ember-data"], function (_exports, _translateProperty, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    languageEn: _emberData.default.attr('string'),
    languageDe: _emberData.default.attr('string'),
    language: (0, _translateProperty.default)('languageEn', 'languageDe'),
    intId: Ember.computed('id', function () {
      return parseInt(this.get('id'), 10);
    })
  });

  _exports.default = _default;
});