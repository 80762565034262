define("cb-ui/templates/components/models-table/status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i246HjFC",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",[26,[\"label \",[25,\"if\",[[25,\"eq\",[[20,[\"record\",\"status\"]],\"draft\"],null],\"label-info\",[25,\"if\",[[25,\"eq\",[[20,[\"record\",\"status\"]],\"sent\"],null],\"label-primary\",[25,\"if\",[[25,\"eq\",[[20,[\"record\",\"status\"]],\"requested\"],null],\"label-warning\",[25,\"if\",[[25,\"eq\",[[20,[\"record\",\"status\"]],\"on hold\"],null],\"label-on-hold\",[25,\"if\",[[25,\"eq\",[[20,[\"record\",\"status\"]],\"cancelled\"],null],\"label-danger\",\"label-success\"],null]],null]],null]],null]],null]]]],[7],[0,\"\\n        \"],[1,[20,[\"record\",\"statusAlias\"]],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/models-table/status.hbs"
    }
  });

  _exports.default = _default;
});