define("cb-ui/routes/app/project/proposal/summary", ["exports", "cb-ui/mixins/scrollbar-reset", "cb-ui/utils/download-file"], function (_exports, _scrollbarReset, _downloadFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollbarReset.default, {
    model: function model() {
      var quote = this.modelFor('app.project');
      var proposal = this.modelFor('app.project.proposal');
      var currentUser = this.get('sessionContext.currentUser');
      return Ember.RSVP.hash({
        proposal: this.modelFor('app.project.proposal'),
        quote: quote,
        criteriaEvaluationGroups: this.store.query('criteria-evaluation-group', {
          include: 'evaluations',
          quote_id: quote.get('id')
        }),
        userProposalRoleList: this.store.query('user-proposal-role', {
          proposal_id: proposal.get('id'),
          user_id: currentUser.get('id')
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('proposal', model.proposal);
      controller.set('quote', this.modelFor('app.project'));
      var technicalQuoteCriteriaGroups = new Ember.A();
      var pitchQuoteCriteriaGroups = new Ember.A();
      model.criteriaEvaluationGroups.forEach(function (ceg) {
        var groupType = ceg.get('type');

        if (groupType === 'technical_proposal') {
          technicalQuoteCriteriaGroups.addObject(ceg);
        } else if (groupType === 'pitch') {
          pitchQuoteCriteriaGroups.addObject(ceg);
        }
      });
      var currentUser = this.get('sessionContext.currentUser');
      controller.set('userProposalRole', model.userProposalRoleList.get('firstObject'));
      controller.set('currentUser', currentUser);
      controller.set('isSendingRequest', false);
      controller.set('technicalQuoteCriteriaGroups', technicalQuoteCriteriaGroups);
      controller.set('pitchQuoteCriteriaGroups', pitchQuoteCriteriaGroups);
      controller.set('cancelPartiticipationModal', false);
    },
    actions: {
      newDocumentToken: function newDocumentToken(id) {
        this.store.findRecord('quote-document', id).then(function (res) {
          (0, _downloadFile.default)(res.data.file, res.get('fileName'));
        });
      },
      newCoverLetterToken: function newCoverLetterToken(id) {
        this.store.findRecord('quote-cover-letter-document', id).then(function (res) {
          (0, _downloadFile.default)(res.data.file, res.get('fileName'));
        });
      }
    }
  });

  _exports.default = _default;
});