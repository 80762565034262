define("cb-ui/components/table-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.later(this, function () {
        _this.destroyFloatThead();

        _this.initFloatThead();
      }, 500);
    },
    observerIsMobileIsTablet: Ember.observer('media.isMobile', 'media.isTablet', function () {
      if (this.get('media.isMobile') || this.get('media.isTablet')) {
        this.destroyFloatThead();
      } else {
        this.initFloatThead();
      }
    }),
    willDestroyElement: function willDestroyElement() {
      this.destroyFloatThead();
    },
    initFloatThead: function initFloatThead() {
      var $table = Ember.$('table.table-container');
      $table.floatThead({
        position: 'fixed',
        top: 95,
        zIndex: 99
      });
    },
    destroyFloatThead: function destroyFloatThead() {
      this.$('table.table-container').floatThead('destroy');
    }
  });

  _exports.default = _default;
});