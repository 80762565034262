define("cb-ui/templates/request-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OSjFCOnC",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"top-navbar\",null,[[\"logout\",\"changeLocalStorageLanguage\"],[\"invalidateSession\",\"changeLocalStorageLanguage\"]]],false],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"col-sm-12\"],[7],[0,\"\\n    \"],[6,\"h1\"],[9,\"class\",\"text-center\"],[7],[0,\"Request form\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n  \"],[2,\"<div class=\\\"bold m-md text-center\\\">“In case you need any assistance with the RFP please contact…”\"],[0,\"\\n  \"],[2,\"</div>\"],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"col-sm-12\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"container\"],[7],[0,\"\\n      \"],[1,[25,\"request-form\",null,[[\"changeset\",\"submitAction\",\"addQuoteProjectGoal\",\"deleteQuoteProjectGoal\",\"addQuoteServiceProvider\",\"deleteQuoteServiceProvider\",\"projectGoals\",\"projectStandards\",\"industryStandards\",\"companySizeTurnover\",\"companySizeEmployees\",\"countries\",\"salutations\",\"languages\",\"localQuoteProjectGoals\",\"localServiceProviders\",\"appLanguage\",\"handleFormErrors\",\"isSendingRequest\"],[[20,[\"quoteChangeset\"]],[25,\"action\",[[19,0,[]],\"submitAction\"],null],[25,\"action\",[[19,0,[]],\"addQuoteProjectGoal\"],null],[25,\"action\",[[19,0,[]],\"deleteQuoteProjectGoal\"],null],[25,\"action\",[[19,0,[]],\"addQuoteServiceProvider\"],null],[25,\"action\",[[19,0,[]],\"deleteQuoteServiceProvider\"],null],[20,[\"projectGoals\"]],[20,[\"projectStandards\"]],[20,[\"industryStandards\"]],[20,[\"companySizeTurnover\"]],[20,[\"companySizeEmployees\"]],[20,[\"countries\"]],[20,[\"salutations\"]],[20,[\"languages\"]],[20,[\"localQuoteProjectGoals\"]],[20,[\"localServiceProviders\"]],[20,[\"appLanguage\"]],[25,\"action\",[[19,0,[]],\"handleFormErrors\"],null],[20,[\"isSendingRequest\"]]]]],false],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/request-form.hbs"
    }
  });

  _exports.default = _default;
});