define("cb-ui/mixins/wizard-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isMobile: false,
    componentTypeName: 'basic-panel',
    equalizerObserver: Ember.observer('isMobile', function () {
      var self = this;
      Ember.run.next(function () {
        self.equalizerEvent();
      });
    }),
    equalizerEvent: function equalizerEvent() {
      Ember.$(".equalizer").each(function () {
        var watchedDiv = Ember.$('.watched');

        if (watchedDiv) {
          Ember.$(".watch").height(watchedDiv.height());
        }
      });
    },
    actions: {
      resizeTriggered: function resizeTriggered(isMobile) {
        this.set('isMobile', isMobile);

        if (!this.get('isMobile')) {
          this.equalizerEvent();
        }
      }
    }
  });

  _exports.default = _default;
});