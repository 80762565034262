define("cb-ui/controllers/wizard/confirm", ["exports", "cb-ui/config/environment", "cb-ui/mixins/company-studies", "ember-local-storage"], function (_exports, _environment, _companyStudies, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_companyStudies.default, {
    session: Ember.inject.service(),
    notify: Ember.inject.service('notify'),
    parentController: Ember.inject.controller('wizard'),
    userLead: Ember.computed.alias('parentController.userLead'),
    quote: null,
    company: null,
    // showScore: true,
    showScore: false,
    quoteEmail: null,
    isShowingModal: null,
    isShowingDownloadPDFModal: null,
    isShowingSendEmailModal: null,
    isShowingStartRfp: null,
    sendToOwner: false,
    showDownloadMessage: false,
    recipientEmail: null,
    localProjectGoals: new Ember.A(),
    message: null,
    quoteConsultants: new Ember.A(),
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    appLanguage: Ember.computed.alias('localStorage.language'),
    consultantModalTitle: Ember.computed('company', function () {
      return this.get('company.companyNameAlias') + ' | COMPANY PROFILE';
    }),
    generalManagementConsultants: Ember.computed.filterBy('company.individuals', 'generalManagement', true),
    globalHeadOffices: Ember.computed.filterBy('company.addresses', 'globalHeadOffice', true),
    downloadPDFUrl: Ember.computed('quote.id', 'session.data.authenticated.token', function () {
      var jwt = this.get('session.data.authenticated.token');
      return _environment.default.backendAPI + '/api/download_pdf/' + this.get('id').toString() + '/?auth_token=' + jwt;
    }),
    sortServicesProperty: Ember.computed('appLanguage', function () {
      return ['service' + this.get('appLanguage')];
    }),
    sortIndustriesProperty: Ember.computed('appLanguage', function () {
      return ['industry' + this.get('appLanguage')];
    }),
    sortedCompanyServices: Ember.computed.sort('company.services', 'sortServicesProperty'),
    sortedCompanyIndustries: Ember.computed.sort('company.industries', 'sortIndustriesProperty'),
    equalizerEvent: function equalizerEvent() {//if (!this.get('isMobile')) {
      //  $(".equalizer").each(function() {
      //    const watchedDiv = $('.watched');
      //    if (watchedDiv) {
      //      $(".watch").height(watchedDiv.height() - 50);
      //    }
      //  });
      //}
    },
    validateEmail: function validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    clearUserLeadErrors: function clearUserLeadErrors() {
      var userLead = this.get('userLead');
      userLead.get('errors').remove('email');
      userLead.get('errors').remove('firstName');
      userLead.get('errors').remove('lastName');
      userLead.get('errors').remove('companyName');
    },
    calculateScores: function calculateScores(company) {
      if (company) {
        var quoteConsultantsList = this.get("quoteConsultants");
        var quoteConsultant = quoteConsultantsList.findBy('companyId', parseInt(company.get('id'), 10));

        if (quoteConsultant) {
          this.set('companyIndustryFitScore', quoteConsultant.get('industryScore'));
          this.set('companyFunctionalFitScore', quoteConsultant.get('serviceScore'));
        }
      } else {
        this.set('companyIndustryFitScore', 0);
        this.set('companyFunctionalFitScore', 0);
      }
    },
    actions: {
      openDownloadPDFModal: function openDownloadPDFModal() {
        this.set('isShowingDownloadPDFModal', true);
      },
      openStartRfp: function openStartRfp() {
        var userLead = this.get('userLead');
        this.set('localStorage.quoteUuid', this.get('quote.uuid'));
        this.set('localStorage.companyName', userLead.get('companyName'));
        this.set('localStorage.firstName', userLead.get('firstName'));
        this.set('localStorage.userEmail', userLead.get('email'));
        this.set('localStorage.lastName', userLead.get('lastName'));
        this.set('isShowingStartRfp', true);
      },
      sendEmail: function sendEmail() {
        var _this = this;

        var userLead = this.get('userLead');
        var quoteEmail = this.get('quoteEmail');
        quoteEmail.setProperties({
          sendToOwner: this.get('sendToOwner'),
          ownerEmail: userLead.get('email'),
          message: this.get('message')
        });
        Ember.RSVP.all([userLead.save(), quoteEmail.save()]).then(function () {
          _this.get('notify').success(_this.get('intl').t('notifyMessages.emailSentSuccess').toString());

          _this.set('isShowingSendEmailModal', false);

          _this.set('sendToOwner', null);
        }).catch(function () {
          _this.get('notify').error(_this.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
        });
      },
      toggleModal: function toggleModal(company) {
        this.set('company', company);
        this.calculateScores(company);
        this.toggleProperty('isShowingModal');
      },
      close: function close() {
        this.set('isShowingModal', false);
      },
      closeDownloadPDFModal: function closeDownloadPDFModal() {
        this.set('ownerEmailError', null);
        this.set('isShowingDownloadPDFModal', false);
        this.clearUserLeadErrors();
      },
      downloadPDF: function downloadPDF() {
        var _this2 = this;

        var userLead = this.get('userLead');
        userLead.save().then(function () {
          _this2.set('showDownloadMessage', true);
        });
      },
      closeSendEmailModal: function closeSendEmailModal() {
        this.set('isShowingSendEmailModal', false);
        this.set('sendToOwner', null);
        this.clearUserLeadErrors();
      },
      closeStartRfpModal: function closeStartRfpModal() {
        this.set('isShowingStartRfp', false);
      },
      previous: function previous() {
        this.transitionToRoute('wizard.consultants');
      },
      projectInformation: function projectInformation() {
        this.transitionToRoute('wizard.index');
      },
      consultants: function consultants() {
        this.transitionToRoute('wizard.consultants');
      }
    }
  });

  _exports.default = _default;
});