define("cb-ui/components/section-editor-bidirectional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['section-editor'],
    localValue: null,
    quote: null,
    section: null,
    localValueInit: Ember.on('init', Ember.observer('quote', 'section', function () {
      this.resetLocalValue();
    })),
    renderValue: Ember.computed('localValue', function () {
      if (this.get('localValue')) {
        return this.get('localValue').replace(/\n/g, '<br>');
      }

      return null;
    }),
    resetLocalValue: function resetLocalValue() {
      var quote = this.get('quote');
      var section = this.get('section');

      if (quote && section && section.get('bidirectionalKey')) {
        this.set('localValue', quote.get(section.get('bidirectionalKey')));
      }
    },
    actions: {
      'delete': function _delete() {
        this.attrs.delete(this.get('section'));
      },
      disable: function disable() {
        this.attrs.disable(this.get('section'));
      },
      enable: function enable() {
        this.attrs.enable(this.get('section'));
      },
      edit: function edit() {
        this.set('isEditing', true);
      },
      saveChanges: function saveChanges() {
        var _this = this;

        var quote = this.get('quote');
        quote.set(this.get('section.bidirectionalKey'), this.get('localValue'));
        quote.save().then(function () {
          _this.set('isEditing', false);
        }).catch(function () {
          _this.get('notify').error(_this.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
        });
      },
      cancelEdit: function cancelEdit() {
        this.set('isEditing', false);
        this.resetLocalValue();
      },
      saveSection: function saveSection() {
        this.get('section').save();
      }
    }
  });

  _exports.default = _default;
});