define("cb-ui/controllers/app/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    templates: null,
    technicalTemplateTitle: null,
    pitchTemplateTitle: null,
    showAddTechnicalTemplate: null,
    showAddPitchTemplate: null,
    userCompany: null,
    technicalType: 'technical_proposal',
    pitchType: 'pitch',
    technicalProposalTemplates: Ember.computed.filterBy('templates', 'type', 'technical_proposal'),
    pitchTemplates: Ember.computed.filterBy('templates', 'type', 'pitch'),
    columns: Ember.computed(function () {
      return [{
        propertyName: 'title',
        title: this.get('intl').t('app.templates.index.label.tableTemplateTitle').toString()
      }, {
        title: this.get('intl').t('app.templates.index.label.tableActions').toString(),
        component: 'models-table/edit-template',
        className: 'models-table__actions-20'
      }];
    }),
    actions: {
      addTemplate: function addTemplate(templateType) {
        if (templateType === this.get('technicalType')) {
          this.set('showAddTechnicalTemplate', true);
        } else if (templateType === this.get('pitchType')) {
          this.set('showAddPitchTemplate', true);
        }
      },
      manageTemplate: function manageTemplate(template) {
        this.transitionToRoute('app.templates.edit', template.get('id'));
      },
      saveTemplate: function saveTemplate(templateType) {
        var _this = this;

        var title = this.get('technicalType') === templateType ? this.get('technicalTemplateTitle') : this.get('pitchType') === templateType ? this.get('pitchTemplateTitle') : null;
        this.store.createRecord('template', {
          title: title,
          company: this.get('userCompany'),
          type: templateType
        }).save().then(function (template) {
          if (templateType === _this.get('technicalType')) {
            _this.set('showAddTechnicalTemplate', false);

            _this.set('technicalTemplateTitle', null);
          } else if (templateType === _this.get('pitchType')) {
            _this.set('showAddPitchTemplate', false);

            _this.set('pitchTemplateTitle', null);
          }

          _this.get('templates').pushObject(template);

          _this.get('notify').success('Template added with success');
        }).catch(function () {
          _this.get('notify').error(_this.get('intl').t('notifyMessages.errorOccurredVerifyFields').toString());
        });
      }
    }
  });

  _exports.default = _default;
});