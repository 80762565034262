define("cb-ui/helpers/t", ["exports", "ember-intl/helpers/t"], function (_exports, _t) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _t.default.extend({
    format: function format(value, options) {
      return this.intl.formatHtmlMessage(value, options);
    }
  });

  _exports.default = _default;
});