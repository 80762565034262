define("cb-ui/components/models-table/select-project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      sendAction: function sendAction(record) {
        this.sendAction('selectProject', record);
      }
    }
  });

  _exports.default = _default;
});