define("cb-ui/routes/signup", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function jwtPayload(token) {
    if (token) {
      return JSON.parse(atob(token.split('.')[1]));
    }

    return null;
  }

  var _default = Ember.Route.extend({
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super(transition);

      if (this.get('session.isAuthenticated')) {
        var token;
        var company_id;

        try {
          token = JSON.parse(window.localStorage.getItem('ember_simple_auth:session')).authenticated.token;
          company_id = jwtPayload(token).company_id;
        } catch (e) {
          token = null;
          company_id = null;
        }

        if (company_id === 786) {// pass
        } else {
          this.set('session.skipRedirect', true);
          this.get('session').invalidate().then(function () {
            _this.set('session.skipRedirect', false);
          });
        }
      }
    },
    model: function model(object, transition) {
      var cbStore = this.get('cbStore');
      var user;

      if (!transition.queryParams.token) {
        if (!this.get('session.isAuthenticated')) {
          this.transitionTo('login');
          return;
        }

        var localStorage = this.get('localStorage');
        user = this.store.createRecord('user', {
          email: localStorage.get('userEmail') ? localStorage && localStorage.get('userEmail') : '',
          language: localStorage.get('language') ? localStorage && localStorage.get('language') : 'en',
          newCompany: true,
          password: '',
          postalCode: '',
          phone: '',
          city: '',
          companyName: localStorage.get('companyName') ? localStorage && localStorage.get('companyName') : '',
          'addressLine-1': '',
          firstName: '',
          lastName: ''
        });
        return Ember.RSVP.hash({
          countries: cbStore.findAllWrapper('country', 'commonNameEn'),
          user: user,
          invitedUser: false,
          tokenExpired: false
        });
      } else {
        var jsontoken = JSON.parse(atob(transition.queryParams.token.split('.')[1]));
        var jsonheaders = JSON.parse(atob(transition.queryParams.token.split('.')[0]));
        var expire = jsonheaders.exp || jsontoken.exp;
        var now = new Date().getTime() / 1000;

        if (expire - now >= 30) {
          user = this.store.query('user', {
            token: transition.queryParams.token
          }).then(function (users) {
            if (users) {
              return users.get('firstObject');
            } else {
              window.location = '#/signup';
            }
          }, function () {
            window.location = '#/signup';
          });
        }

        return Ember.RSVP.hash({
          countries: cbStore.findAllWrapper('country', 'commonNameEn'),
          user: user,
          invitedUser: true,
          tokenExpired: expire - now < 30
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.user);

      controller.set('invitedUser', model.invitedUser);
      controller.set('countries', model.countries);

      if (model.tokenExpired) {
        controller.set('tokenExpired', model.tokenExpired);
      } else {
        if (model.user.get('isConsultant')) {
          controller.set('userType', 'consultant');
        }

        if (model.user.get('isClient')) {
          controller.set('userType', 'client');
        }

        if (model.user.get('email')) {
          Ember.run.later(function () {
            controller.send('checkEmail');
          }, 1000);
        }
      }
    },
    redirect: function redirect(model) {
      if (model.invitedUser && model.user && model.user.get('registrationCompleted')) {
        this.transitionTo('login');
      }
    }
  });

  _exports.default = _default;
});