define("cb-ui/templates/app/project/proposal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ujwDJrso",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"app-top-navbar\",null,[[\"logout\",\"changeUserLanguage\",\"contactTCB\",\"showInfo\",\"quote\"],[\"invalidateSession\",\"changeUserLanguage\",\"contactTCBAgent\",true,[20,[\"model\"]]]]],false],[0,\"\\n\\n\"],[1,[18,\"outlet\"],false],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"showSubmitProposalModal\"]]],null,{\"statements\":[[0,\"  \"],[12,\"submit-proposal-modal\",[]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"showConfirmSubmitProposalModal\"]]],null,{\"statements\":[[0,\"  \"],[12,\"confirm-submit-proposal-modal\",[]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"showCancelParticipationModal\"]]],null,{\"statements\":[[0,\"  \"],[12,\"cancel-participation-modal\",[]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"showIncompleteProposalModal\"]]],null,{\"statements\":[[0,\"  \"],[12,\"incomplete-proposal-modal\",[]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"showSubmittedInfoModal\"]]],null,{\"statements\":[[0,\"  \"],[12,\"submitted-info-modal\",[]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"showCallBackFromClientModal\"]]],null,{\"statements\":[[0,\"  \"],[12,\"request-client-callback-modal\",[]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "cb-ui/templates/app/project/proposal.hbs"
    }
  });

  _exports.default = _default;
});