define("cb-ui/templates/components/section-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9y7RhSyH",
    "block": "{\"symbols\":[\"section\"],\"statements\":[[4,\"each\",[[20,[\"sortedSections\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"section-container\"],[7],[0,\"\\n\"],[4,\"if\",[[19,1,[\"bidirectional\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,\"section-editor-bidirectional\",null,[[\"quote\",\"section\",\"delete\",\"enable\",\"disable\"],[[20,[\"quote\"]],[19,1,[]],[25,\"action\",[[19,0,[]],\"delete\"],null],[25,\"action\",[[19,0,[]],\"enable\"],null],[25,\"action\",[[19,0,[]],\"disable\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[25,\"section-editor\",null,[[\"section\",\"delete\",\"enable\",\"disable\",\"sectionKeys\"],[[19,1,[]],[25,\"action\",[[19,0,[]],\"delete\"],null],[25,\"action\",[[19,0,[]],\"enable\"],null],[25,\"action\",[[19,0,[]],\"disable\"],null],[20,[\"sectionKeys\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[8],[0,\"\\n\"],[4,\"if\",[[19,1,[\"children\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"section-container\",null,[[\"quote\",\"sections\",\"delete\",\"enable\",\"disable\",\"sectionKeys\"],[[20,[\"quote\"]],[19,1,[\"children\"]],[25,\"action\",[[19,0,[]],\"delete\"],null],[25,\"action\",[[19,0,[]],\"enable\"],null],[25,\"action\",[[19,0,[]],\"disable\"],null],[20,[\"sectionKeys\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/section-container.hbs"
    }
  });

  _exports.default = _default;
});