define("cb-ui/routes/wizard/confirm", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    model: function model(obj, transition) {
      var cbStore = this.get('cbStore');
      return Ember.RSVP.hash({
        services: cbStore.findAllWrapper('serviceStandard', 'mpttOrder'),
        industries: cbStore.findAllWrapper('industryStandard', 'mpttOrder'),
        quote: this.store.find('quote', transition.params.wizard.quote_uuid)
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var quote = model.quote;
      this.store.query('quote-consultant', {
        quote_id: quote.get('id')
      }).then(function (quoteConsultants) {
        controller.set('quoteConsultants', quoteConsultants);
      });
      quote.get('projectGoals').then(function (projectGoals) {
        var localProjectGoals = new Ember.A();
        var projectGoalsOrder = JSON.parse(quote.get('projectGoalsOrder')) || [];

        if (projectGoalsOrder) {
          projectGoalsOrder.forEach(function (pgId) {
            var projectGoal = projectGoals.findBy('id', pgId.toString());

            if (projectGoal) {
              localProjectGoals.addObject(projectGoal);
            }
          });
        }

        controller.set('localProjectGoals', localProjectGoals);
      });
      controller.set('quote', model.quote);
      controller.set('isShowingStartRfp', false);
      controller.set('isShowingEmailModal', false);
      controller.set('isShowingModal', false);
    }
  });

  _exports.default = _default;
});