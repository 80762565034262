define("cb-ui/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kNXwMZRw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"loading\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"loading-overlay\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"loading-overlay-image-container\"],[7],[0,\"\\n            \"],[6,\"img\"],[9,\"src\",\"images/loading.gif\"],[9,\"alt\",\"Loading\"],[9,\"class\",\"m-t-xxl\"],[7],[8],[0,\"\\n            \"],[6,\"h3\"],[9,\"class\",\"loading-overlay-text\"],[7],[1,[25,\"t\",[\"loadingText\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[18,\"outlet\"],false],[0,\"\\n\"],[1,[25,\"ember-notify\",null,[[\"messageStyle\",\"closeAfter\"],[\"bootstrap\",\"8000\"]]],false],[0,\"\\n\"],[1,[25,\"loading-slider\",null,[[\"isLoading\",\"duration\",\"color\"],[[20,[\"loading\"]],250,\"#065EC7\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/application.hbs"
    }
  });

  _exports.default = _default;
});