define("cb-ui/controllers/app/account/index", ["exports", "cb-ui/config/environment", "ember-ajax"], function (_exports, _environment, _emberAjax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['returnToProject'],
    returnToProject: null,
    emailSent: null,
    notify: Ember.inject.service(),
    user: null,
    salutations: new Ember.A(),
    academicTitles: new Ember.A(),
    actions: {
      saveChanges: function saveChanges() {
        var _this = this;

        var user = this.get('user');

        if (user.get('website')) {
          if (!(user.get('website').startsWith('http://') || user.get('website').startsWith('https://'))) {
            user.set('website', 'https://' + user.get('website'));
          }
        }

        user.save({
          adapterOptions: {
            complete_profile: true
          }
        }).then(function (savedUser) {
          // we reload the user to update the company relation
          savedUser.reload();

          var returnToProject = _this.get('returnToProject');

          if (returnToProject) {
            history.back();
          }

          _this.get('notify').success(_this.get('intl').t('notifyMessages.profileWasUpdated').toString());
        }).catch(function () {
          _this.get('notify').error(_this.get('intl').t('notifyMessages.checkProfileMandatoryFields').toString());
        });
      },
      resendValidationEmail: function resendValidationEmail() {
        var _this2 = this;

        var url = _environment.default.backendAPI + '/api/send-validation-email/';
        (0, _emberAjax.default)(url, {
          type: 'POST',
          headers: {
            'Authorization': 'Bearer ' + this.get('session.data.authenticated.token')
          }
        }).then(function () {
          _this2.set('emailSent', true);
        });
      }
    }
  });

  _exports.default = _default;
});