define("cb-ui/components/client-statistics", ["exports", "cb-ui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    tagName: '',
    statisticsData: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      var ajax = this.get('ajax');
      ajax.post(_environment.default.backendAPI + '/api/dashboard-statistics-data/', {
        headers: {
          'Authorization': 'Bearer ' + this.get('session.data.authenticated.token')
        }
      }).then(function (response) {
        _this.set('statisticsData', response);
      }).catch(function () {
        _this.get('notify').error(_this.get('intl').t('notifyMessages.projectStatisticsNotLoaded').toString());
      });
    }
  });

  _exports.default = _default;
});