define("cb-ui/routes/app/account/index", ["exports", "cb-ui/mixins/scrollbar-reset"], function (_exports, _scrollbarReset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollbarReset.default, {
    model: function model() {
      return new Ember.RSVP.hash({
        user: this.modelFor('app.account'),
        salutations: this.store.findAll('salutation'),
        academicTitles: this.store.findAll('academic-title'),
        countries: this.store.findAll('country')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.user);

      controller.set('user', model.user);
      controller.set('salutations', model.salutations);
      controller.set('academicTitles', model.academicTitles);
      controller.set('countries', model.countries);
    },
    actions: {
      willTransition: function willTransition(transition) {
        this.controller.set('quoteId', null);

        this._super(transition);
      }
    }
  });

  _exports.default = _default;
});