define("cb-ui/templates/components/ember-table/financial-total-project-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WBNgAWy7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"or\",[[20,[\"cellValue\",\"proposal\",\"isIncludedInEvaluation\"]],[25,\"eq\",[[20,[\"cellValue\",\"proposal\",\"status\"]],\"dismissed\"],null]],null]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"m-t m-b\"],[7],[0,\"\\n      \"],[6,\"h3\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"cellValue\",\"transitions\",\"transitions\"]]],null,{\"statements\":[[0,\"          \"],[1,[25,\"format-number\",[[20,[\"cellValue\",\"transitions\",\"transitions\",\"0\",\"old_value\"]]],[[\"maximumFractionDigits\"],[0]]],false],[0,\" \"],[1,[20,[\"cellValue\",\"proposal\",\"quote\",\"currency\",\"isoCurrencyCode\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[25,\"format-number\",[[20,[\"cellValue\",\"proposal\",\"financialQuotationData\",\"totalProjectVolumeValue\"]]],[[\"maximumFractionDigits\"],[0]]],false],[0,\" \"],[1,[20,[\"cellValue\",\"proposal\",\"quote\",\"currency\",\"isoCurrencyCode\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/ember-table/financial-total-project-cell.hbs"
    }
  });

  _exports.default = _default;
});