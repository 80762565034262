define("cb-ui/routes/app/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      // this.store.unloadAll('quote');  // TODO: find out the reason we used unload ?? anonymous quote
      this.transitionToDashboard();
    },
    model: function model() {},
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },
    transitionToDashboard: function transitionToDashboard() {
      var _this = this;

      this.get('sessionContext.currentUser').then(function (user) {
        if (user.get('isStaff')) {
          _this.transitionTo('app.dashboard.agent');
        } else if (user.get('isConsultant')) {
          _this.transitionTo('app.dashboard.consultant');
        } else if (user.get('isClient')) {
          _this.transitionTo('app.dashboard.client');
        } else {
          _this.transitionTo('external-evaluation');
        }
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (transition.targetName.indexOf('app.dashboard') >= 0) {
          this.transitionToDashboard();
        } else {
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});