define("cb-ui/templates/components/ember-table/company-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DRZvt+Dp",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"style\",\"height:32px; width:32px; display:inline-block\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"company\",\"logo\"]]],null,{\"statements\":[[0,\"    \"],[6,\"img\"],[10,\"src\",[26,[[20,[\"company\",\"logo\"]]]]],[9,\"alt\",\"consultant logo\"],[9,\"height\",\"32\"],[9,\"width\",\"32\"],[9,\"class\",\"m-r-xs\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"company\",\"companyNameAlias\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[7],[1,[20,[\"company\",\"companyNameAlias\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[7],[1,[20,[\"company\",\"companyName\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/ember-table/company-info.hbs"
    }
  });

  _exports.default = _default;
});