define("cb-ui/components/page-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    title: null,
    classNames: ['ibox-title', 'text-notransform'],
    showInfoIcon: null,
    currentStep: 0,
    quote: null,
    iconLinkEnabled: false,
    actions: {
      showCommentModal: function showCommentModal() {
        this.sendAction('showCommentModal');
      }
    }
  });

  _exports.default = _default;
});