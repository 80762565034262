define("cb-ui/serializers/base-criteria-evaluation-group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONAPISerializer = _emberData.default.JSONAPISerializer;

  var _default = JSONAPISerializer.extend({
    attrs: {
      baseCriteriaEvaluations: {
        serialize: 'records',
        deserialize: 'records'
      },
      template: {
        serialize: 'ids'
      }
    }
  });

  _exports.default = _default;
});