define("cb-ui/utils/download-file", ["exports", "file-saver"], function (_exports, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = downloadFile;

  function downloadFile(url, fileName) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    xhr.onload = function (e) {
      if (this.status == 200) {
        _fileSaver.default.saveAs(this.response, fileName);
      }
    };

    xhr.send();
    return true;
  }
});