define("cb-ui/templates/components/status-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gSaPofuD",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",[26,[\"label \",[25,\"if\",[[25,\"eq\",[[20,[\"status\"]],\"projectStatuses.draft\"],null],\"label-info\",[25,\"if\",[[25,\"eq\",[[20,[\"status\"]],\"projectStatuses.sent\"],null],\"label-primary\",[25,\"if\",[[25,\"eq\",[[20,[\"status\"]],\"projectStatuses.requested\"],null],\"label-warning\",[25,\"if\",[[25,\"eq\",[[20,[\"status\"]],\"projectStatuses.onHold\"],null],\"label-on-hold\",[25,\"if\",[[25,\"or\",[[25,\"eq\",[[20,[\"status\"]],\"projectStatuses.cancelled\"],null],[25,\"eq\",[[20,[\"status\"]],\"projectStatuses.rejected\"],null]],null],\"label-danger\",\"label-success\"],null]],null]],null]],null]],null]]]],[7],[0,\"\\n    \"],[1,[25,\"t\",[[20,[\"status\"]]],null],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/status-label.hbs"
    }
  });

  _exports.default = _default;
});