define("cb-ui/components/client-consultant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    proposals: null,
    selectedProposal: null,
    actions: {
      changeSelectedProposal: function changeSelectedProposal(proposal) {
        if (proposal === this.get('selectedProposal')) {
          this.set('selectedProposal', null);
        } else {
          this.set('selectedProposal', proposal);
        }
      }
    }
  });

  _exports.default = _default;
});