define("cb-ui/services/profile-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    shouldShowAlert: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('shouldShowAlert', true);
    },
    alertShown: function alertShown() {
      return this.set('shouldShowAlert', false);
    },
    showAlert: function showAlert() {
      return this.get('shouldShowAlert');
    }
  });

  _exports.default = _default;
});