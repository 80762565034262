define("cb-ui/components/section-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['section-editor'],
    section: null,
    notify: Ember.inject.service('notify'),
    isEditing: false,
    editor: null,
    //tinymce instance
    sectionKeys: null,
    actions: {
      'delete': function _delete() {
        this.attrs.delete(this.get('section'));
      },
      disable: function disable() {
        this.attrs.disable(this.get('section'));
      },
      enable: function enable() {
        this.attrs.enable(this.get('section'));
      },
      edit: function edit() {
        this.set('isEditing', true);
      },
      closeEdit: function closeEdit() {
        var _this = this;

        var editor = this.get('editor');
        this.set('section.content', editor.getContent());
        Ember.run.next(function () {
          _this.get('section').save().then(function () {
            _this.set('isEditing', false);
          }).catch(function () {
            _this.get('notify').error(_this.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
          });
        });
      },
      cancelEdit: function cancelEdit() {
        this.set('isEditing', false);
      },
      saveSection: function saveSection() {
        var _this2 = this;

        this.get('section').save().catch(function () {
          _this2.get('notify').error(_this2.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
        });
      }
    }
  });

  _exports.default = _default;
});