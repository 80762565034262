define("cb-ui/routes/app/management/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var user = this.modelFor('app.management');
      return new Ember.RSVP.hash({
        currentUser: user,
        company: user.get('company'),
        companyUsers: this.store.query('user', {
          company_id: user.get('company.id')
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('currentUser', model.currentUser);
      controller.set('company', model.company);
      var localCompanyUsers = new Ember.A();
      model.companyUsers.forEach(function (user) {
        localCompanyUsers.pushObject(user);
      });
      controller.set('companyUsers', localCompanyUsers);
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});