define("cb-ui/components/increase-decrease", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    property: null,
    disabled: false,
    minValue: 1,
    propertyObserver: Ember.observer('property', function () {
      var property = this.get('property');

      if (isNaN(property)) {
        this.set('property', 1);
      } else {
        if (property > 100) {
          this.set('property', 100);
        }
      }
    }),
    actions: {
      increaseValue: function increaseValue() {
        if (!this.get('disabled')) {
          var property = this.get('property');

          if (isNaN(property)) {
            this.set('property', 1);
          } else {
            this.set('property', parseInt(property) + 1);
          }
        }
      },
      decreaseValue: function decreaseValue() {
        if (!this.get('disabled')) {
          var property = this.get('property');

          if (isNaN(property)) {
            this.set('property', 1);
          } else {
            if (property > this.get('minValue')) {
              this.set('property', parseInt(property) - 1);
            }
          }
        }
      }
    }
  });

  _exports.default = _default;
});