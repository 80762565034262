define("cb-ui/initializers/local-storage-adapter", ["exports", "ember-local-storage/initializers/local-storage-adapter"], function (_exports, _localStorageAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _localStorageAdapter.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _localStorageAdapter.initialize;
    }
  });
});