define("cb-ui/routes/external-evaluation", ["exports", "cb-ui/utils/download-file", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _downloadFile, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(obj) {
      return {
        quoteId: obj.quoteId,
        evaluationType: obj.evaluationType
      };
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('quoteId', model.quoteId);
      controller.set('evaluationType', model.evaluationType);
    },
    actions: {
      newDocumentToken: function newDocumentToken(id) {
        this.store.findRecord('quote-document', id).then(function (res) {
          (0, _downloadFile.default)(res.data.file, res.get('fileName'));
        });
      }
    }
  });

  _exports.default = _default;
});