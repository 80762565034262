define("cb-ui/models/project-phase", ["exports", "ember-data", "cb-ui/utils/translate-property"], function (_exports, _emberData, _translateProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    projectPhaseEn: _emberData.default.attr('string'),
    projectPhaseDe: _emberData.default.attr('string'),
    projectPhase: (0, _translateProperty.default)('projectPhaseEn', 'projectPhaseDe'),
    key: _emberData.default.attr('string') // !! DO NOT ENABLE !! Many to many causes problems if serialized on both sides
    // quotes: DS.hasMany('quote', {async: true, inverse: 'projectPhases'})

  });

  _exports.default = _default;
});