define("cb-ui/models/criteria-evaluation-group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    title: attr('string'),
    type: attr('string'),
    order: attr('number', {
      defaultValue: 0
    }),
    evaluations: hasMany('evaluations', {
      async: true,
      inverse: 'group'
    }),
    quote: belongsTo('quote', {
      async: true
    })
  });

  _exports.default = _default;
});