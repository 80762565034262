define("cb-ui/templates/components/tinymce-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6jHJRrLp",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"textarea\",null,[[\"value\",\"rows\"],[[20,[\"value\"]],[25,\"if\",[[25,\"or\",[[20,[\"media\",\"isDesktop\"]],[20,[\"media\",\"isJumbo\"]]],null],15],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/tinymce-editor.hbs"
    }
  });

  _exports.default = _default;
});