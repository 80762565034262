define("cb-ui/controllers/recover-password", ["exports", "cb-ui/config/environment", "ember-ajax/errors"], function (_exports, _environment, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),
    validateEmail: function validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    actions: {
      checkEmail: function checkEmail() {
        var _this = this;

        var email = this.get('email');

        if (email && this.validateEmail(email)) {
          var ajax = this.get('ajax');
          ajax.post(_environment.default.backendAPI + '/api/recover-password/', {
            data: {
              email: email
            }
          }).then(function (response) {
            if (response && response.valid) {
              _this.set('emailChecked', true);
            } else {
              _this.get('notify').error(_this.get('intl').t('notifyMessages.invalidUser').toString());
            }
          }).catch(function (response) {
            if (response instanceof _errors.ForbiddenError) {
              _this.get('notify').error(_this.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
            } else if (response instanceof _errors.AjaxError) {
              _this.get('notify').error(_this.get('intl').t('notifyMessages.invalidUser').toString());
            }
          });
        } else {
          this.get('notify').error(this.get('intl').t('notifyMessages.provideValidEmail').toString());
        }
      }
    }
  });

  _exports.default = _default;
});