define("cb-ui/routes/app/rfp/management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('app.rfp');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var quote = model;
      controller.set('quote', quote);
      controller.loadBackendPermissions();
      quote.get('owner').then(function (owner) {
        owner.get('company').then(function (company) {
          company.get('companyUsers').then(function (companyUsers) {
            controller.set('companyUsers', companyUsers);
          });
        });
      });
    }
  });

  _exports.default = _default;
});