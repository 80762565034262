define("cb-ui/models/extra-company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    companyName: attr('string'),
    companyNameAlias: attr('string'),
    personOfContact: attr('string'),
    email: attr('string'),
    quote: attr('number'),
    extraConsultant: attr('boolean')
  });

  _exports.default = _default;
});