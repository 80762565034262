define("cb-ui/components/render-partial", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    partialKey: '',
    partialName: Ember.computed('partialKey', 'localStorage.language', function () {
      var partialKey = this.get('partialKey');
      var language = this.get('localStorage.language') || 'en';

      if (partialKey) {
        return partialKey + '-' + language;
      }

      return null;
    })
  });

  _exports.default = _default;
});