define("cb-ui/routes/app/rfp/cover-letter-choices", ["exports", "cb-ui/mixins/scrollbar-reset", "cb-ui/utils/download-file"], function (_exports, _scrollbarReset, _downloadFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollbarReset.default, {
    model: function model() {
      return Ember.RSVP.hash({
        currentUser: this.get('sessionContext.currentUser')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var quote = this.modelFor('app.rfp');
      controller.set('quote', quote);
      controller.set('quote.currentStep', 50);
      controller.set('currentUser', model.currentUser);
      quote.get('coverLetterDocument').then(function (coverLetter) {
        controller.set('localCoverLetterDocument', coverLetter);
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (this.get('controller.blockPrevious')) {
          transition.abort();
        } else {
          this._super(transition);
        }
      },
      newCoverLetterToken: function newCoverLetterToken(id) {
        this.store.findRecord('quote-cover-letter-document', id).then(function (res) {
          (0, _downloadFile.default)(res.data.file, res.get('fileName'));
        });
      }
    }
  });

  _exports.default = _default;
});