define("cb-ui/components/notifications-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notifications: Ember.inject.service(),
    actions: {
      markAllAsRead: function markAllAsRead() {
        this.get('notifications').markAllAsRead();
      }
    }
  });

  _exports.default = _default;
});