define("cb-ui/routes/app/project/proposal/financial-quotation", ["exports", "cb-ui/mixins/scrollbar-reset", "cb-ui/utils/download-file"], function (_exports, _scrollbarReset, _downloadFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollbarReset.default, {
    model: function model() {
      var _this = this;

      var cbStore = this.get('cbStore');
      var self = this;
      var quote = this.modelFor('app.project');
      var proposal = this.modelFor('app.project.proposal');
      var currentUser = this.get('sessionContext.currentUser');
      return Ember.RSVP.hash({
        projectPhases: cbStore.findAllWrapper('projectPhase', 'id'),
        proposalDocumentTypes: cbStore.findAllWrapper('proposalDocumentType', 'id'),
        currentUser: self.get('sessionContext.currentUser'),
        organizationWorkerRoles: quote.get('quoteCompany').then(function (company) {
          return _this.store.query('organization-worker-role', {
            company_id: company.get('id')
          });
        }),
        userProposalRoleList: this.store.query('user-proposal-role', {
          proposal_id: proposal.get('id'),
          user_id: currentUser.get('id')
        })
      });
    },
    setupController: function setupController(controller, model) {
      var _this2 = this;

      this.setProperties({
        analysisDaysInputDisabled: true,
        conceptDaysInputDisabled: true,
        implementationDaysInputDisabled: true,
        auditDaysInputDisabled: true
      });

      this._super(controller, model);

      var proposal = this.modelFor('app.project.proposal');
      var quote = this.modelFor('app.project');
      var self = this;
      controller.set('proposal', proposal);
      controller.set('quote', quote);
      controller.set('projectPhases', model.projectPhases);
      controller.set('totalProjectFeeIsValid', true);
      controller.set('currentUser', model.currentUser);
      controller.set('showUserStatusModal', false);
      controller.set('userStatus', null);
      controller.set('organizationWorkerRoles', model.organizationWorkerRoles);
      controller.set('isProposalSaving', false);

      if (proposal.get('timeMaterialsPaymentPattern')) {
        controller.set('timeMaterialsPaymentPattern', {
          value: proposal.get('timeMaterialsPaymentPattern'),
          label: "app.projectClient.evaluation.label.paymentOptions.".concat(proposal.get('timeMaterialsPaymentPattern').replace(/ /g, ''))
        });
      }

      if (proposal.get('fixedFeePaymentPattern')) {
        controller.set('fixedFeePaymentPattern', {
          value: proposal.get('fixedFeePaymentPattern'),
          label: "app.projectClient.evaluation.label.paymentOptions.".concat(proposal.get('fixedFeePaymentPattern').replace(/ /g, ''))
        });
      }

      controller.set('userProposalRole', model.userProposalRoleList.get('firstObject'));

      if (model.proposalDocumentTypes && model.proposalDocumentTypes.length) {
        controller.set('uploadedFileType', model.proposalDocumentTypes.get('firstObject'));
      } else {
        controller.set('uploadedFileType', null);
      }

      var quoteProjectPhasesPromise = new Ember.RSVP.Promise(function (resolve) {
        quote.get('projectPhases').then(function (quoteProjectPhases) {
          quoteProjectPhases.forEach(function (projectPhase) {
            if (proposal.get('isAccepted') || proposal.get('isOpened')) {
              self.set(projectPhase.get('key') + 'DaysInputDisabled', false);
            }
          });
          resolve();
        });
      });
      quoteProjectPhasesPromise.then(function () {
        controller.set('analysisDaysInputDisabled', _this2.get('analysisDaysInputDisabled'));
        controller.set('conceptDaysInputDisabled', _this2.get('conceptDaysInputDisabled'));
        controller.set('implementationDaysInputDisabled', _this2.get('implementationDaysInputDisabled'));
        controller.set('auditDaysInputDisabled', _this2.get('auditDaysInputDisabled'));
      });
      controller.loadProposalData(proposal);
    },
    actions: {
      willTransition: function willTransition(transition) {
        var proposal = this.controller.get('proposal');

        if (proposal.get('isDirty') && !confirm(this.get('intl').t('confirmMessages.unsavedProposalProgressLost').toString())) {
          transition.abort();
          return false;
        } else {
          proposal.rollbackAttributes();
          return true;
        }
      },
      newDocumentToken: function newDocumentToken(id) {
        this.store.findRecord('proposal-document', id).then(function (res) {
          (0, _downloadFile.default)(res.data.file, res.get('fileName'));
        });
      }
    }
  });

  _exports.default = _default;
});