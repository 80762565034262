define("cb-ui/controllers/app/project/client/configuration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routerService: Ember.inject.service('router'),
    notify: Ember.inject.service('notify'),
    intl: Ember.inject.service(),
    queryParams: ['technicalTemplateId', 'pitchTemplateId'],
    technicalTemplateId: null,
    pitchTemplateId: null,
    quote: null,
    currentUser: null,
    currentUserCompany: null,
    userIsQuoteAdmin: Ember.computed.equal('userQuoteRole.role', 'admin'),
    showWarningModal: false,
    //used for dropdowns
    technicalTemplatesOptions: null,
    pitchTemplatesOptions: null,
    //selected templates from dropdown
    technicalTemplate: null,
    pitchTemplate: null,
    //template groups data
    technicalTemplateGroups: new Ember.A(),
    pitchTemplateGroups: new Ember.A(),
    technicalTemplateDataLoaded: null,
    pitchTemplateDataLoaded: null,
    // flags for displaying dropdowns
    showTechnicalTemplatesDropdown: null,
    showPitchTemplatesDropdown: null,
    technicalQuoteCriteriaGroups: new Ember.A(),
    pitchQuoteCriteriaGroups: new Ember.A(),
    technicalType: 'technical',
    pitchType: 'pitch',
    criteriaEvaluationMax: 100,
    criteriaEvaluationMin: 0,
    sliderStep: 1,
    initialFinancialWeight: null,
    initialTechnicalWeight: null,
    initialPitchWeight: null,
    financialSlider: null,
    technicalSlider: null,
    warnings: [],
    pitchSlider: null,
    pips: Ember.computed('media.isMobile', function () {
      return {
        mode: 'positions',
        values: this.get('media.isMobile') ? [0, 50, 100] : [0, 20, 40, 60, 80, 100],
        density: this.get('media.isMobile') ? 10 : 2
      };
    }),
    updateSliders: function updateSliders(initialValue, newValue, updatedSlider, sliderOne, sliderTwo) {
      var updateWithValue, updateWithValueTwo;

      if (sliderOne.isDisabled && sliderTwo.isDisabled) {
        console.log('slider one dis', sliderOne.isDisabled);
        console.log('slider twp dis', sliderTwo.isDisabled);
        updatedSlider.set(initialValue);
      } else if (sliderOne.isDisabled) {
        newValue = Math.min(100 - sliderOne.get(), newValue);

        if (newValue === 0) {
          updateWithValueTwo = initialValue;
        } else {
          updateWithValueTwo = initialValue - newValue;
        }

        updatedSlider.set(newValue);
        sliderTwo.set(sliderTwo.get() + updateWithValueTwo);
      } else if (sliderTwo.isDisabled) {
        newValue = Math.min(100 - sliderTwo.get(), newValue);

        if (newValue === 0) {
          updateWithValue = initialValue;
        } else {
          updateWithValue = initialValue - newValue;
        }

        updatedSlider.set(newValue);
        sliderOne.set(sliderOne.get() + updateWithValue);
      } else {
        if (initialValue !== newValue) {
          if (newValue === 0) {
            updateWithValue = updateWithValueTwo = initialValue / 2;
          } else {
            updateWithValue = updateWithValueTwo = (initialValue - newValue) / 2;
          }

          if (updateWithValue % 1 !== 0) {
            updateWithValueTwo += parseInt(updateWithValue - Math.floor(updateWithValue));
            updateWithValue = Math.floor(updateWithValue);
          }

          var initialValueSliderOne = sliderOne.get();
          var initialValueSliderTwo = sliderTwo.get();
          var newValueSliderOne = initialValueSliderOne + updateWithValue;
          var newValueSliderTwo = initialValueSliderTwo + updateWithValueTwo;

          if (newValueSliderOne < 0 && newValueSliderTwo < 0) {
            newValue = 100;
            newValueSliderOne = 0;
            newValueSliderTwo = 0;
          } else if (newValueSliderOne < 0) {
            newValueSliderTwo += newValueSliderOne;
            newValueSliderOne = 0;
          } else if (newValueSliderTwo < 0) {
            newValueSliderOne += newValueSliderTwo;
            newValueSliderTwo = 0;
          }

          updatedSlider.set(newValue);
          sliderOne.set(newValueSliderOne);
          sliderTwo.set(newValueSliderTwo);
        }
      }
    },
    updateSliderValues: function updateSliderValues(updatedProperty, value) {
      var modifiedProperty = 'quote.' + updatedProperty;
      var initialValue = this.get(modifiedProperty);
      var financialSlider = this.get('financialSlider');
      var technicalSlider = this.get('technicalSlider');
      var pitchSlider = this.get('pitchSlider');

      if (updatedProperty === 'financialWeight') {
        this.updateSliders(initialValue, value, financialSlider, technicalSlider, pitchSlider);
      } else if (updatedProperty === 'technicalWeight') {
        this.updateSliders(initialValue, value, technicalSlider, financialSlider, pitchSlider);
      } else if (updatedProperty === 'pitchWeight') {
        this.updateSliders(initialValue, value, pitchSlider, financialSlider, technicalSlider);
      }

      this.set('quote.financialWeight', financialSlider.get());
      this.set('quote.technicalWeight', technicalSlider.get());
      this.set('quote.pitchWeight', pitchSlider.get());
    },
    serializeData: function serializeData(template) {
      var dataJSON = {
        template: null,
        template_title: 'Copy of - ' + template.get('title'),
        template_type: template.get('type'),
        groups: []
      };
      var groups = template.hasMany('baseCriteriaEvaluationGroups').value();
      var groupsJSON = [];
      groups.forEach(function (group) {
        var criteriaEvaluations = group.hasMany('baseCriteriaEvaluations').value();
        var criteriaEvaluationsJSON = [];
        criteriaEvaluations.forEach(function (ca) {
          var attributes = {};

          var snapshotAttributes = ca._createSnapshot()._attributes;

          for (var key in snapshotAttributes) {
            attributes[key.underscore()] = snapshotAttributes[key];
          }

          criteriaEvaluationsJSON.push(attributes);
        });
        groupsJSON.push({
          title: group.get('title'),
          order: group.get('order'),
          'base_criteria_evaluations': criteriaEvaluationsJSON
        });
      });
      dataJSON.groups = groupsJSON;
      return dataJSON;
    },
    actions: {
      closeWarningModal: function closeWarningModal() {
        this.set('showWarningModal', false);
        this.set('warnings', []);
      },
      checkForWarnings: function checkForWarnings() {
        if (this.get('initialFinancialWeight') != this.get('quote.financialWeight') || this.get('initialTechnicalWeight') != this.get('quote.technicalWeight') || this.get('initialPitchWeight') != this.get('quote.pitchWeight')) {
          this.warnings.push(this.intl.t('app.partials.evaluationData.weightWarning'));
        }

        if (this.get('technicalTemplate')) {
          this.warnings.push(this.intl.t('app.partials.evaluationData.technicalTemplateWarning'));
        }

        if (this.get('pitchTemplate')) {
          this.warnings.push(this.intl.t('app.partials.evaluationData.pitchTemplateWarning'));
        }

        if (this.get('warnings.length')) {
          this.set('showWarningModal', true);
        }
      },
      selectTemplate: function selectTemplate() {
        var _this = this;

        var quote = this.get('quote');
        var technicalTemplate = this.get('technicalTemplate');
        var pitchTemplate = this.get('pitchTemplate');
        var saveOptions = {
          adapterOptions: {}
        };
        if (technicalTemplate) saveOptions.adapterOptions['technical_id'] = technicalTemplate.get('id');
        if (pitchTemplate) saveOptions.adapterOptions['pitch_id'] = pitchTemplate.get('id');
        return quote.save(saveOptions).then(function () {
          _this.set('technicalTemplateId', null);

          _this.set('pitchTemplateId', null);
        }).catch(function (response) {
          if (response.errors && response.errors[0].detail === _this.get('intl').t('invalidCriteriaEvaluationWeight').toString()) {
            _this.get('notify').error(_this.get('intl').t('invalidCriteriaEvaluationWeight').toString());
          }
        }).finally(function () {
          _this.set('showTechnicalTemplatesDropdown', false);

          _this.set('showPitchTemplatesDropdown', false);

          _this.set('showWarningModal', false);

          _this.set('technicalTemplate', null);

          _this.set('pitchTemplate', null);

          _this.set('warnings', []);

          _this.send('refreshRoute');
        });
      },
      previous: function previous() {
        this.transitionToRoute('app.rfp.project-details');
      },
      updateValues: function updateValues(updatedProperty, value) {
        this.updateSliderValues(updatedProperty, value);
      },
      closeCoverLetterModal: function closeCoverLetterModal() {
        this.set('showCoverLetterQuestion', false);
        this.send('refreshRoute');
      },
      defaultCoverLetter: function defaultCoverLetter() {
        this.set('quote.uploadedCoverLetter', false);
        this.transitionToRoute('app.rfp.visual-editor');
      },
      customCoverLetter: function customCoverLetter() {
        this.set('quote.uploadedCoverLetter', true);
        this.transitionToRoute('app.rfp.custom-cover-letter');
      },
      updateTechnicalTemplate: function updateTechnicalTemplate(template) {
        var _this2 = this;

        var technicalTemplate = this.get('technicalTemplate');
        this.set('technicalTemplateDataLoaded', false);

        if (!technicalTemplate || technicalTemplate.get('id') !== template.get('id')) {
          this.set('technicalTemplate', template);
          this.store.query('base-criteria-evaluation-group', {
            include: 'base_criteria_evaluations',
            template_id: template.get('id')
          }).then(function (groupsData) {
            _this2.set('technicalTemplateDataLoaded', true);

            _this2.set('technicalTemplateGroups', groupsData);
          }).catch(function () {
            _this2.get('notify').error(_this2.get('intl').t('notifyMessages.errorOccurredReloadApp').toString());
          });
        }
      },
      dropdownTemplateChanged: function dropdownTemplateChanged(selectedTemplate) {
        var _this3 = this;

        var templateToSet = null;
        var selectedTemplateType = selectedTemplate.get('type');
        var isTechnical = false;
        var isPitch = false;

        if (selectedTemplateType === 'technical_proposal') {
          templateToSet = this.get('technicalTemplate');
          this.set('technicalTemplateDataLoaded', false);
          isTechnical = true;
        } else if (selectedTemplateType === 'pitch') {
          templateToSet = this.get('pitchTemplate');
          this.set('pitchTemplateDataLoaded', false);
          isPitch = true;
        }

        if (!templateToSet || templateToSet.get('id') !== selectedTemplate.get('id')) {
          this.store.query('base-criteria-evaluation-group', {
            include: 'base_criteria_evaluations',
            template_id: selectedTemplate.get('id')
          }).then(function (groupsData) {
            if (isTechnical) {
              _this3.setProperties({
                technicalTemplate: selectedTemplate,
                technicalTemplateDataLoaded: true,
                technicalTemplateGroups: groupsData
              });
            } else if (isPitch) {
              _this3.setProperties({
                pitchTemplate: selectedTemplate,
                pitchTemplateDataLoaded: true,
                pitchTemplateGroups: groupsData
              });
            }
          }).catch(function () {
            _this3.get('notify').error(_this3.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
          });
        }
      },
      editTemplate: function editTemplate(template) {
        var _this4 = this;

        this.get('quote').save().then(function () {
          _this4.transitionToRoute('app.templates.edit', template.get('id'), {
            queryParams: {
              quoteId: _this4.get('quote.id'),
              technicalTemplateId: _this4.get('technicalTemplate.id'),
              pitchTemplateId: _this4.get('pitchTemplate.id'),
              projectConfiguration: true
            }
          });
        }).catch(function () {
          _this4.get('notify').error(_this4.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
        });
      },
      cloneTemplate: function cloneTemplate(template) {
        var _this5 = this;

        var technicalTemplateId = this.get('technicalTemplate.id');
        var pitchTemplateId = this.get('pitchTemplate.id');
        this.get('quote').save().then(function () {
          template.bulkSave(_this5.serializeData(template)).then(function (response) {
            var cloneId = response.data.template;
            var queryTechnicalTemplateId = template.get('id') === technicalTemplateId ? cloneId : technicalTemplateId;
            var queryPitchTemplateId = template.get('id') === pitchTemplateId ? cloneId : pitchTemplateId;

            _this5.get('notify').success(_this5.get('intl').t('notifyMessages.templateClonedSuccess').toString());

            _this5.transitionToRoute('app.templates.edit', cloneId, {
              queryParams: {
                quoteId: _this5.get('quote.id'),
                technicalTemplateId: queryTechnicalTemplateId,
                pitchTemplateId: queryPitchTemplateId,
                projectConfiguration: true
              }
            }).then(function () {
              _this5.get('notify').success(_this5.get('intl').t('notifyMessages.redirectTemplateSuccess').toString());
            }).catch(function () {
              _this5.get('notify').error(_this5.get('intl').t('notifyMessages.redirectTemplateError').toString());
            });
          }).catch(function () {
            _this5.get('notify').error(_this5.get('intl').t('notifyMessages.templateClonedError').toString());
          });
        });
      },
      showDropdown: function showDropdown(dataType) {
        if (dataType === this.get('technicalType')) {
          this.set('showTechnicalTemplatesDropdown', true);
        } else if (dataType === this.get('pitchType')) {
          this.set('showPitchTemplatesDropdown', true);
        }
      },
      newTemplate: function newTemplate(templateType) {
        var _this6 = this;

        this.get('quote').save().then(function () {
          var isPitch = templateType === _this6.get('pitchType');

          var isTechnical = templateType === _this6.get('technicalType');

          _this6.store.createRecord('template', {
            title: 'New template ',
            company: _this6.get('currentUserCompany'),
            type: templateType === _this6.get('pitchType') ? templateType : 'technical_proposal'
          }).save().then(function (template) {
            var pitchTemplateId = _this6.get('pitchTemplate.id');

            var technicalTemplateId = _this6.get('technicalTemplate.id');

            if (isPitch) {
              pitchTemplateId = template.get('id');
            }

            if (isTechnical) {
              technicalTemplateId = template.get('id');
            }

            _this6.transitionToRoute('app.templates.edit', template.get('id'), {
              queryParams: {
                quoteId: _this6.get('quote.id'),
                technicalTemplateId: technicalTemplateId,
                pitchTemplateId: pitchTemplateId,
                projectConfiguration: true
              }
            }).then(function () {
              _this6.get('notify').success(_this6.get('intl').t('notifyMessages.redirectTemplateSuccess').toString());
            }).catch(function () {
              _this6.get('notify').error(_this6.get('intl').t('notifyMessages.redirectTemplateError').toString());
            });
          });
        }).catch(function () {
          _this6.get('notify').error(_this6.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
        });
      }
    }
  });

  _exports.default = _default;
});