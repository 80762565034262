define("cb-ui/controllers/app/rfp/cover-letter-choices", ["exports", "cb-ui/config/environment", "cb-ui/utils/download-file"], function (_exports, _environment, _downloadFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    localCoverLetterDocument: null,
    quote: null,
    blockPrevious: false,
    showEditCoverLetterModal: false,
    showCreateNewCoverLetterModal: false,
    showMissingCoverLetterAlert: false,
    actions: {
      editCoverLetter: function editCoverLetter() {
        this.set('showEditCoverLetterModal', true);
      },
      closeEditCoverLetterModal: function closeEditCoverLetterModal() {
        this.set('showEditCoverLetterModal', false);
      },
      closeMissingCoverLetterAlert: function closeMissingCoverLetterAlert() {
        this.set('showMissingCoverLetterAlert', false);
      },
      editExistingCoverLetter: function editExistingCoverLetter() {
        var quote = this.get('quote');

        if (quote.get('uploadedCoverLetter')) {
          this.transitionToRoute('app.rfp.custom-cover-letter');
        } else {
          this.transitionToRoute('app.rfp.visual-editor');
        }

        this.set('showEditCoverLetterModal', false);
      },
      createNewCoverLetter: function createNewCoverLetter() {
        this.set('showCreateNewCoverLetterModal', true);
        this.set('showEditCoverLetterModal', false);
      },
      closeCreateNewCoverLetterModal: function closeCreateNewCoverLetterModal() {
        this.set('showCreateNewCoverLetterModal', false);
      },
      newCustomCoverLetter: function newCustomCoverLetter() {
        var _this = this;

        var quote = this.get('quote');
        quote.set('uploadedCoverLetter', true);
        quote.set('coverLetterDocument', null);
        quote.set('coverLetterTypeWasChosen', false);
        this.set('showCustomCoverLetterSpinner', true);
        quote.save().then(function () {
          _this.transitionToRoute('app.rfp.custom-cover-letter');

          _this.set('showCreateNewCoverLetterModal', false);

          _this.set('showCustomCoverLetterSpinner', false);
        });
      },
      newDefaultCoverLetter: function newDefaultCoverLetter() {
        var _this2 = this;

        var quote = this.get('quote');
        quote.set('uploadedCoverLetter', false);
        quote.set('coverLetterDocument', null);
        quote.set('coverLetterTypeWasChosen', false);
        quote.set('sections', new Ember.A());
        this.set('showDefaultCoverLetterSpinner', true);
        quote.save().then(function () {
          _this2.transitionToRoute('app.rfp.visual-editor');

          _this2.set('showCreateNewCoverLetterModal', false);

          _this2.set('showDefaultCoverLetterSpinner', false);
        });
      },
      defaultCoverLetter: function defaultCoverLetter() {
        this.set('quote.uploadedCoverLetter', false);
        this.transitionToRoute('app.rfp.visual-editor');
      },
      customCoverLetter: function customCoverLetter() {
        this.set('quote.uploadedCoverLetter', true);
        this.transitionToRoute('app.rfp.custom-cover-letter');
      },
      downloadRfpPDF: function downloadRfpPDF(url) {
        (0, _downloadFile.default)(url, 'Cover letter.pdf');
      },
      previous: function previous() {
        if (!this.get('blockPrevious')) {
          this.transitionToRoute('app.rfp.criteria-evaluation');
        }
      },
      next: function next() {
        if (!this.get('quote.coverLetterTypeWasChosen')) {
          this.set('showMissingCoverLetterAlert', true);
          return;
        }

        window.location.href = "".concat(_environment.default.CANONICAL_DOMAIN_NEXT, "/project/").concat(this.get('quote').id, "/review-and-submit");
      },
      confirmNext: function confirmNext() {
        window.location.href = "".concat(_environment.default.CANONICAL_DOMAIN_NEXT, "/project/").concat(this.get('quote').id, "/review-and-submit");
      }
    }
  });

  _exports.default = _default;
});