define("cb-ui/services/notifications", ["exports", "cb-ui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    oldCount: null,
    sessionContext: Ember.inject.service(),
    notify: Ember.inject.service(),
    isAuthenticated: false,
    isAuthenticatedObserver: Ember.observer('sessionContext', function () {
      var _this = this;

      this.get('sessionContext.currentUser').then(function (currentUser) {
        if (currentUser.get('id')) {
          _this.set('isAuthenticated', true);
        }
      });
    }),
    init: function init() {
      var _this2 = this;

      if (this.get('isAuthenticated')) {
        this.notifications();
      } else {
        Ember.run.later(function () {
          _this2.notifications();
        }, 1000);
      }
    },
    // notificationsList: new A(),
    // sortProperties: ['timestamp:desc'],
    // sortedNotificationsList: sort('notificationsList', 'sortProperties'),
    // unreadNotifications: filterBy('sortedNotificationsList', 'unread', true),
    // readNotifications: filterBy('sortedNotificationsList', 'unread', false),
    unreadNotificationsCount: null,
    notifications: function notifications() {
      var _this3 = this;

      var self = this;
      var ajax = this.get('ajax');
      ajax.request(_environment.default.backendAPI + '/api/unread-notifications-count/', {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(window.localStorage.getItem('ember_simple_auth:session')).authenticated.token
        }
      }).then(function (response) {
        var newCount = response['unread_notifications_count'];

        _this3.set('unreadNotificationsCount', newCount);

        _this3.set('oldCount', newCount);

        Ember.run.later(function () {
          self.notifications();
        }, 10000);
      }); // const self = this;
      // this.get('store').findAll('notification').then(notifications => {
      //   const oldCount = this.get('oldCount');
      //   this.set('notificationsList', notifications);
      //   const newCount = this.get('notificationsList.length');
      //   if (oldCount && newCount && (oldCount < newCount)) {
      //     this.get('notify').info(this.get('intl').t('notifyMessages.newNotifications').toString());
      //   }
      //   this.set('oldCount', newCount);
      //   run.later(function() {
      //     self.notifications();
      //   }, 10000);
      // });
    },
    markAllAsRead: function markAllAsRead() {
      var unreadNotifications = this.get('unreadNotifications').slice();
      unreadNotifications.forEach(function (notification) {
        notification.set('unread', false);
        notification.save();
      });
    }
  });

  _exports.default = _default;
});