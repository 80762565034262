define("cb-ui/templates/app/org-settings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IKTrx5/I",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"col-md-12\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ibox white-bg\"],[7],[0,\"\\n\\n      \"],[6,\"div\"],[9,\"class\",\"ibox-title p-t-xs\"],[7],[0,\"\\n        \"],[6,\"div\"],[7],[0,\"\\n          \"],[6,\"h5\"],[9,\"class\",\"projects-title m-sm\"],[7],[1,[25,\"t\",[\"app.orgSettings.index.orgSettings\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\\n      \"],[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"app.org-settings.worker-roles.index\"],null,{\"statements\":[[0,\"        \"],[6,\"button\"],[9,\"class\",\"btn btn-primary btn-sm \"],[7],[0,\" \"],[2,\"add action \"],[0,\"\\n          \"],[1,[25,\"fa-icon\",[\"plus\"],null],false],[0,\" \"],[1,[25,\"t\",[\"app.orgSettings.index.workerRoles\"],null],false],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"app.org-settings.cover-letter-settings.index\"],null,{\"statements\":[[0,\"          \"],[6,\"button\"],[9,\"class\",\"btn btn-primary btn-sm \"],[7],[0,\" \"],[2,\"add action \"],[0,\"\\n            \"],[1,[25,\"fa-icon\",[\"plus\"],null],false],[0,\" \"],[1,[25,\"t\",[\"app.orgSettings.index.coverLetterSettings\"],null],false],[0,\"\\n          \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/app/org-settings/index.hbs"
    }
  });

  _exports.default = _default;
});