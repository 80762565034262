define("cb-ui/controllers/request-form", ["exports", "ember-changeset", "ember-changeset-validations", "cb-ui/validations/quote", "ember-intl", "moment", "ember-local-storage"], function (_exports, _emberChangeset, _emberChangesetValidations, _quote, _emberIntl, _moment, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    queryParams: ['c'],
    c: 'demo',
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    appLanguage: Ember.computed.alias('localStorage.language'),
    isSendingRequest: false,
    setupChangeset: function setupChangeset() {
      this.get('quote').set('companyAlias', this.get('c'));
      Ember.set(this, 'quoteChangeset', new _emberChangeset.default(this.get('quote'), (0, _emberChangesetValidations.default)(_quote.default), _quote.default));
    },
    componentHTML: null,
    initComponentHTML: Ember.on('init', function () {
      this.set('componentHTML', this.get('languageHTML'));
    }),
    languageHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.mainLanguage'),
    actions: {
      handleFormErrors: function handleFormErrors() {
        Ember.run.next(function () {
          if (Ember.$('.has-error') && Ember.$('.has-error').length) {
            Ember.$("html, body").animate({
              scrollTop: Ember.$('.has-error').offset().top - 100
            });
          }
        });
      },
      submitAction: function submitAction(changeset) {
        var _this = this;

        if (this.get('isSendingRequest')) {
          return;
        }

        this.set('isSendingRequest', true);
        var quoteProjectGoals = this.get('localQuoteProjectGoals');
        var quoteServiceProviders = this.get('localServiceProviders');

        if (quoteProjectGoals.length === 0) {
          Ember.run.next(function () {
            if (Ember.$('.has-error') && Ember.$('.has-error').length) {
              Ember.$('html, body').animate({
                scrollTop: Ember.$('.has-error').offset().top - 100
              });
            }
          });
          return;
        }

        var oldStartDate = changeset.get('startDate');
        var oldEndDate = changeset.get('endDate');
        changeset.set('quoteProjectGoals', quoteProjectGoals);
        changeset.set('quoteServiceProviders', quoteServiceProviders);
        var quoteRelatedPromises = [];
        quoteProjectGoals.forEach(function (item) {
          quoteRelatedPromises.push(item.save({
            adapterOptions: {
              validate: true
            }
          }));
        });
        quoteServiceProviders.forEach(function (item) {
          quoteRelatedPromises.push(item.save({
            adapterOptions: {
              validate: true
            }
          }));
        });
        Ember.RSVP.all(quoteRelatedPromises).then(function () {
          changeset.set('startDate', (0, _moment.default)(_moment.default.utc(changeset.get('startDate')).format()).toDate());
          changeset.set('endDate', (0, _moment.default)(_moment.default.utc(changeset.get('endDate')).format()).toDate());
          changeset.save().then(function () {
            _this.transitionToRoute('request-success');
          }).catch(function (err) {
            changeset.set('startDate', oldStartDate);
            changeset.set('endDate', oldEndDate);
            err.errors.forEach(function (error) {
              if (error.status === "400") {
                _this.get('notify').error(error.detail);
              }
            });

            _this.set('isSendingRequest', false);
          });
          changeset.set('startDate', oldStartDate);
          changeset.set('endDate', oldEndDate);
        }).catch(function (err) {
          Ember.run.next(function () {
            if (Ember.$('.has-error') && Ember.$('.has-error').length) {
              Ember.$("html, body").animate({
                scrollTop: Ember.$('.has-error').offset().top - 100
              });
            }
          });
          err.errors.forEach(function (error) {
            if (error.status === 400) {
              _this.get('notify').error(error.detail);
            }
          });

          _this.set('isSendingRequest', false);
        });
      },
      addQuoteProjectGoal: function addQuoteProjectGoal() {
        var quoteProjectGoal = this.get('store').createRecord('quote-project-goal', {
          quote: null,
          projectGoal: '',
          description: '',
          name: ''
        });
        var localQuoteProjectGoals = this.get('localQuoteProjectGoals');
        localQuoteProjectGoals.addObject(quoteProjectGoal);
      },
      deleteQuoteProjectGoal: function deleteQuoteProjectGoal(quoteProjectGoal) {
        var localQuoteProjectGoals = this.get('localQuoteProjectGoals');

        if (localQuoteProjectGoals.includes(quoteProjectGoal)) {
          localQuoteProjectGoals.removeObject(quoteProjectGoal);
        }
      },
      addQuoteServiceProvider: function addQuoteServiceProvider() {
        var quoteServiceProvider = this.get('store').createRecord('quote-service-provider', {
          quote: null,
          providerName: '',
          contactPersonName: '',
          email: '',
          phoneNumber: ''
        });
        var localServiceProviders = this.get('localServiceProviders');
        localServiceProviders.addObject(quoteServiceProvider);
      },
      deleteQuoteServiceProvider: function deleteQuoteServiceProvider(quoteServiceProvider) {
        var localServiceProviders = this.get('localServiceProviders');

        if (localServiceProviders.includes(quoteServiceProvider)) {
          localServiceProviders.removeObject(quoteServiceProvider);
        }
      }
    }
  });

  _exports.default = _default;
});