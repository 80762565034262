define("cb-ui/controllers/app/project/proposal/questions", ["exports", "cb-ui/utils/download-file", "cb-ui/config/environment"], function (_exports, _downloadFile, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    parentController: Ember.inject.controller('application'),
    notify: Ember.inject.service('notify'),
    currentUser: null,
    userProposalRole: null,
    userIsProposalAdmin: Ember.computed.equal('userProposalRole.role', 'admin'),
    quote: null,
    questions: null,
    sortedQuestions: Ember.computed.sort('questions', 'sortProperties'),
    proposal: null,
    sortProperties: ['createdAt:desc', 'hasAnswer'],
    showAddQuestion: false,
    showAddQuestionButton: Ember.computed('proposal', function () {
      if (this.get('proposal.status') === 'lost' || this.get('proposal.status') === 'awarded' || this.get('proposal.status') === 'dismissed') {
        return false;
      }

      return true;
    }),
    actions: {
      exportQuestions: function exportQuestions() {
        var jwt = this.get('session.data.authenticated.token');
        var url = _environment.default.backendAPI + '/api/export_questions/' + this.get('quote.id').toString() + '/?auth_token=' + jwt;
        (0, _downloadFile.default)(url);
      },
      toggleAddQuestion: function toggleAddQuestion() {
        this.toggleProperty('showAddQuestion');
      },
      addQuestion: function addQuestion(questionText, type) {
        var _this = this;

        var question = this.store.createRecord('question', {
          quote: this.get('quote'),
          proposal: this.get('proposal'),
          question: questionText,
          type: type
        });
        question.save().then(function () {
          _this.set('showAddQuestion', false);

          _this.get('notify').success(_this.get('intl').t('notifyMessages.questionSubmitted').toString());
        }, function () {
          _this.get('notify').error(_this.get('intl').t('notifyMessages.errorOccurredVerifyFields').toString());

          question.unloadRecord();
        });
      }
    }
  });

  _exports.default = _default;
});