define("cb-ui/controllers/app/project/proposal/announcements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    quote: null,
    proposal: null,
    announcements: null,
    sortedAnnouncements: Ember.computed.sort('announcements', 'sortProperties'),
    sortProperties: ['postedAt:desc'],
    userIsProposalAdmin: Ember.computed.equal('userProposalRole.role', 'admin'),
    localDocuments: new Ember.A(),
    loadQuoteAnnouncementData: function loadQuoteAnnouncementData(quoteAnnouncement) {
      var _this = this;

      var quoteAnnouncementDocuments = new Ember.A();
      var dataLoadedPromises = [];
      var documentsPromise = quoteAnnouncement.get('quoteAnnouncementDocuments');
      dataLoadedPromises.push(documentsPromise);
      documentsPromise.then(function (documents) {
        documents.forEach(function (document) {
          quoteAnnouncementDocuments.addObject(document);
        });
      });
      Ember.RSVP.all(dataLoadedPromises).catch(function () {
        _this.get('notify').error('Announcement details couldn\'t be loaded.');
      });
    },
    actions: {}
  });

  _exports.default = _default;
});