define("cb-ui/components/ember-table/technical-evaluation-table-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isCurrentUser: null,
    showAllActions: false,
    actions: {
      viewEvaluationDetails: function viewEvaluationDetails(evaluator) {
        this.sendAction('viewEvaluationDetails', evaluator);
      },
      deleteEvaluationInvitation: function deleteEvaluationInvitation(evaluator) {
        this.sendAction('deleteEvaluationInvitation', evaluator);
      },
      resendEvaluationInvitation: function resendEvaluationInvitation(evaluator) {
        this.sendAction('resendEvaluationInvitation', evaluator);
      },
      triggerWarningModal: function triggerWarningModal() {
        this.sendAction('triggerWarningModal');
      },
      disableEvaluator: function disableEvaluator(evaluator) {
        this.sendAction('disableEvaluator', evaluator);
      },
      enableEvaluator: function enableEvaluator(evaluator) {
        this.sendAction('enableEvaluator', evaluator);
      }
    }
  });

  _exports.default = _default;
});