define("cb-ui/components/project-evaluation-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    evaluator: null,
    proposals: new Ember.A(),
    proposalsEvaluations: new Ember.A(),
    criteriaGroups: new Ember.A(),
    tableColumnWidth: Ember.computed('proposals.length', function () {
      var proposalsLength = this.get('proposals.length');

      if (proposalsLength) {
        return (75 / parseInt(proposalsLength)).toString() + '%';
      }

      return 0;
    }),
    columns: Ember.computed('proposals.length', 'evaluator', function () {
      var columns = [{
        heading: this.get('intl').t('app.components.projectEvaluationInfo.tableTitle') + ' ' + this.get('evaluator.fullName') + ' - ' + this.get('evaluator.email'),
        valuePath: 'title',
        isFixed: 'left',
        width: 200
      }];
      this.get('proposals').forEach(function (p) {
        columns.push({
          width: 150,
          valuePath: p.get('id'),
          company: p.get('company'),
          heading: '',
          headerComponent: 'ember-table/company-info'
        });
      });
      return columns;
    }),
    rows: Ember.computed('proposals.length', 'criteriaGroups.length', function () {
      var rows = [];
      var criteriaGroups = this.get('criteriaGroups');
      var proposals = this.get('proposals');
      criteriaGroups.forEach(function (cg) {
        var data = {
          title: cg.get('title'),
          rowComponent: 'ember-table/full-row-cell'
        };
        rows.push(data);
        cg.get('evaluations').forEach(function (criteria) {
          var rowData = {
            title: {
              cellComponent: 'ember-table/criteria-info',
              title: criteria.get('title'),
              description: criteria.get('description')
            }
          };
          proposals.forEach(function (p) {
            rowData[p.get('id')] = {
              cellComponent: 'ember-table/evaluation-results-info',
              criteria: criteria,
              proposal: p
            };
          });
          rows.push(rowData);
        });
      });
      return rows;
    })
  });

  _exports.default = _default;
});