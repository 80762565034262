define("cb-ui/templates/app/rfp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NhfBFRyN",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"app-top-navbar\",null,[[\"logout\",\"changeUserLanguage\",\"contactTCB\",\"showInfo\",\"quote\"],[\"invalidateSession\",\"changeUserLanguage\",\"contactTCBAgent\",true,[20,[\"model\"]]]]],false],[0,\"\\n\"],[1,[18,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/app/rfp.hbs"
    }
  });

  _exports.default = _default;
});