define("cb-ui/models/time-material", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    proposal: belongsTo('proposal', {
      async: true,
      inverse: 'timeMaterials'
    }),
    role: attr('string'),
    organizationWorkerRole: belongsTo('organization-worker-role', {
      async: true
    }),
    description: attr('string'),
    rate: attr('number'),
    days: attr('number'),
    analysisDays: attr('number'),
    conceptDays: attr('number'),
    implementationDays: attr('number'),
    auditDays: attr('number'),
    homeLocation: attr('string'),
    order: attr('number'),
    fee: Ember.computed('rate', 'days', function () {
      var rate = this.get('rate');
      var days = this.get('days');

      if (!isNaN(rate) && !isNaN(days)) {
        return rate * days;
      }
    })
  });

  _exports.default = _default;
});