define("cb-ui/routes/app/project/proposal/criteria-evaluation", ["exports", "cb-ui/mixins/scrollbar-reset"], function (_exports, _scrollbarReset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollbarReset.default, {
    model: function model() {
      var quote = this.modelFor('app.project');
      return Ember.RSVP.hash({
        proposal: this.modelFor('app.project.proposal'),
        quote: quote,
        criteriaEvaluationGroups: this.store.query('criteria-evaluation-group', {
          include: 'evaluations',
          quote_id: quote.get('id')
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var technicalQuoteCriteriaGroups = new Ember.A();
      var pitchQuoteCriteriaGroups = new Ember.A();
      model.criteriaEvaluationGroups.forEach(function (ceg) {
        var groupType = ceg.get('type');

        if (groupType === 'technical_proposal') {
          technicalQuoteCriteriaGroups.addObject(ceg);
        } else if (groupType === 'pitch') {
          pitchQuoteCriteriaGroups.addObject(ceg);
        }
      });
      controller.set('technicalQuoteCriteriaGroups', technicalQuoteCriteriaGroups);
      controller.set('pitchQuoteCriteriaGroups', pitchQuoteCriteriaGroups);
      controller.set('proposal', model.proposal);
      controller.set('quote', model.quote);
    }
  });

  _exports.default = _default;
});