define("cb-ui/templates/award-project-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8jeI3dit",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"bs-modal\",null,[[\"footer\",\"body\",\"onHidden\",\"size\"],[false,false,[25,\"action\",[[19,0,[]],\"closeAwardProjectModal\"],null],\"lg\"]],{\"statements\":[[4,\"component\",[[19,1,[\"body\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n\\n          \"],[6,\"div\"],[7],[0,\"\\n            \"],[1,[25,\"t\",[\"app.modals.awardProject.details\"],null],false],[0,\"\\n          \"],[8],[0,\"\\n          \"],[6,\"div\"],[7],[1,[25,\"t\",[\"app.modals.awardProject.confirmQuestion\"],null],false],[8],[0,\"\\n\\n          \"],[6,\"div\"],[9,\"class\",\"m-md\"],[7],[0,\"\\n              \"],[6,\"button\"],[9,\"class\",\"btn btn-primary m-r m-b-sm\"],[3,\"action\",[[19,0,[]],\"awardProject\"]],[7],[1,[25,\"t\",[\"app.modals.awardProject.yes\"],null],false],[8],[0,\"\\n              \"],[6,\"button\"],[9,\"class\",\"btn btn-default m-b-sm\"],[3,\"action\",[[19,0,[]],\"closeAwardProjectModal\"]],[7],[0,\" \"],[1,[25,\"t\",[\"app.modals.awardProject.no\"],null],false],[8],[0,\"\\n          \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/award-project-modal.hbs"
    }
  });

  _exports.default = _default;
});