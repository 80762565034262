define("cb-ui/templates/next-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F9vjfBkV",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-button\",null,[[\"data-test-next-button\",\"onClick\",\"type\"],[true,[25,\"action\",[[19,0,[]],\"next\"],null],\"primary\"]],{\"statements\":[[0,\"  \"],[1,[25,\"t\",[\"buttons.next\"],null],false],[0,\"\\n  \"],[1,[25,\"fa-icon\",[\"arrow-right\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/next-button.hbs"
    }
  });

  _exports.default = _default;
});