define("cb-ui/templates/app/account/confirm-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cbyvejDg",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"col-md-offset-4 col-md-4\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"isConfirmed\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"alert alert-success text-center\"],[7],[0,\"\\n        \"],[1,[25,\"t\",[\"app.account.confirmAccount.title\"],null],false],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"alert alert-danger text-center\"],[7],[0,\"\\n        \"],[1,[25,\"t\",[\"app.account.confirmAccount.notConfirmed\"],null],false],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/app/account/confirm-account.hbs"
    }
  });

  _exports.default = _default;
});