define("cb-ui/routes/app/rfp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super('transition');

      var quoteId = transition.params['app.rfp'].quote_id;
      this.get('sessionContext.currentUser').then(function (user) {
        if (!user.get('isClient')) {
          _this.transitionTo('app.dashboard');
        }

        _this.store.find('quote', quoteId).then(function (quote) {
          if (quote.get('status') !== 'draft') {
            _this.transitionTo('app.dashboard');
          }
        });
      });
    }
  });

  _exports.default = _default;
});