define("cb-ui/routes/app/account/confirm-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      var _this = this;

      if (transition.queryParams.tcbToken && transition.queryParams.token) {
        this.get('sessionContext.currentUser').then(function (user) {
          user.set('emailValidated', true);
          user.set('token', transition.queryParams.token);
          user.save({
            adapterOptions: {
              confirm_email: true
            }
          }).then(function () {
            _this.controller.set('isConfirmed', true);
          }).catch(function () {
            _this.controller.set('isConfirmed', false);
          });
        });
      } else {
        this.transitionTo('app');
      }
    }
  });

  _exports.default = _default;
});