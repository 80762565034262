define("cb-ui/templates/proposal-preview-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q/ICXN9g",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"bs-modal\",null,[[\"onHidden\",\"footer\",\"size\"],[[25,\"action\",[[19,0,[]],\"closeNDA\"],null],false,\"lg\"]],{\"statements\":[[4,\"component\",[[19,1,[\"body\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-md-12\"],[7],[0,\"\\n          \"],[1,[25,\"rfp-summary\",null,[[\"quote\",\"isPreview\"],[[20,[\"proposal\",\"quote\"]],true]]],false],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n                \"],[6,\"button\"],[9,\"class\",\"btn btn-primary\"],[3,\"action\",[[19,0,[]],\"selectProject\",[20,[\"proposal\"]]]],[7],[0,\"\\n                     \"],[1,[25,\"t\",[\"app.dashboardConsultant.projects.response\"],null],false],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/proposal-preview-modal.hbs"
    }
  });

  _exports.default = _default;
});