define("cb-ui/components/document-toc-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ol',
    classNames: ['dd-list'],
    sections: null,
    sectionsSorting: ['order'],
    sortedSections: Ember.computed.sort('sections', 'sectionsSorting'),
    actions: {
      'delete': function _delete(section) {
        this.attrs.delete(section);
      },
      goTo: function goTo(section) {
        var el = Ember.$('#section_' + section.get('uid'));
        el.scrollToBySpeed({
          speed: 1500,
          offset: 160
        });
      }
    }
  });

  _exports.default = _default;
});