define("cb-ui/templates/components/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZUFR3RC9",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"  \"],[6,\"label\"],[10,\"class\",[26,[\"ember-radio-button \",[25,\"if\",[[20,[\"checked\"]],\"checked\"],null],\" \",[18,\"joinedClassNames\"]]]],[10,\"for\",[18,\"radioId\"],null],[7],[0,\"\\n    \"],[1,[25,\"radio-button-input\",null,[[\"class\",\"id\",\"disabled\",\"name\",\"required\",\"groupValue\",\"value\",\"changed\"],[[20,[\"radioClass\"]],[20,[\"radioId\"]],[20,[\"disabled\"]],[20,[\"name\"]],[20,[\"required\"]],[20,[\"groupValue\"]],[20,[\"value\"]],\"changed\"]]],false],[0,\"\\n\\n    \"],[11,1],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"radio-button-input\",null,[[\"class\",\"id\",\"disabled\",\"name\",\"required\",\"groupValue\",\"value\",\"changed\"],[[20,[\"radioClass\"]],[20,[\"radioId\"]],[20,[\"disabled\"]],[20,[\"name\"]],[20,[\"required\"]],[20,[\"groupValue\"]],[20,[\"value\"]],\"changed\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/radio-button.hbs"
    }
  });

  _exports.default = _default;
});