define("cb-ui/models/financial-criteria-evaluation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    title: attr('string'),
    description: attr('string'),
    isEliminatory: attr('boolean', {
      defaultValue: false
    }),
    order: attr('number', {
      defaultValue: 0
    }),
    private: attr('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});