define("cb-ui/routes/app/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var parentModel = this.modelFor('app.templates');
      var currentUser = parentModel.currentUser;
      var templates = parentModel.templates;
      currentUser.get('company').then(function (company) {
        controller.set('userCompany', company);
        controller.set('templates', templates.filterBy('company.id', company.get('id')));
      });
      controller.set('technicalTemplateTitle', null);
      controller.set('pitchTemplateTitle', null);
      controller.set('showAddTechnicalTemplate', false);
      controller.set('showAddPitchTemplate', false);
    }
  });

  _exports.default = _default;
});