define("cb-ui/routes/wizard/project-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(obj, transition) {
      var cbStore = this.get('cbStore');
      return Ember.RSVP.hash({
        projectContexts: cbStore.findAllWrapper('projectContext', 'contextEn'),
        projectRoles: cbStore.findAllWrapper('projectRole', 'roleEn'),
        quote: this.store.find('quote', transition.params.wizard.quote_uuid)
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('quote', model.quote);
      controller.set('quote.currentStep', 30);
      controller.set('projectContext', model.quote.get('projectContext.contextEn'));

      if (model.quote.get('extraProjectRole')) {
        controller.set('projectRole', controller.get('otherProjectRole'));
      } else {
        controller.set('projectRole', model.quote.get('projectRole.roleEn'));
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (transition.targetName === 'wizard.consultants') {
          this.get('controller.quote').rollbackAttributes();
          this.get('controller.quote').reload();
        } else {
          this._super(transition);
        }
      }
    }
  });

  _exports.default = _default;
});