define("cb-ui/components/multiple-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    label: null,
    items: Ember.A(),
    selectedItems: Ember.A(),
    language: 'en',
    actions: {
      addItem: function addItem(item) {
        this.sendAction('addItem', item);
      },
      removeItem: function removeItem(item) {
        this.sendAction('removeItem', item);
      }
    }
  });

  _exports.default = _default;
});