define("cb-ui/mixins/company-services-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    setupController: function setupController(controller, model) {
      controller.set('sortedServices', model.services);
      controller.set('sortedIndustries', model.industries);
      var quote = model.quote;
      var propertyNameService = quote && quote.get('projectLanguage') === 'de' ? 'serviceDe' : 'serviceEn';
      var propertyNameIndustry = quote && quote.get('projectLanguage') === 'de' ? 'industryDe' : 'industryEn';
      controller.set('propertyNameService', propertyNameService);
      controller.set('propertyNameIndustry', propertyNameIndustry);
      controller.initializeServicesIndustries();
    }
  });

  _exports.default = _default;
});