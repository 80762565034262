define("cb-ui/templates/components/ember-table/financial-final-evaluation-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1ABv4O8C",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"or\",[[20,[\"proposal\",\"isIncludedInEvaluation\"]],[25,\"and\",[[25,\"eq\",[[20,[\"proposal\",\"status\"]],\"dismissed\"],null],[20,[\"proposal\",\"keepEvaluationsAfterDismissal\"]]],null]],null]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n    \"],[6,\"h3\"],[7],[0,\"\\n      \"],[1,[25,\"t\",[\"app.projectClient.evaluation.label.score\"],null],false],[0,\"\\n    \"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"proposal\",\"financialQuotationData\",\"totalProjectVolumeScore\"]]],null,{\"statements\":[[0,\"      \"],[6,\"h2\"],[7],[0,\"\\n        \"],[6,\"strong\"],[7],[1,[20,[\"proposal\",\"financialQuotationData\",\"totalProjectVolumeScore\"]],false],[0,\" \"],[8],[0,\"\\n      \"],[8],[0,\"\\n      \"],[2,\"TODO: Check if should be kept \"],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"m-b\"],[7],[0,\"\\n        \"],[1,[25,\"t\",[\"app.projectClient.evaluation.label.financialProposals.notValidFinancial\"],null],false],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[6,\"button\"],[9,\"class\",\"btn\"],[3,\"action\",[[19,0,[]],\"openFinancialDetailsModal\",[20,[\"proposal\"]]]],[7],[0,\"\\n      \"],[1,[25,\"t\",[\"app.projectClient.evaluation.label.checkDetails\"],null],false],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"proposal\",\"isDeclined\"]]],null,{\"statements\":[[0,\"  \"],[6,\"strong\"],[7],[0,\"\\n    \"],[1,[25,\"t\",[\"app.projectClient.evaluation.label.proposalDeclined\"],null],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"proposal\",\"isDraftInvitedOrAccepted\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"t\",[\"app.projectClient.evaluation.label.notSubmitted\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/ember-table/financial-final-evaluation-cell.hbs"
    }
  });

  _exports.default = _default;
});