define("cb-ui/models/address", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    company: _emberData.default.belongsTo('company', {
      async: true,
      inverse: 'addresses'
    }),
    'addressLine-1': _emberData.default.attr('string'),
    'addressLine-2': _emberData.default.attr('string'),
    'addressLine-3': _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    country: _emberData.default.belongsTo('country', {
      async: true
    }),
    globalHeadOffice: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    countryHeadOffice: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    postalCode: _emberData.default.attr('string')
  });

  _exports.default = _default;
});