define("cb-ui/components/consultant-statistics", ["exports", "cb-ui/components/client-statistics"], function (_exports, _clientStatistics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _clientStatistics.default.extend({});

  _exports.default = _default;
});