define("cb-ui/models/user-lead", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    firstName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    companyName: attr('string'),
    newsletterSubscription: attr('boolean', {
      defaultValue: true
    })
  });

  _exports.default = _default;
});