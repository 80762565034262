define("cb-ui/models/organization-worker-role", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    company: belongsTo('company', {
      async: true
    }),
    consultantRole: attr('string'),
    level: attr('string'),
    description: attr('string'),
    descriptionShort: Ember.computed('description', function () {
      if (this.get('description')) {
        return this.get('description').substring(0, 100);
      }
    })
  });

  _exports.default = _default;
});