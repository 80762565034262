define("cb-ui/routes/app/rfp/index", ["exports", "cb-ui/mixins/scrollbar-reset"], function (_exports, _scrollbarReset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollbarReset.default, {
    notify: Ember.inject.service(),
    model: function model() {
      var cbStore = this.get('cbStore');
      return Ember.RSVP.hash({
        countries: cbStore.findAllWrapper('country', 'commonNameEn'),
        industries: cbStore.findAllWrapper('industryStandard', 'mpttOrder'),
        services: cbStore.findAllWrapper('serviceStandard', 'mpttOrder'),
        companySizeTurnovers: cbStore.findAllWrapper('companySizeTurnover', 'id'),
        companySizeEmployees: cbStore.findAllWrapper('companySizeEmployee', 'id'),
        projectGoals: cbStore.findAllWrapper('projectGoal', 'projectGoalEn'),
        serviceTypes: cbStore.findAllWrapper('serviceType', 'id'),
        currentUser: this.get('sessionContext.currentUser')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('componentHTML', '');
      var projectIndustries = [];
      var sortedServices = model.services;
      var sortedIndustries = model.industries;
      var quote = this.modelFor('app.rfp');
      var propertyNameService = quote.get('projectLanguage') === 'de' ? 'serviceDe' : 'serviceEn';
      var propertyNameIndustry = quote.get('projectLanguage') === 'de' ? 'industryDe' : 'industryEn';
      quote.get('secondaryLocations').then(function (countries) {
        var localCountries = [];
        countries.forEach(function (country) {
          localCountries.push(country);
        });
        controller.set('localSecondaryLocations', localCountries);
      });
      quote.get('projectGoals').then(function (projectGoals) {
        var localProjectGoals = new Ember.A();
        var quoteProjectGoalsOrder = quote.get('projectGoalsOrder');
        var projectGoalsOrder;

        if (typeof quoteProjectGoalsOrder === 'string' && quoteProjectGoalsOrder !== '') {
          projectGoalsOrder = JSON.parse(quoteProjectGoalsOrder);
        } else {
          projectGoalsOrder = [];
        }

        if (projectGoalsOrder) {
          projectGoalsOrder.forEach(function (pgId) {
            var projectGoal = projectGoals.findBy('id', pgId.toString());

            if (projectGoal) {
              localProjectGoals.addObject(projectGoal);
            }
          });
        }

        controller.set('localProjectGoals', localProjectGoals);
      });
      quote.get('quoteProjectGoals').then(function (quoteProjectGoals) {
        var localQuoteProjectGoals = new Ember.A();
        quoteProjectGoals.forEach(function (quoteProjectGoal) {
          localQuoteProjectGoals.addObject(quoteProjectGoal);
        });
        controller.set('localQuoteProjectGoals', localQuoteProjectGoals);
      });
      var projectGoalsEnArray = new Ember.A();
      var projectGoalsDeArray = new Ember.A();
      model.projectGoals.forEach(function (item) {
        projectGoalsEnArray.pushObject(item.get('projectGoalEn'));
        projectGoalsDeArray.pushObject(item.get('projectGoalDe'));
      });
      controller.set('projectServices', sortedServices);
      controller.set('projectIndustries', sortedIndustries);
      controller.set('projectGoals', model.projectGoals);
      controller.set('projectGoalsEn', projectGoalsEnArray);
      controller.set('projectGoalsDe', projectGoalsDeArray);
      controller.set('quote', quote); //controller.set('projectService', controller.get('quote.serviceStandard.' + propertyNameService));
      //controller.set('projectIndustry', controller.get('quote.industryStandard.' + propertyNameIndustry));

      controller.set('projectService', quote.get('serviceStandard'));
      controller.set('projectIndustry', quote.get('industryStandard'));
      controller.set('propertyNameService', propertyNameService);
      controller.set('propertyNameIndustry', propertyNameIndustry);
      var serviceTypes = new Ember.A();
      model.serviceTypes.forEach(function (st) {
        serviceTypes.addObject(st);
      });
      controller.set('serviceTypes', serviceTypes);
      controller.set('serviceType', null);
      quote.get('serviceType').then(function (serviceType) {
        if (serviceType) {
          controller.set('serviceType', serviceType.get('serviceTypeEn'));
        }
      });
      controller.set('currentUser', model.currentUser);
      controller.set('quote.currentStep', 10);
    },
    generateChildrenNameList: function generateChildrenNameList(parentNode, nodesList, propertyName) {
      var _this = this;

      var children = nodesList.filterBy('parent.id', parentNode.get('id'));

      if (!children.get('length')) {
        return parentNode.get(propertyName);
      } else {
        return {
          groupName: parentNode.get(propertyName),
          options: children.map(function (child) {
            return _this.generateChildrenNameList(child, nodesList, propertyName);
          })
        };
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (this.get('controller.blockPrevious')) {
          transition.abort();
        } else {
          this._super(transition);
        }
      },
      newAgentQuestionTcbInvalid: function newAgentQuestionTcbInvalid() {
        var _this2 = this;

        if (this.controller.get('question')) {
          this.store.createRecord('agent-question', {
            question: this.controller.get('question'),
            userPhone: this.controller.get('userPhone')
          }).save().then(function () {
            _this2.get('notify').success(_this2.get('intl').t('notifyMessages.requestWasSent').toString());

            _this2.controller.set("showAgentQuestionModal", false);

            _this2.controller.set("showWarningModal", false);

            _this2.controller.set("question", null);

            _this2.controller.set("userPhone", null);
          }).catch(function () {
            _this2.get('notify').error(_this2.get('intl').t('notifyMessages.errorOccurredTryAgain'));
          });
        } else {
          this.get('notify').error(this.get('intl').t('notifyMessages.fillInQuestion').toString());
        }
      },
      closeAgentQuestionModal: function closeAgentQuestionModal() {
        this.controller.set('userPhone', null);
        this.controller.set('showAgentQuestionModal', false);
        this.controller.set('question', null);
      }
    }
  });

  _exports.default = _default;
});