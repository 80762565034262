define("cb-ui/serializers/proposal", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONAPISerializer = _emberData.default.JSONAPISerializer;

  var _default = JSONAPISerializer.extend({
    attrs: {
      documents: {
        serialize: true
      },
      references: {
        serialize: true
      },
      teamMembers: {
        serialize: true
      },
      workPackages: {
        serialize: true
      },
      consultantUser: {
        serialize: true
      },
      proposalEvaluations: {
        serialize: true
      },
      timeMaterials: {
        serialize: true
      },
      timeMaterialSupplements: {
        serialize: true
      },
      fixedFees: {
        serialize: true
      }
    }
  });

  _exports.default = _default;
});