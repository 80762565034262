define("cb-ui/templates/components/document-toc-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0Jw1s7WG",
    "block": "{\"symbols\":[],\"statements\":[[6,\"h4\"],[7],[1,[25,\"t\",[\"app.components.documentTocEditor.title\"],null],false],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"m-b-sm\"],[7],[1,[25,\"t\",[\"app.components.documentTocEditor.details\"],null],false],[8],[0,\"\\n\"],[6,\"div\"],[9,\"data-test-add-section\",\"\"],[9,\"class\",\"btn btn-primary\"],[3,\"action\",[[19,0,[]],\"addSection\"]],[7],[6,\"i\"],[9,\"class\",\"glyphicon glyphicon-plus-sign\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"app.components.documentTocEditor.addNewSection\"],null],false],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"dd\"],[9,\"id\",\"nestable\"],[7],[0,\"\\n    \"],[1,[25,\"document-toc-list\",null,[[\"sections\",\"delete\"],[[20,[\"rootSections\"]],[25,\"action\",[[19,0,[]],\"delete\"],null]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"clearfix\"],[7],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/document-toc-editor.hbs"
    }
  });

  _exports.default = _default;
});