define("cb-ui/controllers/signup", ["exports", "cb-ui/config/environment", "ember-local-storage"], function (_exports, _environment, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    queryParams: ['token', 'email', 'ratecards'],
    token: null,
    tokenExpired: null,
    email: null,
    ratecards: null,
    userType: 'client',
    isNewUser: null,
    inactiveUser: null,
    invitedUser: null,
    salutations: null,
    academicTitles: null,
    countries: null,
    emailChecked: null,
    blacklistedDomain: null,
    user: Ember.computed.alias('model'),
    appLanguage: Ember.computed.alias('localStorage.language'),
    init: function init() {
      $("#progressBar").remove();
    },
    emailObserver: Ember.observer('user.email', function () {
      this.set('isNewUser', false);
      this.set('inactiveUser', false);
      this.set('invitedUser', false);
      this.set('emailChecked', false);
    }),
    validateEmail: function validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    passwordMatchObserver: Ember.observer('user.retypePassword', 'user.password', function () {
      var user = this.get('user');
      var retypePassword = user.get('retypePassword');

      user.get('errors')._remove('retypePassword');

      if (user.get('password') && retypePassword !== user.get('password')) {
        user.get('errors')._add('retypePassword', 'Values don\'t match. Please correct the password.');
      }
    }),
    actions: {
      searchCompanies: function searchCompanies(companyQuery) {
        if (Ember.isBlank(companyQuery)) {
          return [];
        }

        return this.store.query('public-company', {
          company_query: companyQuery,
          company_type: this.get('user.isClient') ? 'client' : 'consultant'
        }).then(function (companies) {
          return companies;
        });
      },
      userTypeChanged: function userTypeChanged(value) {
        this.set('user.isClient', value === 'client');
        this.set('user.isConsultant', value === 'consultant');

        if (this.get('user.isConsultant')) {
          this.set('user.newCompany', false);
          this.get('user.errors').remove('company');
        } else if (this.get('user.isClient')) {
          this.set('user.newCompany', true);
          this.set('user.company', null);
        }
      },
      checkEmail: function checkEmail() {
        var _this = this;

        var user = this.get('user');

        if (user.get('email') && this.validateEmail(user.get('email'))) {
          var ajax = this.get('ajax');
          ajax.post(_environment.default.backendAPI + '/api/check-email/', {
            data: {
              email: user.get('email')
            }
          }).then(function (response) {
            if (response) {
              _this.set('isNewUser', response.new_user === true);

              _this.set('inactiveUser', response.inactive_user === true);

              _this.set('blacklistedDomain', response.blacklisted_domain === true);
            }

            if (_this.get('blacklistedDomain')) {
              _this.get('notify').error('Please use a corporate email.');
            } else {
              _this.set('emailChecked', true);
            }
          });
        } else {
          this.get('notify').error(this.get('intl').t('notifyMessages.provideValidEmail').toString());
        }
      },
      addCompany: function addCompany() {
        this.set('user.newCompany', true);
      },
      cancelCompany: function cancelCompany() {
        this.set('user.newCompany', false);
      },
      signup: function signup() {
        var _this2 = this;

        if (this.get('tokenExpired')) {
          return;
        }

        if (!this.get('user.errors.retypePassword')) {
          var user = this.get('user');
          var rawPassword = user.get('password');
          user.set('token', this.get('token'));
          var self = this;

          user.get('errors')._clear();

          user.save({
            adapterOptions: {
              new_account: true
            }
          }).then(function () {
            _this2.get('notify').success(_this2.get('intl').t('notifyMessages.signupSuccessful').toString());

            if (_this2.get('session.isAuthenticated')) {
              _this2.transitionToRoute('application');
            } else {
              if (_this2.get('ratecards')) {
                window.location.href = _environment.default.ratecardsURL + '/dashboard/rfp?token=' + _this2.get('ratecards');
              } else {
                window.location = "".concat(_environment.default.ratecardsURL, "/login?logoutBefore=true");
              }
            }
          }).catch(function () {
            _this2.get('notify').error(_this2.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
          });
        } else {
          this.get('notify').error(this.get('intl').t('notifyMessages.passwordNotMatching').toString());
        }
      }
    }
  });

  _exports.default = _default;
});