define("cb-ui/components/template-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    template: null,
    groupsData: new Ember.A(),
    //technical or pitch type
    type: null,
    // used for hiding details for consultant
    hideDetails: false,
    actions: {
      editTemplate: function editTemplate(template) {
        this.sendAction('editTemplate', template);
      },
      cloneTemplate: function cloneTemplate(template) {
        this.sendAction('cloneTemplate', template);
      },
      showDropdown: function showDropdown() {
        this.sendAction('showDropdown', this.get('type'));
      },
      newTemplate: function newTemplate() {
        this.sendAction('newTemplate', this.get('type'));
      }
    }
  });

  _exports.default = _default;
});