define("cb-ui/metrics-adapters/google-analytics4", ["exports", "ember-metrics/metrics-adapters/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _base.default.extend({
    includeKeys: function includeKeys(opts, include) {
      var newOpts = {};

      for (var key in opts) {
        if (include(key)) newOpts[key] = opts[key];
      }

      return newOpts;
    },
    compact: function compact(opts) {
      return this.includeKeys(opts, function (key) {
        return Ember.isPresent(opts[key]);
      });
    },
    removeFromDOM: function removeFromDOM(selectors) {
      document.querySelectorAll(selectors).forEach(function (el) {
        el.parentElement.removeChild(el);
      });
    },
    toStringExtension: function toStringExtension() {
      return 'GoogleAnalytics4';
    },
    init: function init() {
      var config = Ember.assign({}, Ember.get(this, 'config'));
      var id = config.id,
          options = config.options;
      (true && !(id) && Ember.assert("[ember-metrics] You must pass a valid `id` to the ".concat(this.toString(), " adapter"), id));
      this.options = _objectSpread({
        send_page_view: true
      }, options);

      this._injectScript(id);

      window.dataLayer = window.dataLayer || [];
      this.gtag('js', new Date());
      this.gtag('config', id, this.compact(this.options));
    },
    identify: function identify() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var compactedOptions = this.compact(options);
      var distinctId = compactedOptions.distinctId;
      var canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

      if (canUseDOM) {
        window.ga('set', 'userId', distinctId);
      }
    },
    _injectScript: function _injectScript(id) {
      var script = document.createElement('script');
      script.setAttribute("async", true);
      script.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=".concat(id));
      document.head.appendChild(script);
    },
    gtag: function gtag() {
      window.dataLayer.push(arguments);
      return arguments;
    },
    trackEvent: function trackEvent(options) {
      var compactedOptions = this.compact(options);
      var event = compactedOptions.event;

      if (!event) {
        return;
      }

      delete compactedOptions.event;
      return this.gtag('event', event, compactedOptions);
    },
    trackPage: function trackPage() {
      return this.trackEvent({
        event: 'page_view'
      });
    },
    alias: function alias() {},
    willDestroy: function willDestroy() {
      this.removeFromDOM('script[src*="gtag/js"]');
      delete window.dataLayer;
    }
  });

  _exports.default = _default;
});