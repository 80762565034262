define("cb-ui/templates/components/models-table/select-company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XK0R9X3d",
    "block": "{\"symbols\":[],\"statements\":[[6,\"button\"],[9,\"class\",\"btn btn-white btn-block btn-mini btn-table\"],[3,\"action\",[[19,0,[]],\"sendAction\",[20,[\"record\"]]]],[7],[6,\"i\"],[9,\"class\",\"glyphicon glyphicon-log-in\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"buttons.moreDetails\"],null],false],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/models-table/select-company.hbs"
    }
  });

  _exports.default = _default;
});