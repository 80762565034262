define("cb-ui/templates/components/project-steps-completion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6dg3VGbM",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"row m-b \"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-md-3 font-size-16 p-sm\"],[9,\"style\",\"border: 1px solid #f3f3f4; border-radius: 3%\"],[7],[0,\"\\n        \"],[6,\"div\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"quote\",\"projectFirstStepCompleted\"]]],null,{\"statements\":[[0,\"            \"],[1,[25,\"fa-icon\",[\"check\"],[[\"class\"],[\"green\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[25,\"fa-icon\",[\"close\"],[[\"class\"],[\"red\"]]],false],[0,\"\\n          \"]],\"parameters\":[]}],[0,\" Project Information Step\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"quote\",\"projectSecondStepCompleted\"]]],null,{\"statements\":[[0,\"            \"],[1,[25,\"fa-icon\",[\"check\"],[[\"class\"],[\"green\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[25,\"fa-icon\",[\"close\"],[[\"class\"],[\"red\"]]],false],[0,\"\\n          \"]],\"parameters\":[]}],[0,\" Consultants Step\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"quote\",\"projectThirdStepCompleted\"]]],null,{\"statements\":[[0,\"            \"],[1,[25,\"fa-icon\",[\"check\"],[[\"class\"],[\"green\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[25,\"fa-icon\",[\"close\"],[[\"class\"],[\"red\"]]],false],[0,\"\\n          \"]],\"parameters\":[]}],[0,\" Project Details Step\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/project-steps-completion.hbs"
    }
  });

  _exports.default = _default;
});