define("cb-ui/routes/request-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        projectStandards: this.store.findAll('service-standard'),
        industryStandards: this.store.findAll('industry-standard'),
        companySizeTurnover: this.store.findAll('company-size-turnover'),
        companySizeEmployees: this.store.findAll('company-size-employee'),
        countries: this.store.findAll('country'),
        serviceType: this.store.query('service-type', {
          serviceTypeEn: 'Management Consulting'
        }),
        projectGoals: this.store.findAll('project-goal'),
        // projectPhases: this.store.findAll('project-phase'),
        salutations: this.store.findAll('salutation'),
        languages: this.store.findAll('language'),
        // user:this.store.findRecord('user',124),
        // 45 represents the ID for EURO currency which is the default
        currency: this.store.find('currency', 45)
      });
    },
    setupController: function setupController(controller, model) {
      var quote = this.store.createRecord('quote', {
        // owner:model.user,
        //ramane sa pun companyAlias ,ABB,sa rezolv layout-ul si sa fac redirect
        companyAlias: 'ABB',
        currency: model.currency,
        status: 'requested',
        personSalutation: null,
        personFirstName: null,
        personLastName: null,
        projectAdministratorJobTitle: null,
        projectAdministratorEmail: null,
        projectAdministratorPhone: null,
        companyName: null,
        companyAddressLineOne: null,
        companyAddressLineTwo: null,
        companyAddressLineThree: null,
        companyPostalCode: null,
        companyCity: null,
        companyCountry: null,
        projectName: null,
        industryStandard: null,
        companySizeTurnover: null,
        companySizeEmployees: null,
        serviceType: model.serviceType.objectAt(0),
        serviceStandard: null,
        mainLocation: null,
        secondaryLocations: [],
        clientIntroduction: null,
        initialSituation: null,
        organizationProjectReference: null,
        mainLanguage: null,
        proposalLanguage: null,
        secondaryLanguages: [],
        startDate: null,
        endDate: null,
        quoteProjectGoals: [],
        quoteServiceProviders: [],
        documents: [],
        projectPhases: []
      });
      var localQuoteProjectGoals = new Ember.A();
      var localServiceProviders = new Ember.A();
      controller.set('isSendingRequest', false);
      controller.set('languages', model.languages);
      controller.set('localQuoteProjectGoals', localQuoteProjectGoals);
      controller.set('localServiceProviders', localServiceProviders);
      controller.set('projectGoals', model.projectGoals);
      controller.set('salutations', model.salutations.toArray()); // controller.set('projectPhases', model.projectPhases.toArray());

      controller.set('countries', model.countries.toArray()); // controller.set('serviceTypes', model.serviceTypes.toArray());

      controller.set('projectStandards', model.projectStandards.toArray());
      controller.set('industryStandards', model.industryStandards.toArray());
      controller.set('companySizeTurnover', model.companySizeTurnover.toArray());
      controller.set('companySizeEmployees', model.companySizeEmployees.toArray());
      controller.set('quote', quote);
      controller.setupChangeset();
      var quoteProjectGoal = this.store.createRecord('quote-project-goal', {
        quote: null,
        name: '',
        description: '',
        projectGoal: null
      });
      var localQuoteProjectGoalsArray = controller.get('localQuoteProjectGoals');
      localQuoteProjectGoalsArray.addObject(quoteProjectGoal);
    }
  });

  _exports.default = _default;
});