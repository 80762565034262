define("cb-ui/models/template", ["exports", "ember-data", "ember-api-actions"], function (_exports, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    title: attr('string'),
    type: attr('string'),
    company: belongsTo('company', {
      inverse: 'templates',
      async: true
    }),
    baseCriteriaEvaluationGroups: hasMany('base-criteria-evaluation-group', {
      inverse: 'template'
    }),
    bulkSave: (0, _emberApiActions.collectionAction)({
      path: 'bulk-save/',
      type: 'post'
    }),
    isDraft: attr('boolean', {
      defaultValue: true
    })
  });

  _exports.default = _default;
});