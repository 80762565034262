define("cb-ui/mixins/beauty-contest-invitation", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    notify: Ember.inject.service(),
    quoteCompany: null,
    beautyContestProposal: null,
    beautyContestInvitationText: null,
    beautyContestInvitationAddress: null,
    showBeautyContestModal: null,
    minStartDatePresentation: Ember.computed('quote.presentationStartDate', function () {
      return (0, _moment.default)().add({
        days: 1
      }).toDate();
    }),
    actions: {
      openBeautyContestModal: function openBeautyContestModal(proposal) {
        var _this = this;

        this.store.createRecord('proposal-perm', {
          proposal: proposal.get('id')
        }).save().then(function (perms) {
          if (perms.get('allowClientBeautyContestInvite') || proposal.get('ignoreTimelineValidation')) {
            _this.set('beautyContestProposal', proposal);

            _this.set('showBeautyContestModal', true);
          } else {
            var rfpReleaseDue = (0, _moment.default)(_this.get('quote.rfpReleaseDueDate')).add(1, 'days').tz('Europe/Berlin').format('DD.MM.YYYY');
            var presentationDue = (0, _moment.default)(_this.get('quote.presentationEndDate')).tz('Europe/Berlin').format('DD.MM.YYYY');

            _this.get('notify').error(_this.get('intl').t('notifyMessages.beautyContestInvitePeriod').toString() + rfpReleaseDue + _this.get('intl').t('notifyMessages.and').toString() + presentationDue + ' (CET)');
          }
        }).catch(function () {
          _this.get('notify').error(_this.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
        });
      },
      closeBeautyContestModal: function closeBeautyContestModal() {
        this.set('showBeautyContestModal', false);
        var proposal = this.get('beautyContestProposal');
        proposal.rollbackAttributes();
        this.set('beautyContestProposal', null);
      },
      sendInvitation: function sendInvitation(callback) {
        var _this2 = this;

        var proposal = this.get('beautyContestProposal');
        proposal.set('beautyContestInvitationStatus', 'invitation sent');
        var promise = proposal.save();
        promise.then(function () {
          _this2.set('showBeautyContestModal', false);

          _this2.get('notify').success(_this2.get('intl').t('notifyMessages.invitationSentSuccess').toString());
        }).catch(function (response) {
          //TODO:Revise
          if (response.errors && response.errors[0].detail === _this2.get('intl').t('requiredField').toString()) {
            _this2.get('notify').error(_this2.get('intl').t('notifyMessages.errorOccurredVerifyFields').toString());
          } else {
            _this2.get('notify').error(response.errors[0].detail);
          }
        });
        callback(promise);
      },
      removeInvitation: function removeInvitation(proposal) {
        if (confirm(this.get('intl').t('confirmMessages.deleteInvitation').toString())) {
          proposal.set('beautyContestInvitationStatus', 'not invited');
          proposal.save(function () {
            proposal.reload();
          });
        }
      },
      openDatepicker: function openDatepicker(datepickerId) {
        var selector = '#' + datepickerId.toString() + '.start-date-picker';
        Ember.$(selector).focus();
      }
    }
  });

  _exports.default = _default;
});