define("cb-ui/templates/components/section-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qFjXLmKW",
    "block": "{\"symbols\":[],\"statements\":[[12,\"section-editor-interface\",[]],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"isEditing\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"section-modal-overlay translucent\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"section-modal-dialog\"],[7],[0,\"\\n            \"],[6,\"h4\"],[7],[1,[25,\"t\",[\"app.labels.editSectionContent\"],null],false],[0,\": \"],[1,[20,[\"section\",\"title\"]],false],[8],[0,\"\\n            \"],[1,[25,\"tinymce-editor\",null,[[\"data-test-section-textarea\",\"value\",\"parent\",\"keys\"],[true,[20,[\"section\",\"content\"]],[19,0,[]],[20,[\"sectionKeys\"]]]]],false],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"m-t-lg pull-right\"],[7],[0,\"\\n                \"],[6,\"button\"],[9,\"data-test-apply-changes\",\"\"],[9,\"class\",\"btn btn-primary\"],[3,\"action\",[[19,0,[]],\"closeEdit\"]],[7],[1,[25,\"t\",[\"buttons.applyChanges\"],null],false],[8],[0,\"\\n                \"],[6,\"button\"],[9,\"data-test-cancel-changes\",\"\"],[9,\"class\",\"btn btn-white\"],[3,\"action\",[[19,0,[]],\"cancelEdit\"]],[7],[1,[25,\"t\",[\"buttons.cancelChanges\"],null],false],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"class\",[26,[[25,\"if\",[[25,\"or\",[[20,[\"section\",\"content\"]],[20,[\"section\",\"disabled\"]]],null],\"section-editor-area\"],null]]]],[7],[0,\"\\n        \"],[6,\"div\"],[7],[0,\"\\n            \"],[1,[20,[\"section\",\"content\"]],true],[0,\"\\n        \"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"section\",\"disabled\"]]],null,{\"statements\":[[0,\"            \"],[6,\"div\"],[9,\"class\",\"section-overlay\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":true}",
    "meta": {
      "moduleName": "cb-ui/templates/components/section-editor.hbs"
    }
  });

  _exports.default = _default;
});