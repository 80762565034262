define("cb-ui/components/projects-table-clientagent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var customCssClasses = {
    outerTableWrapper: 'models-table-wrapper',
    innerTableWrapper: 'inner-table-wrapper',
    table: 'table table-striped table-bordered table-condensed table-hover table-dashboard',
    globalFilterWrapper: 'pull-left',
    columnsDropdownWrapper: 'pull-right columns-dropdown',
    columnsDropdownButtonWrapper: 'btn-group',
    columnsDropdown: 'dropdown-menu pull-right',
    theadCell: 'table-header',
    tfooterWrapper: 'table-footer clearfix',
    footerSummary: 'table-summary',
    footerSummaryNumericPagination: 'col-md-3 col-sm-3',
    footerSummaryDefaultPagination: 'col-md-8 col-sm-8',
    pageSizeWrapper: 'col-md-2 col-sm-2',
    pageSizeSelectWrapper: 'pull-right',
    paginationWrapper: 'table-nav',
    paginationWrapperNumeric: 'col-md-7 col-sm-7',
    paginationWrapperDefault: 'col-md-2 col-sm-2',
    buttonDefault: 'btn btn-white',
    noDataCell: ''
  };
  var customMessages = {
    searchLabel: "app.components.modelsTable.searchLabel",
    columnsTitle: "app.components.modelsTable.columnsTitle",
    columnsShowAll: "app.components.modelsTable.columnsShowAll",
    columnsHideAll: "app.components.modelsTable.columnsHideAll",
    columnsRestoreDefaults: "app.components.modelsTable.columnsRestoreDefaults",
    tableSummaryShow: "app.components.modelsTable.tableSummaryShow",
    tableSummaryOf: "app.components.modelsTable.tableSummaryOf",
    allColumnsAreHidden: "app.components.modelsTable.allColumnsAreHidden",
    noDataToShow: "app.components.modelsTable.noDataToShow"
  };

  var _default = Ember.Component.extend({
    items: null,
    draft: true,
    sent: true,
    completed: true,
    onHold: false,
    cancelled: false,
    customCssClasses: customCssClasses,
    filteredItems: Ember.computed('draft', 'sent', 'completed', 'onHold', 'cancelled', function () {
      var _this = this;

      var filtered = [];
      this.get('items').forEach(function (item) {
        if (_this.get('draft') && item.get('status') === 'draft') {
          filtered.push(item);
        }

        if (_this.get('sent') && item.get('status') === 'sent') {
          filtered.push(item);
        }

        if (_this.get('completed') && item.get('status') === 'completed') {
          filtered.push(item);
        }

        if (_this.get('cancelled') && item.get('status') === 'cancelled') {
          filtered.push(item);
        }

        if (_this.get('onHold') && item.get('status') === 'on hold') {
          filtered.push(item);
        }
      });
      return filtered;
    }),
    customMessages: Ember.computed('intl.locale.[]', function () {
      return {
        "searchLabel": this.get('intl').t(customMessages.searchLabel).toString(),
        "columns-title": this.get('intl').t(customMessages.columnsTitle).toString(),
        "columns-showAll": this.get('intl').t(customMessages.columnsShowAll).toString(),
        "columns-hideAll": this.get('intl').t(customMessages.columnsHideAll).toString(),
        "columns-restoreDefaults": this.get('intl').t(customMessages.columnsRestoreDefaults).toString(),
        "tableSummary": this.get('intl').t(customMessages.tableSummaryShow).toString() + ' %@ - %@ ' + this.get('intl').t(customMessages.tableSummaryOf).toString() + ' %@',
        "allColumnsAreHidden": this.get('intl').t(customMessages.allColumnsAreHidden).toString(),
        "noDataToShow": this.get('intl').t(customMessages.noDataToShow).toString()
      };
    }),
    columnsFieldsToUpdate: ['title', 'propertyName', 'template'],
    columns: Ember.computed('intl.locale.[]', function () {
      return [{
        propertyName: 'intID',
        title: 'ID',
        className: 'table-id',
        sortPrecedence: 0,
        sortDirection: 'desc'
      }, {
        propertyName: 'projectName',
        title: this.get('intl').t("app.dashboardClient.projects.projectName").toString()
      }, {
        propertyName: 'consultantsNames.names',
        title: this.get('intl').t("app.dashboardClient.projects.consultants").toString(),
        component: 'models-table/consultants',
        className: 'consulting-firms-spacing'
      }, {
        propertyName: 'ownerName',
        title: this.get('intl').t("app.dashboardClient.projects.projectManager").toString()
      }, {
        propertyName: 'publishedAt',
        title: this.get('intl').t("app.dashboardClient.projects.submittedAt").toString(),
        component: 'models-table/published',
        className: "text-center"
      }, {
        propertyName: 'projectAwardDate',
        title: this.get('intl').t("app.dashboardClient.projects.awardDate").toString(),
        component: 'models-table/award',
        className: "text-center"
      }, {
        propertyName: 'statusAlias',
        title: this.get('intl').t("app.dashboardClient.projects.projectStatus").toString(),
        component: 'models-table/status',
        className: "text-center"
      }, {
        title: this.get('intl').t("app.dashboardClient.projects.actions").toString(),
        component: 'models-table/select-project'
      }];
    }),
    actions: {
      selectProject: function selectProject(record) {
        this.sendAction('selectProject', record);
      },
      showPreviewProject: function showPreviewProject(record) {
        this.sendAction('showPreviewProject', record);
      }
    }
  });

  _exports.default = _default;
});