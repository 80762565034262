define("cb-ui/mixins/loading-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      loading: function loading() {
        var controller = this.controllerFor('application');
        controller.set('loading', true);

        if (this.router) {
          this.router.one('didTransition', function () {
            controller.set('loading', false);
          });
        }
      },
      finished: function finished() {
        this.controllerFor('application').set('loading', false);
      }
    }
  });

  _exports.default = _default;
});