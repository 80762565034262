define("cb-ui/routes/wizard/index", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    model: function model(obj, transition) {
      var cbStore = this.get('cbStore');
      return Ember.RSVP.hash({
        countries: cbStore.findAllWrapper('country', 'commonNameEn'),
        industries: cbStore.findAllWrapper('industryStandard', 'mpttOrder'),
        services: cbStore.findAllWrapper('serviceStandard', 'mpttOrder'),
        businessTypes: cbStore.findAllWrapper('businessType', 'businessTypeEn'),
        companySizeTurnovers: cbStore.findAllWrapper('companySizeTurnover', 'id'),
        companySizeEmployees: cbStore.findAllWrapper('companySizeEmployee', 'id'),
        projectGoals: cbStore.findAllWrapper('projectGoal', 'projectGoalEn'),
        serviceTypes: cbStore.findAllWrapper('serviceType', 'id'),
        quote: this.store.find('quote', transition.params.wizard.quote_uuid)
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      var projectServices = [];
      var leafProjectServices = Ember.A();
      var projectIndustries = [];
      var leafProjectIndustries = Ember.A();
      var sortedServices = model.services;
      var sortedIndustries = model.industries;
      controller.set('quote', model.quote);
      model.quote.get('secondaryLocations').then(function (countries) {
        var localCountries = [];
        countries.forEach(function (country) {
          localCountries.push(country);
        });
        controller.set('localSecondaryLocations', localCountries);
      }); // const projectPhases = model.projectPhases;
      // const localProjectPhasesItems = new A();
      // model.quote.get('projectPhases').then(quoteProjectPhases=> {
      //   projectPhases.forEach(projectPhase=> {
      //     localProjectPhasesItems.addObject({
      //       projectPhase: projectPhase,
      //       isChecked: quoteProjectPhases.includes(projectPhase) ? true : false,
      //     });
      //   });
      //   controller.set('localProjectPhasesItems', localProjectPhasesItems);
      // });
      // const localLanguages = new A();
      // model.quote.get('secondaryLanguages').then(secondaryLanguages=> {
      //   secondaryLanguages.forEach(language=> {
      //     localLanguages.addObject(language);
      //   });
      //   controller.set('localSecondaryLanguages', localLanguages);
      // });

      var quote = model.quote;
      var selectedLanguage = this.get('localStorage.language') || 'en';
      var propertyNameService = selectedLanguage === 'de' ? 'serviceDe' : 'serviceEn';
      sortedServices.forEach(function (service) {
        if (!service.get('parent.id')) {
          var children = _this.generateChildrenNameList(service, sortedServices, propertyNameService);

          projectServices.push(children);
        }

        if (!service.get('children.length')) {
          leafProjectServices.addObject(service);
        }
      });
      var propertyNameIndustry = selectedLanguage === 'de' ? 'industryDe' : 'industryEn';
      sortedIndustries.forEach(function (industry) {
        if (!industry.get('parent.id')) {
          var children = _this.generateChildrenNameList(industry, sortedIndustries, propertyNameIndustry);

          projectIndustries.push(children);
        }

        if (!industry.get('children.length')) {
          leafProjectIndustries.addObject(industry);
        }
      });
      quote.get('projectGoals').then(function (projectGoals) {
        var localProjectGoals = new Ember.A();
        var quoteProjectGoalsOrder = quote.get('projectGoalsOrder');
        var projectGoalsOrder;

        if (typeof quoteProjectGoalsOrder === 'string') {
          projectGoalsOrder = JSON.parse(quoteProjectGoalsOrder);
        } else {
          projectGoalsOrder = [];
        }

        if (projectGoalsOrder) {
          projectGoalsOrder.forEach(function (pgId) {
            var projectGoal = projectGoals.findBy('id', pgId.toString());

            if (projectGoal) {
              localProjectGoals.addObject(projectGoal);
            }
          });
        }

        controller.set('localProjectGoals', localProjectGoals);
      });
      quote.get('quoteProjectGoals').then(function (quoteProjectGoals) {
        var localQuoteProjectGoals = new Ember.A();
        quoteProjectGoals.forEach(function (quoteProjectGoal) {
          localQuoteProjectGoals.addObject(quoteProjectGoal);
        });
        controller.set('localQuoteProjectGoals', localQuoteProjectGoals);
      });
      var projectGoalsEnArray = new Ember.A();
      var projectGoalsDeArray = new Ember.A();
      model.projectGoals.forEach(function (item) {
        projectGoalsEnArray.pushObject(item.get('projectGoalEn'));
        projectGoalsDeArray.pushObject(item.get('projectGoalDe'));
      });
      controller.set('projectGoalsEn', projectGoalsEnArray);
      controller.set('projectGoalsDe', projectGoalsDeArray);
      controller.set('projectServices', projectServices);
      controller.set('leafProjectServices', leafProjectServices.sortBy(propertyNameService));
      controller.set('projectIndustries', projectIndustries);
      controller.set('leafProjectIndustries', leafProjectIndustries.sortBy(propertyNameIndustry)); //controller.set('projectService', controller.get('quote.serviceStandard.' + propertyNameService));
      //controller.set('projectIndustry', controller.get('quote.industryStandard.' + propertyNameIndustry));

      controller.set('projectService', quote.get('serviceStandard'));
      controller.set('projectIndustry', quote.get('industryStandard'));
      controller.set('propertyNameService', propertyNameService);
      controller.set('propertyNameIndustry', propertyNameIndustry);
      controller.set('componentHTML', null);
      var serviceTypes = new Ember.A();
      model.serviceTypes.forEach(function (st) {
        serviceTypes.addObject(st);
      });
      controller.set('serviceTypes', serviceTypes);
      controller.set('serviceType', null);
      quote.get('serviceType').then(function (serviceType) {
        if (serviceType) {
          controller.set('serviceType', serviceType.get('serviceTypeEn'));
        }
      });
      controller.set('quote.currentStep', 10);
      controller.setInitialIndustryService();
    },
    generateChildrenNameList: function generateChildrenNameList(parentNode, nodesList, propertyName) {
      var _this2 = this;

      var children = nodesList.filterBy('parent.id', parentNode.get('id'));

      if (!children.get('length')) {
        return parentNode.get(propertyName);
      } else {
        return {
          groupName: parentNode.get(propertyName),
          options: children.map(function (child) {
            return _this2.generateChildrenNameList(child, nodesList, propertyName);
          })
        };
      }
    }
  });

  _exports.default = _default;
});