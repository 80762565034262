define("cb-ui/helpers/user-proposal-evaluation-status-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getUserProposalEvaluationStatusInfo = getUserProposalEvaluationStatusInfo;
  _exports.default = void 0;

  function getUserProposalEvaluationStatusInfo(params, namedArgs) {
    var intl = namedArgs.intl;

    if (namedArgs.userProposalEvaluationStatuses) {
      var user = namedArgs.user;
      var proposal = namedArgs.proposal;
      var userProposalEvaluationStatuses = namedArgs.userProposalEvaluationStatuses;
      var currentStatus = userProposalEvaluationStatuses.find(function (item) {
        return item.get('proposal.id') === proposal.get('id') && item.get('user.id') === user.get('id');
      });
      return currentStatus ? intl.t('app.helpers.userProposalEvaluationStatusInfo.' + currentStatus.get('status')).toString() : intl.t('app.helpers.userProposalEvaluationResult.notYetAccepted').toString();
    }
  }

  var _default = Ember.Helper.helper(getUserProposalEvaluationStatusInfo);

  _exports.default = _default;
});