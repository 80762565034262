define("cb-ui/templates/local-storage-language", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eUgFPUXA",
    "block": "{\"symbols\":[],\"statements\":[[6,\"a\"],[9,\"href\",\"#\"],[9,\"class\",\"dropdown-toggle\"],[9,\"data-toggle\",\"dropdown\"],[9,\"role\",\"button\"],[7],[0,\"\\n\"],[4,\"if\",[[25,\"eq\",[[20,[\"intl\",\"locale\",\"firstObject\"]],\"de\"],null]],null,{\"statements\":[[0,\"      \"],[6,\"img\"],[9,\"src\",\"images/DE.png\"],[9,\"alt\",\"German\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"img\"],[9,\"src\",\"images/EN.png\"],[9,\"alt\",\"English\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[6,\"span\"],[9,\"class\",\"caret color-white\"],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"ul\"],[9,\"class\",\"dropdown-menu\"],[9,\"role\",\"menu\"],[7],[0,\"\\n    \"],[6,\"li\"],[3,\"action\",[[19,0,[]],\"changeLocalStorageLanguage\",\"en\"]],[7],[0,\"\\n        \"],[6,\"a\"],[7],[0,\"\\n            \"],[6,\"img\"],[9,\"src\",\"images/EN.png\"],[9,\"alt\",\"English\"],[7],[8],[0,\"\\n            \"],[6,\"span\"],[7],[1,[25,\"t\",[\"app.modals.projectLanguage.en\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"li\"],[3,\"action\",[[19,0,[]],\"changeLocalStorageLanguage\",\"de\"]],[7],[0,\"\\n        \"],[6,\"a\"],[7],[0,\"\\n            \"],[6,\"img\"],[9,\"src\",\"images/DE.png\"],[9,\"alt\",\"German\"],[7],[8],[0,\"\\n          \"],[1,[25,\"t\",[\"app.modals.projectLanguage.de\"],null],false],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/local-storage-language.hbs"
    }
  });

  _exports.default = _default;
});