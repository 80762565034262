define("cb-ui/routes/app/management-consultant/proposals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(obj, transition) {
      return Ember.RSVP.hash({
        proposals: this.store.findAll('proposal'),
        currentUser: this.get('sessionContext.currentUser'),
        companyUser: this.store.findRecord('user', obj.user_id),
        userProposalsRoles: this.store.query('user-proposal-role', {
          user_id: obj.user_id
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('currentUser', model.currentUser);
      controller.setProperties({
        currentUser: model.currentUser,
        companyUser: model.companyUser,
        userProposalsAccess: model.userProposalsRoles
      });
      var proposalsWithAccessIds = model.userProposalsRoles.map(function (item) {
        return item.get('proposal.id');
      });
      var proposalsWithoutAccess = model.proposals.filter(function (item) {
        return !proposalsWithAccessIds.contains(item.get('id'));
      });
      controller.set('proposalsWithoutAccess', proposalsWithoutAccess);
      controller.set('newProposalsAccess', new Ember.A());
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});