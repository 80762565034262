define("cb-ui/components/power-select-multiple2", ["exports", "ember-power-select/components/power-select-multiple"], function (_exports, _powerSelectMultiple) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _powerSelectMultiple.default.extend({
    actions: {
      handleOpen: function handleOpen() {
        return false;
      }
    }
  });

  _exports.default = _default;
});