define("cb-ui/helpers/user-proposal-evaluation-result", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params, namedArgs) {
      if (namedArgs.proposalsEvaluations) {
        var user = namedArgs.user;
        var criteriaEvaluation = namedArgs.criteria;
        var proposalsEvaluations = namedArgs.proposalsEvaluations;
        var proposal = namedArgs.proposal;
        var proposalEvaluation = proposalsEvaluations.find(function (item) {
          return item.get('user.id') === user.id && item.get('criteriaEvaluation.id') === criteriaEvaluation.get('id') && item.get('proposal.id') === proposal.id;
        });

        if (proposalEvaluation) {
          var evaluationScore = proposalEvaluation.get('score');

          if (evaluationScore === 0) {
            return this.get('intl').t('app.helpers.userProposalEvaluationResult.notYetRated').toString();
          } else if (evaluationScore === 1) {
            return this.get('intl').t('app.helpers.userProposalEvaluationResult.disagree').toString();
          } else if (evaluationScore === 2) {
            return this.get('intl').t('app.helpers.userProposalEvaluationResult.pDisagree').toString();
          } else if (evaluationScore === 3) {
            return this.get('intl').t('app.helpers.userProposalEvaluationResult.pAgree').toString();
          } else if (evaluationScore === 4) {
            return this.get('intl').t('app.helpers.userProposalEvaluationResult.agree').toString();
          }
        } else {
          return this.get('intl').t('app.helpers.userProposalEvaluationResult.notYetAccepted').toString();
        }
      }
    }
  });

  _exports.default = _default;
});