define("cb-ui/components/top-navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      logout: function logout() {
        this.sendAction('logout');
      },
      changeLocalStorageLanguage: function changeLocalStorageLanguage(language) {
        this.sendAction('changeLocalStorageLanguage', language);
      }
    }
  });

  _exports.default = _default;
});