define("cb-ui/controllers/app/project/client/timeline-configuration", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _this = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    quote: null,
    currentUser: null,
    localQuoteTimelineEntries: new Ember.A(),
    pitchPresentationGroup: null,
    sortedLocalQuoteTimelineEntries: Ember.computed('localQuoteTimelineEntries.@each.date', function () {
      var entries = this.get('localQuoteTimelineEntries');

      if (this.get('quote.pitchWeight') === 0) {
        var psd = entries.findBy('name', 'presentationStartDate');
        var ped = entries.findBy('name', 'presentationEndDate');

        if (psd) {
          entries.removeObject(psd);
        }

        if (ped) {
          entries.removeObject(ped);
        }
      }

      entries.sort(function (el1, el2) {
        if (!el1.get('date') && !el2.get('date')) {
          return 0;
        }

        if (!!el1.get('date') ^ !!el2.get('date')) {
          return el1.get('date') ? -1 : 1;
        }

        if (el1.get('date') == el2.get('date')) {
          return 0;
        }

        return el1.get('date') < el2.get('date') ? -1 : 1;
      });
      return entries;
    }),
    rfpReleaseTimelineEntry: Ember.computed('localQuoteTimelineEntries.[]', function () {
      return _this.get('localQuoteTimelineEntries').findBy('name', 'rfpReleaseDue');
    }),
    validateTimelineEntries: Ember.on('init', Ember.observer('sortedLocalQuoteTimelineEntries.@each.date', function () {
      var _this2 = this;

      Ember.run.once(function () {
        var entries = _this2.get('sortedLocalQuoteTimelineEntries');

        if (entries.length < 3) {
          return;
        }

        var rfpRelease = entries.findBy('name', 'rfpReleaseDueDate');
        var proposalDue = entries.findBy('name', 'proposalDueDate');
        var projectAward = entries.findBy('name', 'projectAwardDate');
        var projectStartDate = entries.findBy('slug', 'project_start_date');
        var projectEndDate = entries.findBy('slug', 'project_end_date');
        var psd = entries.findBy('name', 'presentationStartDate');
        var ped = entries.findBy('name', 'presentationEndDate');

        if (!rfpRelease.get('date')) {
          entries.forEach(function (entry) {
            entry.set('disabled', true);
          });
          rfpRelease.set('disabled', false);
          rfpRelease.set('minDate', new Date());
          return;
        } else {
          var anyDateSet = entries.filterBy('isCustom', false).any(function (item) {
            return item.get('name') != 'rfpReleaseDueDate' && !!item.get('date');
          });

          if (!anyDateSet) {
            proposalDue.set('date', (0, _moment.default)(rfpRelease.get('date')).add(3, 'weeks').toDate());
            projectAward.set('date', (0, _moment.default)(rfpRelease.get('date')).add(4, 'weeks').toDate());

            if (psd) {
              psd.set('date', (0, _moment.default)(rfpRelease.get('date')).add(3, 'weeks').toDate());
              ped.set('date', (0, _moment.default)(rfpRelease.get('date')).add(25, 'days').toDate());
            }
          }

          proposalDue.set('minDate', (0, _moment.default)(rfpRelease.get('date')).add(1, 'days').toDate());
          proposalDue.set('disabled', false);
          projectAward.set('minDate', (0, _moment.default)(proposalDue.get('date')).add(1, 'days').toDate());
          projectAward.set('disabled', false);
          rfpRelease.set('maxDate', (0, _moment.default)(proposalDue.get('date')).subtract(1, 'days').toDate());

          if (psd && ped) {
            psd.set('disabled', false);
            psd.set('minDate', rfpRelease.get('date'));
            psd.set('minDate', rfpRelease.get('date'));

            if (psd.get('date')) {
              ped.set('minDate', (0, _moment.default)(psd.get('date')).add('1', 'days').toDate());
              ped.set('maxDate', projectAward.get('date'));
              ped.set('disabled', false);

              if (psd.get('date') < projectAward.get('minDate')) {
                projectAward.set('minDate', psd.get('date'));
              }
            } else {
              ped.set('disabled', true);
            }

            if (ped.get('date')) {
              psd.set('maxDate', ped.get('date'));

              if (ped.get('date') < projectAward.get('minDate')) {
                projectAward.set('minDate', ped.get('date'));
              }
            }
          }

          entries.filterBy('isCustom', true).forEach(function (entry) {
            if (entry.get('slug') == 'project_start_date' || entry.get('slug') == 'project_end_date') {
              return;
            }

            entry.set('disabled', false);
            entry.set('minDate', rfpRelease.get('date'));

            if (entry.get('date') && entry.get('date') > rfpRelease.get('maxDate')) {
              rfpRelease.set('maxDate', entry.get('date'));
            }
          });

          if (projectStartDate) {
            if (projectAward.get('date')) {
              projectStartDate.set('disabled', false);
            }
          }

          if (projectEndDate) {
            if (projectAward.get('date')) {
              projectEndDate.set('disabled', false);
            }
          }
        }
      });
    })),
    actions: {
      removeTimelineEntry: function removeTimelineEntry(timelineEntry) {
        this.get('localQuoteTimelineEntries').removeObject(timelineEntry);
      },
      addTimelineEntry: function addTimelineEntry() {
        var newEntry = this.store.createRecord('quote-timeline-entry', {});
        newEntry.set('isCustom', true);
        this.get('localQuoteTimelineEntries').addObject(newEntry);
      },
      saveQuote: function saveQuote() {
        var _this3 = this;

        var quote = this.get('quote');

        if (quote.get('status') !== 'cancelled') {
          var localQuoteTimelineEntries = this.get('localQuoteTimelineEntries').filterBy('isCustom', true);
          quote.set('timelineEntries', localQuoteTimelineEntries);
          var quoteRelatedPromises = [];
          localQuoteTimelineEntries.forEach(function (item) {
            quoteRelatedPromises.push(item.save({
              adapterOptions: {
                validate: true
              }
            }));
          });
          var mandatoryQuoteTimelineEntries = this.get('localQuoteTimelineEntries').filterBy('isCustom', false);
          mandatoryQuoteTimelineEntries.forEach(function (quoteMileStone) {
            if (quoteMileStone.get('label') === 'RfP release Due' || quoteMileStone.get('label') === 'Veröffentlichung des RFP') {
              quote.set('rfpReleaseDueDate', quoteMileStone.get('date'));
            }

            if (quoteMileStone.get('label') === 'Proposal Due' || quoteMileStone.get('label') === 'Angebotseingang bis') {
              quote.set('proposalDueDate', quoteMileStone.get('date'));
            }

            if (quoteMileStone.get('label') === 'Project Award' || quoteMileStone.get('label') === 'Projektvergabe') {
              quote.set('projectAwardDate', quoteMileStone.get('date'));
            }

            if (quoteMileStone.get('label') === 'Pitch Presentation Start' || quoteMileStone.get('label') === 'Start der Pitch-Präsentation') {
              quote.set('presentationStartDate', quoteMileStone.get('date'));
            }

            if (quoteMileStone.get('label') === 'Pitch Presentation End' || quoteMileStone.get('label') === 'Ende der Pitch-Präsentation') {
              quote.set('presentationEndDate', quoteMileStone.get('date'));
            }

            if (quoteMileStone.get('label') === 'Project start date' || quoteMileStone.get('label') === 'Projektstartdatum') {
              quote.set('startDate', quoteMileStone.get('date'));
            }

            if (quoteMileStone.get('label') === 'Project end date' || quoteMileStone.get('label') === 'Projektenddatum') {
              quote.set('endDate', quoteMileStone.get('date'));
            }
          });
          return Ember.RSVP.all(quoteRelatedPromises).then(function () {
            return quote.save().then(function () {
              _this3.get('notify').success(_this3.get('intl').t('app.projectClient.configuration.timeline.success').toString());
            });
          }).catch(function (err) {
            _this3.get('notify').error(_this3.get('intl').t('notifyMessages.errorOccurredFixErrors').toString());

            Ember.run.later(function () {
              if (Ember.$('div.has-error:first') && Ember.$('div.has-error:first').length) {
                Ember.$('html, body').animate({
                  scrollTop: Ember.$('div.has-error:first').offset().top - 175
                });
              }
            }, 200);
            throw err;
          });
        }
      }
    }
  });

  _exports.default = _default;
});