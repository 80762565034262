define("cb-ui/models/study", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    title: attr('string'),
    descriptionEn: attr('string'),
    descriptionDe: attr('string'),
    year: attr('number'),
    logo: attr('string') // !! DO NOT ENABLE !! Many to many relation causes problems if serialized on both sides
    // companies: hasMany('company', {
    //   async: true,
    //   inverse: 'studies',
    // }),

  });

  _exports.default = _default;
});