define("cb-ui/routes/app/project/client/configuration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var quote = this.modelFor('app.project');
      return Ember.RSVP.hash({
        templates: this.store.query('template', {
          default_templates: true,
          global_templates: true
        }),
        criteriaEvaluationGroups: this.store.query('criteria-evaluation-group', {
          quote_id: quote.get('id'),
          include: 'evaluations'
        }),
        quote: quote,
        currentUser: this.get('sessionContext.currentUser'),
        userQuoteRoleList: this.store.query('user-quote-role', {
          quote_id: quote.get('id')
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var quote = model.quote;
      var technicalTemplates = new Ember.A();
      var pitchTemplates = new Ember.A();
      var defaultTechnicalTemplates = new Ember.A();
      var companyTechnicalTemplates = new Ember.A();
      var defaultPitchTemplates = new Ember.A();
      var companyPitchTemplates = new Ember.A();
      var technicalQuoteCriteriaGroups = new Ember.A();
      var pitchQuoteCriteriaGroups = new Ember.A();
      controller.set('quote', quote);
      controller.set('userQuoteRole', model.userQuoteRoleList.get('firstObject'));
      controller.set('initialFinancialWeight', quote.get('financialWeight'));
      controller.set('initialTechnicalWeight', quote.get('technicalWeight'));
      controller.set('initialPitchWeight', quote.get('pitchWeight'));
      controller.set('showCoverLetterQuestion', false);
      controller.set('technicalTemplate', null);
      controller.set('pitchTemplate', null);
      model.templates.forEach(function (template) {
        var templateType = template.get('type');

        if (templateType === 'technical_proposal') {
          technicalTemplates.addObject(template);

          if (template.get('company.id')) {
            companyTechnicalTemplates.addObject(template);
          } else {
            defaultTechnicalTemplates.addObject(template);
          }
        } else if (templateType === 'pitch') {
          pitchTemplates.addObject(template);

          if (template.get('company.id')) {
            companyPitchTemplates.addObject(template);
          } else {
            defaultPitchTemplates.addObject(template);
          }
        }
      });
      controller.set('technicalTemplatesOptions', [{
        groupName: this.get('intl').t('app.rfp.criteriaEvaluation.label.companyTemplates').toString(),
        options: companyTechnicalTemplates.sortBy('title')
      }, {
        groupName: this.get('intl').t('app.rfp.criteriaEvaluation.label.defaultTemplates').toString(),
        options: defaultTechnicalTemplates.sortBy('title')
      }]);
      controller.set('pitchTemplatesOptions', [{
        groupName: this.get('intl').t('app.rfp.criteriaEvaluation.label.companyTemplates').toString(),
        options: companyPitchTemplates.sortBy('title')
      }, {
        groupName: this.get('intl').t('app.rfp.criteriaEvaluation.label.defaultTemplates').toString(),
        options: defaultPitchTemplates.sortBy('title')
      }]);
      model.criteriaEvaluationGroups.forEach(function (ceg) {
        var groupType = ceg.get('type');

        if (groupType === 'technical_proposal') {
          technicalQuoteCriteriaGroups.addObject(ceg);
        } else if (groupType === 'pitch') {
          pitchQuoteCriteriaGroups.addObject(ceg);
        }
      });
      controller.set('technicalQuoteCriteriaGroups', technicalQuoteCriteriaGroups);
      controller.set('pitchQuoteCriteriaGroups', pitchQuoteCriteriaGroups);
      controller.set('showTechnicalTemplatesDropdown', !technicalQuoteCriteriaGroups.get('length'));
      controller.set('showPitchTemplatesDropdown', !pitchQuoteCriteriaGroups.get('length'));
      controller.set('currentUser', model.currentUser);
      model.currentUser.get('company').then(function (company) {
        controller.set('currentUserCompany', company);
      });
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});