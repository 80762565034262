define("cb-ui/controllers/wizard/project-details", ["exports", "cb-ui/mixins/wizard-controller", "ember-intl"], function (_exports, _wizardController, _emberIntl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_wizardController.default, {
    quote: null,
    notify: Ember.inject.service('notify'),
    otherProjectRole: 'otherProjectRole',
    showCommentModal: null,
    projectContext: null,
    projectRole: null,
    commentModalText: (0, _emberIntl.translationMacro)('wizard.info.projectDetails'),
    componentHTML: null,
    initComponentHTML: Ember.on('init', function () {
      this.set('componentHTML', this.get('languageHTML'));
    }),
    languageHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.mainLanguage'),
    // info for form fields
    projectContextHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.projectContext'),
    personalDataHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.personalData'),
    projectRoleHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.projectRole'),
    minEndDate: Ember.computed('quote.startDate', function () {
      var startDate = this.get('quote.startDate');

      if (startDate) {
        return startDate;
      }

      return new Date();
    }),
    actions: {
      changeInstructionsText: function changeInstructionsText(componentType) {
        this.set('componentHTML', this.get(componentType + 'HTML'));

        if (this.get('media.isMobile') || this.get('media.isTablet')) {
          this.set('commentModalText', this.get('componentHTML'));
          this.set('showCommentModal', true);
        }
      },
      choosePrimaryLanguage: function choosePrimaryLanguage(language) {
        this.set('quote.mainLanguage', language);
      },
      chooseSecondaryLanguages: function chooseSecondaryLanguages(languages) {
        this.set('localSecondaryLanguages', languages);
      },
      chooseProjectContext: function chooseProjectContext(context) {
        this.set('quote.projectContext', context);
      },
      chooseProjectPhases: function chooseProjectPhases(phases) {
        this.set('quote.projectPhases', phases);
      },
      chooseProjectRole: function chooseProjectRole(role) {
        this.set('quote.projectRole', role);
      },
      wrapperFocusTriggered: function wrapperFocusTriggered(element, componentType) {
        if (componentType === 'projectGoals') {
          this.set('showProjectGoals', true);
          this.set('componentHTML', this.get('basicHTML'));
        } else {
          this.set('showProjectGoals', false);
          this.set('componentHTML', this.get(componentType + 'HTML'));
        }
      },
      openDatepicker: function openDatepicker(dateType) {
        if (dateType === 'start') {
          Ember.$('.start-date-picker').focus();
        } else {
          Ember.$('.end-date-picker').focus();
        }
      },
      next: function next() {
        var _this = this;

        var quote = this.get('quote');
        var extraProjectRole = quote.get('extraProjectRole');

        if (this.get('projectRole') !== this.get('otherProjectRole')) {
          quote.set('extraProjectRole', null);
        }

        quote.save().then(function () {
          _this.transitionToRoute('wizard.confirm');
        }).catch(function () {
          quote.set('extraProjectRole', extraProjectRole);

          _this.get('notify').error(_this.get('intl').t('notifyMessages.errorOccurredFixErrors').toString());
        });
      },
      previous: function previous() {
        this.transitionToRoute('wizard.consultants');
      },
      commentModalShow: function commentModalShow() {
        this.set('commentModalText', (0, _emberIntl.translationMacro)('wizard.info.projectDetails'));
        this.set('showCommentModal', true);
      },
      closeCommentModal: function closeCommentModal() {
        this.set('showCommentModal', false);
      },
      projectContextChanged: function projectContextChanged(value) {
        if (value) {
          var projectContexts = this.get('model.projectContexts');
          this.set('quote.projectContext', projectContexts.findBy('contextEn', value));
        }
      },
      projectRoleChanged: function projectRoleChanged(value) {
        if (value) {
          if (value === this.get('otherProjectRole')) {
            this.set('quote.projectRole', null);
          } else {
            var projectRoles = this.get('model.projectRoles');
            this.set('quote.projectRole', projectRoles.findBy('roleEn', value));
          }
        }
      }
    }
  });

  _exports.default = _default;
});