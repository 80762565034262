define("cb-ui/templates/comment-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LW8+FcJA",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"bs-modal\",null,[[\"footer\",\"body\",\"onHidden\",\"size\"],[false,false,[25,\"action\",[[19,0,[]],\"closeCommentModal\"],null],\"lg\"]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[19,1,[\"body\"]]],null,{\"statements\":[[0,\"\\n    \"],[1,[18,\"commentModalText\"],true],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[25,\"component\",[[19,1,[\"footer\"]]],[[\"closeTitle\"],[[25,\"t\",[\"buttons.close\"],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/comment-modal.hbs"
    }
  });

  _exports.default = _default;
});