define("cb-ui/templates/next-button-async", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BqIEc091",
    "block": "{\"symbols\":[\"component\",\"state\"],\"statements\":[[4,\"async-button\",null,[[\"class\",\"data-test-next-button\",\"action\"],[\"btn btn-primary\",true,[25,\"action\",[[19,0,[]],\"next\"],null]]],{\"statements\":[[4,\"if\",[[19,2,[\"isDefault\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"t\",[\"buttons.next\"],null],false],[0,\"\\n      \"],[1,[25,\"fa-icon\",[\"arrow-right\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[19,2,[\"isPending\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"t\",[\"buttons.saving\"],null],false],[0,\"\\n      \"],[1,[25,\"fa-icon\",[\"spinner\"],[[\"spin\"],[true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[19,2,[\"isRejected\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"t\",[\"buttons.retry\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[19,2,[\"isFulfilled\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"t\",[\"buttons.saved\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/next-button-async.hbs"
    }
  });

  _exports.default = _default;
});