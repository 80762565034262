define("cb-ui/components/tinymce-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['text-editor'],
    parent: null,
    //parent component dependency
    opened: false,
    value: null,
    keys: null,
    _options: {
      removed_menuitems: 'newdocument visualaid code Blocks',
      inline_styles: false,
      skin_url: '/tinymce/skins/lightgray',
      theme_url: '/tinymce/themes/modern/theme.min.js',
      content_css: '/plugins/variable.css',
      external_plugins: {
        noneditable: '/tinymce/plugins/noneditable/plugin.min.js',
        textcolor: '/tinymce/plugins/textcolor/plugin.min.js',
        variable: '/plugins/variable.js',
        variable_list: '/plugins/variable_list.js'
      },
      menubar: false,
      toolbar1: 'bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor | variable variable_list',
      extended_valid_elements: "span[class|style|width|height|align|name|data-variable-key|data-variable-value|data-variable-default|data-type]",
      remove_trailing_brs: false
    },
    didInsertElement: function () {
      if (!this.get('opened')) {
        var component = this;
        var options = this.get('_options');
        var hasVariables = this.get('hasVariables');
        var hasExternalPluginsKey = 'variable' in this._options.external_plugins;

        if (hasVariables === false) {
          delete this._options.external_plugins['variable'];
          delete this._options.external_plugins['variable_list'];
        } else if (hasExternalPluginsKey === false) {
          this._options.external_plugins['variable'] = '/plugins/variable.js';
          this._options.external_plugins['variable_list'] = '/plugins/variable_list.js';
        }

        Ember.merge(options, {
          setup: function setup(editor) {
            // bind change event
            //component.set('editor', editor);
            component.set('parent.editor', editor); //editor.on('change', function() {
            //  component.set('value',
            //    editor.getContent());
            //});
          },
          sectionKeys: this.get('keys')
        });
        this.$('textarea').tinymce(options);
        this.set('opened', true);
      }
    }.on('didInsertElement'),
    //valueChanged: function() {
    //  var controller = this;
    //  tinymce.editors.filter(function(editor) {
    //    return editor.id === controller.get('parent.editor').id;
    //  }).forEach(function(editor) {
    //    //editor.setContent(controller.get('value'));
    //  });
    //}.observes('value'),
    willDestroyElement: function willDestroyElement() {
      //this.get('editor').off('change');
      this.$('textarea').tinymce().destroy();
    }
  });

  _exports.default = _default;
});