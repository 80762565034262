define("cb-ui/templates/components/multiple-select-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y44LlH/h",
    "block": "{\"symbols\":[],\"statements\":[[6,\"label\"],[9,\"class\",\"item-info pointer-cursor\"],[7],[0,\"\\n    \"],[1,[25,\"translate-model-field\",[[20,[\"item\"]],\"projectGoal\",[20,[\"language\"]]],null],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/multiple-select-item.hbs"
    }
  });

  _exports.default = _default;
});