define("cb-ui/templates/components/label-with-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RwGRUOnZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"  \"],[6,\"label\"],[7],[0,\"\\n    \"],[11,1],[0,\"\\n    \"],[1,[18,\"label\"],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"label\"],[10,\"class\",[26,[\"control-label \",[25,\"if\",[[20,[\"invisibleLabel\"]],\"sr-only\"],null],\" \",[18,\"labelClass\"]]]],[10,\"for\",[18,\"formElementId\"],null],[7],[1,[18,\"label\"],false],[0,\"\\n\"],[4,\"if\",[[20,[\"tooltipText\"]]],null,{\"statements\":[[0,\"      \"],[6,\"span\"],[10,\"id\",[26,[\"tool-\",[18,\"formElementId\"]]]],[7],[0,\"\\n\"],[4,\"bs-tooltip\",null,[[\"placement\"],[\"left\"]],{\"statements\":[[0,\"          \"],[6,\"h4\"],[7],[0,\"\\n            \"],[6,\"span\"],[9,\"class\",\"glyphicon glyphicon-info-sign info-sign-26-middle\"],[7],[8],[0,\"\\n            \"],[1,[25,\"t\",[\"tooltip.information\"],null],false],[0,\"\\n          \"],[8],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"m-t m-b-sm bold\"],[7],[1,[25,\"titleize\",[[20,[\"label\"]]],null],false],[8],[0,\"\\n          \"],[6,\"p\"],[9,\"class\",\"m-b-lg\"],[7],[1,[18,\"tooltipText\"],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[6,\"span\"],[9,\"class\",\"glyphicon glyphicon-info-sign pointer-cursor m-l-xs\"],[7],[8],[0,\"\\n\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/label-with-tooltip.hbs"
    }
  });

  _exports.default = _default;
});