define("cb-ui/components/ember-table/financial-total-proposal-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    cellValue: null,
    showVolumeModal: false
  });

  _exports.default = _default;
});