define("cb-ui/routes/app/dashboard/client", ["exports", "cb-ui/utils/append-access-token", "cb-ui/utils/get-target-domain"], function (_exports, _appendAccessToken, _getTargetDomain) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      window.location = (0, _appendAccessToken.default)("".concat((0, _getTargetDomain.default)(), "/dashboard/rfp"), this.get('session.data.authenticated.token'));
    }
  });

  _exports.default = _default;
});