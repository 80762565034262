define("cb-ui/mixins/consultants-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    notify: Ember.inject.service(),
    extraConsultants: new Ember.A(),
    extraNewConsultants: new Ember.A(),
    localSelectedCompanies: new Ember.A(),
    newConsultantCompanyName: null,
    newPersonOfContact: null,
    newPersonEmail: null,
    searchCompanies: null,
    showAddExtraConsultant: null,
    showExtraConsultantForm: null,
    showCompanyExists: false,
    showExtraConsultantsContainer: null,
    finalSpecialistConsultants: Ember.computed('sortedSpecialistConsultants', 'showAllSpecialistConsultants', function () {
      var sortedConsultants = this.get('sortedSpecialistConsultants');

      if (this.get('showAllSpecialistConsultants')) {
        return sortedConsultants.slice(0, 10);
      } else {
        return sortedConsultants.slice(0, 5);
      }
    }),
    finalDiversifiedConsultants: Ember.computed('sortedDiversifiedConsultants', 'showAllDiversifiedConsultants', function () {
      var sortedConsultants = this.get('sortedDiversifiedConsultants');

      if (this.get('showAllDiversifiedConsultants')) {
        return sortedConsultants.slice(0, 10);
      } else {
        return sortedConsultants.slice(0, 5);
      }
    }),
    finalGeneralistConsultants: Ember.computed('sortedGeneralistConsultants', 'showAllGeneralistConsultants', function () {
      var sortedConsultants = this.get('sortedGeneralistConsultants');

      if (this.get('showAllGeneralistConsultants')) {
        return sortedConsultants.slice(0, 10);
      } else {
        return sortedConsultants.slice(0, 5);
      }
    }),
    showExtraConsultantsContainerObserver: Ember.on('init', Ember.observer('extraConsultants.length', 'extraNewConsultants.length', 'showAddExtraConsultant', 'showExtraConsultantForm', function () {
      this.set('showExtraConsultantsContainer', this.get('extraConsultants.length') > 0 || this.get('extraNewConsultants.length') > 0 || this.get('showAddExtraConsultant') || this.get('showExtraConsultantForm'));
    })),
    validateEmail: function validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    newConsultantCompanyNameObserver: Ember.observer('newConsultantCompanyName', function () {
      var newConsultantCompanyName = this.get('newConsultantCompanyName');

      if (!newConsultantCompanyName || !newConsultantCompanyName.trim()) {
        this.set('showCompanyExists', false);
      }
    }),
    saveQuote: function saveQuote() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolveQuote, rejectQuote) {
        var quote = _this.get('quote');

        quote.get('companies').then(function (selectedCompanies) {
          var extraNewCompanyIds = {};
          var extraNewConsultantsPromise = new Ember.RSVP.Promise(function (resolve, reject) {
            var extraNewConsultants = _this.get('extraNewConsultants');

            var newConsultantsSavePromises = [];
            extraNewConsultants.forEach(function (newConsultant) {
              if (!newConsultant.get('id')) {
                newConsultantsSavePromises.push(new Ember.RSVP.Promise(function (resolveSave) {
                  newConsultant.save().then(function (savedCompany) {
                    extraNewCompanyIds[savedCompany.get('id')] = true;
                    resolveSave();
                  }).catch(function () {
                    reject();

                    _this.get('notify').error('All fields for a new consultant are required.');
                  });
                }));
              } else {
                extraNewCompanyIds[newConsultant.get('id')] = true;
              }
            });
            Ember.RSVP.all(newConsultantsSavePromises).then(function () {
              quote.reload().then(function () {
                resolve();
              });
            });
          });
          extraNewConsultantsPromise.then(function () {
            var extraConsultants = _this.get('extraConsultants');

            extraConsultants.forEach(function (extraCompany) {
              if (!selectedCompanies.includes(extraCompany)) {
                selectedCompanies.addObject(extraCompany);
              }
            });

            var localSelectedCompanies = _this.get('localSelectedCompanies');

            localSelectedCompanies.forEach(function (comp) {
              if (!selectedCompanies.includes(comp)) {
                selectedCompanies.addObject(comp);
              }
            });
            var companiesToRemove = selectedCompanies.filter(function (company) {
              return !_this.get('localSelectedCompanies').includes(company) && !_this.get('extraConsultants').includes(company) && !(company.get('id') in extraNewCompanyIds);
            });
            selectedCompanies.removeObjects(companiesToRemove);

            if (!localSelectedCompanies.get('length') && !extraConsultants.get('length') && !_this.get('extraNewConsultants.length')) {
              _this.set('showError', true);

              rejectQuote();
            } else {
              //quote.set('companies', selectedCompanies)
              quote.save().then(function () {
                resolveQuote();
              }).catch(function () {
                rejectQuote();
              });
            }
          });
        });
      });
    },
    performSearchCompanies: function performSearchCompanies(companyQuery, resolve, reject) {
      var _this2 = this;

      if (companyQuery.trim().length < 2) {
        this.set('newConsultantCompanyName', '');
        return resolve([]);
      }

      return this.store.query('public-company', {
        company_query: companyQuery,
        company_type: 'consultant'
      }).then(function (companies) {
        resolve(companies.mapBy('companyNameAlias'));

        _this2.set('searchCompanies', companies);
      }, reject);
    },
    actions: {
      addCompany: function addCompany(companyPromise) {
        var _this3 = this;

        if (!this.get('maximumConsultantsSelected')) {
          companyPromise.then(function (company) {
            var selectedCompanies = _this3.get('localSelectedCompanies');

            _this3.set('showError', false);

            if (!selectedCompanies.includes(company)) {
              selectedCompanies.addObject(company);

              var extraConsultants = _this3.get('extraConsultants');

              if (extraConsultants.includes(company)) {
                _this3.set('extraConsultants', extraConsultants.removeObject(company));
              }
            }
          });
        }
      },
      removeCompany: function removeCompany(companyPromise) {
        var _this4 = this;

        companyPromise.then(function (company) {
          var selectedCompanies = _this4.get('localSelectedCompanies');

          if (selectedCompanies.includes(company)) {
            selectedCompanies.removeObject(company);

            _this4.set('showAddExtraConsultant', true);
          }
        });
      },
      showMoreConsultants: function showMoreConsultants(consultantsType) {
        if (consultantsType === 'specialist') {
          this.set('showAllSpecialistConsultants', true);
        } else if (consultantsType === 'diversified') {
          this.set('showAllDiversifiedConsultants', true);
        } else if (consultantsType === 'generalist') {
          this.set('showAllGeneralistConsultants', true);
        }
      },
      addExtraConsultant: function addExtraConsultant() {
        this.set('showAddExtraConsultant', false);
        this.set('showExtraConsultantForm', true);
      },
      consultantCompanyChanged: function consultantCompanyChanged(newCompanyName) {
        this.set('newConsultantCompanyName', newCompanyName);

        if (newCompanyName && newCompanyName.trim()) {
          this.set('showCompanyExists', true);
        }
      },
      createCompany: function createCompany(newCompanyName) {
        this.set('showCompanyExists', false);
        this.set('newConsultantCompanyName', newCompanyName);
      },
      searchCompanies: function searchCompanies(companyQuery) {
        var _this5 = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.run.debounce(_this5, _this5.performSearchCompanies, companyQuery, resolve, reject, 600);
        });
      },
      showCreate: function showCreate(companyQuery) {
        var ok = true;
        this.get('searchCompanies').forEach(function (company) {
          if (String(companyQuery).toUpperCase() == String(company.get('companyNameAlias').toUpperCase())) {
            ok = false;
          }
        });
        return ok;
      },
      addNewConsultant: function addNewConsultant() {
        var companyName = this.get('newConsultantCompanyName');
        var personOfContact = this.get('newPersonOfContact');
        var email = this.get('newPersonEmail');
        var errors = [];

        if (!companyName || !companyName.trim()) {
          errors.push('Company name is required.');
        } // if (!personOfContact || !personOfContact.trim()) {
        //   errors.push('Person of contact is required.');
        // }
        // if (!email || !email.trim()) {
        //   errors.push('Email is required.');
        // }
        // if (!this.validateEmail(email)) {
        //   errors.push('Email should be valid.');
        // }


        if (errors.length) {
          this.get('notify').error(errors.join(' '));
        } else {
          var extraConsultants = this.get('extraNewConsultants');
          var extraCompany = this.store.createRecord('extra-company', {
            companyName: companyName,
            companyNameAlias: companyName,
            personOfContact: personOfContact,
            email: email,
            quote: this.get('quote.id')
          });
          extraConsultants.pushObject(extraCompany);
          this.set('newConsultantCompanyName', null);
          this.set('newPersonOfContact', null);
          this.set('newPersonEmail', null);
          this.set('extraNewConsultants', extraConsultants);
          this.set('showExtraConsultantForm', false);
          this.set('showAddExtraConsultant', true);
        }
      },
      useExistingConsultant: function useExistingConsultant(companyName) {
        var _this6 = this;

        if (companyName && companyName.trim()) {
          var extraConsultants = this.get('extraConsultants');
          this.store.query('public-company', {
            company_query: companyName,
            company_type: 'consultant'
          }).then(function (companies) {
            var publicCompany = companies.findBy('companyNameAlias', companyName);

            _this6.store.find('company', publicCompany.get('id')).then(function (company) {
              if (company) {
                var localSelectedCompanies = _this6.get('localSelectedCompanies');

                if (extraConsultants.includes(company) || localSelectedCompanies.includes(company)) {
                  _this6.get('notify').info('This consultant is already selected.');
                } else {
                  _this6.set('extraConsultants', extraConsultants.addObject(company));

                  _this6.set('newConsultantCompanyName', null);

                  _this6.set('newPersonOfContact', null);

                  _this6.set('newPersonEmail', null);

                  _this6.set('showExtraConsultantForm', false);

                  _this6.set('showAddExtraConsultant', true);
                }
              }
            });
          });
        } else {
          this.get('notify').error('You should search first for a consultant');
        }
      },
      removeExtraConsultant: function removeExtraConsultant(company) {
        if (confirm('Are you sure ?')) {
          var extraConsultants = this.get('extraConsultants');

          if (extraConsultants.includes(company)) {
            this.set('extraConsultants', extraConsultants.removeObject(company));
          }

          this.set('showAddExtraConsultant', true);
        }
      },
      removeExtraNewConsultant: function removeExtraNewConsultant(company) {
        if (confirm('Are you sure ?')) {
          var extraConsultants = this.get('extraNewConsultants');

          if (extraConsultants.includes(company)) {
            this.set('extraNewConsultants', extraConsultants.removeObject(company));
          }

          this.set('showAddExtraConsultant', true);
        }
      }
    }
  });

  _exports.default = _default;
});