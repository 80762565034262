define("cb-ui/components/post-announcement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    newAnnouncement: null,
    actions: {
      addAnnouncement: function addAnnouncement() {
        var editor = this.get('editor');
        var editorText = editor.getContent();

        if (Ember.isEmpty(editorText)) {
          this.get('notify').error(this.get('intl').t('notifyMessages.announcementContentEmpty').toString());
          return;
        } else if (editorText !== this.get('newAnnouncement')) {
          this.set('newAnnouncement', editorText);
        }

        this.sendAction('addAnnouncement', this.get('newAnnouncement'));
      }
    }
  });

  _exports.default = _default;
});