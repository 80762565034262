define("cb-ui/templates/components/increase-decrease", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oRmiBaE6",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[7],[0,\"\\n    \"],[6,\"div\"],[7],[6,\"a\"],[3,\"action\",[[19,0,[]],\"increaseValue\"]],[7],[0,\"\\n        \"],[6,\"span\"],[9,\"class\",\"glyphicon glyphicon-chevron-up\"],[7],[8],[0,\"\\n    \"],[8],[8],[0,\"\\n    \"],[6,\"div\"],[7],[6,\"a\"],[3,\"action\",[[19,0,[]],\"decreaseValue\"]],[7],[0,\"\\n        \"],[6,\"span\"],[9,\"class\",\"glyphicon glyphicon-chevron-down\"],[7],[8],[0,\"\\n    \"],[8],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/increase-decrease.hbs"
    }
  });

  _exports.default = _default;
});