define("cb-ui/mixins/selected-consultant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isShowingModal: false,
    companyIndustryFitScore: 0,
    companyFunctionalFitScore: 0,
    // showScore: true,
    showScore: false,
    actions: {
      toggleModal: function toggleModal(company, industryFitScore, functionalFitScore) {
        this.set('company', company);
        this.set('companyIndustryFitScore', industryFitScore);
        this.set('companyFunctionalFitScore', functionalFitScore);
        this.toggleProperty('isShowingModal');
      }
    }
  });

  _exports.default = _default;
});