define("cb-ui/components/proposal-evaluation-criteria-comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    user: null,
    proposal: null,
    criteriaEvaluation: null,
    proposalsEvaluations: new Ember.A(),
    proposalEvaluation: Ember.computed('user', 'proposal', 'criteriaEvaluation', 'proposalEvaluations', function () {
      var that = this;
      var proposalsEvaluations = this.get('proposalsEvaluations');
      return proposalsEvaluations.find(function (pe) {
        return pe.get('user.id') === that.get('user.id') && pe.get('criteriaEvaluation.id') === that.get('criteriaEvaluation.id') && pe.get('proposal.id') === that.get('proposal.id');
      });
    })
  });

  _exports.default = _default;
});