define("cb-ui/initializers/simple-token", ["exports", "cb-ui/authenticators/token"], function (_exports, _token) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.register('authenticator:token', _token.default);
  }

  var _default = {
    name: 'simple-token',
    initialize: initialize
  };
  _exports.default = _default;
});