define("cb-ui/templates/components/ember-table/proposal-role-mandays", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SxfTKbyn",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"or\",[[20,[\"cellValue\",\"proposal\",\"isIncludedInEvaluation\"]],[25,\"eq\",[[20,[\"cellValue\",\"proposal\",\"status\"]],\"dismissed\"],null]],null]],null,{\"statements\":[[0,\"  \"],[6,\"h4\"],[9,\"style\",\"margin: 0 0 0 0;\"],[7],[1,[20,[\"cellValue\",\"mandays\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/ember-table/proposal-role-mandays.hbs"
    }
  });

  _exports.default = _default;
});