define("cb-ui/components/project-evaluation-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    proposals: new Ember.A(),
    criteriaGroups: new Ember.A(),
    quoteEvaluationResults: new Ember.A(),
    columns: Ember.computed('proposals.length', function () {
      var columns = [{
        heading: '',
        valuePath: 'title',
        isFixed: 'left',
        width: 200
      }];
      this.get('proposals').forEach(function (p) {
        columns.push({
          width: 150,
          valuePath: p.get('id'),
          company: p.get('company'),
          heading: '',
          headerComponent: 'ember-table/company-info'
        });
      });
      return columns;
    }),
    rows: Ember.computed('proposals.length', 'criteriaGroups.length', function () {
      var rows = [];
      var criteriaGroups = this.get('criteriaGroups');
      var proposals = this.get('proposals');
      criteriaGroups.forEach(function (cg) {
        var data = {
          title: cg.get('title'),
          rowComponent: 'ember-table/full-row-cell'
        };
        rows.push(data);
        cg.get('evaluations').forEach(function (criteria) {
          var rowData = {
            title: {
              cellComponent: 'ember-table/criteria-info',
              title: criteria.get('title'),
              description: criteria.get('description')
            }
          };
          proposals.forEach(function (p) {
            rowData[p.get('id')] = {
              cellComponent: 'ember-table/result-data',
              criteria: criteria,
              proposal: p,
              criteriaScore: true
            };
          });
          rows.push(rowData);
        });
      });
      var concludedEvaluationsRow = {
        title: this.get('intl').t('app.components.projectEvaluationResults.concludedEvaluations')
      };
      proposals.forEach(function (p) {
        concludedEvaluationsRow[p.get('id')] = {
          cellComponent: 'ember-table/result-data',
          proposal: p,
          finishedEvaluations: true,
          bold: true
        };
      });
      var totalScoreRow = {
        title: this.get('intl').t('app.components.projectEvaluationResults.totalScore')
      };
      proposals.forEach(function (p) {
        totalScoreRow[p.get('id')] = {
          cellComponent: 'ember-table/result-data',
          proposal: p,
          totalScore: true,
          bold: true
        };
      });
      rows.push(concludedEvaluationsRow);
      rows.push(totalScoreRow);
      return rows;
    })
  });

  _exports.default = _default;
});