define("cb-ui/controllers/app", ["exports", "ember-ajax", "cb-ui/config/environment"], function (_exports, _emberAjax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    showAgentQuestionModal: null,
    question: null,
    userPhone: null,
    user: null,
    closedAtLeastOneAlert: false,
    actions: {
      toggleClosedAtLeastOneAlert: function toggleClosedAtLeastOneAlert() {
        this.set('closedAtLeastOneAlert', true);
      },
      resendValidationEmail: function resendValidationEmail() {
        var _this = this;

        var url = _environment.default.backendAPI + '/api/send-validation-email/';
        (0, _emberAjax.default)(url, {
          type: 'POST',
          headers: {
            'Authorization': 'Bearer ' + this.get('session.data.authenticated.token')
          }
        }).then(function () {
          _this.get('notify').success(_this.get('intl').t('infoRibbon.emailSent').toString());
        }).catch(function () {
          _this.get('notify').error(_this.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
        });
      }
    }
  });

  _exports.default = _default;
});