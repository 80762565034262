define("cb-ui/models/base-criteria-evaluation-group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany; // import { collectionAction } from 'ember-api-actions';

  var _default = Model.extend({
    title: attr('string'),
    order: attr('number', {
      defaultValue: 0
    }),
    template: belongsTo('template', {
      inverse: 'baseCriteriaEvaluationGroups'
    }),
    baseCriteriaEvaluations: hasMany('base-criteria-evaluation', {
      inverse: 'group'
    }),
    evaluations: Ember.computed.alias('baseCriteriaEvaluations')
  });

  _exports.default = _default;
});