define("cb-ui/components/drag-sort-item", ["exports", "ember-drag-sort/components/drag-sort-item"], function (_exports, _dragSortItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _dragSortItem.default;
    }
  });
});