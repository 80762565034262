define("cb-ui/components/form-group-control", ["exports", "ember-rapid-forms/components/form-group-control"], function (_exports, _formGroupControl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import Ember from 'ember';
  var _default = _formGroupControl.default;
  _exports.default = _default;
});