define("cb-ui/controllers/app/project/proposal/technical-proposal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var fileTypes = ['Approach presentation', 'Company presentation', 'Reference presentation', 'Project plan & milestones', 'Contract template', 'Team Member CVs', 'Terms & Conditions', 'Other document type'];

  var _default = Ember.Controller.extend({
    proposal: null,
    quote: null,
    localDocuments: new Ember.A(),
    localReferences: new Ember.A(),
    localTeamMembers: new Ember.A(),
    localWorkPackages: new Ember.A(),
    uploadedFileType: 'Approach presentation',
    otherDocumentType: 'Other document type',
    otherFileType: null,
    fileTypesOptions: fileTypes,
    notify: Ember.inject.service(),
    loadProposalData: function loadProposalData(proposal) {
      var _this = this;

      var proposalDocuments = new Ember.A();
      var proposalReferences = new Ember.A();
      var proposalTeamMembers = new Ember.A();
      var proposalWorkPackages = new Ember.A();
      var dataLoadedPromises = [];
      var documentsPromise = proposal.get('documents');
      var referencesPromise = proposal.get('references');
      var teamMembersPromise = proposal.get('teamMembers');
      var workPackagesPromise = proposal.get('workPackages');
      dataLoadedPromises.push(documentsPromise);
      dataLoadedPromises.push(referencesPromise);
      dataLoadedPromises.push(teamMembersPromise);
      dataLoadedPromises.push(workPackagesPromise);
      documentsPromise.then(function (documents) {
        documents.forEach(function (document) {
          proposalDocuments.addObject(document);
        });
      });
      referencesPromise.then(function (references) {
        references.forEach(function (reference) {
          proposalReferences.addObject(reference);
        });
      });
      teamMembersPromise.then(function (teamMembers) {
        teamMembers.forEach(function (member) {
          proposalTeamMembers.addObject(member);
        });
      });
      workPackagesPromise.then(function (workPackages) {
        workPackages.forEach(function (workPackage) {
          proposalWorkPackages.addObject(workPackage);
        });
      });
      Ember.RSVP.all(dataLoadedPromises).then(function () {
        _this.set('localDocuments', proposalDocuments);

        _this.set('localReferences', proposalReferences);

        _this.set('localTeamMembers', proposalTeamMembers);

        _this.set('localWorkPackages', proposalWorkPackages);

        if (_this.get('localReferences.length') === 0) {
          _this.get('localReferences').addObject(_this.store.createRecord('reference', {
            clientName: '',
            projectName: '',
            achievements: ''
          }));
        }

        if (_this.get('localTeamMembers.length') === 0) {
          _this.get('localTeamMembers').addObject(_this.store.createRecord('team-member', {
            projectRole: '',
            name: '',
            specialization: ''
          }));
        }

        if (_this.get('localWorkPackages.length') === 0) {
          _this.get('localWorkPackages').addObject(_this.store.createRecord('work-package', {
            name: '',
            description: '',
            deliverables: ''
          }));
        }
      }).catch(function () {
        _this.get('notify').error('Proposal details couldn\'t be loaded.');
      });
    },
    saveProposal: function saveProposal() {
      var proposal = this.get('proposal');
      var localReferences = this.get('localReferences');
      var localTeamMembers = this.get('localTeamMembers');
      var localWorkPackages = this.get('localWorkPackages');
      proposal.set('documents', this.get('localDocuments'));
      var proposalChildrenPromises = [];
      proposal.get('references').then(function (references) {
        var referencesCopy = references.slice();
        referencesCopy.forEach(function (reference) {
          if (!localReferences.includes(reference)) {
            proposalChildrenPromises.push(reference.destroyRecord());
          }
        });
      });
      proposal.get('teamMembers').then(function (members) {
        var membersCopy = members.slice();
        membersCopy.forEach(function (member) {
          if (!localTeamMembers.includes(member)) {
            proposalChildrenPromises.push(member.destroyRecord());
          }
        });
      });
      proposal.get('workPackages').then(function (workPackages) {
        var packagesCopy = workPackages.slice();
        packagesCopy.forEach(function (wp) {
          if (!localWorkPackages.includes(wp)) {
            proposalChildrenPromises.push(wp.destroyRecord());
          }
        });
      });
      localReferences.forEach(function (reference) {
        reference.set('proposal', proposal);
        proposalChildrenPromises.push(reference.save());
      });
      localTeamMembers.forEach(function (member) {
        member.set('proposal', proposal);
        proposalChildrenPromises.push(member.save());
      });
      localWorkPackages.forEach(function (workPackage) {
        workPackage.set('proposal', proposal);
        proposalChildrenPromises.push(workPackage.save());
      });
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.RSVP.all(proposalChildrenPromises).then(function () {
          proposal.set('consultantCompletedProposal', true);
          proposal.save().then(function () {
            proposal.reload();
            resolve();
          }).catch(function () {
            proposal.set('consultantCompletedProposal', false);
            reject();
          });
        });
      });
    },
    validateProposal: function validateProposal() {
      var localReferences = this.get('localReferences');
      var localTeamMembers = this.get('localTeamMembers');
      var localWorkPackages = this.get('localWorkPackages');
      var localDocumentsAreValid = true;
      var localReferencesAreValid = true;
      var localTeamMembersAreValid = true;
      var localWorkPackagesAreValid = true;
      localReferences.forEach(function (reference) {
        reference.get('errors').remove('clientName');
        reference.get('errors').remove('projectName');
        reference.get('errors').remove('achievements');

        if (!reference.get('clientName') || !reference.get('clientName').trim()) {
          reference.get('errors').add('clientName', 'This field is required.');
          localReferencesAreValid = false;
        }

        if (!reference.get('projectName') || !reference.get('projectName').trim()) {
          reference.get('errors').add('projectName', 'This field is required.');
          localReferencesAreValid = false;
        }

        if (!reference.get('achievements') || !reference.get('achievements').trim()) {
          reference.get('errors').add('achievements', 'This field is required.');
          localReferencesAreValid = false;
        }
      });
      localTeamMembers.forEach(function (member) {
        member.get('errors').remove('projectRole');
        member.get('errors').remove('name');
        member.get('errors').remove('specialization');

        if (!member.get('projectRole') || !member.get('projectRole').trim()) {
          member.get('errors').add('projectRole', 'This field is required.');
          localTeamMembersAreValid = false;
        }

        if (!member.get('name') || !member.get('name').trim()) {
          member.get('errors').add('name', 'This field is required.');
          localTeamMembersAreValid = false;
        }

        if (!member.get('specialization') || !member.get('specialization').trim()) {
          member.get('errors').add('specialization', 'This field is required.');
          localTeamMembersAreValid = false;
        }
      });
      localWorkPackages.forEach(function (workPackage) {
        workPackage.get('errors').remove('name');
        workPackage.get('errors').remove('description');
        workPackage.get('errors').remove('deliverables');

        if (!workPackage.get('name') || !workPackage.get('name').trim()) {
          workPackage.get('errors').add('name', 'This field is required.');
          localWorkPackagesAreValid = false;
        }

        if (!workPackage.get('description') || !workPackage.get('description').trim()) {
          workPackage.get('errors').add('description', 'This field is required.');
          localWorkPackagesAreValid = false;
        }

        if (!workPackage.get('deliverables') || !workPackage.get('deliverables').trim()) {
          workPackage.get('errors').add('deliverables', 'This field is required.');
          localWorkPackagesAreValid = false;
        }
      });
      var localDocuments = this.get('localDocuments');

      if (!localDocuments.filterBy('type', 'Approach presentation').get('length')) {
        this.get('notify').error('You have to upload at least 1 Approach presentation document');
        localDocumentsAreValid = false;
      }

      if (!localReferences.get('length') || !localTeamMembers.get('length') || !localWorkPackages.get('length')) {
        this.get('notify').error('You have to add at least one item of each category.');
        localReferencesAreValid = false;
      }

      return localReferencesAreValid && localTeamMembersAreValid && localWorkPackagesAreValid && localDocumentsAreValid;
    },
    actions: {
      remove: function remove(item) {
        if (item.constructor.modelName === "reference") {
          this.get("localReferences").removeObject(item);
        }

        if (item.constructor.modelName === "team-member") {
          this.get("localTeamMembers").removeObject(item);
        }

        if (item.constructor.modelName === "work-package") {
          this.get("localWorkPackages").removeObject(item);
        }
      },
      addProposalDocument: function addProposalDocument(file) {
        var _this2 = this;

        this.store.find('proposal-document', file.id).then(function (currentFile) {
          var localDocuments = _this2.get('localDocuments');

          localDocuments.addObject(currentFile);

          _this2.get('notify').success('The file was uploaded and added');
        });
      },
      addReference: function addReference() {
        var localReferences = this.get('localReferences');
        localReferences.addObject(this.store.createRecord('reference', {
          clientName: '',
          projectName: '',
          achievements: ''
        }));
      },
      addTeamMember: function addTeamMember() {
        var localTeamMembers = this.get('localTeamMembers');
        localTeamMembers.addObject(this.store.createRecord('team-member', {
          projectRole: '',
          name: '',
          specialization: ''
        }));
      },
      addWorkPackage: function addWorkPackage() {
        var localWorkPackages = this.get('localWorkPackages');
        localWorkPackages.addObject(this.store.createRecord('work-package', {
          name: '',
          description: '',
          deliverables: ''
        }));
      },
      fileTypeChanged: function fileTypeChanged(value) {
        this.set('uploadedFileType', value);
        this.set('otherFileType', null);
      },
      save: function save() {
        var _this3 = this;

        if (this.get('proposal.isAccepted')) {
          if (this.validateProposal()) {
            this.saveProposal().then(function () {
              _this3.get('notify').success('The proposal was saved');
            });
          }
        }
      },
      saveAndNext: function saveAndNext() {
        var _this4 = this;

        if (this.get('proposal.isAccepted')) {
          if (this.validateProposal()) {
            this.saveProposal().then(function () {
              _this4.get('notify').success('The proposal was saved');

              _this4.transitionToRoute('app.project.proposal.beauty-contest', _this4.get('quote'), _this4.get('proposal'));
            });
          }
        }
      },
      discardChanges: function discardChanges() {
        if (this.get('proposal.isAccepted')) {
          var proposal = this.get('proposal');
          proposal.rollbackAttributes();
          this.get('localReferences').forEach(function (reference) {
            reference.rollbackAttributes();
          });
          this.get('localTeamMembers').forEach(function (member) {
            member.rollbackAttributes();
          });
          this.get('localWorkPackages').forEach(function (workPackage) {
            workPackage.rollbackAttributes();
          });
          this.loadProposalData(proposal);
        }
      },
      submitProposal: function submitProposal() {
        var _this5 = this;

        if (this.get('proposal.isAccepted')) {
          if (this.validateProposal()) {
            this.saveProposal().then(function () {
              if (_this5.get('proposal.technicalProposalIsCompleted') && _this5.get('proposal.financialProposalIsCompleted')) {
                _this5.send('openSubmitProposalModal');
              } else {
                _this5.send('openIncompleteProposalModal');
              }
            });
          }
        }
      }
    }
  });

  _exports.default = _default;
});