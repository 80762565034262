define("cb-ui/models/quote-cover-letter-document", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    file: attr(),
    filePath: attr(),
    quote: belongsTo('quote', {
      async: true,
      inverse: 'coverLetterDocument'
    }),
    fileName: Ember.computed('filePath', function () {
      var filePath = this.get('filePath');

      if (filePath) {
        return this.get('filePath').split('/').pop();
      }
    })
  });

  _exports.default = _default;
});