define("cb-ui/controllers/app/rfp/project-details", ["exports", "moment", "ember-changeset", "cb-ui/validations/contactInformation", "cb-ui/mixins/wizard-controller", "cb-ui/mixins/rfp-timeline", "ember-local-storage", "ember-intl", "cb-ui/config/environment", "ember-changeset-validations"], function (_exports, _moment, _emberChangeset, _contactInformation, _wizardController, _rfpTimeline, _emberLocalStorage, _emberIntl, _environment, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _this = void 0;

  var radioOptions = {
    timeMaterial: 'timeMaterial',
    fixedFee: 'fixedFee',
    successBased: 'successBased',
    hybridModel: 'hybridModel',
    publicQuestions: 'publicQuestions',
    undisclosedQuestions: 'undisclosedQuestions',
    closedEnvelopeYes: true,
    closedEnvelopeNo: false,
    personalContactInformation: 'personalContactInformation',
    noPersonalContactInformation: 'noPersonalContactInformation',
    meAsPersonContact: 'meAsPersonContact',
    anotherAsPersonContact: 'anotherAsPersonContact',
    deadlineMidDay: 'mid-day',
    deadlineEndOfDay: 'end-of-day',
    otherMentionsTranslation: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.otherMentionSaveNotification'),
    //projectRoles
    externalStakeholder: 'External Stakeholder',
    projectAssistance: 'Project Assistance',
    defaultCompany: 'default',
    affiliateCompany: 'affiliate'
  };

  var _default = Ember.Controller.extend(_wizardController.default, _rfpTimeline.default, {
    notify: Ember.inject.service('notify'),
    quote: null,
    currentUser: null,
    allowSaveToLocalStorage: null,
    projectContext: null,
    projectRole: null,
    localProjectPhasesItems: new Ember.A(),
    localQuoteDocuments: new Ember.A(),
    localSecondaryLanguages: new Ember.A(),
    localQuoteTimelineEntries: new Ember.A(),
    blockPrevious: false,
    showUnsavedChangesModal: false,
    targetRoute: 'ap.rfp.criteria-evaluation',
    appLanguage: Ember.computed.alias('localStorage.language'),
    ContactInformationsValidations: _contactInformation.default,
    sortedLocalQuoteTimelineEntries: Ember.computed('localQuoteTimelineEntries.@each.date', function () {
      var entries = this.get('localQuoteTimelineEntries');
      entries.sort(function (el1, el2) {
        if (!el1.get('date') && !el2.get('date')) {
          return 0;
        }

        if (!!el1.get('date') ^ !!el2.get('date')) {
          return el1.get('date') ? -1 : 1;
        }

        if (el1.get('date') == el2.get('date')) {
          return 0;
        }

        return el1.get('date') < el2.get('date') ? -1 : 1;
      });
      return entries;
    }),
    rfpReleaseTimelineEntry: Ember.computed('localQuoteTimelineEntries.[]', function () {
      return _this.get('localQuoteTimelineEntries').findBy('name', 'rfpReleaseDue');
    }),
    isDirty: false,
    quoteChangesObserver: Ember.observer('quote.initialSituation', 'quote.clientIntroduction', 'quote.mainLanguage', 'quote.currency', 'quote.rfpReleaseDueDate', 'quote.proposalDueDate', 'quote.projectAwardDate', 'quote.useDefaultCompany', 'quote.companyName', 'quote.companyAddressLineOne', 'quote.companyAddressLineTwo', 'quote.companyAddressLineThree', 'quote.companyPostalCode', 'quote.companyCity', 'quote.compantCountry', 'quote.companyWebsite', 'quote.personFirstName', 'quote.personLastName', 'quote.personRole', 'quote.personSalutation', 'quote.noPersonalContactInformation', 'quote.pitchEvaluationIsActive', 'quote.presentationStartDate', 'quote.presentationEndDate', 'quote.proposalValidityWeeks', 'quote.projectsWorkedOn', 'quote.companyPortrait', 'quote.outlineProjectPhases', 'quote.projectReferences', 'quote.otherMentions', function () {
      var _this2 = this;

      var step3_fields = ['initialSituation', 'clientIntroduction', 'mainLanguage', 'currency', 'rfpReleaseDueDate', 'proposalDueDate', 'projectAwardDate', 'useDefaultCompany', 'companyName', 'companyAddressLineOne', 'companyAddressLineTwo', 'companyAddressLineThree', 'companyPostalCode', 'companyCity', 'compantCountry', 'companyWebsite', 'personFirstName', 'personLastName', 'personRole', 'personSalutation', 'noPersonalContactInformation', 'pitchEvaluationIsActive', 'presentationStartDate', 'presentationEndDate', 'proposalValidityWeeks', 'projectsWorkedOn', 'companyPortrait', 'outlineProjectPhases', 'projectReferences', 'otherMentions'];
      var step3_dirty_fields = step3_fields.filter(function (value) {
        return Object.keys(_this2.get('quote').changedAttributes()).includes(value);
      });

      if (step3_dirty_fields.length > 0) {
        // currentStep is always dirty
        this.set('isDirty', true);
      }
    }),
    commentModalText: '',
    uploadedFileType: null,
    otherDocumentType: 'Other document type',
    otherFileType: null,
    otherProjectRole: 'otherProjectRole',
    companyType: 'default',
    userCompanyName: null,
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    componentHTML: null,
    initComponentHTML: Ember.on('init', function () {
      this.set('componentHTML', this.get('languageHTML'));
    }),
    // info for form fields
    languageHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.mainLanguage'),
    projectContextHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.projectContext'),
    timelineHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.timeline'),
    projectPhasesHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.projectPhases'),
    personalDataHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.personalData'),
    proposalElementsHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.proposalElements'),
    renumerationModelHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.financialProposal'),
    technicalProposalHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.technicalProposal'),
    teamOverviewDetailsHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.teamOverview'),
    questionsHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.questions'),
    rfpTimelineHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.rfpTimeline'),
    projectRoleHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.projectRole'),
    documentsHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.documents'),
    contactInformationHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.contactInformation'),
    deliverablesHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.deliverables'),
    clientIntroductionHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.clientIntroduction'),
    projectManagerFunctionHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.projectManagerFunction'),
    steeringCommitteeHeadHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.steeringCommitteeHead'),
    initialSituationHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.initialSituation'),
    otherMentionSetNotification: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.otherMentionSetNotification'),
    radioButtonOptions: radioOptions,
    deadlineTime: null,
    financialProposalRenumeration: null,
    disclosureOfQuestions: null,
    contactInformation: null,
    personalInformation: null,
    pitchPresentationGroup: null,
    beautyItems: [],
    newBeautyItem: '',
    dateFormat: 'DD, dd.mm.yyyy',
    smallResolution: Ember.computed('media.isMobile', 'media.isTablet', function () {
      return this.get('media.isMobile') || this.get('media.isTablet');
    }),
    validateTimelineEntries: Ember.on('init', Ember.observer('sortedLocalQuoteTimelineEntries.@each.date', function () {
      var _this3 = this;

      Ember.run.once(function () {
        var entries = _this3.get('sortedLocalQuoteTimelineEntries');

        if (entries.length < 3) {
          return;
        }

        var rfpRelease = entries.findBy('name', 'rfpReleaseDueDate');
        var proposalDue = entries.findBy('name', 'proposalDueDate');
        var projectAward = entries.findBy('name', 'projectAwardDate');
        var projectStartDate = entries.findBy('slug', 'project_start_date');
        var projectEndDate = entries.findBy('slug', 'project_end_date');
        var psd = entries.findBy('name', 'presentationStartDate');
        var ped = entries.findBy('name', 'presentationEndDate');

        if (!rfpRelease.get('date')) {
          entries.forEach(function (entry) {
            entry.set('disabled', true);
          });
          rfpRelease.set('disabled', false);
          rfpRelease.set('minDate', new Date());
          return;
        } else {
          var anyDateSet = entries.filterBy('isCustom', false).any(function (item) {
            return item.get('name') != 'rfpReleaseDueDate' && !!item.get('date');
          });

          if (!anyDateSet) {
            proposalDue.set('date', (0, _moment.default)(rfpRelease.get('date')).add(3, 'weeks').toDate());
            projectAward.set('date', (0, _moment.default)(rfpRelease.get('date')).add(4, 'weeks').toDate());

            if (psd) {
              psd.set('date', (0, _moment.default)(rfpRelease.get('date')).add(3, 'weeks').toDate());
              ped.set('date', (0, _moment.default)(rfpRelease.get('date')).add(25, 'days').toDate());
            }
          }

          proposalDue.set('minDate', (0, _moment.default)(rfpRelease.get('date')).add(1, 'days').toDate());
          proposalDue.set('disabled', false);
          projectAward.set('minDate', (0, _moment.default)(proposalDue.get('date')).add(1, 'days').toDate());
          projectAward.set('disabled', false);
          rfpRelease.set('maxDate', (0, _moment.default)(proposalDue.get('date')).subtract(1, 'days').toDate());

          if (psd && ped) {
            psd.set('disabled', false);
            psd.set('minDate', rfpRelease.get('date'));
            psd.set('minDate', rfpRelease.get('date'));

            if (psd.get('date')) {
              ped.set('minDate', (0, _moment.default)(psd.get('date')).add('1', 'days').toDate());
              ped.set('maxDate', projectAward.get('date'));
              ped.set('disabled', false);

              if (psd.get('date') < projectAward.get('minDate')) {
                projectAward.set('minDate', psd.get('date'));
              }
            } else {
              ped.set('disabled', true);
            }

            if (ped.get('date')) {
              psd.set('maxDate', ped.get('date'));

              if (ped.get('date') < projectAward.get('minDate')) {
                projectAward.set('minDate', ped.get('date'));
              }
            }
          }

          entries.filterBy('isCustom', true).forEach(function (entry) {
            if (entry.get('slug') == 'project_start_date' || entry.get('slug') == 'project_end_date') {
              return;
            }

            entry.set('disabled', false);
            entry.set('minDate', rfpRelease.get('date'));

            if (entry.get('date') && entry.get('date') > rfpRelease.get('maxDate')) {
              rfpRelease.set('maxDate', entry.get('date'));
            }
          });

          if (projectStartDate) {
            if (projectAward.get('date')) {
              projectStartDate.set('disabled', false); // projectStartDate.set('minDate', projectAward.get('date'));
            }
          }

          if (projectEndDate) {
            if (projectAward.get('date')) {
              projectEndDate.set('disabled', false); // projectEndDate.set('minDate', projectAward.get('date'));
            }
          }
        }
      });
    })),
    setupChangesets: function setupChangesets() {
      var _this4 = this;

      this.get('contactInformations').forEach(function (item) {
        var contactChangeset = new _emberChangeset.default(item, (0, _emberChangesetValidations.default)(_contactInformation.default), _contactInformation.default);

        _this4.get('changesetList').addObject(contactChangeset);
      });
    },
    saveToLocalStorage: function saveToLocalStorage() {
      if (this.get('allowSaveToLocalStorage')) {
        var quote = this.get('quote');
        var localStorage = this.get('localStorage'); //const checkedBeautyItems = this.get('beautyItems').filterBy('checked', true);
        //const lsBeautyItems = checkedBeautyItems.map(beautyItem => beautyItem.item.serialize({includeId: true}));

        var lsQuoteDocuments = this.get('localQuoteDocuments').map(function (document) {
          return document.get('id');
        }); // Save rfp dates for standard entries on quote

        this.get('localQuoteTimelineEntries').filter(function (te) {
          return !te.get('isCustom') || te.get('slug');
        }).forEach(function (entry) {
          if (!entry.get('isCustom')) {
            quote.set(entry.get('name'), entry.get('date'));
          } else {
            var slug = entry.get('slug');

            if (slug) {
              var key = slug == 'project_start_date' ? 'startDate' : 'endDate';
              quote.set(key, entry.get('date'));
            }
          }
        }); // const timelineEntriesPromises = customTimelineEntries.filterBy('hasDirtyAttributes', true).map(entry => entry.save());
        // RSVP.all(timelineEntriesPromises).then(() => {
        //   console.log('saving timeline entries');
        //   localStorage.set('quoteTimelineEntries', customTimelineEntries.mapBy('id'));
        // });

        var selectedProjectPhases = this.get('localProjectPhasesItems').filterBy('isChecked', true);
        var lsProjectPhases = selectedProjectPhases.map(function (projectPhaseItem) {
          return projectPhaseItem.projectPhase.get('id');
        });
        var lsSecondaryLanguages = {};
        this.get('localSecondaryLanguages').map(function (language) {
          return lsSecondaryLanguages[language.get('id')] = true;
        });
        localStorage.set('quote', quote.serialize({
          includeId: true
        })); //localStorage.set('selectedBeautyItems', lsBeautyItems);

        localStorage.set('quoteDocuments', lsQuoteDocuments);
        localStorage.set('quoteProjectPhases', lsProjectPhases);
        localStorage.set('quoteSecondaryLanguages', lsSecondaryLanguages);
        var self = this;
        Ember.run.later(function () {
          self.saveToLocalStorage();
        }, 1000);
      }
    },
    convertTimeToCET: function convertTimeToCET(value) {
      if (value) {
        var datePickerTime = this.get('quote.deadlineTime') === 'mid-day' ? ' 12:00' : ' 23:59';
        var datePickerMonth = ("0" + (value.getMonth() + 1)).slice(-2);
        var datePickerDay = ("0" + value.getDate()).slice(-2);

        var newDate = _moment.default.tz(value.getFullYear() + '-' + datePickerMonth + '-' + datePickerDay + datePickerTime, 'Europe/Berlin');

        return newDate.toDate();
      }
    },
    saveQuote: function saveQuote(goBack, goToEditProfile, saveAsDraft, route) {
      var _this5 = this;

      var quote = this.get('quote');
      quote.set('saveAsDraft', saveAsDraft);
      var editor = this.get('editor');
      var otherMentions = quote.get('otherMentions');
      var editorText = editor.getContent();
      if (Ember.isEmpty(editorText)) this.set('quote.otherMentions', null);else if (editorText !== otherMentions) {
        this.set('quote.otherMentions', editorText);
      }

      if (!quote.get('pitchSession') && quote.get('pitchWeight') > 0) {
        quote.set('pitchWeight', 0);
        quote.set('financialWeight', 50);
        quote.set('technicalWeight', 50);
      }

      if (quote.get('pitchSession') && quote.get('pitchWeight') == 0) {
        quote.set('pitchWeight', 20);
        quote.set('financialWeight', 40);
        quote.set('technicalWeight', 40);
      }

      quote.set('documents', this.get('localQuoteDocuments'));
      var localQuoteTimelineEntries = this.get('localQuoteTimelineEntries').filterBy('isCustom', true);
      quote.set('timelineEntries', localQuoteTimelineEntries);
      var selectedProjectPhasesItems = this.get('localProjectPhasesItems').filterBy('isChecked', true);
      var selectedProjectPhases = selectedProjectPhasesItems.map(function (item) {
        return item.projectPhase;
      });
      quote.set('projectPhases', selectedProjectPhases);
      quote.set('secondaryLanguages', this.get('localSecondaryLanguages'));
      var quoteRelatedPromises = [];

      if (quote.get('companyWebsite')) {
        if (!(quote.get('companyWebsite').startsWith('http://') || quote.get('companyWebsite').startsWith('https://'))) {
          quote.set('companyWebsite', 'https://' + quote.get('companyWebsite'));
        }
      }

      localQuoteTimelineEntries.forEach(function (item) {
        quoteRelatedPromises.push(item.save({
          adapterOptions: {
            validate: !saveAsDraft
          }
        }));
      });
      var changesetValidatePromises = []; // validate contact information changes

      if (this.get('quote.noPersonalContactInformation')) {
        this.get('changesetList').forEach(function (changeset) {
          if (changeset.get('id')) {
            var aux = _this5.get('store').peekRecord('quote-contact', changeset.get('id'));

            aux.deleteRecord();
            quoteRelatedPromises.addObject(aux.save());
          }
        });
        this.set('changesetList', []);
      }

      this.get('changesetList').forEach(function (changeset) {
        changesetValidatePromises.addObject(changeset.validate());
      });
      var mainUser = this.get('changesetList')[0];

      if (mainUser) {
        quote.set('personFirstName', mainUser.get('firstName'));
        quote.set('personLastName', mainUser.get('lastName'));
        quote.set('personRole', mainUser.get('jobTitle'));
        quote.set('personSalutation', mainUser.get('salutation'));
      }

      return Ember.RSVP.all(changesetValidatePromises).then(function () {
        var array = _this5.get('changesetList');

        var canPass = true;
        array.forEach(function (item) {
          if (item.get('isValid') === false) {
            canPass = false;
          }
        });

        if (canPass) {
          _this5.get('changesetList').forEach(function (changeset) {
            quoteRelatedPromises.addObject(changeset.save());
          });

          return Ember.RSVP.all(quoteRelatedPromises).then(function () {
            return quote.save().then(function () {
              //const extraNole = quote.get('extraProjectRole');
              //if (this.get('projectRole') !== this.get('otherProjectRole')) {
              //  quote.set('extraProjectRole', null);
              //}
              _this5.set('showUnsavedChangesModal', false);

              _this5.set('allowSaveToLocalStorage', false);

              _this5.send('setShowConfirm', false);

              var localStorage = _this5.get('localStorage');

              localStorage.set('quote', null); //localStorage.set('selectedBeautyItems', []);

              localStorage.set('quoteDocuments', []);
              localStorage.set('quoteProjectPhases', []);
              localStorage.set('quoteSecondaryLanguages', []);
              localStorage.set('quoteProjectGoals', []);

              _this5.set('blockPrevious', false);

              _this5.set('isDirty', false);

              if (saveAsDraft) {
                _this5.get('notify').success('Successfully saved draft');

                quote.set('saveAsDraft', false);
              }

              if (saveAsDraft && route && route.includes(_environment.default.CANONICAL_DOMAIN_NEXT)) {
                window.location = route;
                quote.set('saveAsDraft', false);
                return;
              }

              if (saveAsDraft && route) {
                quote.set('saveAsDraft', false);

                _this5.transitionToRoute(route);
              }

              if (goBack && goToEditProfile) {
                quote.set('saveAsDraft', false);

                _this5.transitionToRoute('app.account.index', {
                  queryParams: {
                    returnToProject: true
                  }
                });
              }

              if (goBack && !goToEditProfile) {
                quote.set('saveAsDraft', false);

                _this5.transitionToRoute('app.rfp.consultants');
              }

              if (!goBack && !saveAsDraft) {
                quote.set('saveAsDraft', false);

                _this5.transitionToRoute(_this5.get('targetRoute'));
              }
            });
          }).catch(function (err) {
            _this5.get('notify').error(_this5.get('intl').t('notifyMessages.errorOccurredFixErrors').toString());

            Ember.run.later(function () {
              if (Ember.$('div.has-error:first') && Ember.$('div.has-error:first').length) {
                Ember.$('html, body').animate({
                  scrollTop: Ember.$('div.has-error:first').offset().top - 175
                });
              }
            }, 200);

            _this5.set('blockPrevious', false);

            quote.set('saveAsDraft', false);
            throw err;
          });
        } else {
          _this5.get('notify').error(_this5.get('intl').t('notifyMessages.errorOccurredFixErrors').toString());

          Ember.run.later(function () {
            if (Ember.$('.has-error') && Ember.$('.has-error').length) {
              Ember.$("html, body").animate({
                scrollTop: Ember.$('.has-error').offset().top - 100
              });
            }
          }, 200);

          _this5.set('blockPrevious', false);

          quote.set('saveAsDraft', false);
          throw 'Please fix the contact errors';
        }
      });
    },
    actions: {
      openUnsavedChangesModal: function openUnsavedChangesModal() {
        this.set('showUnsavedChangesModal', true);
      },
      closeUnsavedChangesModal: function closeUnsavedChangesModal() {
        this.set('showUnsavedChangesModal', false);
      },
      addContactPerson: function addContactPerson() {
        var item = this.store.createRecord('quote-contact', {
          quote: this.get('quote'),
          salutation: null,
          firstName: '',
          lastName: '',
          jobTitle: ''
        });
        var contactChangeset = new _emberChangeset.default(item, (0, _emberChangesetValidations.default)(_contactInformation.default), _contactInformation.default);
        this.get('changesetList').addObject(contactChangeset);
        this.set('isDirty', true);
      },
      removeContactPerson: function removeContactPerson(contact, index) {
        if (contact.get('id')) {
          var aux = this.get('store').peekRecord('quote-contact', contact.get('id'));
          aux.deleteRecord();
          aux.save();
        }

        this.get('changesetList').removeAt(index);
        this.set('isDirty', true);
      },
      chooseBusinessType: function chooseBusinessType(businessType) {
        this.set('quote.businessType', businessType);
        this.set('isDirty', true);
      },
      choosePrimaryLanguage: function choosePrimaryLanguage(language) {
        this.set('quote.mainLanguage', language);

        if (!this.get('quote.proposalLanguage.id')) {
          this.set('quote.proposalLanguage', language);
        }

        this.set('isDirty', true);
      },
      chooseProposalLanguage: function chooseProposalLanguage(language) {
        this.set('quote.proposalLanguage', language);
        this.set('isDirty', true);
      },
      chooseSecondaryLanguages: function chooseSecondaryLanguages(languages) {
        this.set('localSecondaryLanguages', languages);
        this.set('isDirty', true);
      },

      /*
       chooseProjectContext(context){
        this.set('quote.projectContext', context);
      },
      chooseProjectRole(role){
        this.set('quote.projectRole', role);
      },
      */
      wrapperFocusTriggered: function wrapperFocusTriggered(element, componentType) {
        this.set('showProjectGoals', false);
        this.set('componentHTML', this.get(componentType + 'HTML'));
      },
      changeInstructionsText: function changeInstructionsText(componentType) {
        this.set('componentHTML', this.get(componentType + 'HTML'));

        if (this.get('media.isMobile') || this.get('media.isTablet')) {
          this.set('commentModalText', this.get('componentHTML'));
          this.set('showCommentModal', true);
        }
      },
      openDatepicker: function openDatepicker(dateType) {
        if (dateType === 'start') {
          Ember.$('.start-date-picker').focus();
        } else if (dateType === 'end') {
          Ember.$('.end-date-picker').focus();
        }
      },
      financialProposalChanged: function financialProposalChanged(value) {
        var radioOptions = this.get('radioButtonOptions');
        this.set('quote.timeMaterial', value === radioOptions.timeMaterial);
        this.set('quote.fixedFee', value === radioOptions.fixedFee);
        this.set('quote.successBased', value === radioOptions.successBased);
        this.set('quote.hybridModel', value === radioOptions.hybridModel);
        this.set('isDirty', true);
      },
      disclosureOfQuestionsChanged: function disclosureOfQuestionsChanged(value) {
        var radioOptions = this.get('radioButtonOptions');
        this.set('quote.publicQuestions', value === radioOptions.publicQuestions);
        this.set('quote.undisclosedQuestions', value === radioOptions.undisclosedQuestions);
        this.set('isDirty', true);
      },
      pitchPresentationEnabledChanged: function pitchPresentationEnabledChanged(value) {
        if (this.get('quote.pitchSession') == (value === 1)) {
          return;
        }

        if (value === 1) {
          this.set('quote.pitchSession', true);
        } else {
          this.set('quote.pitchSession', false);
          this.set('quote.pitchWeight', 0);
          this.set('quote.financialWeight', 50);
          this.set('quote.technicalWeight', 50);
        }

        var fields = ['presentationStartDate', 'presentationEndDate'];

        if (value === 1) {
          for (var i = 0; i < fields.length; i++) {
            var newEntry = this.store.createRecord('quote-timeline-entry', {
              name: fields[i],
              date: this.get('quote').get(fields[i]),
              isCustom: false
            });
            this.get('localQuoteTimelineEntries').addObject(newEntry);
          }
        } else {
          for (var _i = 0; _i < fields.length; _i++) {
            var entry = this.get('localQuoteTimelineEntries').findBy('name', fields[_i]);

            if (entry) {
              this.get('localQuoteTimelineEntries').removeObject(entry);
            }
          }
        }

        this.set('isDirty', true);
      },
      contactInformationChanged: function contactInformationChanged(value) {
        var radioOptions = this.get('radioButtonOptions');
        this.set('quote.personalContactInformation', value === radioOptions.personalContactInformation);
        this.set('quote.noPersonalContactInformation', value === radioOptions.noPersonalContactInformation);

        if (this.get('quote.personalContactInformation')) {
          if (!this.get('changesetList').length) {
            var item = this.store.createRecord('quote-contact', {
              quote: this.get('quote'),
              salutation: null,
              firstName: this.get('currentUser.firstName'),
              lastName: this.get('currentUser.lastName'),
              jobTitle: ''
            });
            var contactChangeset = new _emberChangeset.default(item, (0, _emberChangesetValidations.default)(_contactInformation.default), _contactInformation.default);
            this.get('changesetList').addObject(contactChangeset);
          }
        }

        this.set('isDirty', true);
      },
      closedEnvelopeChanged: function closedEnvelopeChanged(value) {
        this.set('quote.closedEnvelope', value);
        this.set('isDirty', true);
      },
      fileTypeChanged: function fileTypeChanged(value) {
        this.set('uploadedFileType', value);
        this.set('otherFileType', null);
        this.set('isDirty', true);
      },
      companyTypeChanged: function companyTypeChanged(value) {
        var radioOptions = this.get('radioButtonOptions');
        this.set('quote.useDefaultCompany', value === radioOptions.defaultCompany);
        this.set('isDirty', true);
      },
      //personalInformationChanged(value) {
      //  const radioOptions = this.get('radioButtonOptions');
      //  this.set('quote.meAsPersonContact', value === radioOptions.meAsPersonContact);
      //  this.set('quote.anotherAsPersonContact', value === radioOptions.anotherAsPersonContact);
      //},
      deadlineTimeChanged: function deadlineTimeChanged(value) {
        var radioOptions = this.get('radioButtonOptions');
        this.set('quote.deadlineTime', value === radioOptions.deadlineMidDay ? radioOptions.deadlineMidDay : value === radioOptions.deadlineEndOfDay ? radioOptions.deadlineEndOfDay : '');
        this.set('isDirty', true);
      },

      /* projectContextChanged(value){
         if (value) {
           const projectContexts = this.get('model.projectContexts');
           this.set('quote.projectContext', projectContexts.findBy('contextEn', value));
         }
       },
       projectRoleChanged(value) {
         if (value) {
           if (value === this.get('otherProjectRole')) {
             this.set('quote.projectRole', null);
             this.set('quote.personRole', this.get('quote.extraProjectRole'));
           } else {
             const projectRoles = this.get('model.projectRoles');
             this.set('quote.projectRole', projectRoles.findBy('roleEn', value));
             this.set('quote.personRole', value);
           }
         }
       },
       */
      //addBeautyItem() {
      //  const newBeautyItem = this.get('newBeautyItem').trim();
      //  if (newBeautyItem) {
      //    const newItem = this.store.createRecord('beautyItem', {
      //      description: newBeautyItem,
      //      'default': false,
      //    });
      //    this.get('beautyItems').addObject({
      //      item: newItem,
      //      checked: true
      //    });
      //    this.set('newBeautyItem', '');
      //  }
      //},
      addProjectPhase: function addProjectPhase(projectPhase) {
        var localProjectPhasesItems = this.get('localProjectPhasesItems');
        var projectPhaseItem = localProjectPhasesItems.filterBy('projectPhase', projectPhase);

        if (projectPhaseItem) {
          projectPhaseItem.set('isChecked', true);
        }

        this.get('quote.errors').remove('projectPhases');
        this.set('isDirty', true);
      },
      addTimelineEntry: function addTimelineEntry() {
        var newEntry = this.store.createRecord('quote-timeline-entry', {});
        newEntry.set('isCustom', true);
        this.get('localQuoteTimelineEntries').addObject(newEntry);
        this.set('isDirty', true);
      },
      removeProjectPhase: function removeProjectPhase(projectPhase) {
        var localProjectPhasesItems = this.get('localProjectPhasesItems');
        var projectPhaseItem = localProjectPhasesItems.filterBy('projectPhase', projectPhase);

        if (projectPhaseItem) {
          projectPhaseItem.set('isChecked', false);
        }

        this.set('isDirty', true);
      },
      addQuoteDocument: function addQuoteDocument(file) {
        var _this6 = this;

        this.store.find('quote-document', file.id).then(function (currentFile) {
          var localQuoteDocuments = _this6.get('localQuoteDocuments');

          localQuoteDocuments.addObject(currentFile);

          _this6.get('notify').success(_this6.get('intl').t('notifyMessages.fileUploaded').toString());
        });
        this.set('isDirty', true);
      },
      removeQuoteDocument: function removeQuoteDocument(file) {
        this.get('localQuoteDocuments').removeObject(file);
        this.set('isDirty', true);
      },
      removeTimelineEntry: function removeTimelineEntry(timelineEntry) {
        this.get('localQuoteTimelineEntries').removeObject(timelineEntry);
        this.set('isDirty', true);
      },
      saveAsDraft: function saveAsDraft(callback) {
        this.set('blockPrevious', true);
        callback(this.saveQuote(false, null, true));
      },
      proceed: function proceed(route) {
        this.set('isDirty', false);
        this.set('showUnsavedChangesModal', false);
        this.set('allowSaveToLocalStorage', false);
        var quote = this.get('quote');
        quote.rollbackAttributes();
        var localStorage = this.get('localStorage');
        localStorage.set('quote', null);
        localStorage.set('quoteDocuments', []);
        localStorage.set('quoteProjectGoals', []);
        localStorage.set('quoteProjectPhases', []);
        localStorage.set('quoteSecondaryLanguages', []);
        this.get('localQuoteProjectGoals').forEach(function (quoteProjectGoal) {
          return quoteProjectGoal.rollbackAttributes();
        });
        quote.reload();

        if (route && route.includes(_environment.default.CANONICAL_DOMAIN_NEXT)) {
          window.location = route;
          return;
        }

        this.transitionToRoute(route);
      },
      saveAndProceed: function saveAndProceed(route) {
        this.set('blockPrevious', true);
        this.saveQuote(false, null, true, route);
      },
      next: function next() {
        this.set('targetRoute', 'app.rfp.criteria-evaluation');

        if (this.get('isDirty')) {
          this.send('openUnsavedChangesModal');
        } else {
          this.transitionToRoute(this.get('targetRoute'));
        }
      },
      previous: function previous() {
        if (this.get('blockPrevious')) {
          return;
        }

        this.set('targetRoute', 'app.rfp.consultants');

        if (this.get('isDirty')) {
          this.send('openUnsavedChangesModal');
        } else {
          this.transitionToRoute(this.get('targetRoute'));
        }
      },
      closeCommentModal: function closeCommentModal() {
        this.set('showCommentModal', false);
      },
      completeProfile: function completeProfile() {
        this.send('setShowConfirm', true);
        this.transitionToRoute('app.account.index', {
          queryParams: {
            returnToProject: true
          }
        });
      }
    }
  });

  _exports.default = _default;
});