define("cb-ui/components/client-proposal", ["exports", "cb-ui/utils/download-file", "ember-local-storage"], function (_exports, _downloadFile, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service('notify'),
    store: Ember.inject.service(),
    proposal: null,
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    appLanguage: Ember.computed.alias('localStorage.language'),
    proposalStatus: Ember.computed('proposal', function () {
      if (this.get('proposal.isActive')) {
        return "projectStatuses.".concat(this.get('proposal.status'));
      }

      return 'projectStatuses.inactive';
    }),
    actions: {
      cloneProposal: function cloneProposal(proposal) {
        var _this = this;

        if (confirm(this.get('intl').t('confirmMessages.cloneProposal'))) {
          proposal.cloneProposal().then(function () {
            _this.get('notify').success(_this.get('intl').t('notifyMessages.cloneProposal').toString());

            window.location.reload();
          }).catch(function () {
            _this.get('notify').error(_this.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
          });
        }
      },
      downloadProposalDoc: function downloadProposalDoc(id) {
        this.get('store').findRecord('proposal-document', id).then(function (res) {
          (0, _downloadFile.default)(res.data.file, res.get('fileName'));
        });
      }
    }
  });

  _exports.default = _default;
});