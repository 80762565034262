define("cb-ui/templates/financial-details-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AYPEHbtQ",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"bs-modal\",null,[[\"footer\",\"body\",\"onHidden\",\"size\"],[false,false,[25,\"action\",[[19,0,[]],\"closeFinancialDetailsModal\"],null],\"lg\"]],{\"statements\":[[4,\"component\",[[19,1,[\"body\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n          \"],[6,\"h3\"],[7],[1,[25,\"t\",[\"app.labels.financialQuotationDetails\"],null],false],[8],[0,\"\\n        \"],[1,[25,\"financial-quotation-details\",null,[[\"proposal\"],[[20,[\"activeProposal\"]]]]],false],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"m-md\"],[7],[0,\"\\n              \"],[6,\"button\"],[9,\"class\",\"btn btn-primary m-b-sm\"],[3,\"action\",[[19,0,[]],\"closeFinancialDetailsModal\"]],[7],[0,\" \"],[1,[25,\"t\",[\"buttons.close\"],null],false],[0,\"\\n              \"],[8],[0,\"\\n          \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/financial-details-modal.hbs"
    }
  });

  _exports.default = _default;
});