define("cb-ui/controllers/app/project/proposal/criteria-evaluation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    quote: null,
    proposal: null,
    technicalQuoteCriteriaGroups: new Ember.A(),
    pitchQuoteCriteriaGroups: new Ember.A()
  });

  _exports.default = _default;
});