define("cb-ui/controllers/app/project/client/project-management", ["exports", "ember-ajax", "cb-ui/config/environment"], function (_exports, _emberAjax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionContext: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    quote: null,
    showHoldModal: null,
    showCancelModal: null,
    showReviveModal: null,
    showNotificationsModal: null,
    selectedReason: null,
    message: null,
    holdMessage: null,
    cancelMessage: null,
    reviveMessage: null,
    selectedCancelReason: null,
    nofiticationMessageClasses: 'form-control border-radius-0 rfp-textarea',
    holdMessageClasses: 'form-control border-radius-0 rfp-textarea',
    cancelMessageClasses: 'form-control border-radius-0 rfp-textarea',
    reviveMessageClasses: 'form-control border-radius-0 rfp-textarea',
    reasons: ['app.projectClient.projectManagement.projectAwardOrStartPostponed', 'app.projectClient.projectManagement.projectScopeNeedsRevison', 'app.projectClient.projectManagement.projectStakeholderChanges', 'app.projectClient.projectManagement.internalClarifications', 'app.projectClient.projectManagement.otherReasons'],
    cancelReasons: ['app.projectClient.projectManagement.projectWasCancelledInternally', 'app.projectClient.projectManagement.expectationsOfInternalClientConcerningPresentedProposalsWereNotMet', 'app.projectClient.projectManagement.projectWontBeExecutedViaExternalServiceProvider', 'app.projectClient.projectManagement.otherReasons'],
    messageObserver: Ember.observer('message', function () {
      this.set('nofiticationMessageClasses', 'form-control border-radius-0 rfp-textarea');
      this.set('notificationMessageMissing', false);
    }),
    holdMessageObserver: Ember.observer('holdMessage', function () {
      this.set('holdMessageClasses', 'form-control border-radius-0 rfp-textarea');
      this.set('holdMessageMissing', false);
    }),
    cancelMessageObserver: Ember.observer('cancelMessage', function () {
      this.set('cancelMessageClasses', 'form-control border-radius-0 rfp-textarea');
      this.set('cancelMessageMissing', false);
    }),
    reviveMessageObserver: Ember.observer('reviveMessage', function () {
      this.set('reviveMessageClasses', 'form-control border-radius-0 rfp-textarea');
      this.set('reviveMessageMissing', false);
    }),
    holdReasonObserver: Ember.observer('selectedReason', function () {
      this.set('holdReasonMissing', false);
    }),
    cancelReasonObserver: Ember.observer('selectedCancelReason', function () {
      this.set('cancelReasonMissing', false);
    }),
    actions: {
      openCancelModal: function openCancelModal() {
        this.set('showCancelModal', true);
      },
      closeCancelModal: function closeCancelModal() {
        this.set('showCancelModal', false);
      },
      openHoldModal: function openHoldModal() {
        this.set('showHoldModal', true);
      },
      closeHoldModal: function closeHoldModal() {
        this.set('showHoldModal', false);
      },
      dropdownReasonChanged: function dropdownReasonChanged(reason) {
        this.set('selectedReason', reason);
      },
      dropdownCancelReasonChanged: function dropdownCancelReasonChanged(reason) {
        this.set('selectedCancelReason', reason);
      },
      openReviveModal: function openReviveModal() {
        this.set('showReviveModal', true);
      },
      closeReviveModal: function closeReviveModal() {
        this.set('showReviveModal', false);
      },
      openNotificationsModal: function openNotificationsModal() {
        this.set('showNotificationsModal', true);
      },
      closeNotificationsModal: function closeNotificationsModal() {
        this.set('showNotificationsModal', false);
      },
      putQuoteOnHold: function putQuoteOnHold() {
        var _this = this;

        if (this.get('selectedReason') && this.get('holdMessage')) {
          this.set('showSpinner', true);
          var url = _environment.default.backendAPI + '/api/send-project-notification/?quote_id=' + this.get('quote.id');
          (0, _emberAjax.default)(url, {
            type: 'POST',
            headers: {
              Accept: 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
              'Authorization': 'Bearer ' + this.get('session.data.authenticated.token')
            },
            data: JSON.stringify({
              new_status: 'on hold',
              reason: this.get('intl').t(this.get('selectedReason')),
              message: this.get('holdMessage')
            })
          }).then(function () {
            _this.set('showSpinner', false);

            _this.get('notify').success(_this.intl.t('app.projectClient.projectManagement.quoteOnHold'));

            _this.set('showHoldModal', null);

            _this.set('showCancelModal', null);

            _this.set('showReviveModal', null);

            _this.set('selectedReason', null);

            _this.set('selectedCancelReason', null);

            _this.set('message', null);

            _this.set('holdMessage', null);

            _this.set('cancelMessage', null);

            _this.set('reviveMessage', null);

            _this.set('notificationMessageMissing', false);

            _this.set('holdReasonMissing', false);

            _this.set('holdMessageMissing', false);

            _this.set('cancelReasonMissing', false);

            _this.set('cancelMessageMissing', false);

            _this.set('reviveMessageMissing', false);

            _this.set('nofiticationMessageClasses', 'form-control border-radius-0 rfp-textarea');

            _this.set('holdMessageClasses', 'form-control border-radius-0 rfp-textarea');

            _this.set('cancelMessageClasses', 'form-control border-radius-0 rfp-textarea');

            _this.set('cancelMessageClasses', 'form-control border-radius-0 rfp-textarea');

            _this.send('refreshRoute');
          }).catch(function (error) {
            _this.set('showSpinner', false);

            _this.get('notify').error(error.detail);
          });
        } else {
          if (!this.get('selectedReason')) {
            this.set('holdReasonMissing', true);
          }

          if (!this.get('holdMessage')) {
            this.set('holdMessageClasses', 'has-error-variation form-control border-radius-0 rfp-textarea');
            this.set('holdMessageMissing', true);
          }
        }
      },
      cancelQuote: function cancelQuote() {
        var _this2 = this;

        if (this.get('selectedCancelReason') && this.get('cancelMessage')) {
          this.set('showSpinner', true);
          var url = _environment.default.backendAPI + '/api/send-project-notification/?quote_id=' + this.get('quote.id');
          (0, _emberAjax.default)(url, {
            type: 'POST',
            headers: {
              Accept: 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
              'Authorization': 'Bearer ' + this.get('session.data.authenticated.token')
            },
            data: JSON.stringify({
              new_status: 'cancelled',
              reason: this.get('intl').t(this.get('selectedCancelReason')),
              message: this.get('cancelMessage')
            })
          }).then(function () {
            _this2.set('showSpinner', false);

            _this2.get('notify').success(_this2.intl.t('app.projectClient.projectManagement.quoteCancelled'));

            _this2.set('showHoldModal', null);

            _this2.set('showCancelModal', null);

            _this2.set('showReviveModal', null);

            _this2.set('selectedReason', null);

            _this2.set('selectedCancelReason', null);

            _this2.set('message', null);

            _this2.set('holdMessage', null);

            _this2.set('cancelMessage', null);

            _this2.set('reviveMessage', null);

            _this2.set('notificationMessageMissing', false);

            _this2.set('holdReasonMissing', false);

            _this2.set('holdMessageMissing', false);

            _this2.set('cancelReasonMissing', false);

            _this2.set('cancelMessageMissing', false);

            _this2.set('reviveMessageMissing', false);

            _this2.set('nofiticationMessageClasses', 'form-control border-radius-0 rfp-textarea');

            _this2.set('holdMessageClasses', 'form-control border-radius-0 rfp-textarea');

            _this2.set('cancelMessageClasses', 'form-control border-radius-0 rfp-textarea');

            _this2.set('cancelMessageClasses', 'form-control border-radius-0 rfp-textarea');

            _this2.send('refreshRoute');
          }).catch(function (error) {
            _this2.set('showSpinner', false);

            _this2.get('notify').error(error.detail);
          });
        } else {
          if (!this.get('selectedCancelReason')) {
            this.set('cancelReasonMissing', true);
          }

          if (!this.get('cancelMessage')) {
            this.set('cancelMessageClasses', 'has-error-variation form-control border-radius-0 rfp-textarea');
            this.set('cancelMessageMissing', true);
          }
        }
      },
      reviveQuote: function reviveQuote() {
        var _this3 = this;

        if (this.get('reviveMessage')) {
          this.set('showSpinner', true);
          var url = _environment.default.backendAPI + '/api/send-project-notification/?quote_id=' + this.get('quote.id');
          (0, _emberAjax.default)(url, {
            type: 'POST',
            headers: {
              Accept: 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
              'Authorization': 'Bearer ' + this.get('session.data.authenticated.token')
            },
            data: JSON.stringify({
              new_status: 'sent',
              message: this.get('reviveMessage')
            })
          }).then(function () {
            _this3.set('showSpinner', false);

            _this3.get('notify').success(_this3.intl.t('app.projectClient.projectManagement.quoteRevived'));

            _this3.set('showHoldModal', null);

            _this3.set('showCancelModal', null);

            _this3.set('showReviveModal', null);

            _this3.set('selectedReason', null);

            _this3.set('selectedCancelReason', null);

            _this3.set('message', null);

            _this3.set('holdMessage', null);

            _this3.set('cancelMessage', null);

            _this3.set('reviveMessage', null);

            _this3.set('notificationMessageMissing', false);

            _this3.set('holdReasonMissing', false);

            _this3.set('holdMessageMissing', false);

            _this3.set('cancelReasonMissing', false);

            _this3.set('cancelMessageMissing', false);

            _this3.set('reviveMessageMissing', false);

            _this3.set('nofiticationMessageClasses', 'form-control border-radius-0 rfp-textarea');

            _this3.set('holdMessageClasses', 'form-control border-radius-0 rfp-textarea');

            _this3.set('cancelMessageClasses', 'form-control border-radius-0 rfp-textarea');

            _this3.set('cancelMessageClasses', 'form-control border-radius-0 rfp-textarea');

            _this3.send('refreshRoute');
          }).catch(function (error) {
            _this3.set('showSpinner', false);

            _this3.get('notify').error(error.detail);
          });
        } else {
          this.set('reviveMessageClasses', 'has-error-variation form-control border-radius-0 rfp-textarea');
          this.set('reviveMessageMissing', true);
        }
      },
      toggleBlockNotifications: function toggleBlockNotifications() {
        var _this4 = this;

        this.set('showSpinner', true);
        var quote = this.get('quote');
        quote.set('blockNotifications', !quote.get('blockNotifications'));
        quote.save().then(function () {
          _this4.set('showSpinner', false);

          _this4.get('notify').success(_this4.intl.t('app.projectClient.projectManagement.success'));

          _this4.set('showHoldModal', null);

          _this4.set('showCancelModal', null);

          _this4.set('showReviveModal', null);

          _this4.set('selectedReason', null);

          _this4.set('selectedCancelReason', null);

          _this4.set('message', null);

          _this4.set('holdMessage', null);

          _this4.set('cancelMessage', null);

          _this4.set('reviveMessage', null);

          _this4.set('notificationMessageMissing', false);

          _this4.set('holdReasonMissing', false);

          _this4.set('holdMessageMissing', false);

          _this4.set('cancelReasonMissing', false);

          _this4.set('cancelMessageMissing', false);

          _this4.set('reviveMessageMissing', false);

          _this4.set('nofiticationMessageClasses', 'form-control border-radius-0 rfp-textarea');

          _this4.set('holdMessageClasses', 'form-control border-radius-0 rfp-textarea');

          _this4.set('cancelMessageClasses', 'form-control border-radius-0 rfp-textarea');

          _this4.set('cancelMessageClasses', 'form-control border-radius-0 rfp-textarea');

          _this4.send('refreshRoute');
        }).catch(function (error) {
          _this4.set('showSpinner', false);

          _this4.get('notify').error(error.detail);
        });
      }
    }
  });

  _exports.default = _default;
});