define("cb-ui/templates/consultants-unsaved-changes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C89nZ7dJ",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"bs-modal\",null,[[\"footer\",\"body\",\"onHidden\",\"size\"],[false,false,[25,\"action\",[[19,0,[]],\"closeUnsavedChangesModal\"],null],\"lg\"]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[19,1,[\"body\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"col-md-12\"],[7],[0,\"\\n                \"],[6,\"h4\"],[7],[1,[25,\"t\",[\"app.rfp.consultants.unsavedChangesModal.title\"],null],false],[8],[0,\"\\n\\n                \"],[6,\"p\"],[7],[0,\"\\n                    \"],[1,[25,\"t\",[\"app.rfp.consultants.unsavedChangesModal.details\"],null],false],[0,\"\\n                \"],[8],[0,\"\\n\\n\"],[4,\"bs-button\",null,[[\"type\",\"onClick\"],[\"secondary\",[25,\"action\",[[19,0,[]],\"closeUnsavedChangesModal\"],null]]],{\"statements\":[[0,\"                    \"],[1,[25,\"t\",[\"app.rfp.consultants.unsavedChangesModal.cancel\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"bs-button\",null,[[\"type\",\"onClick\"],[\"secondary\",[25,\"action\",[[19,0,[]],\"previous\"],null]]],{\"statements\":[[0,\"                    \"],[1,[25,\"t\",[\"app.rfp.consultants.unsavedChangesModal.discardProceed\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"bs-button\",null,[[\"type\",\"onClick\"],[\"primary\",[25,\"action\",[[19,0,[]],\"saveAndPrevious\"],null]]],{\"statements\":[[0,\"                    \"],[1,[25,\"t\",[\"app.rfp.consultants.unsavedChangesModal.saveProceed\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/consultants-unsaved-changes.hbs"
    }
  });

  _exports.default = _default;
});