define("cb-ui/services/cb-store", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service('store'),
    findAllWrapper: function findAllWrapper(modelName, sortKey) {
      return this.get('store').findAll(modelName).then(function (records) {
        return records.sortBy(sortKey);
      });
    },
    queryWrapper: function queryWrapper(modelName, sortKey, queryArgs) {
      return this.get('store').query(modelName, queryArgs).then(function (records) {
        return records.sortBy(sortKey);
      });
    }
  });

  _exports.default = _default;
});