define("cb-ui/components/container-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    type: null,
    didInsertElement: function didInsertElement() {
      var self = this;
      Ember.run.schedule('afterRender', this, function () {
        //TODO: handle different if it s input
        if (this.get('media.isMobile') || this.get('media.isTablet')) {
          self.sendAction('focusTriggered', this, self.get('type'));
        } else {
          this.$('div:first').on('focusin.wrapper', function () {
            self.sendAction('focusTriggered', this, self.get('type'));
          });
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.off('focusin.wrapper');
    }
  });

  _exports.default = _default;
});