define("cb-ui/components/checkbox-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    item: null,
    isChecked: null,
    label: null,
    isCheckedObserver: Ember.observer('isChecked', function () {
      if (this.get('isChecked')) {
        this.sendAction('addItem', this.get('item'));
      } else {
        this.sendAction('removeItem', this.get('item'));
      }
    })
  });

  _exports.default = _default;
});