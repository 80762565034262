define("cb-ui/components/proposal-evaluation-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    proposal: null,
    proposalEvaluations: new Ember.A(),
    proposalIsSubmitted: Ember.computed.equal('proposal.status', 'submitted'),
    evaluationCategory: null,
    evaluation: null,
    notYetRatedClass: '',
    roundedScore: Ember.computed('evaluation', 'evaluation.score', function () {
      var evaluation = this.get('evaluation');

      if (evaluation) {
        var score = evaluation.get('score');

        if (score) {
          return Math.round(score);
        }
      }

      return null;
    }),
    evaluationObserver: Ember.on('init', Ember.observer('proposalEvaluations', 'proposal', function () {
      var _this = this;

      if (this.get('proposalEvaluations')) {
        this.get('proposalEvaluations').then(function (evaluations) {
          var evaluationList = evaluations.filterBy('criteriaEvaluation.titleEn', _this.get('evaluationCategory.titleEn'));

          if (evaluationList) {
            _this.set('evaluation', evaluationList.get('firstObject'));
          }
        });
      }
    })),
    actions: {
      changeEvaluationScore: function changeEvaluationScore() {
        if (this.get('proposal.isEditAllowed')) {
          this.sendAction('changeEvaluationScore', this.get('evaluation'), this.get('evaluationCategory'));
        }
      }
    }
  });

  _exports.default = _default;
});