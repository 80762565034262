define("cb-ui/components/ember-table/mandays-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    beforeGraphSetup: function beforeGraphSetup(svg, chart) {
      chart.color(['#ffc000']);
      chart.showValues(true);
      chart.showControls(false);
      chart.noData("There are no roles");
      chart.valueFormat(d3.format(",.0f"));
    }
  });

  _exports.default = _default;
});