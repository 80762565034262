define("cb-ui/templates/components/rfp-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eq7rDqBl",
    "block": "{\"symbols\":[],\"statements\":[[6,\"ul\"],[10,\"class\",[26,[[25,\"if\",[[20,[\"media\",\"isMobile\"]],\"nav-stacked\"],null],\" nav nav-tabs rfp-steps\"]]],[7],[0,\"\\n    \"],[6,\"li\"],[10,\"class\",[25,\"if\",[[25,\"eq\",[[20,[\"activeStep\"]],1],null],\"active\"],null],null],[7],[6,\"a\"],[7],[0,\"Project Information\"],[8],[8],[0,\"\\n    \"],[6,\"li\"],[10,\"class\",[25,\"if\",[[25,\"eq\",[[20,[\"activeStep\"]],2],null],\"active\"],null],null],[7],[6,\"a\"],[7],[0,\"Consultants\"],[8],[8],[0,\"\\n    \"],[6,\"li\"],[10,\"class\",[25,\"if\",[[25,\"eq\",[[20,[\"activeStep\"]],3],null],\"active\"],null],null],[7],[6,\"a\"],[7],[0,\"Project Details\"],[8],[8],[0,\"\\n    \"],[6,\"li\"],[10,\"class\",[25,\"if\",[[25,\"eq\",[[20,[\"activeStep\"]],4],null],\"active\"],null],null],[7],[6,\"a\"],[7],[0,\"Cover Letter\"],[8],[8],[0,\"\\n    \"],[6,\"li\"],[10,\"class\",[25,\"if\",[[25,\"eq\",[[20,[\"activeStep\"]],5],null],\"active\"],null],null],[7],[6,\"a\"],[7],[0,\"Review & Submit\"],[8],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/rfp-menu.hbs"
    }
  });

  _exports.default = _default;
});