define("cb-ui/templates/components/post-announcement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ooToL9Cj",
    "block": "{\"symbols\":[],\"statements\":[[6,\"form\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"form-group m-t-xs\"],[7],[0,\"\\n        \"],[6,\"label\"],[7],[0,\"\\n          \"],[1,[25,\"t\",[\"app.partials.announcement.announcementContent\"],null],false],[0,\"\\n        \"],[8],[0,\"\\n        \"],[1,[25,\"tinymce-editor\",null,[[\"value\",\"parent\",\"hasVariables\"],[[20,[\"newAnnouncement\"]],[19,0,[]],false]]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"btn btn-primary m-t-md\"],[3,\"action\",[[19,0,[]],\"addAnnouncement\"]],[7],[0,\"\\n      \"],[1,[25,\"t\",[\"buttons.post\"],null],false],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/post-announcement.hbs"
    }
  });

  _exports.default = _default;
});