define("cb-ui/components/consultants-list-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    specialistConsultants: new Ember.A(),
    diversifiedConsultants: new Ember.A(),
    generalistConsultants: new Ember.A(),
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('media.isDesktop') || this.get('media.isJumbo')) {
        var self = this;
        Ember.run.next(this, function () {
          var containers = self.$('.consultants-container');
          var descriptionContainers = self.$(containers).find('.consultants-container-description');
          var descriptionHeights = descriptionContainers.map(function (index, item) {
            return self.$(item).height();
          });
          var maxDescriptionHeight = Math.max.apply(null, descriptionHeights);
          descriptionContainers.height(maxDescriptionHeight);
          var containersHeights = containers.map(function (index, item) {
            return self.$(item).height();
          });
          var maxContainersHeights = Math.max.apply(null, containersHeights);
          containers.css('min-height', (maxContainersHeights + 12).toString() + 'px');
        });
      }
    }
  });

  _exports.default = _default;
});