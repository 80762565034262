define("cb-ui/components/ember-table/graphical-representation-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    beforeGraphSetup: function beforeGraphSetup(svg, chart) {
      chart.color(['#0d3c5a']);
      chart.showValues(true);
      chart.showControls(false);
      chart.noData("");
      chart.valueFormat(d3.format(".0f"));
    },
    afterChartSetup: function afterChartSetup(svg, chart) {
      if (svg[0][0].childNodes[0].childNodes[0]) {
        while (svg[0][0].childNodes[0].childNodes[0].childNodes.length > 1) {
          svg[0][0].childNodes[0].childNodes[0].childNodes[svg[0][0].childNodes[0].childNodes[0].childNodes.length - 1].remove();
        }

        svg[0][0].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.forEach(function (svgcn) {
          if (svgcn.childNodes.length > 1) {
            svgcn.childNodes[0].remove();
          }
        });
      }
    },
    dispatchChartEvents: {
      chart: {
        renderEnd: function renderEnd(svg, chart) {
          svg[0][0].setAttribute("style", "width: 400px");
          setTimeout(function () {
            svg[0][0].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.forEach(function (svgcn) {
              var originalWidth = svg[0][0].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[0].getBoundingClientRect().width;
              var outerWidth = document.getElementsByClassName('chart-outer-layer')[0].parentNode.parentNode.parentNode.offsetWidth;
              var innerWidth = svgcn.getBoundingClientRect().width;

              if (svgcn.transform.baseVal[0]) {
                if (innerWidth == originalWidth) {
                  svgcn.transform.baseVal[0].setMatrix(svgcn.transform.baseVal[0]["matrix"].translate(outerWidth - 50 - innerWidth / 2, 0));
                } else {
                  svgcn.transform.baseVal[0].setMatrix(svgcn.transform.baseVal[0]["matrix"].translate(outerWidth - 50 - originalWidth / 2, 0));
                }
              }
            });
          }, 100);
        }
      }
    }
  });

  _exports.default = _default;
});