define("cb-ui/models/evaluation", ["exports", "ember-data", "cb-ui/models/base-criteria-evaluation"], function (_exports, _emberData, _baseCriteriaEvaluation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;

  var _default = _baseCriteriaEvaluation.default.extend({
    group: belongsTo('criteria-evaluation-group', {
      async: true,
      inverse: 'evaluations'
    }),
    type: attr('string')
  });

  _exports.default = _default;
});