define("cb-ui/models/country", ["exports", "ember-data", "cb-ui/utils/translate-property"], function (_exports, _emberData, _translateProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    commonNameEn: _emberData.default.attr('string'),
    commonNameDe: _emberData.default.attr('string'),
    commonName: (0, _translateProperty.default)('commonNameEn', 'commonNameDe')
  });

  _exports.default = _default;
});