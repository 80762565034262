define("cb-ui/instance-initializers/raven-setup", ["exports", "cb-ui/config/environment", "ember-cli-sentry/utils/parse-regex-errors"], function (_exports, _environment, _parseRegexErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  // Ember merge is deprecated as of 2.5, but we need to check for backwards
  // compatibility.
  var assign = Ember.assign || Ember.merge;

  function initialize(application) {
    if (Ember.get(_environment.default, 'sentry.development') === true) {
      if (Ember.get(_environment.default, 'sentry.debug') === true) {
        Ember.Logger.info('`sentry` is configured for development mode.');
      }

      return;
    }

    if (!_environment.default.sentry) {
      throw new Error('`sentry` should be configured when not in development mode.');
    }

    var _config$sentry = _environment.default.sentry,
        dsn = _config$sentry.dsn,
        environment = _config$sentry.environment,
        _config$sentry$debug = _config$sentry.debug,
        debug = _config$sentry$debug === void 0 ? true : _config$sentry$debug,
        _config$sentry$includ = _config$sentry.includePaths,
        includePaths = _config$sentry$includ === void 0 ? [] : _config$sentry$includ,
        _config$sentry$whitel = _config$sentry.whitelistUrls,
        whitelistUrls = _config$sentry$whitel === void 0 ? [] : _config$sentry$whitel,
        _config$sentry$servic = _config$sentry.serviceName,
        serviceName = _config$sentry$servic === void 0 ? 'raven' : _config$sentry$servic,
        _config$sentry$servic2 = _config$sentry.serviceReleaseProperty,
        serviceReleaseProperty = _config$sentry$servic2 === void 0 ? 'release' : _config$sentry$servic2,
        _config$sentry$ravenO = _config$sentry.ravenOptions,
        ravenOptions = _config$sentry$ravenO === void 0 ? {} : _config$sentry$ravenO;

    if (Ember.get(ravenOptions, 'ignoreErrors.length')) {
      Ember.set(ravenOptions, 'ignoreErrors', (0, _parseRegexErrors.parseRegexErrors)(ravenOptions.ignoreErrors));
    }

    var lookupName = "service:".concat(serviceName);
    var service = application.lookup ? application.lookup(lookupName) : application.container.lookup(lookupName);

    try {
      window.Raven.debug = debug; // Keeping existing config values for includePaths, whitelistUrls, for compatibility.

      var ravenConfig = assign({
        environment: environment,
        includePaths: includePaths,
        whitelistUrls: whitelistUrls,
        release: service.get(serviceReleaseProperty) || _environment.default.APP.version
      }, ravenOptions);
      window.Raven.config(dsn, ravenConfig);
    } catch (e) {
      Ember.Logger.warn('Error during `sentry` initialization: ' + e);
      return;
    }

    window.Raven.install();
    var _config$sentry$global = _environment.default.sentry.globalErrorCatching,
        globalErrorCatching = _config$sentry$global === void 0 ? true : _config$sentry$global;

    if (globalErrorCatching === true) {
      service.enableGlobalErrorCatching();
    }
  }

  var _default = {
    initialize: initialize,
    name: 'sentry-setup'
  };
  _exports.default = _default;
});