define("cb-ui/controllers/wizard/index", ["exports", "cb-ui/mixins/wizard-controller", "ember-intl", "ember-local-storage"], function (_exports, _wizardController, _emberIntl, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var radioOptions = {
    managementConsulting: 'managementConsulting',
    itConsulting: 'itConsulting',
    interimManagement: 'interimManagement',
    recruiting: 'recruiting'
  };

  var _default = Ember.Controller.extend(_wizardController.default, {
    notify: Ember.inject.service('notify'),
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    componentHTML: null,
    queryParams: ['industry', 'service'],
    industry: null,
    service: null,
    // info for form fields
    countryHTML: (0, _emberIntl.translationMacro)('app.rfp.projectInformation.info.country'),
    projectNameHTML: (0, _emberIntl.translationMacro)('app.rfp.projectInformation.info.projectName'),
    industryHTML: (0, _emberIntl.translationMacro)('app.rfp.projectInformation.info.industryStandard'),
    companySizeHTML: (0, _emberIntl.translationMacro)('app.rfp.projectInformation.info.companySizeTurnover'),
    projectScopeHTML: (0, _emberIntl.translationMacro)('app.rfp.projectInformation.info.serviceStandard'),
    locationHTML: (0, _emberIntl.translationMacro)('app.rfp.projectInformation.info.mainLocation'),
    projectGoalsHTML: (0, _emberIntl.translationMacro)('app.rfp.projectInformation.info.projectGoals'),
    projectPhasesHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.projectPhases'),
    timelineHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.timeline'),
    languageHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.mainLanguage'),
    projectGoalAddedNotification: (0, _emberIntl.translationMacro)('app.rfp.projectInformation.projectGoalAddedNotification'),
    radioButtonOptions: radioOptions,
    serviceType: 'managementConsulting',
    modeOfEngagement: Ember.computed('quote.remoteConsulting', function () {
      return this.get('quote.remoteConsulting');
    }),
    propertyNameIndustry: null,
    propertyNameService: null,
    projectServices: [],
    leafProjectServices: new Ember.A(),
    projectService: null,
    projectIndustries: [],
    leafProjectIndustries: new Ember.A(),
    projectIndustry: null,
    localSecondaryLocations: new Ember.A(),
    localProjectPhasesItems: new Ember.A(),
    localSecondaryLanguages: new Ember.A(),
    serviceTypes: new Ember.A(),
    // show lists for project goals
    showProjectGoals: false,
    showCommentModal: null,
    commentModalText: (0, _emberIntl.translationMacro)('wizard.info.projectInformation'),
    projectGoals: Ember.computed.alias('model.projectGoals'),
    monetaryProjectGoals: Ember.computed.filterBy('model.projectGoals', 'isMonetary', true),
    nonMonetaryProjectGoals: Ember.computed.filterBy('model.projectGoals', 'isMonetary', false),
    localProjectGoals: new Ember.A(),
    localProjectGoalsObserver: Ember.observer('localProjectGoals.[]', function () {
      var projectGoalsIds = this.get('localProjectGoals').map(function (item) {
        return parseInt(item.get('id'));
      });
      this.set('quote.projectGoalsOrder', projectGoalsIds);
    }),
    minStartDate: Ember.computed(function () {
      return new Date();
    }),
    appLanguage: Ember.computed.alias('localStorage.language'),
    quoteObserver: Ember.observer('quote.errors.length', 'quote.industryStandard', 'quote.serviceStandard', 'localSecondaryLocations.length', 'localProjectGoals.length', function () {
      Ember.run.debounce(this, this.equalizeHeight, 50);
    }),
    setInitialIndustryService: function setInitialIndustryService() {
      var industryId = this.get('industry');
      var serviceId = this.get('service');

      if (industryId) {
        var industries = this.get('leafProjectIndustries');
        var selectedIndustry = industries.findBy('id', industryId);
        this.set('projectIndustry', selectedIndustry);
        this.set('quote.industryStandard', selectedIndustry);
      }

      if (serviceId) {
        var services = this.get('leafProjectServices');
        var selectedService = services.findBy('id', serviceId);
        this.set('projectService', selectedService);
        this.set('quote.serviceStandard', selectedService);
      }
    },
    equalizeHeight: function equalizeHeight() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        if (_this.get('media.isDesktop') || _this.get('media.isJumbo')) {
          _this.equalizerEvent();
        }
      });
    },
    actions: {
      changeInstructionsText: function changeInstructionsText(componentType) {
        if (componentType === 'projectGoals') {
          this.set('showProjectGoals', true);
        } else {
          this.set('showProjectGoals', false);
        }

        this.set('componentHTML', this.get(componentType + 'HTML'));

        if (this.get('media.isMobile') || this.get('media.isTablet')) {
          this.set('commentModalText', this.get('componentHTML'));
          this.set('showCommentModal', true);
        }
      },
      chooseCountry: function chooseCountry(country) {
        this.set('quote.country', country);
      },
      chooseIndustry: function chooseIndustry(industry) {
        this.set('projectIndustry', industry); //const projectIndustry = this.get('leafProjectIndustries').findBy(this.get('propertyNameIndustry'), industry);
        //this.set('quote.industryStandard', projectIndustry);

        this.set('quote.industryStandard', industry);
      },
      chooseBusinessType: function chooseBusinessType(businessType) {
        this.set('quote.businessType', businessType);
      },
      chooseCompanySizeTurnover: function chooseCompanySizeTurnover(companySizeTurnover) {
        this.set('quote.companySizeTurnover', companySizeTurnover);
      },
      chooseCompanySizeEmployees: function chooseCompanySizeEmployees(companySizeEmployees) {
        this.set('quote.companySizeEmployees', companySizeEmployees);
      },
      chooseProjectGoal: function chooseProjectGoal(projectGoals) {
        this.set('localProjectGoals', projectGoals);
      },
      chooseProjectScope: function chooseProjectScope(projectScope) {
        this.set('projectService', projectScope); //const projectService = this.get('leafProjectServices').findBy(this.get('propertyNameService'), projectScope);
        //this.set('quote.serviceStandard', projectService);

        this.set('quote.serviceStandard', projectScope);
      },
      choosePrimaryCountry: function choosePrimaryCountry(country) {
        this.set('quote.mainLocation', country);
      },
      chooseSecondaryCountries: function chooseSecondaryCountries(countries) {
        this.set('localSecondaryLocations', countries);
      },
      choosePrimaryLanguage: function choosePrimaryLanguage(language) {
        this.set('quote.mainLanguage', language);
      },
      chooseSecondaryLanguages: function chooseSecondaryLanguages(languages) {
        this.set('localSecondaryLanguages', languages);
      },
      addQuoteProjectGoal: function addQuoteProjectGoal(goal) {
        var quote = this.get('quote');
        quote.get('errors').remove('quoteProjectGoals');

        if (goal) {
          var language = quote.get('projectLanguage');
          var notifyText = '';
          if (language === 'de') notifyText = notifyText + goal.get('projectGoalDe');else notifyText = notifyText + goal.get('projectGoalEn');
          this.get('notify').success(notifyText + ' ' + this.get('projectGoalAddedNotification'));
        }

        var name = goal ? goal.get('projectGoalEn') : '';
        var quoteProjectGoal = this.get('store').createRecord('quote-project-goal', {
          quote: null,
          projectGoal: goal,
          description: '',
          name: name
        });
        var localQuoteProjectGoals = this.get('localQuoteProjectGoals');
        if (!localQuoteProjectGoals.includes(quoteProjectGoal)) localQuoteProjectGoals.addObject(quoteProjectGoal);
      },
      deleteQuoteProjectGoal: function deleteQuoteProjectGoal(quoteProjectGoal) {
        var localQuoteProjectGoals = this.get('localQuoteProjectGoals');

        if (localQuoteProjectGoals.includes(quoteProjectGoal)) {
          localQuoteProjectGoals.removeObject(quoteProjectGoal);
        }
      },
      addMonetaryGoal: function addMonetaryGoal(goal) {
        var quote = this.get('quote');
        var selectedGoals = this.get('localProjectGoals');
        quote.get('errors').remove('projectGoals');

        if (!selectedGoals.includes(goal)) {
          if (selectedGoals.get('length') < 5) {
            selectedGoals.addObject(goal);
          }
        }
      },
      removeMonetaryGoal: function removeMonetaryGoal(goal) {
        var selectedGoals = this.get('localProjectGoals');

        if (selectedGoals.includes(goal)) {
          selectedGoals.removeObject(goal);
        }
      },
      // addProjectPhase(projectPhase){
      //   const localProjectPhasesItems = this.get('localProjectPhasesItems');
      //   const projectPhaseItem = localProjectPhasesItems.filterBy('projectPhase', projectPhase);
      //   if (projectPhaseItem) {
      //     projectPhaseItem.set('isChecked', true);
      //   }
      //   this.get('quote.errors').remove('projectPhases');
      // },
      // removeProjectPhase(projectPhase){
      //   const localProjectPhasesItems = this.get('localProjectPhasesItems');
      //   const projectPhaseItem = localProjectPhasesItems.filterBy('projectPhase', projectPhase);
      //   if (projectPhaseItem) {
      //     projectPhaseItem.set('isChecked', false);
      //   }
      // },
      serviceTypeChanged: function serviceTypeChanged(value) {
        this.set('serviceType', value);
        var serviceType = this.get('serviceTypes').findBy('serviceTypeEn', this.get('serviceType'));
        this.set('quote.serviceType', serviceType);
      },
      engagementChanged: function engagementChanged(value) {
        this.set('quote.remoteConsulting', value);
      },
      wrapperFocusTriggered: function wrapperFocusTriggered(element, componentType) {
        if (componentType === 'projectGoals') {
          this.set('showProjectGoals', true);
          this.set('componentHTML', this.get('basicHTML'));
        } else {
          this.set('showProjectGoals', false);
          this.set('componentHTML', this.get(componentType + 'HTML'));
        }
      },
      next: function next(callback) {
        var _this2 = this;

        var quote = this.get('quote');
        var quoteProjectGoals = this.get('localQuoteProjectGoals');
        quote.set('quoteProjectGoals', quoteProjectGoals);
        var quoteRelatedPromises = [];
        quoteProjectGoals.forEach(function (item) {
          quoteRelatedPromises.push(item.save({
            adapterOptions: {
              validate: true
            }
          }));
        });
        var globalPromise = Ember.RSVP.all(quoteRelatedPromises).then(function () {
          var promise = quote.save();
          promise.then(function () {
            _this2.transitionToRoute('wizard.consultants');
          });
        }).catch(function (err) {
          err.errors.forEach(function (error) {
            _this2.get('notify').error(error.detail);
          });
          throw err;
        });
        callback(globalPromise);
      },
      cancel: function cancel() {
        var quote = this.get('quote');
        quote.rollbackAttributes();
        window.location = 'https://apadua.com';
      },
      commentModalShow: function commentModalShow() {
        this.set('commentModalText', (0, _emberIntl.translationMacro)('wizard.info.projectInformation'));
        this.set('showCommentModal', true);
      },
      closeCommentModal: function closeCommentModal() {
        this.set('showCommentModal', false);
      }
    }
  });

  _exports.default = _default;
});