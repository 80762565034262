define("cb-ui/components/rfp-timeline-graph", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    quote: null,
    quoteTimelineEntries: null,
    timelineGraph: null,
    didInsertElement: function didInsertElement() {
      var self = this;
      Ember.run.schedule('afterRender', function () {
        self.createTimelineGraph();
      });
    },
    quoteObserver: Ember.observer('quote', 'quoteTimelineEntries.@each.date', 'quoteTimelineEntries.@each.name', 'sessionContext.currentUser.language', function () {
      Ember.run.debounce(this, this.createTimelineGraphDebounced, 1000);
    }),
    willDestroyElement: function willDestroyElement() {
      this.destroyTimelineGraph();
    },
    createTimelineGraphDebounced: function createTimelineGraphDebounced() {
      this.destroyTimelineGraph();
      this.createTimelineGraph();
    },
    createTimelineGraph: function createTimelineGraph() {
      var _this = this;

      var container = document.getElementById('timeline-graph');
      var timelineEntries = this.get('quoteTimelineEntries');
      var data = [{
        name: this.get('intl').t('app.components.rfpTimelineGraph.today').toString(),
        originalDate: (0, _moment.default)()
      }];

      for (var i = 0; i < timelineEntries.length; i++) {
        var entry = timelineEntries[i];

        if (!entry.get('date')) {
          continue;
        }

        data.addObject({
          originalDate: entry.get('date'),
          name: entry.get('label')
        });
      }

      data = data.map(function (item, idx) {
        item.id = idx + 1;
        item.start = _this.CETDate(item.originalDate);
        item.content = '<div>' + item.name + '</div><div>' + _this.CETDate2(item.originalDate) + '</div>';
        return item;
      });
      var timeline = new vis.Timeline(container, data, {
        // eslint-disable-line
        //min zoom 1 day
        zoomMin: 700000000.0,
        //max zoom 3 months
        zoomMax: 7.884e+9,
        min: '2016-01-01',
        max: '2050-01-01',
        showCurrentTime: false,
        locale: this.get('intl.locale')[0]
      });
      this.set('timelineGraph', timeline);

      if (data.length > 1) {
        timeline.setWindow((0, _moment.default)(data[1].originalDate).subtract(3, 'days').toDate(), (0, _moment.default)(data[data.length - 1].originalDate).add(2, 'days').toDate());
        Ember.run.later(function () {
          if (!timeline.getVisibleItems().includes(2)) {
            timeline.focus(2);
          }
        }, 1000);
      } else {
        timeline.fit();
        timeline.focus(1);
      }
    },
    destroyTimelineGraph: function destroyTimelineGraph() {
      var timelineGraph = this.get('timelineGraph');

      if (timelineGraph) {
        timelineGraph.destroy();
      }
    },
    CETDate: function CETDate(date) {
      return (0, _moment.default)(date).tz('Europe/Berlin').format('YYYY-MM-DD');
    },
    CETDate2: function CETDate2(date) {
      return (0, _moment.default)(date).tz('Europe/Berlin').format('DD.MM.YYYY');
    },
    actions: {
      move: function move(direction) {
        var timeline = this.get('timelineGraph');
        var interval;
        var range;
        var percentage;

        if (timeline) {
          range = timeline.getWindow();
          interval = range.end - range.start;
        }

        if (direction === 'left') {
          percentage = 0.2;
        } else if (direction === 'right') {
          percentage = -0.2;
        }

        timeline.setWindow({
          start: range.start.valueOf() - interval * percentage,
          end: range.end.valueOf() - interval * percentage
        });
      },
      zoom: function zoom(type) {
        var timeline = this.get('timelineGraph');
        var interval;
        var range;
        var percentage;

        if (timeline) {
          range = timeline.getWindow();
          interval = range.end - range.start;
        }

        if (type === 'out') {
          percentage = 0.2;
        } else if (type === 'in') {
          percentage = -0.2;
        }

        timeline.setWindow({
          start: range.start.valueOf() - interval * percentage,
          end: range.end.valueOf() + interval * percentage
        });
      }
    }
  });

  _exports.default = _default;
});