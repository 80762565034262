define("cb-ui/templates/components/supplement-fee", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "enbiVQuw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[25,\"eq\",[[20,[\"value\"]],null],null]],null,{\"statements\":[[4,\"unless\",[[20,[\"showOnlyValue\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"input-group\"],[7],[0,\"\\n          \"],[6,\"input\"],[10,\"value\",[26,[[25,\"format-number\",[[20,[\"value\"]]],[[\"format\",\"maximumFractionDigits\"],[[20,[\"numberFormat\"]],2]]]]]],[9,\"class\",\"form-control m-b numeric-input\"],[9,\"disabled\",\"\"],[7],[8],[0,\"\\n        \"],[6,\"span\"],[9,\"class\",\"input-group-addon\"],[7],[0,\"\\n          \"],[1,[18,\"currency\"],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n    \"],[1,[25,\"format-number\",[[20,[\"value\"]]],[[\"format\",\"maximumFractionDigits\"],[[20,[\"numberFormat\"]],2]]],false],[0,\" \"],[1,[18,\"currency\"],false],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/supplement-fee.hbs"
    }
  });

  _exports.default = _default;
});