define("cb-ui/serializers/quote", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONAPISerializer = _emberData.default.JSONAPISerializer;

  var _default = JSONAPISerializer.extend({
    attrs: {
      companyAlias: {
        serialize: true
      },
      documents: {
        serialize: true
      },
      beautyItems: {
        serialize: true
      },
      sections: {
        serialize: true
      },
      businessType: {
        serialize: true
      },
      proposals: {
        serializer: true
      },
      questions: {
        serializer: true
      },
      timelineEntries: {
        serialize: true
      },
      quoteProjectGoals: {
        serialize: true
      },
      quoteServiceProviders: {
        serialize: true
      },
      projectPhases: {
        serialize: true
      },
      contactInformations: {
        serialize: true
      }
    }
  });

  _exports.default = _default;
});