define("cb-ui/controllers/app/project/client/review", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    quote: null,
    currentUser: null,
    criteriaEvaluations: new Ember.A(),
    quoteCompanies: new Ember.A(),
    pitchQuoteCriteriaGroups: new Ember.A(),
    technicalQuoteCriteriaGroups: new Ember.A(),
    userIsQuoteAdmin: Ember.computed.equal('userQuoteRole.role', 'admin'),
    beautyContestCriteriaEvaluations: Ember.computed.filterBy('criteriaEvaluations', 'type', 'beauty_contest'),
    extraConsultants: Ember.computed('quoteCompanies', function () {
      var extraCons = [];
      this.get('quoteCompanies').forEach(function (company) {
        if (company.get('extraConsultant') || company.get('consultantType.consultantTypeEn') === undefined) {
          extraCons.push(company);
        }
      });
      return extraCons;
    }),
    actions: {
      navbarTransitionToComponent: function navbarTransitionToComponent(componentId) {
        Ember.run.next(function () {
          Ember.$('html, body').animate({
            scrollTop: Ember.$('#' + componentId).offset().top - 200
          });
        });
      }
    }
  });

  _exports.default = _default;
});