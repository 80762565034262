define("cb-ui/templates/request-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7QXGJe+S",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"top-navbar\",null,[[\"logout\",\"changeLocalStorageLanguage\"],[\"invalidateSession\",\"changeLocalStorageLanguage\"]]],false],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"container\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"text-center h3 m-md\"],[7],[0,\"\\n    \"],[6,\"strong\"],[9,\"class\",\"m-xs\"],[7],[1,[25,\"t\",[\"app.requestSuccess.message\"],null],false],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"text-center m-xs \"],[7],[0,\"\\n\"],[4,\"link-to\",[\"request-form\"],null,{\"statements\":[[0,\"      \"],[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"btn btn-primary\"],[7],[0,\"Send another request\"],[8],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/request-success.hbs"
    }
  });

  _exports.default = _default;
});