define("cb-ui/controllers/app/management/quotes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ADMIN = 'admin',
      USER = 'user';

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    currentUser: null,
    companyUser: null,
    userQuotesAccess: new Ember.A(),
    quotesWithoutAccess: new Ember.A(),
    newQuotesAccess: new Ember.A(),
    provideAdminAccess: false,
    actions: {
      addUserQuoteRole: function addUserQuoteRole() {},
      changeQuoteAccess: function changeQuoteAccess(access) {
        var _this = this;

        var currentRole = access.get('role');

        if (currentRole === ADMIN) {
          access.set('role', USER);
        } else if (currentRole === USER) {
          access.set('role', ADMIN);
        }

        access.save().then(function () {
          _this.get('notify').success('Access was updated for quote with id ' + access.get('quote.id'));
        });
      },
      deleteQuoteAccess: function deleteQuoteAccess(access) {
        var _this2 = this;

        access.destroyRecord().then(function () {
          _this2.send('refreshRoute');

          _this2.get('notify').success('Access was removed');
        });
      },
      save: function save(newQuotesAccess) {
        var _this3 = this;

        var promisesList = newQuotesAccess.map(function (item) {
          return _this3.store.createRecord('user-quote-role', {
            quote: item,
            user: _this3.get('companyUser'),
            role: _this3.get('provideAdminAccess') ? ADMIN : USER
          }).save();
        });
        return new Ember.RSVP.all(promisesList).then(function () {
          _this3.send('refreshRoute');

          _this3.get('notify').success(_this3.get('intl').t('notifyMessages.newQuoteAccess').toString());
        }).catch(function () {
          _this3.get('notify').error(_this3.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
        });
      }
    }
  });

  _exports.default = _default;
});