define("cb-ui/models/general-info", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    descriptionEn: _emberData.default.attr('string'),
    descriptionDe: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    website: _emberData.default.attr('string'),
    logo: _emberData.default.attr('string'),
    consultantType: _emberData.default.belongsTo('consultant-type', {
      async: true
    }),
    foundationYear: _emberData.default.attr('number'),
    numberOfEmployees: _emberData.default.attr('number'),
    turnover: _emberData.default.attr('string'),
    turnoverCurrency: _emberData.default.belongsTo('currency', {
      async: true
    }),
    company: _emberData.default.belongsTo('company', {
      async: true,
      inverse: 'generalInfo'
    })
  });

  _exports.default = _default;
});