define("cb-ui/routes/app/agent-project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super(transition);

      this.get('sessionContext.currentUser').then(function (user) {
        if (!user.get('isStaff')) {
          _this.transitionTo('app.dashboard');
        }
      });
    },
    model: function model(obj) {
      var quotePromise = this.store.find('quote', obj.quote_id);
      var proposalsPromise = quotePromise.then(function (quote) {
        return quote.get('proposals');
      });
      return Ember.RSVP.hash({
        quote: quotePromise,
        proposals: proposalsPromise,
        quoteCompanyContacts: this.store.query('quote-company-contact', {
          quote: obj.quote_id
        }),
        companies: this.store.query('public-company', {
          company_type: 'consultant'
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var quote = model.quote;
      var localCompanies = new Ember.A();
      var proposalCompaniesIds = {};
      var proposalCompaniesPromises = [];
      controller.set('quote', quote);
      controller.set('proposals', model.proposals); // create the local companies list

      model.proposals.forEach(function (proposal) {
        proposalCompaniesPromises.push(new Ember.RSVP.Promise(function (resolve) {
          proposal.get('company').then(function (company) {
            proposalCompaniesIds[company.get('id')] = true;
            resolve();
          });
        }));
        var contacts = [];
        model.quoteCompanyContacts.forEach(function (qcc) {
          if (qcc.get('company.id') == proposal.get('company.id')) {
            contacts.push(qcc);
          }
        });
        proposal.set('contacts', contacts);
      });
      Ember.RSVP.all(proposalCompaniesPromises).then(function () {
        model.companies.forEach(function (company) {
          if (!(company.get('id') in proposalCompaniesIds)) {
            localCompanies.addObject(company);
          } else {
            company.reload();
          }
        });
        controller.set('companies', localCompanies);
      });
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});