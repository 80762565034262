define("cb-ui/models/document-section", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    uid: attr('string'),
    quote: belongsTo('quote', {
      inverse: 'sections',
      async: true
    }),
    parent: belongsTo('document-section', {
      inverse: 'children',
      async: true
    }),
    children: hasMany('document-section', {
      async: true,
      inverse: 'parent'
    }),
    order: attr('number'),
    title: attr('string'),
    content: attr('string'),
    // if the section will not be rendered into the final document
    disabled: attr('boolean', {
      defaultValue: false
    }),
    // if the element cannot be deleted
    blocked: attr('boolean', {
      defaultValue: false
    }),
    notDeletable: attr('boolean', {
      defaultValue: false
    }),
    bidirectional: attr('boolean', {
      defaultValue: false
    }),
    bidirectionalKey: attr('string'),
    number: null,
    //calculates the section number
    numberWatcher: Ember.observer('quote.rootSections.@each.number', 'parent.children.@each.number', 'parent.number', 'parent.disabled', 'disabled', 'order', 'isLoaded', function () {
      var _this = this;

      if (!this.get('isLoaded') || this.get('isDeleted')) {
        return;
      } //TODO: improve performance with run.once ?


      if (this.get('disabled')) {
        this.set('number', '');
      } else {
        var order = this.get('order');
        this.get('parent').then(function (parent) {
          new Ember.RSVP.Promise(function (resolve) {
            if (parent) {
              resolve(parent.get('children'));
            } else {
              resolve(_this.get('quote.rootSections'));
            }
          }).then(function (sections) {
            if (sections) {
              var sectionsBefore = sections.filter(function (section) {
                return section.get('disabled') === false && section.get('order') < order;
              });

              _this.set('number', (_this.get('parent.number') ? _this.get('parent.number') + "." : "") + (sectionsBefore.get('length') + 1));
            }
          });
        });
      }
    }),
    //disabled section if parent is disabled
    disabledParentWatcher: Ember.observer('parent.disabled', function () {
      var _this2 = this;

      if (this.get('isDeleted')) {
        return;
      }

      this.get('parent').then(function (parent) {
        if (parent) {
          _this2.set('disabled', parent.get('disabled'));
        }
      });
    })
  });

  _exports.default = _default;
});