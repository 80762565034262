define("cb-ui/controllers/app/project/proposal/summary", ["exports", "ember-intl"], function (_exports, _emberIntl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    quote: null,
    proposal: null,
    currentUser: null,
    technicalQuoteCriteriaGroups: new Ember.A(),
    pitchQuoteCriteriaGroups: new Ember.A(),
    notify: Ember.inject.service(),
    disabledButtonErrorMessage: (0, _emberIntl.translationMacro)('app.modals.cancelParticipation.disabledButtonErrorMessage'),
    isSendingRequest: false,
    userProposalRole: null,
    userIsProposalAdmin: Ember.computed.equal('userProposalRole.role', 'admin'),
    actions: {
      navbarTransitionToComponent: function navbarTransitionToComponent(componentId) {
        Ember.run.next(function () {
          Ember.$('html, body').animate({
            scrollTop: Ember.$('#' + componentId).offset().top - 200
          });
        });
      },
      turnOffCancelParticipationModal: function turnOffCancelParticipationModal() {
        this.set('cancelPartiticipationModal', false);
      },
      turnOnCancelParticipationModal: function turnOnCancelParticipationModal() {
        this.set('cancelPartiticipationModal', true);
      },
      sendCancelParticipationReason: function sendCancelParticipationReason() {
        var _this = this;

        if (this.get('isSendingRequest')) {
          return;
        }

        this.set('isSendingRequest', true);

        if (this.get('proposal').get('cancelReason') && !Ember.isEmpty(Ember.$.trim(this.get('proposal').get('cancelReason')))) {
          var proposal = this.get('proposal');

          if (proposal.get('hasDirtyAttributes') && proposal.changedAttributes()['cancelReason'] !== undefined) {
            proposal.set('status', 'declined');
            proposal.save().then(function () {
              _this.set('cancelPartiticipationModal', false);

              _this.transitionToRoute('app.project.proposal.result', proposal);
            }).catch(function () {
              _this.set('isSendingRequest', false);
            });
          } else {
            this.set('isSendingRequest', false);
          }
        } else {
          this.set('isSendingRequest', false);
          this.get('notify').error(this.get('disabledButtonErrorMessage'));
        }
      }
    }
  });

  _exports.default = _default;
});