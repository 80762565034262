define("cb-ui/components/project-request-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      projectLanguageChanged: function projectLanguageChanged(value) {
        this.set('changeset.projectLanguage', value);

        if (value === 'de') {
          var german = this.get('languages').filter(function (x) {
            return x.id === "2";
          })[0];
          this.set('changeset.proposalLanguage', german);
        }

        if (value === 'en') {
          var english = this.get('languages').filter(function (x) {
            return x.id === "1";
          })[0];
          this.set('changeset.proposalLanguage', english);
        }
      },
      engagementChanged: function engagementChanged(value) {
        this.set('changeset.remoteConsulting', value);
      }
    }
  });

  _exports.default = _default;
});