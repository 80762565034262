define("cb-ui/components/ember-table/fee-per-role-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    beforeGraphSetup: function beforeGraphSetup(svg, chart) {
      chart.color(['#0d3c5a']);
      chart.showValues(true);
      chart.showControls(false);
      chart.noData("There are no roles");
      chart.valueFormat(d3.format(",.0f"));
    },
    afterChartSetup: function afterChartSetup(svg, chart) {
      chart.dispatch.on('renderEnd', function () {
        d3.selectAll("g.nv-axisMaxMin.nv-axisMaxMin-x.nv-axisMax-x").each(function () {
          var d = d3.select(this);
          d.style('visibility', 'hidden');
        });
        d3.selectAll("g.nv-axisMaxMin.nv-axisMaxMin-x.nv-axisMin-x").each(function () {
          var d = d3.select(this);
          d.style('visibility', 'hidden');
        });
        d3.selectAll("g.tick").each(function () {
          var d = d3.select(this);
          d.style('visibility', 'hidden');
        });
        d3.selectAll("g.tick.zero").each(function () {
          var d = d3.select(this);
          d.style('visibility', 'visible');
        });
        d3.selectAll("g.tick.zero")[0].forEach(function (g) {
          g.childNodes.forEach(function (gcn) {
            gcn.childNodes.forEach(function (gcncn) {
              if (_typeof(gcncn) == "object") {
                if (gcncn.textContent.split("] - ").length == 2) {
                  g.style = "opacity: 1; visibility: hidden;";
                }
              }
            });
          });
        });
      });
    }
  });

  _exports.default = _default;
});