define("cb-ui/templates/components/proposal-evaluation-criteria-comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zFiVuQqF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"and\",[[20,[\"proposalEvaluation\"]],[20,[\"proposalEvaluation\",\"comment\"]]],null]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"text-left\"],[9,\"style\",\"word-break:break-all; white-space: pre-line;\"],[7],[0,\"\\n    \"],[6,\"i\"],[7],[1,[25,\"t\",[\"app.components.proposalEvaluationCriteriaComment.comment\"],null],false],[8],[6,\"br\"],[7],[8],[0,\" \"],[1,[20,[\"proposalEvaluation\",\"comment\"]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/proposal-evaluation-criteria-comment.hbs"
    }
  });

  _exports.default = _default;
});