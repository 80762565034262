define("cb-ui/components/basic-dropdown2", ["exports", "cb-ui/components/basic-dropdown"], function (_exports, _basicDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicDropdown.default.extend({
    openDropdown: false,
    // open dropdown options only if openDropdown is true
    open: function open(e) {
      if (this.get('openDropdown')) {
        var publicAPI = this.get('publicAPI');

        if (publicAPI.disabled || publicAPI.isOpen) {
          return;
        }

        var onOpen = this.get('onOpen');

        if (onOpen && onOpen(publicAPI, e) === false) {
          return;
        }

        this.updateState({
          isOpen: true
        });
      }
    }
  });

  _exports.default = _default;
});