define("cb-ui/components/agent-proposal", ["exports", "ember-changeset", "cb-ui/config/environment"], function (_exports, _emberChangeset, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    proposal: null,
    companyIndividualEmail: '',
    consultant: null,
    showCompanies: false,
    companies: new Ember.A(),
    selectedCompany: null,
    showSaveNewCompany: null,
    memberEmails: null,
    adminEmails: null,
    reassingInvitation: false,
    uniqueCompanyIndividuals: [],
    //adminUrl: config.apiURL + 'admin/app/individual/add/',
    adminUrl: Ember.computed('window.location.hostname', function () {
      return "".concat(_environment.default.CANONICAL_DOMAIN_SOURCE, "/apapanel/app/individual/add/");
    }),
    emailsObserver: Ember.computed('proposal.company.companyUsers.[]', function () {
      var _this = this;

      this.get('proposal.company.companyUsers').then(function (users) {
        var adminEmails = [];
        users.forEach(function (user) {
          if (user.get('isCompanyAdmin')) {
            adminEmails.push(user.get('email'));
          }
        });

        _this.set('adminEmails', adminEmails.join(', '));
      });
    }),
    consultantObserver: Ember.observer('proposal', 'proposal.consultantUser', function () {
      var _this2 = this;

      if (this.get('proposal')) {
        this.get('proposal.consultantUser').then(function (user) {
          _this2.set('consultant', user);
        });
      }
    }),
    uniqueIndividualsObserver: Ember.computed('proposal', 'proposal.company.individuals.[]', function () {
      var _this3 = this;

      var computedIndividuals = [];
      this.get('proposal.company.individuals').then(function (individuals) {
        individuals.forEach(function (individual) {
          if ((!computedIndividuals.map(function (x) {
            return x.get('email');
          }).includes(individual.get('email')) || individual.get('email') === '') && !_this3.get('proposal.contacts').map(function (x) {
            return x.get('email');
          }).includes(individual.get('email'))) {
            computedIndividuals.push(individual);
          }
        });
        computedIndividuals.sort(function (a, b) {
          return "".concat(a.get('firstName'), " ").concat(a.get('surmame'), " - ").concat(a.get('email')) < "".concat(b.get('firstName'), " ").concat(b.get('surmame'), " - ").concat(b.get('email')) ? -1 : 1;
        });

        _this3.set('uniqueCompanyIndividuals', computedIndividuals);
      });
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('proposalChangeset', new _emberChangeset.default(this.get('proposal')));
    },
    actions: {
      submitAction: function submitAction(changeset) {
        var _this4 = this;

        changeset.validate();

        if (changeset.get('isValid')) {
          changeset.save().then(function () {
            _this4.get('notify').success("The changes have been saved.");
          }).catch(function (err) {
            err.errors.forEach(function (error) {
              _this4.get('notify').error(error.detail);
            });
          });
        } else {
          this.get('notify').error("Form is not valid");
        }
      },
      sendInvitation: function sendInvitation() {
        var companyIndividual = this.get('companyIndividualEmail');

        if (companyIndividual) {
          this.sendAction('sendInvitation', this.get('proposal'), companyIndividual);
          this.set('reassingInvitation', false);
        } else {
          this.get('notify').error(this.get('intl').t('notifyMessages.selectValidIndividual'));
        }
      },
      removeConsultant: function removeConsultant() {
        this.sendAction('removeConsultant', this.get('proposal'));
      },
      showIndividualInfo: function showIndividualInfo(individual) {
        this.sendAction('showIndividualInfo', individual);
      },
      showCompanyList: function showCompanyList() {
        this.set('showCompanies', true);
      },
      selectedCompanyChanged: function selectedCompanyChanged(newCompany) {
        this.set('selectedCompany', newCompany);

        if (newCompany) {
          this.set('showSaveNewCompany', true);
        } else {
          this.set('showSaveNewCompany', true);
        }
      },
      saveCompanyProposal: function saveCompanyProposal() {
        var _this5 = this;

        var proposal = this.get('proposal');
        proposal.get('company').then(function (oldCompany) {
          _this5.sendAction('saveCompanyProposal', proposal, oldCompany, _this5.get('selectedCompany'));
        });
      }
    }
  });

  _exports.default = _default;
});