define("cb-ui/models/quote-project-goal", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    quote: belongsTo('quote', {
      async: true
    }),
    projectGoal: belongsTo('project-goal', {
      async: true
    }),
    description: attr('string'),
    name: attr('string'),
    saveItem: function saveItem() {
      this.save();
    },
    autoSave: Ember.observer('name', 'description', function () {
      Ember.run.debounce(this, this.saveItem, 1000);
    })
  });

  _exports.default = _default;
});