define("cb-ui/routes/app/templates/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var groups = this.store.peekAll('base-criteria-evaluation-group').filterBy('isNew', true);
      groups.forEach(function (group) {
        group.deleteRecord();
      });
      var criteriaEvaluations = this.store.peekAll('base-criteria-evaluation').filterBy('isNew', true);
      criteriaEvaluations.forEach(function (ce) {
        ce.deleteRecord();
      });
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        template: this.store.findRecord('template', params.template_id, {
          include: 'base_criteria_evaluation_groups'
        }),
        baseCriteriaEvaluationGroups: this.store.query('base-criteria-evaluation-group', {
          template_id: params.template_id,
          include: 'base_criteria_evaluations'
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var groups = new Ember.A();
      model.baseCriteriaEvaluationGroups.forEach(function (item) {
        groups.addObject(item);
      });
      controller.set('groups', groups.sortBy('order'));
      controller.set('template', model.template);
      controller.set('showAddGroup', false);
      controller.set('isLoading', false);
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.controller.set('isLoading', true);
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});