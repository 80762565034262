define("cb-ui/components/proposal-fixed-menu", ["exports", "cb-ui/utils/append-access-token", "cb-ui/utils/get-target-domain", "cb-ui/config/environment"], function (_exports, _appendAccessToken, _getTargetDomain, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    quote: null,
    proposal: null,
    notify: Ember.inject.service(),
    currentUser: null,
    isProposalOrCompanyAdmin: null,
    domain: Ember.computed('window.location.hostname', function () {
      return _environment.default.CANONICAL_DOMAIN_NEXT;
    }),
    base64QuoteId: Ember.computed('quote', function () {
      return window.btoa(unescape(encodeURIComponent("QuoteNode:".concat(this.get('quote.id')))));
    }),
    base64ProposalId: Ember.computed('proposal', function () {
      return window.btoa(unescape(encodeURIComponent("QuoteProposalNode:".concat(this.get('proposal.id')))));
    }),
    userManagementUrl: Ember.computed('session.data.authenticated.token', function () {
      return (0, _appendAccessToken.default)("".concat((0, _getTargetDomain.default)(), "/dashboard/rfp/").concat(this.get('base64QuoteId'), "/proposals/").concat(this.get('base64ProposalId'), "/setup/users"), this.get('session.data.authenticated.token'));
    }),
    announcementstUrl: Ember.computed('session.data.authenticated.token', function () {
      return (0, _appendAccessToken.default)("".concat((0, _getTargetDomain.default)(), "/dashboard/rfp/").concat(this.get('base64QuoteId'), "/proposals/").concat(this.get('base64ProposalId'), "/setup/announcements"), this.get('session.data.authenticated.token'));
    }),
    internalDocsUrl: Ember.computed('session.data.authenticated.token', function () {
      return (0, _appendAccessToken.default)("".concat((0, _getTargetDomain.default)(), "/dashboard/rfp/").concat(this.get('base64QuoteId'), "/proposals/").concat(this.get('base64ProposalId'), "/setup/documents"), this.get('session.data.authenticated.token'));
    }),
    actions: {
      confirmPariticipation: function confirmPariticipation() {
        var _this = this;

        var proposal = this.get('proposal');
        proposal.set('participationConfirmed', true);
        proposal.save().then(function () {
          _this.get('notify').success(_this.get('intl').t('notifyMessages.participationConfirmed').toString());
        }).catch(function () {
          _this.get('notify').error('error');
        });
      }
    }
  });

  _exports.default = _default;
});