define("cb-ui/routes/app/project/proposal/technical-proposal", ["exports", "cb-ui/mixins/scrollbar-reset"], function (_exports, _scrollbarReset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollbarReset.default, {
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var proposal = model;
      controller.set('proposal', proposal);
      controller.set('quote', this.modelFor('app.project'));
      controller.loadProposalData(proposal);
    },
    actions: {
      willTransition: function willTransition(transition) {
        var proposal = this.controller.get('proposal');

        if (proposal.get('isDirty') && !confirm(this.get('intl').t('confirmMessages.abandonProgress').toString())) {
          transition.abort();
          return false;
        } else {
          proposal.rollbackAttributes();
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});