define("cb-ui/templates/components/top-navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XiyRVXps",
    "block": "{\"symbols\":[],\"statements\":[[6,\"nav\"],[9,\"class\",\"navbar navbar-static-top\"],[9,\"role\",\"navigation\"],[9,\"style\",\"margin-bottom: 0\"],[7],[0,\"\\n    \"],[6,\"ul\"],[9,\"class\",\"nav navbar-top-links navbar-left\"],[7],[0,\"\\n        \"],[6,\"li\"],[7],[0,\"\\n            \"],[6,\"a\"],[9,\"href\",\"https://apadua.com\"],[9,\"class\",\"public-navbar\"],[7],[0,\"\\n                    \"],[6,\"div\"],[9,\"class\",\"navbar-header\"],[9,\"style\",\"margin-left: 52px !important\"],[7],[0,\"\\n            \"],[6,\"button\"],[9,\"aria-controls\",\"navbar\"],[9,\"aria-expanded\",\"false\"],[9,\"data-target\",\"#navbar\"],[9,\"data-toggle\",\"collapse\"],[9,\"class\",\"navbar-toggle collapsed\"],[9,\"type\",\"button\"],[7],[0,\"\\n                \"],[6,\"i\"],[9,\"class\",\"glyphicon glyphicon-menu-hamburger color-white\"],[7],[8],[0,\"\\n            \"],[8],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"nav-bar-logo \"],[7],[0,\"\\n                \"],[6,\"a\"],[9,\"href\",\"#\"],[7],[0,\"\\n                    \"],[1,[18,\"apadua-svg-logo\"],false],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"ul\"],[9,\"class\",\"nav navbar-top-links navbar-right\"],[7],[0,\"\\n        \"],[6,\"li\"],[9,\"class\",\"dropdown\"],[7],[0,\"\\n          \"],[12,\"local-storage-language\",[]],[0,\"\\n        \"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"          \"],[6,\"li\"],[7],[0,\"\\n              \"],[6,\"a\"],[3,\"action\",[[19,0,[]],\"logout\"]],[7],[0,\"\\n                  \"],[6,\"i\"],[9,\"class\",\"fa fa-sign-out\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"app.navigation.logOut\"],null],false],[0,\"\\n              \"],[8],[0,\"\\n          \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "cb-ui/templates/components/top-navbar.hbs"
    }
  });

  _exports.default = _default;
});