define("cb-ui/templates/components/proposal-status-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0CzQ01jw",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",[26,[\"label \",[25,\"if\",[[25,\"eq\",[[20,[\"status\"]],\"projectStatuses.accepted\"],null],\"label-info\",[25,\"if\",[[25,\"eq\",[[20,[\"status\"]],\"projectStatuses.draft\"],null],\"label-primary\",[25,\"if\",[[25,\"or\",[[25,\"eq\",[[20,[\"status\"]],\"projectStatuses.invited\"],null],[25,\"eq\",[[20,[\"status\"]],\"projectStatuses.inactive\"],null]],null],\"label-on-hold\",[25,\"if\",[[25,\"eq\",[[20,[\"status\"]],\"projectStatuses.declined\"],null],\"label-danger\",[25,\"if\",[[25,\"or\",[[25,\"eq\",[[20,[\"status\"]],\"projectStatuses.dismissed\"],null],[25,\"eq\",[[20,[\"status\"]],\"projectStatuses.lost\"],null]],null],\"label-dark-red\",[25,\"if\",[[25,\"eq\",[[20,[\"status\"]],\"projectStatuses.submitted\"],null],\"label-success\",\"label-dark-green\"],null]],null]],null]],null]],null]],null]]]],[7],[0,\"\\n\"],[4,\"if\",[[25,\"eq\",[[20,[\"status\"]],\"projectStatuses.accepted\"],null]],null,{\"statements\":[[4,\"if\",[[20,[\"participationConfirmed\"]]],null,{\"statements\":[[0,\"            \"],[1,[25,\"t\",[[20,[\"status\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[25,\"t\",[\"projectStatuses.opened\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[25,\"t\",[[20,[\"status\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/proposal-status-label.hbs"
    }
  });

  _exports.default = _default;
});