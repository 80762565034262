define("cb-ui/routes/app/project/client/beauty-contest", ["exports", "cb-ui/mixins/scrollbar-reset"], function (_exports, _scrollbarReset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollbarReset.default, {
    model: function model() {
      var quote = this.modelFor('app.project');
      return Ember.RSVP.hash({
        quote: quote,
        proposals: this.store.query('proposal', {
          quote_id: quote.get('id')
        }),
        company: quote.get('owner.company')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('quote', model.quote);
      controller.set('proposals', model.proposals);
      controller.set('quoteCompany', model.company);
    }
  });

  _exports.default = _default;
});