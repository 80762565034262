define("cb-ui/templates/ie-detected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6SkHXq+o",
    "block": "{\"symbols\":[],\"statements\":[[1,[18,\"outlet\"],false],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"flex flex-col\"],[9,\"style\",\"background-color: #2f4050; color: white; width: 100%; height:100%; position: absolute; top: 0; left: 0;\"],[7],[0,\"\\n    \"],[6,\"a\"],[9,\"href\",\"https://www.apadua.com\"],[9,\"class\",\"flex flex-row flex-horizontal-center m-t-xl m-b\"],[7],[0,\"\\n        \"],[6,\"img\"],[9,\"src\",\"images/apadua-logo.png\"],[9,\"alt\",\"Apadua\"],[9,\"height\",\"40px\"],[9,\"class\",\"logo\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"flex flex-row flex-horizontal-center\"],[9,\"style\",\"width: 100%;\"],[7],[0,\"\\n        \"],[6,\"strong\"],[9,\"class\",\"flex flex-row flex-horizontal-center\"],[9,\"style\",\"width: 50%;\"],[7],[0,\"\\n            \"],[6,\"h1\"],[7],[1,[25,\"t\",[\"notifyMessages.usesIE\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/ie-detected.hbs"
    }
  });

  _exports.default = _default;
});