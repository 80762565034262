define("cb-ui/models/industry-standard", ["exports", "ember-data", "cb-ui/utils/translate-property"], function (_exports, _emberData, _translateProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    industryEn: _emberData.default.attr('string'),
    industryDe: _emberData.default.attr('string'),
    industry: (0, _translateProperty.default)('industryEn', 'industryDe'),
    parent: _emberData.default.belongsTo('industry-standard', {
      async: true,
      inverse: 'children'
    }),
    children: _emberData.default.hasMany('industry-standard', {
      async: true,
      inverse: 'parent'
    }),
    mpttOrder: _emberData.default.attr('string')
  });

  _exports.default = _default;
});