define("cb-ui/components/rfp-summary", ["exports", "cb-ui/config/environment", "cb-ui/utils/download-file", "ember-local-storage"], function (_exports, _environment, _downloadFile, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend((_EmberComponent$exte = {
    quote: null,
    requestedTimelineEntries: new Ember.A(),
    hideEvaluationCriterias: false,
    otherDocumentType: 'Other document type',
    quoteConsultants: new Ember.A(),
    localProjectGoals: new Ember.A(),
    extraConsultants: new Ember.A(),
    showTitle: true,
    showSections: true,
    isPreview: false,
    isQuoteOwner: false,
    isContactInfoVisible: false,
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    appLanguage: Ember.computed.alias('localStorage.language'),
    beautyContestCriteriaEvaluations: new Ember.A(),
    pitchQuoteCriteriaGroups: new Ember.A(),
    technicalQuoteCriteriaGroups: new Ember.A(),
    isEditAllowed: Ember.computed('quote.status', 'isQuoteOwner', function () {
      return this.get('isQuoteOwner') && this.get('quote.status') === 'draft';
    }),
    monetaryProjectGoals: Ember.computed.filterBy('localProjectGoals', 'isMonetary', true),
    nonMonetaryProjectGoals: Ember.computed.filterBy('localProjectGoals', 'isMonetary', false),
    specialistConsultants: Ember.computed.filterBy('quoteConsultants', 'consultantType.consultantTypeEn', 'Specialist'),
    diversifiedConsultants: Ember.computed.filterBy('quoteConsultants', 'consultantType.consultantTypeEn', 'Diversified Consultant'),
    generalistConsultants: Ember.computed.filterBy('quoteConsultants', 'consultantType.consultantTypeEn', 'Generalist'),
    typeNotSpecifiedConsultants: Ember.computed.filterBy('quoteConsultants', 'consultantType.consultantTypeEn', 'Not Specified')
  }, _defineProperty(_EmberComponent$exte, "requestedTimelineEntries", Ember.computed('quote.allTimelineEntries.@each.name', function () {
    if (this.get('quote.status') === 'requested') {
      var entries = [];
      this.get('quote.allTimelineEntries').forEach(function (te) {
        if (te.get('date')) {
          entries.push(te);
        }
      });
      return entries;
    }
  })), _defineProperty(_EmberComponent$exte, "didInsertElement", function didInsertElement() {
    this.$().find('.summary-section-title').on('click', function () {
      Ember.$(this).find('span:first').toggleClass('glyphicon-triangle-bottom glyphicon-triangle-right');
      Ember.$(this).next('div').slideToggle();
    });
  }), _defineProperty(_EmberComponent$exte, "actions", {
    editCoverLetter: function editCoverLetter() {
      this.sendAction('editCoverLetter');
    },
    createNewCoverLetter: function createNewCoverLetter() {
      this.sendAction('createNewCoverLetter');
    },
    showQuoteCompanyContacts: function showQuoteCompanyContacts() {
      this.sendAction('showQuoteCompanyContacts');
    },
    downloadRfpPDF: function downloadRfpPDF(url) {
      (0, _downloadFile.default)(url, 'Cover letter.pdf');
    }
  }), _EmberComponent$exte));

  _exports.default = _default;
});