define("cb-ui/templates/components/ember-table/proposal-role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SfgQ3YKU",
    "block": "{\"symbols\":[],\"statements\":[[6,\"strong\"],[7],[1,[20,[\"cellValue\",\"title\"]],false],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/ember-table/proposal-role.hbs"
    }
  });

  _exports.default = _default;
});