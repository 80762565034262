define("cb-ui/helpers/translate-model-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    var instance = params[0];
    var field = params[1];
    var language = params[2];

    if (instance && field) {
      if (language === 'de') {
        var deField = field + 'De';

        if (instance.get(deField)) {
          return instance.get(deField);
        }
      }

      return instance.get(field + 'En');
    }

    return null;
  });

  _exports.default = _default;
});