define("cb-ui/templates/app/project/client/question-header-one", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FXrsfF3Y",
    "block": "{\"symbols\":[],\"statements\":[[6,\"h3\"],[9,\"class\",\"m-t-md scrollspy\"],[9,\"id\",\"round1\"],[7],[0,\"1\"],[6,\"sup\"],[7],[0,\"st\"],[8],[0,\" round of questions\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"m-b-lg\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"quote\",\"rfpReleaseDate\"]]],null,{\"statements\":[[0,\"        Open from: \"],[6,\"strong\"],[7],[1,[25,\"moment-format\",[[20,[\"quote\",\"rfpReleaseDate\"]],\"DD-MM-YYYY\"],null],false],[8],[0,\"\\n        \"],[6,\"span\"],[9,\"class\",\"m-l-md\"],[7],[4,\"if\",[[20,[\"quote\",\"firstQuestionsDueDate\"]]],null,{\"statements\":[[0,\"\\n            until:\\n            \"],[6,\"strong\"],[7],[1,[25,\"moment-format\",[[20,[\"quote\",\"firstQuestionsDueDate\"]],\"DD-MM-YYYY\"],null],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[6,\"span\"],[9,\"class\",\"m-l-md\"],[7],[0,\"\\n    Current status:\\n\"],[4,\"if\",[[20,[\"quote\",\"questionsRound1IsNotOpen\"]]],null,{\"statements\":[[0,\"            \"],[6,\"span\"],[9,\"class\",\"label label-info\"],[7],[0,\"Not Open\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[20,[\"quote\",\"questionsRound1IsOpen\"]]],null,{\"statements\":[[0,\"            \"],[6,\"span\"],[9,\"class\",\"label label-success\"],[7],[0,\"Open\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[20,[\"quote\",\"questionsRound1IsClosed\"]]],null,{\"statements\":[[0,\"            \"],[6,\"span\"],[9,\"class\",\"label label-danger\"],[7],[0,\"Closed\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/app/project/client/question-header-one.hbs"
    }
  });

  _exports.default = _default;
});