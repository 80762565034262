define("cb-ui/templates/components/models-table/worker-roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NdchGh3C",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[6,\"button\"],[9,\"class\",\"btn btn-primary btn-mini btn-table btn-block\"],[3,\"action\",[[19,0,[]],\"send\",\"selectProject\",[20,[\"record\"]]]],[7],[0,\"\\n  \"],[6,\"i\"],[9,\"class\",\"glyphicon glyphicon-edit\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"app.orgSettings.workerRoles.index.editWorkerRole\"],null],false],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[6,\"button\"],[9,\"class\",\"btn btn-danger btn-mini btn-table btn-block\"],[3,\"action\",[[19,0,[]],\"send\",\"showPreviewProject\",[20,[\"record\"]]]],[7],[0,\"\\n  \"],[6,\"i\"],[9,\"class\",\"glyphicon glyphicon-remove\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"app.orgSettings.workerRoles.index.deleteWorkerRole\"],null],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/models-table/worker-roles.hbs"
    }
  });

  _exports.default = _default;
});