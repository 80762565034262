define("cb-ui/templates/app/org-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2fCboD5w",
    "block": "{\"symbols\":[\"navbar\",\"nav\"],\"statements\":[[1,[25,\"app-top-navbar\",null,[[\"logout\",\"changeUserLanguage\",\"contactTCB\"],[\"invalidateSession\",\"changeUserLanguage\",\"contactTCBAgent\"]]],false],[0,\"\\n\"],[4,\"bs-navbar\",null,[[\"collapsed\",\"onCollapse\",\"onExpand\",\"type\",\"class\"],[[20,[\"collapsed\"]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"collapsed\"]]],null],true],null],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"collapsed\"]]],null],false],null],\"default\",\" \"]],{\"statements\":[[0,\"  \"],[1,[19,1,[\"toggle\"]],false],[0,\"\\n\"],[4,\"component\",[[19,1,[\"content\"]]],null,{\"statements\":[[4,\"component\",[[19,1,[\"nav\"]]],null,{\"statements\":[[4,\"component\",[[19,2,[\"item\"]]],null,{\"statements\":[[4,\"component\",[[19,2,[\"link-to\"]],\"app.org-settings.worker-roles.index\"],null,{\"statements\":[[0,\"          \"],[1,[25,\"t\",[\"app.orgSettings.index.workerRoles\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"component\",[[19,2,[\"item\"]]],null,{\"statements\":[[4,\"component\",[[19,2,[\"link-to\"]],\"app.org-settings.cover-letter-settings.index\"],null,{\"statements\":[[0,\"          \"],[1,[25,\"t\",[\"app.orgSettings.index.coverLetterSettings\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null],[1,[18,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/app/org-settings.hbs"
    }
  });

  _exports.default = _default;
});