define("cb-ui/components/menu-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['col-md-2', 'col-md-2-sidebar', 'col-md-pull-10'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.schedule('afterRender', function () {
        _this.calculateHeight();
      });
      this.get('resizeService').on('debouncedDidResize', function () {
        _this.calculateHeight();
      });
    },
    calculateHeight: function calculateHeight() {
      if (this.$()) {
        var position = this.$().position();

        if (position.top) {
          //45 - padding bottom + margin bottom for the content container
          var maxHeight = Ember.$(window).height() - position.top - 45;
          this.$().height(maxHeight);
        }
      }
    }
  });

  _exports.default = _default;
});