define("cb-ui/models/individual", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    company: _emberData.default.hasMany('company', {
      async: true
    }),
    industries: _emberData.default.hasMany('industry-standard', {
      async: true
    }),
    services: _emberData.default.hasMany('service-standard', {
      async: true
    }),
    language: _emberData.default.belongsTo('language', {
      async: true
    }),
    firstName: _emberData.default.attr('string'),
    surname: _emberData.default.attr('string'),
    jobTitleEn: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    userSpecific: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    generalManagement: _emberData.default.attr('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});