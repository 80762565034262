define("cb-ui/components/upload-file", ["exports", "ember-uploader", "cb-ui/config/environment"], function (_exports, _emberUploader, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Override EmberUploader.Uploader to allow custom headers like Authorization
  var CustomUploader = _emberUploader.default.Uploader.extend({
    dom: null,
    headers: {},
    // Override
    _ajax: function _ajax(settings) {
      settings = Ember.merge(settings, this.getProperties('headers'));
      return this._super(settings);
    },
    isUploadingObserver: Ember.observer('isUploading', function () {
      if (this.get('isUploading')) {
        this.get('dom').css('visibility', 'hidden');
        this.get('dom').after('<img src="images/loading.gif">');
      } else {
        this.get('dom').next().remove();
        this.get('dom').css('visibility', 'visible');
      }
    })
  });

  var _default = _emberUploader.default.FileField.extend({
    notify: Ember.inject.service('notify'),
    name: null,
    quoteId: null,
    isProposal: false,
    fileType: null,
    extraFileType: null,
    multiple: true,
    host: _environment.default.backendAPI,
    url: '',
    accept: '',
    filesDidChange: function filesDidChange(files) {
      var _this = this;

      var uploadUrl = this.get('host') + this.get('url');
      var session = this.get('session');
      var uploader = CustomUploader.create({
        url: uploadUrl,
        headers: {
          'Authorization': 'Bearer ' + session.get('data.authenticated.token')
        },
        dom: this.$()
      });

      if (!Ember.isEmpty(files)) {
        uploader.upload(files[0], {
          file_type: this.get('fileType.id'),
          extra_file_type: this.get('extraFileType')
        }).then(function (response) {
          _this.sendAction('addDocument', response.data);
        }).catch(function (response) {
          if (response && response.responseJSON && response.responseJSON.errors) {
            if (response.responseJSON.errors.filterBy('detail', 'Invalid file type')) {
              _this.get('notify').error(_this.get('intl').t('notifyMessages.invalidCoverLetterFileFormat').toString());
            } else {
              _this.get('notify').error(_this.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
            }
          } else {
            _this.get('notify').error(_this.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
          }
        });
      }
    }
  });

  _exports.default = _default;
});