define("cb-ui/controllers/app/rfp/index", ["exports", "cb-ui/mixins/wizard-controller", "ember-intl", "cb-ui/config/environment"], function (_exports, _wizardController, _emberIntl, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var radioOptions = {
    managementConsulting: 'managementConsulting',
    itConsulting: 'itConsulting',
    interimManagement: 'interimManagement',
    recruiting: 'recruiting'
  };

  var _default = Ember.Controller.extend(_wizardController.default, {
    notify: Ember.inject.service(),
    store: Ember.inject.service('store'),
    componentHTML: '',
    // info for form fields
    countryHTML: (0, _emberIntl.translationMacro)('app.rfp.projectInformation.info.country'),
    projectNameHTML: (0, _emberIntl.translationMacro)('app.rfp.projectInformation.info.projectName'),
    industryHTML: (0, _emberIntl.translationMacro)('app.rfp.projectInformation.info.industryStandard'),
    companySizeHTML: (0, _emberIntl.translationMacro)('app.rfp.projectInformation.info.companySizeTurnover'),
    projectScopeHTML: (0, _emberIntl.translationMacro)('app.rfp.projectInformation.info.serviceStandard'),
    locationHTML: (0, _emberIntl.translationMacro)('app.rfp.projectInformation.info.mainLocation'),
    projectGoalsHTML: (0, _emberIntl.translationMacro)('app.rfp.projectInformation.info.projectGoals'),
    projectPhasesHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.projectPhases'),
    timelineHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.timeline'),
    organizationProjectReferenceHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.organizationProjectReference'),
    projectGoalAddedNotification: (0, _emberIntl.translationMacro)('app.rfp.projectInformation.projectGoalAddedNotification'),
    radioButtonOptions: radioOptions,
    serviceType: 'managementConsulting',
    modeOfEngagement: Ember.computed('quote.remoteConsulting', function () {
      return this.get('quote.remoteConsulting');
    }),
    isDirty: false,
    projectNameObserver: Ember.observer('quote.projectName', function () {
      if (Object.keys(this.get('quote').changedAttributes()).includes('projectName')) {
        this.set('isDirty', true);
      }
    }),
    currentUser: null,
    showWarningModal: null,
    showAgentQuestionModal: null,
    commentModalText: '',
    propertyNameService: null,
    propertyNameIndustry: null,
    projectServices: [],
    projectService: null,
    projectIndustries: [],
    projectIndustry: null,
    localSecondaryLocations: new Ember.A(),
    serviceTypes: new Ember.A(),
    blockPrevious: false,
    showUnsavedChangesModal: false,
    targetRoute: 'app.rfp.consultants',
    // show lists for project goals
    showProjectGoals: false,
    projectGoals: Ember.computed.alias('model.projectGoals'),
    monetaryProjectGoals: Ember.computed.filterBy('model.projectGoals', 'isMonetary', true),
    nonMonetaryProjectGoals: Ember.computed.filterBy('model.projectGoals', 'isMonetary', false),
    localProjectGoals: new Ember.A(),
    smallResolution: Ember.computed('media.isMobile', 'media.isTablet', function () {
      return this.get('media.isMobile') || this.get('media.isTablet');
    }),
    localProjectGoalsObserver: Ember.observer('localProjectGoals.@each', function () {
      var projectGoalsIds = this.get('localProjectGoals').map(function (item) {
        return parseInt(item.get('id'));
      });
      this.set('quote.projectGoalsOrder', projectGoalsIds);
    }),
    localQuoteProjectGoalsComputed: Ember.computed('localQuoteProjectGoals.@each.name', function () {
      var hasError = false;
      var localQuoteProjectGoals = this.get('localQuoteProjectGoals');

      if (localQuoteProjectGoals) {
        localQuoteProjectGoals.forEach(function (quoteProjectGoal) {
          if (quoteProjectGoal.get('name') === '') {
            hasError = true;
          }
        });
        return hasError;
      } else {
        return true;
      }
    }),
    actions: {
      openUnsavedChangesModal: function openUnsavedChangesModal() {
        this.set('showUnsavedChangesModal', true);
      },
      closeUnsavedChangesModal: function closeUnsavedChangesModal() {
        this.set('showUnsavedChangesModal', false);
      },
      changeInstructionsText: function changeInstructionsText(componentType) {
        if (componentType === 'projectGoals') {
          this.set('showProjectGoals', true);
        } else {
          this.set('showProjectGoals', false);
        }

        this.set('componentHTML', this.get(componentType + 'HTML'));

        if (this.get('media.isMobile') || this.get('media.isTablet')) {
          this.set('commentModalText', this.get('componentHTML'));
          this.set('showCommentModal', true);
        }
      },
      chooseCountry: function chooseCountry(country) {
        this.set('quote.country', country);
        this.set('isDirty', true);
      },
      chooseIndustry: function chooseIndustry(industry) {
        this.set('projectIndustry', industry); //const projectIndustry = this.get('leafProjectIndustries').findBy(this.get('propertyNameIndustry'), industry);
        //this.set('quote.industryStandard', projectIndustry);

        this.set('quote.industryStandard', industry);
        this.set('isDirty', true);
      },
      chooseCompanySizeTurnover: function chooseCompanySizeTurnover(companySizeTurnover) {
        this.set('quote.companySizeTurnover', companySizeTurnover);
        this.set('isDirty', true);
      },
      chooseCompanySizeEmployees: function chooseCompanySizeEmployees(companySizeEmployees) {
        this.set('quote.companySizeEmployees', companySizeEmployees);
        this.set('isDirty', true);
      },
      chooseProjectGoal: function chooseProjectGoal(projectGoals) {
        this.set('localProjectGoals', projectGoals);
        this.set('isDirty', true);
      },
      chooseProjectScope: function chooseProjectScope(projectScope) {
        this.set('projectService', projectScope);
        this.set('quote.serviceStandard', projectScope);
        this.set('isDirty', true);
      },
      choosePrimaryCountry: function choosePrimaryCountry(country) {
        this.set('quote.mainLocation', country);
        this.set('isDirty', true);
      },
      chooseSecondaryCountries: function chooseSecondaryCountries(countries) {
        this.set('localSecondaryLocations', countries);
        this.set('isDirty', true);
      },
      addQuoteProjectGoal: function addQuoteProjectGoal(goal) {
        var quote = this.get('quote');
        quote.get('errors').remove('quoteProjectGoals');

        if (goal) {
          var language = quote.get('projectLanguage');
          var notifyText = '';
          if (language === 'de') notifyText = notifyText + goal.get('projectGoalDe');else notifyText = notifyText + goal.get('projectGoalEn');
          this.get('notify').success(notifyText + ' ' + this.get('projectGoalAddedNotification'));
        }

        var name = goal ? goal.get('projectGoalEn') : '';
        var quoteProjectGoal = this.get('store').createRecord('quote-project-goal', {
          quote: null,
          projectGoal: goal,
          description: '',
          name: name
        });
        var localQuoteProjectGoals = this.get('localQuoteProjectGoals');
        if (!localQuoteProjectGoals.includes(quoteProjectGoal)) localQuoteProjectGoals.addObject(quoteProjectGoal);
        this.set('isDirty', true);
      },
      deleteQuoteProjectGoal: function deleteQuoteProjectGoal(quoteProjectGoal) {
        var localQuoteProjectGoals = this.get('localQuoteProjectGoals');

        if (localQuoteProjectGoals.includes(quoteProjectGoal)) {
          localQuoteProjectGoals.removeObject(quoteProjectGoal);
        }

        this.set('isDirty', true);
      },
      addMonetaryGoal: function addMonetaryGoal(goal) {
        var quote = this.get('quote');
        var selectedGoals = this.get('localProjectGoals');
        quote.get('errors').remove('projectGoals');

        if (!selectedGoals.includes(goal)) {
          if (selectedGoals.get('length') < 5) {
            selectedGoals.addObject(goal);
          }
        }

        this.set('isDirty', true);
      },
      removeMonetaryGoal: function removeMonetaryGoal(goal) {
        var selectedGoals = this.get('localProjectGoals');

        if (selectedGoals.includes(goal)) {
          selectedGoals.removeObject(goal);
        }

        this.set('isDirty', true);
      },
      serviceTypeChanged: function serviceTypeChanged(value) {
        this.set('serviceType', value);
        var serviceType = this.get('serviceTypes').findBy('serviceTypeEn', this.get('serviceType'));
        this.set('quote.serviceType', serviceType);
        this.set('isDirty', true);
      },
      engagementChanged: function engagementChanged(value) {
        this.set('quote.remoteConsulting', value);
        this.set('isDirty', true);
      },
      wrapperFocusTriggered: function wrapperFocusTriggered(element, componentType) {
        if (componentType === 'projectGoals') {
          this.set('showProjectGoals', true);
          this.set('componentHTML', this.get('basicHTML'));
        } else {
          this.set('showProjectGoals', false);
          this.set('componentHTML', this.get(componentType + 'HTML'));
        }

        this.set('isDirty', true);
      },
      saveAsDraft: function saveAsDraft(callback, route) {
        var _this = this;

        var quote = this.get('quote');
        var quoteProjectGoals = this.get('localQuoteProjectGoals');
        this.set('blockPrevious', true);
        quote.set('saveAsDraft', true);
        quote.set('secondaryLocations', this.get('localSecondaryLocations'));
        quote.set('quoteProjectGoals', quoteProjectGoals);
        var quoteRelatedPromises = [];
        quoteProjectGoals.forEach(function (item) {
          quoteRelatedPromises.push(item.save({
            adapterOptions: {
              validate: true
            }
          }));
        });
        var globalPromise = Ember.RSVP.all(quoteRelatedPromises).then(function () {
          var promise = quote.save();
          return promise.then(function () {
            _this.set('blockPrevious', false);

            _this.set('isDirty', false);

            _this.set('showUnsavedChangesModal', false);

            if (_this.get('currentUser.tcbValidated')) {
              _this.get('notify').success('Successfully saved draft');

              if (route && route.includes(_environment.default.CANONICAL_DOMAIN_NEXT)) {
                window.location = route;
                return;
              }

              _this.transitionToRoute(route);

              quote.set('saveAsDraft', false);
            } else {
              _this.set('showWarningModal', true);

              quote.set('saveAsDraft', false);
            }
          });
        }).catch(function (err) {
          err.errors.forEach(function (error) {
            _this.get('notify').error(error.detail);
          });
          quote.set('saveAsDraft', false);

          _this.set('blockPrevious', false);

          throw err;
        });

        if (callback) {
          callback(globalPromise);
        }
      },
      proceed: function proceed(route) {
        this.set('isDirty', false);
        this.set('showUnsavedChangesModal', false);
        var quote = this.get('quote');
        quote.rollbackAttributes();
        quote.reload();

        if (route && route.includes(_environment.default.CANONICAL_DOMAIN_NEXT)) {
          window.location = route;
          return;
        }

        this.transitionToRoute(route);
      },
      saveAndProceed: function saveAndProceed(route) {
        this.send('saveAsDraft', null, route);
      },
      next: function next() {
        if (this.get('isDirty')) {
          this.send('openUnsavedChangesModal');
        } else {
          this.transitionToRoute(this.get('targetRoute'));
        }
      },
      cancel: function cancel() {
        if (!this.get('blockPrevious')) {
          var quote = this.get('quote');
          quote.rollbackAttributes();
          this.transitionToRoute('app.dashboard');
        }
      },
      openSupportModal: function openSupportModal() {
        this.set('showAgentQuestionModal', true);
      },
      closeCommentModal: function closeCommentModal() {
        this.set('showCommentModal', false);
      },
      closeWarningModal: function closeWarningModal() {
        this.set('showWarningModal', false);
      }
    }
  });

  _exports.default = _default;
});