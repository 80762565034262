define("cb-ui/templates/components/client-consultant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VOkLBfEY",
    "block": "{\"symbols\":[\"proposal\"],\"statements\":[[6,\"h3\"],[7],[1,[25,\"t\",[\"app.projectClient.proposals.overview\"],null],false],[8],[0,\"\\n\\n\"],[4,\"each\",[[20,[\"proposals\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"class\",[26,[\"company-profile \",[25,\"if\",[[25,\"eq\",[[19,1,[]],[20,[\"selectedProposal\"]]],null],\"company-profile--selected\"],null]]]],[3,\"action\",[[19,0,[]],\"changeSelectedProposal\",[19,1,[]]]],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"company-profile-content\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"company-profile-name\"],[7],[0,\"\\n\"],[4,\"if\",[[19,1,[\"isActive\"]]],null,{\"statements\":[[0,\"                    \"],[1,[25,\"t\",[\"app.projectClient.proposals.activeProposal\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                    \"],[1,[25,\"t\",[\"app.projectClient.proposals.inactiveProposal\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[1,[25,\"client-proposal\",null,[[\"proposal\",\"appLanguage\"],[[20,[\"selectedProposal\"]],[20,[\"appLanguage\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/client-consultant.hbs"
    }
  });

  _exports.default = _default;
});