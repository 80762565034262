define("cb-ui/mixins/evaluation", ["exports", "cb-ui/helpers/user-proposal-evaluation-status-info"], function (_exports, _userProposalEvaluationStatusInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    proposals: new Ember.A(),
    showUserEvaluationInvitationModal: null,
    showSpinner: null,
    //technical or pitch
    invitationType: null,
    currentUser: null,
    quote: null,
    invitedUser: null,
    // invited users to evaluate proposals
    evaluators: new Ember.A(),
    userProposalEvaluationStatuses: new Ember.A(),
    evaluationIsOpen: null,
    propertyNameEvaluationIsActive: '',
    // see results for the evaluator
    evaluator: null,
    showProjectEvaluation: null,
    showExternalProjectEvaluation: null,
    showProjectResults: null,
    // technical or pitch criteria groups
    criteriaGroups: new Ember.A(),
    proposalsEvaluations: new Ember.A(),
    userQuoteRole: null,
    userIsQuoteAdmin: Ember.computed.equal('userQuoteRole.role', 'admin'),
    hasSubmittedProposals: Ember.computed('proposals.[]', function () {
      var submittedProposals = false;
      this.get('proposals').forEach(function (proposal) {
        if (proposal.get('status') === 'submitted') {
          submittedProposals = true;
        }
      });
      return submittedProposals;
    }),
    //TODO: rename to a generic name
    technicalTableColumns: Ember.computed('intl.locale.[]', 'proposals.[]', function () {
      var columns = [{
        name: this.get('intl').t('app.partials.evaluationData.evaluatorManagement'),
        isFixed: 'left',
        valuePath: 'title',
        width: 200
      }, {
        name: 'Actions',
        isFixed: 'left',
        width: 150,
        valuePath: 'action',
        cellComponent: 'ember-table/technical-evaluation-table-action',
        viewAction: 'viewEvaluationDetails',
        deleteAction: 'deleteEvaluationInvitation',
        resendAction: 'resendEvaluationInvitation',
        disableEvaluatorAction: 'disableEvaluator',
        enableEvaluatorAction: 'enableEvaluator'
      }];
      this.get('proposals').forEach(function (proposal) {
        columns.push({
          name: proposal.get('company.companyNameAlias'),
          valuePath: proposal.get('id'),
          width: 150,
          proposal: proposal,
          heading: '',
          headerComponent: 'ember-table/company-info-technical' //TODO:  cellComponent: 'ember-table/get-user-proposal-evaluation-status-info'

        });
      });
      return columns;
    }),
    technicalTableRows: Ember.computed('intl.locale.[]', 'proposals.length', 'evaluators.length', function () {
      var _this = this;

      var proposals = this.get('proposals');
      var currentUser = this.get('currentUser');
      var intl = this.get('intl');
      var rows = [];
      var currentUserData = {
        title: currentUser.get('fullName'),
        action: '',
        isCurrentUser: true,
        evaluator: currentUser,
        showAllActions: this.get('userIsQuoteAdmin')
      };
      proposals.forEach(function (proposal) {
        currentUserData[proposal.get('id')] = (0, _userProposalEvaluationStatusInfo.getUserProposalEvaluationStatusInfo)(null, {
          proposal: proposal,
          user: currentUser,
          userProposalEvaluationStatuses: _this.get('userProposalEvaluationStatuses'),
          intl: intl
        });
      });
      rows.push(currentUserData);
      this.get('evaluators').forEach(function (evaluator) {
        var data = {
          title: evaluator.get('fullName') + '- ' + evaluator.get('email'),
          action: '',
          isCurrentUser: false,
          evaluator: evaluator,
          technicalEvaluationIsActive: _this.get('quote.technicalEvaluationIsActive'),
          showAllActions: _this.get('userIsQuoteAdmin')
        };

        if (evaluator.isClient) {
          _this.get('allUserQuoteRoles').forEach(function (role) {
            if (role.get('user.id') == evaluator.get('id')) {
              data['canEvaluate'] = role.get('canEvaluate');
            }
          });
        }

        proposals.forEach(function (proposal) {
          data[proposal.get('id')] = (0, _userProposalEvaluationStatusInfo.getUserProposalEvaluationStatusInfo)(null, {
            proposal: proposal,
            user: evaluator,
            userProposalEvaluationStatuses: _this.get('userProposalEvaluationStatuses'),
            intl: intl
          });
        });
        rows.push(data);
      });
      return rows;
    }),
    pitchTableColumns: Ember.computed('intl.locale.[]', 'proposals.[]', function () {
      var columns = [{
        name: this.get('intl').t('app.partials.evaluationData.evaluatorManagement'),
        isFixed: 'left',
        valuePath: 'title',
        width: 200
      }, {
        name: 'Actions',
        isFixed: 'left',
        width: 150,
        valuePath: 'action',
        cellComponent: 'ember-table/pitch-evaluation-table-action',
        viewAction: 'viewEvaluationDetails',
        deleteAction: 'deleteEvaluationInvitation',
        resendAction: 'resendEvaluationInvitation',
        disableEvaluatorAction: 'disableEvaluator',
        enableEvaluatorAction: 'enableEvaluator'
      }];
      this.get('proposals').forEach(function (proposal) {
        columns.push({
          name: proposal.get('company.companyNameAlias'),
          valuePath: proposal.get('id'),
          width: 150,
          proposal: proposal,
          heading: '',
          headerComponent: 'ember-table/company-info-pitch' //TODO:  cellComponent: 'ember-table/get-user-proposal-evaluation-status-info'

        });
      });
      return columns;
    }),
    pitchTableRows: Ember.computed('intl.locale.[]', 'proposals.length', 'evaluators.length', function () {
      var _this2 = this;

      var proposals = this.get('proposals');
      var currentUser = this.get('currentUser');
      var intl = this.get('intl');
      var rows = [];
      var currentUserData = {
        title: currentUser.get('fullName'),
        action: '',
        isCurrentUser: true,
        evaluator: currentUser,
        showAllActions: this.get('userIsQuoteAdmin')
      };
      proposals.forEach(function (proposal) {
        currentUserData[proposal.get('id')] = (0, _userProposalEvaluationStatusInfo.getUserProposalEvaluationStatusInfo)(null, {
          proposal: proposal,
          user: currentUser,
          userProposalEvaluationStatuses: _this2.get('userProposalEvaluationStatuses'),
          intl: intl
        });
      });
      rows.push(currentUserData);
      this.get('evaluators').forEach(function (evaluator) {
        var data = {
          title: evaluator.get('fullName') + '- ' + evaluator.get('email'),
          action: '',
          isCurrentUser: false,
          evaluator: evaluator,
          pitchEvaluationIsActive: _this2.get('quote.pitchEvaluationIsActive'),
          showAllActions: _this2.get('userIsQuoteAdmin')
        };

        if (evaluator.isClient) {
          _this2.get('allUserQuoteRoles').forEach(function (role) {
            if (role.get('user.id') == evaluator.get('id')) {
              data['canEvaluate'] = role.get('canEvaluate');
            }
          });
        }

        proposals.forEach(function (proposal) {
          data[proposal.get('id')] = (0, _userProposalEvaluationStatusInfo.getUserProposalEvaluationStatusInfo)(null, {
            proposal: proposal,
            user: evaluator,
            userProposalEvaluationStatuses: _this2.get('userProposalEvaluationStatuses'),
            intl: intl
          });
        });
        rows.push(data);
      });
      return rows;
    }),
    actions: {
      resendEvaluationInvitation: function resendEvaluationInvitation(user) {
        var _this3 = this;

        user.resendEvaluationInvitation({
          quote_id: this.get('quote.id'),
          user_id: user.get('id'),
          evaluation_type: this.get('invitationType')
        }).then(function () {
          _this3.get('notify').success(_this3.get('intl').t('notifyMessages.projectEvaluationInvitationResent').toString() + user.get('fullName') + ' - ' + user.get('email'));
        }).catch(function () {
          _this3.get('notify').error(_this3.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
        });
      },
      viewEvaluationDetails: function viewEvaluationDetails(evaluator) {
        this.set('showExternalProjectEvaluation', true);
        this.set('showProjectResults', false);
        this.set('showProjectEvaluation', false);
        this.set('evaluator', evaluator);
      },
      changeActiveProperty: function changeActiveProperty(propertyName) {
        this.set('showProjectEvaluation', propertyName === 'showProjectEvaluation');
        this.set('showExternalProjectEvaluation', propertyName === 'showExternalProjectEvaluation');
        this.set('showProjectResults', propertyName === 'showProjectResults');

        if (propertyName === 'showExternalProjectEvaluation') {
          if (!this.get('evaluator')) {
            var evaluators = this.get('evaluators');

            if (evaluators.get('length')) {
              this.set('evaluator', evaluators.get('firstObject'));
            } else {
              this.set('evaluator', this.get('currentUser'));
            }
          }
        }
      },
      disableEvaluator: function disableEvaluator(user) {
        var _this4 = this;

        var userQuoteRole;
        this.get('allUserQuoteRoles').forEach(function (role) {
          if (role.get('user.id') == user.get('id')) {
            userQuoteRole = role;
          }
        });
        userQuoteRole.canEvaluate = false;
        userQuoteRole.save().then(function () {
          _this4.get('notify').success('Evaluator disabled');
        }).catch(function () {
          _this4.get('notify').error(_this4.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
        }).finally(function () {
          _this4.send('refreshRoute');
        });
      },
      enableEvaluator: function enableEvaluator(user) {
        var _this5 = this;

        var userQuoteRole;
        this.get('allUserQuoteRoles').forEach(function (role) {
          if (role.get('user.id') == user.get('id')) {
            userQuoteRole = role;
          }
        });
        userQuoteRole.canEvaluate = true;
        userQuoteRole.save().then(function () {
          _this5.get('notify').success('Evaluator enabled');
        }).catch(function () {
          _this5.get('notify').error(_this5.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
        }).finally(function () {
          _this5.send('refreshRoute');
        });
      },
      deleteEvaluationInvitation: function deleteEvaluationInvitation(user) {
        var _this6 = this;

        if (confirm(this.get('intl').t('confirmMessages.deleteEvaluationInvitation').toString())) {
          user.deleteEvaluationInvitation({
            quote_id: this.get('quote.id'),
            evaluation_type: this.get('invitationType')
          }).then(function () {
            _this6.get('notify').success(_this6.get('intl').t('notifyMessages.projectEvaluationInvitationDeleted').toString());
          }).catch(function () {
            _this6.get('notify').error(_this6.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
          }).finally(function () {
            _this6.send('refreshRoute');
          });
        }
      },
      openUserEvaluationInvitationModal: function openUserEvaluationInvitationModal(user) {
        this.set('invitedUser', user ? user : this.store.createRecord('user', {
          isClient: false,
          isConsultant: false,
          isExternalEvaluator: true,
          company: this.get('currentUser.company')
        }));
        this.set('showUserEvaluationInvitationModal', true);
      },
      closeUserEvaluationInvitationModal: function closeUserEvaluationInvitationModal() {
        this.set('showUserEvaluationInvitationModal', false);
        this.set('invitedUser', null);
      },
      saveUserEvaluationInvitation: function saveUserEvaluationInvitation(invitedUser) {
        var _this7 = this;

        var evaluators = this.get('evaluators');
        var alreadyInvitedEvaluator = evaluators.findBy('email', invitedUser.get('email'));
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (alreadyInvitedEvaluator) {
            _this7.get('notify').error(invitedUser.get('email') + _this7.get('intl').t('notifyMessages.projectEvaluationAlreadyInvited').toString());

            reject();
          } else {
            // const oldUser = this.store.peekAll('user').findBy('email', invitedUser.get('email'));
            // if (oldUser) {
            //   this.store.unloadRecord(oldUser);
            // }
            invitedUser.save({
              adapterOptions: {
                user_for_evaluation: true,
                quote_id: _this7.get('quote.id'),
                evaluation_type: _this7.get('invitationType')
              }
            }).then(function (savedUser) {
              _this7.set('showUserEvaluationInvitationModal', false);

              _this7.set('invitedUser', null);

              _this7.get('evaluators').pushObject(savedUser);

              _this7.get('notify').success(_this7.get('intl').t('notifyMessages.projectEvaluationInvitationSuccess').toString());

              resolve();

              _this7.send('refreshRoute');
            }).catch(function (err) {
              if (err.message.includes("'user' was saved to the server, but the response returned the new id")) {
                // the user was already in the database, and a userQuoteRole object was created
                _this7.get('notify').success(_this7.get('intl').t('notifyMessages.projectEvaluationInvitationSuccess').toString());

                reject();

                _this7.send('refreshRoute');
              } else {
                _this7.get('notify').error(_this7.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());

                reject();
              }
            });
          }
        });
      },
      startEvaluation: function startEvaluation() {
        var _this8 = this;

        this.set('showSpinner', true);
        var quote = this.get('quote');
        quote.set(this.get('propertyNameEvaluationIsActive'), true);
        return quote.save().then(function () {
          _this8.get('notify').success(_this8.get('intl').t('notifyMessages.projectEvaluationStarted').toString());

          _this8.set('evaluationIsActive', true);
        }).catch(function () {
          _this8.get('notify').error(_this8.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
        }).finally(function () {
          _this8.set('showSpinner', false);

          _this8.send('refreshRoute');
        });
      },
      stopEvaluation: function stopEvaluation() {
        var _this9 = this;

        this.set('showSpinner', true);
        var quote = this.get('quote');
        quote.set(this.get('propertyNameEvaluationIsActive'), false);
        return quote.save().then(function () {
          _this9.get('notify').success(_this9.get('intl').t('notifyMessages.projectEvaluationStopped').toString());

          _this9.set('evaluationIsActive', false);
        }).catch(function () {
          _this9.get('notify').error(_this9.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
        }).finally(function () {
          _this9.set('showSpinner', false);

          _this9.send('refreshRoute');
        });
      }
    }
  });

  _exports.default = _default;
});