define("cb-ui/routes/app/management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {// this.transitionToDashboard();
    },
    model: function model() {
      return this.get('sessionContext.currentUser');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model); // controller.set('quotes', model);

    },
    transitionToDashboard: function transitionToDashboard() {
      var _this = this;

      //TODO: refactor
      this.get('sessionContext.currentUser').then(function (user) {
        if (user.get('isCompanyAdmin')) {
          if (_this.get('routeName') !== 'app.management.quotes') {
            _this.transitionTo('app.management.index');
          }
        } else {
          _this.transitionTo('app.dashboard');
        }
      });
    },
    actions: {// willTransition(transition) {
      //   if (transition.targetName.indexOf('app.management') >= 0) {
      //     this.transitionToDashboard();
      //   } else {
      //     return true;
      //   }
      // }
    }
  });

  _exports.default = _default;
});