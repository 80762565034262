define("cb-ui/templates/components/models-table/select-project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7GYO8JmH",
    "block": "{\"symbols\":[],\"statements\":[[6,\"button\"],[9,\"class\",\"btn btn-primary btn-block btn-mini btn-table\"],[9,\"data-test-open-request\",\"\"],[3,\"action\",[[19,0,[]],\"sendAction\",[20,[\"record\"]]]],[7],[6,\"i\"],[9,\"class\",\"glyphicon glyphicon-log-in\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"app.dashboardClient.projects.open\"],null],false],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/models-table/select-project.hbs"
    }
  });

  _exports.default = _default;
});