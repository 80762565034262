define("cb-ui/validations/siemensQuote", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    personFirstName: [(0, _validators.validateLength)({
      max: 128
    }), (0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    personLastName: [(0, _validators.validateLength)({
      max: 128
    }), (0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    })],
    projectAdministratorEmail: [(0, _validators.validateLength)({
      max: 254
    }), (0, _validators.validateFormat)({
      type: 'email'
    }), (0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    projectAdministratorPhone: [(0, _validators.validateLength)({
      max: 32
    }), (0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    // serviceStandard: [
    //   validatePresence({ presence: true, allowBlank: false }),
    // ],
    budgetEstimate: [(0, _validators.validateNumber)({
      integer: true
    })],
    initialSituation: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })]
  };
  _exports.default = _default;
});