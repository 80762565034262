define("cb-ui/components/supplement-fee", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currency: null,
    totalFee: null,
    reference: null,
    percentage: null,
    fee: null,
    showOnlyValue: false,
    numberFormat: '0,0.00',
    value: Ember.computed('reference', 'percentage', 'totalFee', 'fee', function () {
      var totalFee = this.get('totalFee');
      var reference = this.get('reference');
      var percentage = this.get('percentage');
      var fee = this.get('fee');

      if (reference === '% of Fee') {
        if (totalFee && !isNaN(totalFee) && !isNaN(percentage)) {
          return percentage * totalFee / 100;
        }
      } else if (reference === 'Fixed Fee') {
        if (totalFee && !isNaN(totalFee) && !isNaN(fee)) {
          return fee * 100 / totalFee;
        }
      }

      return 0;
    })
  });

  _exports.default = _default;
});