define("cb-ui/initializers/resize", ["exports", "ember-resize/services/resize", "cb-ui/config/environment"], function (_exports, _resize, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    var application = arguments[1] || arguments[0];
    var resizeServiceDefaults = _environment.default.resizeServiceDefaults;
    var injectionFactories = resizeServiceDefaults.injectionFactories;
    application.register('config:resize-service', resizeServiceDefaults, {
      instantiate: false
    });
    application.register('service:resize', _resize.default);
    application.inject('service:resize', 'resizeServiceDefaults', 'config:resize-service');
    injectionFactories.forEach(function (factory) {
      application.inject(factory, 'resizeService', 'service:resize');
    });
  }

  var _default = {
    name: 'resize',
    initialize: initialize
  };
  _exports.default = _default;
});