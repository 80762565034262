define("cb-ui/components/financial-quotation-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    proposal: null,
    numberFormat: '0,0.00',
    timeMaterialsSupplements: Ember.computed.filterBy('proposal.timeMaterialSupplements', 'type', 'time_material'),
    fixedFeeSupplements: Ember.computed.filterBy('proposal.timeMaterialSupplements', 'type', 'fixed_fee'),
    supplementsFee: Ember.computed('proposal.financialQuotationData', function () {
      return this.get('proposal.financialQuotationData.supplementsFeeValue');
    }),
    analysisDaysFee: 0,
    conceptDaysFee: 0,
    implementationDaysFee: 0,
    auditDaysFee: 0,
    isLoading: false,
    durationInWorkingDays: Ember.computed('proposal', function () {
      return 5 * this.get('proposal').get('durationInWeeks');
    }),
    analysisDaysFeeObs: Ember.on('init', Ember.observer('proposal', function () {
      var _this = this;

      this.set('isLoading', true);
      this.get('proposal.timeMaterials').then(function (timeMaterials) {
        //analysis
        var timeMaterialsAnalysis = timeMaterials.filter(function (timeMaterial) {
          return timeMaterial && !isNaN(timeMaterial.get('analysisDays'));
        });

        _this.set('analysisDaysFee', timeMaterialsAnalysis.reduce(function (prevValue, item) {
          return prevValue + item.get('analysisDays') * item.get('rate');
        }, 0)); //concept


        var timeMaterialsConcept = timeMaterials.filter(function (timeMaterial) {
          return timeMaterial && !isNaN(timeMaterial.get('conceptDays'));
        });

        _this.set('conceptDaysFee', timeMaterialsConcept.reduce(function (prevValue, item) {
          return prevValue + item.get('conceptDays') * item.get('rate');
        }, 0)); //implementation


        var timeMaterialsImplementation = timeMaterials.filter(function (timeMaterial) {
          return timeMaterial && !isNaN(timeMaterial.get('implementationDays'));
        });

        _this.set('implementationDaysFee', timeMaterialsImplementation.reduce(function (prevValue, item) {
          return prevValue + item.get('implementationDays') * item.get('rate');
        }, 0)); //audit


        var timeMaterialsAudit = timeMaterials.filter(function (timeMaterial) {
          return timeMaterial && !isNaN(timeMaterial.get('auditDays'));
        });

        _this.set('auditDaysFee', timeMaterialsAudit.reduce(function (prevValue, item) {
          return prevValue + item.get('auditDays') * item.get('rate');
        }, 0));

        _this.set('isLoading', false);
      });
    }))
  });

  _exports.default = _default;
});