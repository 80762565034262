define("cb-ui/controllers/app/rfp/visual-editor", ["exports", "cb-ui/mixins/wizard-controller", "cb-ui/config/environment", "ember-uuid"], function (_exports, _wizardController, _environment, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_wizardController.default, {
    quote: null,
    hideTOC: false,
    notify: Ember.inject.service('notify'),
    showQualityCheckModal: null,
    blockPrevious: false,
    isDirty: false,
    sectionsObserver: Ember.observer('quote.serctions.[]', function () {
      if (this.get('quote.sections.length')) {
        this.set('isDirty', true);
      }
    }),
    getHighestOrderChild: function getHighestOrderChild(parent) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        if (parent === null) {
          var sections = _this.get('quote.rootSections');

          if (sections.get('length')) {
            resolve(Math.max.apply(Math, sections.mapBy('order')));
          } else {
            resolve(0);
          }
        } else {
          parent.get('children').then(function (sections) {
            if (sections.get('length') > 0) {
              resolve(Math.max.apply(Math, sections.mapBy('order')));
            } else {
              resolve(0);
            }
          });
        }
      });
    },
    saveSections: function saveSections() {
      var _this2 = this;

      var quote = this.get('quote');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        quote.get('sections').then(function (sections) {
          var sectionsWithPlaceholders = sections.filter(function (section) {
            return section.get('content').indexOf('*Placeholder Text') > -1;
          });

          if (sectionsWithPlaceholders.length > 0) {
            _this2.set('showQualityCheckModal', true);

            reject();
          }

          var promises = [];
          sections.forEach(function (section) {
            promises.push(section.save());
          });
          new Ember.RSVP.all(promises).then(function () {
            quote.save().then(function () {
              quote.reload();
              resolve(quote, sections);

              _this2.get('notify').success(_this2.get('intl').t('app.orgSettings.coverLetterSettings.actions.successSaved').toString());
            });
          });
        });
      });
    },
    actions: {
      deleteSection: function deleteSection(section) {
        var _this3 = this;

        if (confirm(this.get('intl').t('confirmMessages.deleteSection').toString() + section.get('title') + ' ?')) {
          this.set('hideTOC', true);
          Ember.run.next(function () {
            section.get('parent').then(function (parent) {
              section.get('children').then(function (subsections) {
                _this3.getHighestOrderChild(null).then(function (highest) {
                  var i = 1;
                  subsections.forEach(function (subsection) {
                    subsection.setProperties({
                      'order': highest + i,
                      parent: parent
                    });
                    i++;
                  });
                }); //find last order number


                section.destroyRecord();

                _this3.set('hideTOC', false);

                _this3.set('isDirty', true);
              });
            });
          });
        }
      },
      disable: function disable(section) {
        section.set('disabled', true);
        this.set('isDirty', true);
      },
      enable: function enable(section) {
        var _this4 = this;

        section.get('parent').then(function (parent) {
          if (parent) {
            if (parent.get('disabled')) {
              alert(_this4.get('intl').t('notifyMessages.enableParent').toString());
            } else {
              section.set('disabled', false);
            }
          } else {
            section.set('disabled', false);
          }

          _this4.set('isDirty', true);
        });
      },
      addSection: function addSection() {
        var _this5 = this;

        this.getHighestOrderChild(null).then(function (highest) {
          _this5.store.createRecord('document-section', {
            uid: (0, _emberUuid.v4)(),
            quote: _this5.get('quote'),
            title: 'Section title',
            content: '',
            blocked: false,
            hidden: false,
            order: highest + 1,
            parent: null
          }).save().then(function () {
            _this5.set('isDirty', true);

            _this5.get('notify').success(_this5.get('intl').t('notifyMessages.sectionAdded').toString());
          }).catch(function () {
            _this5.get('notify').error(_this5.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
          });
        });
      },
      changeListOrder: function changeListOrder(newList) {
        var _this6 = this;

        this.set('hideTOC', true);
        this.set('isDirty', true);
        this.get('quote.sections').then(function (sections) {
          Ember.run.next(function () {
            _this6.remakeSectionsTree(sections, newList, null).then(function () {
              _this6.set('hideTOC', false);

              sections.forEach(function (section) {
                if (section.get('isDirty')) {
                  section.save();
                }
              });
            });
          });
        });
      },
      closeQualityCheckModal: function closeQualityCheckModal() {
        this.set('showQualityCheckModal', false);
      },
      save: function save() {
        this.saveSections();
      },
      proceed: function proceed(route) {
        this.set('isDirty', false);
        this.set('showUnsavedChangesModal', false);
        var quote = this.get('quote');
        quote.rollbackAttributes();
        quote.reload();

        if (route && route.includes(_environment.default.CANONICAL_DOMAIN_NEXT)) {
          window.location = route;
          return;
        }

        this.transitionToRoute(route);
      },
      saveAndProceed: function saveAndProceed(route) {
        var _this7 = this;

        this.set('blockPrevious', true);
        var promise = new Ember.RSVP.Promise(function (resolve, reject) {
          _this7.saveSections().then(function () {
            _this7.set('isDirty', false);

            var quote = _this7.get('quote');

            quote.set('coverLetterTypeWasChosen', true);
            quote.save().then(function () {
              resolve();

              _this7.set('blockPrevious', false);

              if (route && route.includes(_environment.default.CANONICAL_DOMAIN_NEXT)) {
                window.location = route;
                return;
              }

              _this7.transitionToRoute(route);
            }).catch(function () {
              quote.set('coverLetterTypeWasChosen', false);

              _this7.get('notify').error(_this7.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());

              reject();

              _this7.set('blockPrevious', false);
            });
          }).catch(function () {
            _this7.get('notify').error(_this7.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());

            reject();

            _this7.set('blockPrevious', false);
          });
        });
      },
      next: function next(callback) {
        var _this8 = this;

        this.set('blockPrevious', true);
        var promise = new Ember.RSVP.Promise(function (resolve, reject) {
          _this8.saveSections().then(function () {
            var quote = _this8.get('quote');

            quote.set('coverLetterTypeWasChosen', true);
            quote.save().then(function () {
              resolve();

              _this8.set('blockPrevious', false);

              window.location.href = "".concat(_environment.default.CANONICAL_DOMAIN_NEXT, "/project/").concat(_this8.get('quote').id, "/review-and-submit");
            }).catch(function () {
              quote.set('coverLetterTypeWasChosen', false);

              _this8.get('notify').error(_this8.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());

              reject();

              _this8.set('blockPrevious', false);
            });
          }).catch(function () {
            _this8.get('notify').error(_this8.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());

            reject();

            _this8.set('blockPrevious', false);
          });
        });
        callback(promise);
      },
      previous: function previous() {
        if (!this.get('blockPrevious')) {
          this.transitionToRoute('app.rfp.criteria-evaluation');
        }
      }
    },
    remakeSectionsTree: function remakeSectionsTree(sections, newList, parent) {
      var _this9 = this;

      var promiseList = [];
      var sectionOrder = 0;
      newList.forEach(function (item) {
        promiseList.push(new Ember.RSVP.Promise(function (resolve) {
          var section = _this9.getSectionByUID(sections, item.id.toString()); //check if parent is disabled


          var isSectionDisabled;

          if (parent && parent.get('disabled')) {
            isSectionDisabled = true;
          }

          sectionOrder++;
          section.setProperties({
            parent: parent,
            order: sectionOrder,
            disabled: isSectionDisabled || section.get('disabled')
          });

          if ('children' in item && item.children.length > 0) {
            resolve(_this9.remakeSectionsTree(sections, item.children, section));
          } else {
            resolve();
          }
        }));
      });
      return new Ember.RSVP.all(promiseList);
    },
    //list operations
    getSectionByUID: function getSectionByUID(list, uid) {
      return list.findBy('uid', uid);
    }
  });

  _exports.default = _default;
});