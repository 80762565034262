define("cb-ui/components/em-form-group", ["exports", "ember-rapid-forms/components/em-form-group"], function (_exports, _emFormGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import Ember from 'ember';
  var _default = _emFormGroup.default;
  _exports.default = _default;
});