define("cb-ui/routes/recover-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      this._super(transition);

      if (this.get('session.isAuthenticated')) {
        this.transitionTo('app.dashboard');
      }
    }
  });

  _exports.default = _default;
});