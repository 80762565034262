define("cb-ui/templates/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M5TCYBK0",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"container consulting-container\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"consulting-title\"],[7],[0,\"\\n    \"],[8],[0,\"\\n\\n    \"],[6,\"div\"],[9,\"class\",\"consulting-body\"],[7],[0,\"\\n      \"],[1,[18,\"outlet\"],false],[0,\"\\n    \"],[8],[0,\"\\n\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/base.hbs"
    }
  });

  _exports.default = _default;
});