define("cb-ui/routes/app/rfp/consultants", ["exports", "cb-ui/mixins/scrollbar-reset"], function (_exports, _scrollbarReset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Route.extend(_scrollbarReset.default, {
    model: function model() {
      var cbStore = this.get('cbStore');
      var quote = this.modelFor('app.rfp');
      return Ember.RSVP.hash({
        quoteConsultants: this.store.query('quote-consultant', {
          quote_id: quote.get('id')
        }),
        services: cbStore.findAllWrapper('serviceStandard', 'mpttOrder'),
        industries: cbStore.findAllWrapper('industryStandard', 'mpttOrder'),
        quote: quote,
        currentUser: this.get('sessionContext.currentUser'),
        quoteServiceProviders: this.store.query('quote-service-provider', {
          quote_id: quote.get('id')
        })
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      var quote = this.modelFor('app.rfp');
      var extraCompanies = new Ember.A();
      var extraNewCompanies = new Ember.A();
      var localSelectedCompanies = new Ember.A();
      controller.set('localQuoteServiceProviders', model.quoteServiceProviders);
      quote.get('companies').then(function (selectedCompanies) {
        var quoteConsultantCompanyPromises = [];
        var quoteConsultantCompanies = [];
        model.quoteConsultants.forEach(function (consultant) {
          var companyPromise = new Ember.RSVP.Promise(function (resolve) {
            consultant.get('company').then(function (company) {
              quoteConsultantCompanies.push(company);
              resolve();
            });
          });
          quoteConsultantCompanyPromises.push(companyPromise);
        });
        Ember.RSVP.all(quoteConsultantCompanyPromises).then(function () {
          selectedCompanies.forEach(function (company) {
            if (!quoteConsultantCompanies.includes(company)) {
              if (company.get('extraConsultant')) {
                _this.store.query('extra-company', {
                  company: company.get('id')
                }).then(function (extraCompanyList) {
                  if (extraCompanyList.get('length')) {
                    extraNewCompanies.addObject(extraCompanyList.get('firstObject'));
                    controller.set('originalExtraNewConsultants', _toConsumableArray(extraNewCompanies));
                  }
                });
              } else {
                extraCompanies.addObject(company);
              }
            } else {
              localSelectedCompanies.addObject(company);
            }
          });
          controller.set('extraConsultants', extraCompanies);
          controller.set('extraNewConsultants', extraNewCompanies);
          controller.set('localSelectedCompanies', localSelectedCompanies);
          controller.set('originalExtraConsultants', _toConsumableArray(extraCompanies));
          controller.set('originalLocalSelectedCompanies', _toConsumableArray(localSelectedCompanies));
        });
      });
      controller.set('quote', quote);
      controller.set('quoteConsultants', model.quoteConsultants);
      controller.set('quote.currentStep', 20);
      controller.set('isShowingModal', false);
      controller.set('showError', false);
      controller.set('showAllSpecialistConsultants', false);
      controller.set('showAllDiversifiedConsultants', false);
      controller.set('showAllGeneralistConsultants', false);
      controller.set('showAddExtraConsultant', true);
      controller.set('showExtraConsultantForm', false);
      controller.set('showExtraConsultantsContainer', true);
      controller.set('currentUser', model.currentUser);
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (this.get('controller.blockPrevious')) {
          transition.abort();
        } else {
          this._super(transition);
        }
      }
    }
  });

  _exports.default = _default;
});