define("cb-ui/routes/app/project/client/project-management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var quote = this.modelFor('app.project');
      return Ember.RSVP.hash({
        quote: quote,
        currentUser: this.get('sessionContext.currentUser'),
        userQuoteRoleList: this.store.query('user-quote-role', {
          quote_id: quote.get('id')
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.modelFor('app.project').reload().then(function (quote) {
        controller.set('quote', quote);
        controller.set('record', quote);
        controller.set('userQuoteRole', model.userQuoteRoleList.get('firstObject'));
        controller.set('currentUser', model.currentUser);
        model.currentUser.get('company').then(function (company) {
          controller.set('currentUserCompany', company);
        });
      });
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});