define("cb-ui/templates/components/labeled-radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n3NpdgqG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[25,\"radio-button\",null,[[\"radioClass\",\"radioId\",\"changed\",\"disabled\",\"groupValue\",\"name\",\"required\",\"value\"],[[20,[\"radioClass\"]],[20,[\"radioId\"]],\"innerRadioChanged\",[20,[\"disabled\"]],[20,[\"groupValue\"]],[20,[\"name\"]],[20,[\"required\"]],[20,[\"value\"]]]]],false],[0,\"\\n\\n\"],[11,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/labeled-radio-button.hbs"
    }
  });

  _exports.default = _default;
});