define("cb-ui/templates/landing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XrlU80Mb",
    "block": "{\"symbols\":[],\"statements\":[[1,[18,\"outlet\"],false],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"row\"],[9,\"style\",\"margin: 10px 10px 0\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-lg-12\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"col-md-4 col-md-offset-4 text-center\"],[7],[0,\"\\n                \"],[6,\"h3\"],[7],[0,\"What do you want to do ?\"],[8],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"p-md\"],[7],[0,\"\\n                    \"],[6,\"div\"],[9,\"class\",\"btn btn-primary\"],[3,\"action\",[[19,0,[]],\"newQuote\"]],[7],[0,\" Create a new Quote\"],[8],[0,\"\\n                \"],[8],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"btn btn-primary\"],[3,\"action\",[[19,0,[]],\"lastQuote\"]],[7],[0,\" Continue editing the last Quote\"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/landing.hbs"
    }
  });

  _exports.default = _default;
});