define("cb-ui/models/quote-consultant", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    quote: _emberData.default.belongsTo('quote', {
      async: true
    }),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    companyId: _emberData.default.attr('number'),
    consultantType: _emberData.default.attr('string'),
    score: _emberData.default.attr('number'),
    finalScore: _emberData.default.attr('number'),
    industryScore: _emberData.default.attr('number'),
    serviceScore: _emberData.default.attr('number')
  });

  _exports.default = _default;
});