define("cb-ui/templates/submit-rfp-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K/UK7ceG",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"bs-modal\",null,[[\"footer\",\"body\",\"onHidden\",\"size\"],[false,false,[25,\"route-action\",[\"closeSubmitRFPModal\"],null],\"lg\"]],{\"statements\":[[4,\"component\",[[19,1,[\"body\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n          \"],[6,\"div\"],[7],[1,[25,\"t\",[\"confirmMessages.submitProject\"],null],false],[0,\"\\n          \"],[8],[0,\"\\n\\n          \"],[6,\"div\"],[9,\"class\",\"m-md\"],[7],[0,\"\\n              \"],[6,\"button\"],[9,\"data-test-confirm-submit-rfp\",\"\"],[9,\"class\",\"btn btn-primary m-r m-b-sm\"],[3,\"action\",[[19,0,[]],\"confirm\"]],[7],[0,\"\\n                \"],[1,[25,\"t\",[\"app.modals.submitQuote.yes\"],null],false],[0,\"\\n              \"],[8],[0,\"\\n              \"],[6,\"button\"],[9,\"data-test-confirm-cancel-rfp\",\"\"],[9,\"class\",\"btn btn-default m-b-sm\"],[3,\"action\",[[19,0,[]],\"closeSubmitRFPModal\"]],[7],[0,\"\\n                \"],[1,[25,\"t\",[\"app.modals.submitQuote.no\"],null],false],[0,\"\\n              \"],[8],[0,\"\\n          \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/submit-rfp-modal.hbs"
    }
  });

  _exports.default = _default;
});