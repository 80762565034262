define("cb-ui/routes/app/management/quotes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(obj, transition) {
      return Ember.RSVP.hash({
        quotes: this.store.findAll('quote'),
        currentUser: this.get('sessionContext.currentUser'),
        companyUser: this.store.findRecord('user', obj.user_id),
        userQuotesRoles: this.store.query('user-quote-role', {
          user_id: obj.user_id
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('currentUser', model.currentUser);
      controller.setProperties({
        currentUser: model.currentUser,
        companyUser: model.companyUser,
        userQuotesAccess: model.userQuotesRoles
      });
      var quotesWithAccessIds = model.userQuotesRoles.map(function (item) {
        return item.get('quote.id');
      });
      var quotesWithoutAccess = model.quotes.filter(function (item) {
        return !quotesWithAccessIds.contains(item.get('id'));
      });
      controller.set('quotesWithoutAccess', quotesWithoutAccess);
      controller.set('newQuotesAccess', new Ember.A());
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});