define("cb-ui/controllers/app/rfp/criteria-evaluation", ["exports", "cb-ui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routerService: Ember.inject.service('router'),
    notify: Ember.inject.service('notify'),
    queryParams: ['technicalTemplateId', 'pitchTemplateId'],
    technicalTemplateId: null,
    pitchTemplateId: null,
    quote: null,
    currentUser: null,
    currentUserCompany: null,
    showCoverLetterQuestion: null,
    //used for dropdowns
    technicalTemplatesOptions: null,
    pitchTemplatesOptions: null,
    //selected templates from dropdown
    technicalTemplate: null,
    pitchTemplate: null,
    //template groups data
    technicalTemplateGroups: new Ember.A(),
    pitchTemplateGroups: new Ember.A(),
    //
    technicalTemplateDataLoaded: null,
    pitchTemplateDataLoaded: null,
    // flags for displaying dropdowns
    showTechnicalTemplatesDropdown: null,
    showPitchTemplatesDropdown: null,
    technicalQuoteCriteriaGroups: new Ember.A(),
    pitchQuoteCriteriaGroups: new Ember.A(),
    //TODO: REFACTOR use same value for technicalType on both BE and FE
    technicalType: 'technical',
    pitchType: 'pitch',
    criteriaEvaluationMax: 100,
    criteriaEvaluationMin: 0,
    sliderStep: 1,
    localFinancialWeight: null,
    localTechnicalWeight: null,
    localPitchWeight: null,
    financialSlider: null,
    technicalSlider: null,
    pitchSlider: null,
    pitchIsDisabled: false,
    blockPrevious: false,
    isDirty: false,
    showUnsavedChangedModal: false,
    targetRoute: 'app.rfp.cover-letter-choices',
    pips: Ember.computed('media.isMobile', function () {
      return {
        mode: 'positions',
        values: this.get('media.isMobile') ? [0, 50, 100] : [0, 20, 40, 60, 80, 100],
        density: this.get('media.isMobile') ? 10 : 2
      };
    }),
    pitchSliderObserver: Ember.observer('pitchSlider', 'quote.pitchSession', function () {
      var quote = this.get('quote');
      var hasPitchSession = quote.get('pitchSession');

      if (!hasPitchSession) {
        var pitchSlider = this.get('pitchSlider');

        if (pitchSlider) {
          pitchSlider.isDisabled = true;
        }
      }
    }),
    //TODO: refactor
    updateSliders: function updateSliders(initialValue, newValue, updatedSlider, sliderOne, sliderTwo) {
      var updateWithValue, updateWithValueTwo;

      if (sliderOne.isDisabled && sliderTwo.isDisabled) {
        console.log('slider one dis', sliderOne.isDisabled);
        console.log('slider twp dis', sliderTwo.isDisabled);
        updatedSlider.set(initialValue);
      } else if (sliderOne.isDisabled) {
        newValue = Math.min(100 - sliderOne.get(), newValue);

        if (newValue === 0) {
          updateWithValueTwo = initialValue;
        } else {
          updateWithValueTwo = initialValue - newValue;
        }

        updatedSlider.set(newValue);
        sliderTwo.set(sliderTwo.get() + updateWithValueTwo);
      } else if (sliderTwo.isDisabled) {
        newValue = Math.min(100 - sliderTwo.get(), newValue);

        if (newValue === 0) {
          updateWithValue = initialValue;
        } else {
          updateWithValue = initialValue - newValue;
        }

        updatedSlider.set(newValue);
        sliderOne.set(sliderOne.get() + updateWithValue);
      } else {
        if (initialValue !== newValue) {
          if (newValue === 0) {
            updateWithValue = updateWithValueTwo = initialValue / 2;
          } else {
            updateWithValue = updateWithValueTwo = (initialValue - newValue) / 2;
          } //todo: handle case when one of the values is 100


          if (updateWithValue % 1 !== 0) {
            updateWithValueTwo += parseInt(updateWithValue - Math.floor(updateWithValue));
            updateWithValue = Math.floor(updateWithValue);
          }

          var initialValueSliderOne = sliderOne.get();
          var initialValueSliderTwo = sliderTwo.get();
          var newValueSliderOne = initialValueSliderOne + updateWithValue;
          var newValueSliderTwo = initialValueSliderTwo + updateWithValueTwo;

          if (newValueSliderOne < 0 && newValueSliderTwo < 0) {
            newValue = 100;
            newValueSliderOne = 0;
            newValueSliderTwo = 0;
          } else if (newValueSliderOne < 0) {
            newValueSliderTwo += newValueSliderOne;
            newValueSliderOne = 0;
          } else if (newValueSliderTwo < 0) {
            newValueSliderOne += newValueSliderTwo;
            newValueSliderTwo = 0;
          }

          updatedSlider.set(newValue);
          sliderOne.set(newValueSliderOne);
          sliderTwo.set(newValueSliderTwo);
        }
      }
    },
    updateSliderValues: function updateSliderValues(updatedProperty, value) {
      var modifiedProperty = 'quote.' + updatedProperty;
      var initialValue = this.get(modifiedProperty);
      var financialSlider = this.get('financialSlider');
      var technicalSlider = this.get('technicalSlider');
      var pitchSlider = this.get('pitchSlider'); //if (this.get('pitchIsDisabled')) {
      //  pitchSlider.isDisabled = true;
      //}

      if (updatedProperty === 'financialWeight') {
        this.updateSliders(initialValue, value, financialSlider, technicalSlider, pitchSlider);
      } else if (updatedProperty === 'technicalWeight') {
        this.updateSliders(initialValue, value, technicalSlider, financialSlider, pitchSlider);
      } else if (updatedProperty === 'pitchWeight') {
        this.updateSliders(initialValue, value, pitchSlider, financialSlider, technicalSlider);
      }

      this.set('quote.financialWeight', financialSlider.get());
      this.set('quote.technicalWeight', technicalSlider.get());
      this.set('quote.pitchWeight', pitchSlider.get());
    },
    serializeData: function serializeData(template) {
      var dataJSON = {
        template: null,
        template_title: 'Copy of - ' + template.get('title'),
        template_type: template.get('type'),
        groups: []
      };
      var groups = template.hasMany('baseCriteriaEvaluationGroups').value();
      var groupsJSON = [];
      groups.forEach(function (group) {
        var criteriaEvaluations = group.hasMany('baseCriteriaEvaluations').value();
        var criteriaEvaluationsJSON = [];
        criteriaEvaluations.forEach(function (ca) {
          var attributes = {};

          var snapshotAttributes = ca._createSnapshot()._attributes;

          for (var key in snapshotAttributes) {
            attributes[key.underscore()] = snapshotAttributes[key];
          }

          criteriaEvaluationsJSON.push(attributes);
        });
        groupsJSON.push({
          title: group.get('title'),
          order: group.get('order'),
          'base_criteria_evaluations': criteriaEvaluationsJSON
        });
      });
      dataJSON.groups = groupsJSON;
      return dataJSON;
    },
    actions: {
      openUnsavedChangesModal: function openUnsavedChangesModal() {
        this.set('showUnsavedChangesModal', true);
      },
      closeUnsavedChangesModal: function closeUnsavedChangesModal() {
        this.set('showUnsavedChangesModal', false);
      },
      next: function next() {
        this.set('targetRoute', 'app.rfp.cover-letter-choices');

        if (this.get('isDirty')) {
          this.send('openUnsavedChangesModal');
        } else {
          this.transitionToRoute(this.get('targetRoute'));
        }
      },
      previous: function previous() {
        if (this.get('blockPrevious')) {
          return;
        }

        this.set('targetRoute', "".concat(_environment.default.CANONICAL_DOMAIN_NEXT, "/project/").concat(this.get('quote').id, "/rfp-details"));

        if (this.get('isDirty')) {
          this.send('openUnsavedChangesModal');
        } else {
          window.location.href = "".concat(_environment.default.CANONICAL_DOMAIN_NEXT, "/project/").concat(this.get('quote').id, "/rfp-details");
        }
      },
      proceed: function proceed(route) {
        this.set('isDirty', false);
        this.set('showUnsavedChangesModal', false);
        var quote = this.get('quote');
        quote.rollbackAttributes();
        quote.reload();

        if (route && route.includes(_environment.default.CANONICAL_DOMAIN_NEXT)) {
          window.location = route;
          return;
        }

        console.log(route);
        this.transitionToRoute(route);
      },
      saveAndProceed: function saveAndProceed(route) {
        var _this = this;

        this.set('blockPrevious', true);
        var quote = this.get('quote');
        var technicalTemplate = this.get('technicalTemplate');
        var pitchTemplate = this.get('pitchTemplate');
        return new Ember.RSVP.Promise(function (resolve, reject) {
          var saveOptions = {
            adapterOptions: {}
          };

          if (technicalTemplate) {
            saveOptions.adapterOptions['technical_id'] = technicalTemplate.get('id');
          }

          if (pitchTemplate) {
            saveOptions.adapterOptions['pitch_id'] = pitchTemplate.get('id');
          }

          if (quote.get('pitchWeight') == 0 && quote.get('pitchSession')) {
            _this.get('notify').error(_this.get('intl').t('invalidPitchWeight').toString());

            reject();
          } else {
            quote.save(saveOptions).then(function () {
              resolve();

              _this.set('showUnsavedChangesModal', false);

              _this.set('technicalTemplateId', null);

              _this.set('pitchTemplateId', null);

              _this.set('blockPrevious', false);

              _this.set('isDirty', false);

              if (route && route.includes(_environment.default.CANONICAL_DOMAIN_NEXT)) {
                window.location = route;
                return;
              }

              _this.transitionToRoute(route);
            }).catch(function (response) {
              if (response.errors && response.errors[0].detail === _this.get('intl').t('invalidCriteriaEvaluationWeight').toString()) {
                _this.get('notify').error(_this.get('intl').t('invalidCriteriaEvaluationWeight').toString());
              }

              reject();

              _this.set('blockPrevious', false);
            });
          }
        });
      },
      updateValues: function updateValues(updatedProperty, value) {
        this.updateSliderValues(updatedProperty, value);
        this.set('isDirty', true);
      },
      closeCoverLetterModal: function closeCoverLetterModal() {
        this.set('showCoverLetterQuestion', false);
        this.send('refreshRoute');
      },
      defaultCoverLetter: function defaultCoverLetter() {
        this.set('quote.uploadedCoverLetter', false);
        this.transitionToRoute('app.rfp.visual-editor');
      },
      customCoverLetter: function customCoverLetter() {
        this.set('quote.uploadedCoverLetter', true);
        this.transitionToRoute('app.rfp.custom-cover-letter');
      },
      updateTechnicalTemplate: function updateTechnicalTemplate(template) {
        var _this2 = this;

        var technicalTemplate = this.get('technicalTemplate');
        this.set('technicalTemplateDataLoaded', false);

        if (!technicalTemplate || technicalTemplate.get('id') !== template.get('id')) {
          this.set('technicalTemplate', template);
          this.store.query('base-criteria-evaluation-group', {
            include: 'base_criteria_evaluations',
            template_id: template.get('id')
          }).then(function (groupsData) {
            _this2.set('technicalTemplateDataLoaded', true);

            _this2.set('technicalTemplateGroups', groupsData);
          }).catch(function () {
            _this2.get('notify').error(_this2.get('intl').t('notifyMessages.errorOccurredReloadApp').toString());
          });
        }

        this.set('isDirty', true);
      },
      dropdownTemplateChanged: function dropdownTemplateChanged(selectedTemplate) {
        var _this3 = this;

        var templateToSet = null;
        var selectedTemplateType = selectedTemplate.get('type');
        var isTechnical = false;
        var isPitch = false;

        if (selectedTemplateType === 'technical_proposal') {
          templateToSet = this.get('technicalTemplate');
          this.set('technicalTemplateDataLoaded', false);
          isTechnical = true;
        } else if (selectedTemplateType === 'pitch') {
          templateToSet = this.get('pitchTemplate');
          this.set('pitchTemplateDataLoaded', false);
          isPitch = true;
        }

        if (!templateToSet || templateToSet.get('id') !== selectedTemplate.get('id')) {
          this.store.query('base-criteria-evaluation-group', {
            include: 'base_criteria_evaluations',
            template_id: selectedTemplate.get('id')
          }).then(function (groupsData) {
            if (isTechnical) {
              _this3.setProperties({
                technicalTemplate: selectedTemplate,
                technicalTemplateDataLoaded: true,
                technicalTemplateGroups: groupsData
              });
            } else if (isPitch) {
              _this3.setProperties({
                pitchTemplate: selectedTemplate,
                pitchTemplateDataLoaded: true,
                pitchTemplateGroups: groupsData
              });
            }
          }).catch(function () {
            _this3.get('notify').error(_this3.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
          });
        }

        this.set('isDirty', true);
      },
      editTemplate: function editTemplate(template) {
        var _this4 = this;

        this.get('quote').save().then(function () {
          /*let routerService = this.get('routerService');
          let url = routerService.urlFor('app.templates.edit', template.get('id'));
          if(typeof this.get('pitchTemplate.id') === 'undefined'){
            url += '?quoteId='+this.get('quote.id')+'&technicalTemplateId='+this.get('technicalTemplate.id');
          } else {
            url += '?quoteId='+this.get('quote.id')+'&pitchTemplateId='+this.get('pitchTemplate.id');
          }
          window.open(url);*/
          _this4.transitionToRoute('app.templates.edit', template.get('id'), {
            queryParams: {
              quoteId: _this4.get('quote.id'),
              technicalTemplateId: _this4.get('technicalTemplate.id'),
              pitchTemplateId: _this4.get('pitchTemplate.id')
            }
          });
        }).catch(function () {
          _this4.get('notify').error(_this4.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
        });
      },
      cloneTemplate: function cloneTemplate(template) {
        var _this5 = this;

        var technicalTemplateId = this.get('technicalTemplate.id');
        var pitchTemplateId = this.get('pitchTemplate.id');
        this.get('quote').save().then(function () {
          template.bulkSave(_this5.serializeData(template)).then(function (response) {
            var cloneId = response.data.template;
            var queryTechnicalTemplateId = template.get('id') === technicalTemplateId ? cloneId : technicalTemplateId;
            var queryPitchTemplateId = template.get('id') === pitchTemplateId ? cloneId : pitchTemplateId;
            /*let routerService = this.get('routerService');
            let url = routerService.urlFor('app.templates.edit', template.get('id'));
            if(typeof queryPitchTemplateId === 'undefined'){
              url += '?quoteId='+this.get('quote.id')+'&technicalTemplateId='+queryTechnicalTemplateId;
            } else {
              url += '?quoteId='+this.get('quote.id')+'&pitchTemplateId='+queryPitchTemplateId;
            }*/

            _this5.get('notify').success(_this5.get('intl').t('notifyMessages.templateClonedSuccess').toString()); //window.open(url);


            _this5.transitionToRoute('app.templates.edit', cloneId, {
              queryParams: {
                quoteId: _this5.get('quote.id'),
                technicalTemplateId: queryTechnicalTemplateId,
                pitchTemplateId: queryPitchTemplateId
              }
            }).then(function () {
              _this5.get('notify').success(_this5.get('intl').t('notifyMessages.redirectTemplateSuccess').toString());
            }).catch(function () {
              _this5.get('notify').error(_this5.get('intl').t('notifyMessages.redirectTemplateError').toString());
            });
          }).catch(function () {
            _this5.get('notify').error(_this5.get('intl').t('notifyMessages.templateClonedError').toString());
          });
        });
      },
      showDropdown: function showDropdown(dataType) {
        if (dataType === this.get('technicalType')) {
          this.set('showTechnicalTemplatesDropdown', true);
        } else if (dataType === this.get('pitchType')) {
          this.set('showPitchTemplatesDropdown', true);
        }
      },
      newTemplate: function newTemplate(templateType) {
        var _this6 = this;

        this.get('quote').save().then(function () {
          var isPitch = templateType === _this6.get('pitchType');

          var isTechnical = templateType === _this6.get('technicalType');

          _this6.store.createRecord('template', {
            title: 'New template ',
            company: _this6.get('currentUserCompany'),
            type: templateType === _this6.get('pitchType') ? templateType : 'technical_proposal'
          }).save().then(function (template) {
            var pitchTemplateId = _this6.get('pitchTemplate.id');

            var technicalTemplateId = _this6.get('technicalTemplate.id');

            if (isPitch) {
              pitchTemplateId = template.get('id');
            }

            if (isTechnical) {
              technicalTemplateId = template.get('id');
            }
            /*let routerService = this.get('routerService');
            let url = routerService.urlFor('app.templates.edit', template.get('id'));
            if(typeof pitchTemplateId === 'undefined'){
              url += '?quoteId='+this.get('quote.id')+'&technicalTemplateId='+technicalTemplateId;
            } else {
              url += '?quoteId='+this.get('quote.id')+'&pitchTemplateId='+pitchTemplateId;
            }
            window.open(url);*/


            _this6.transitionToRoute('app.templates.edit', template.get('id'), {
              queryParams: {
                quoteId: _this6.get('quote.id'),
                technicalTemplateId: technicalTemplateId,
                pitchTemplateId: pitchTemplateId
              }
            }).then(function () {
              _this6.get('notify').success(_this6.get('intl').t('notifyMessages.redirectTemplateSuccess').toString());
            }).catch(function () {
              _this6.get('notify').error(_this6.get('intl').t('notifyMessages.redirectTemplateError').toString());
            });
          });
        }).catch(function () {
          _this6.get('notify').error(_this6.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
        });
      }
    }
  });

  _exports.default = _default;
});