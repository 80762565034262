define("cb-ui/components/axa-rfp-summary", ["exports", "cb-ui/config/environment", "cb-ui/utils/download-file", "ember-local-storage"], function (_exports, _environment, _downloadFile, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    quote: null,
    requestedTimelineEntries: new Ember.A(),
    hideEvaluationCriterias: false,
    otherDocumentType: 'Other document type',
    quoteConsultants: new Ember.A(),
    localProjectGoals: new Ember.A(),
    extraConsultants: new Ember.A(),
    showTitle: true,
    showSections: true,
    isPreview: false,
    isQuoteOwner: false,
    isContactInfoVisible: false,
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    appLanguage: Ember.computed.alias('localStorage.language'),
    beautyContestCriteriaEvaluations: new Ember.A(),
    pitchQuoteCriteriaGroups: new Ember.A(),
    technicalQuoteCriteriaGroups: new Ember.A(),
    isEditAllowed: Ember.computed('quote.status', 'isQuoteOwner', function () {
      return this.get('isQuoteOwner') && this.get('quote.status') === 'draft';
    }),
    monetaryProjectGoals: Ember.computed.filterBy('localProjectGoals', 'isMonetary', true),
    nonMonetaryProjectGoals: Ember.computed.filterBy('localProjectGoals', 'isMonetary', false),
    specialistConsultants: Ember.computed.filterBy('quoteConsultants', 'consultantType.consultantTypeEn', 'Specialist'),
    diversifiedConsultants: Ember.computed.filterBy('quoteConsultants', 'consultantType.consultantTypeEn', 'Diversified Consultant'),
    generalistConsultants: Ember.computed.filterBy('quoteConsultants', 'consultantType.consultantTypeEn', 'Generalist'),
    typeNotSpecifiedConsultants: Ember.computed.filterBy('quoteConsultants', 'consultantType.consultantTypeEn', 'Not Specified')
  });

  _exports.default = _default;
});