define("cb-ui/controllers/app/process", ["exports", "ember-changeset", "ember-changeset-validations", "cb-ui/validations/quoteProcess"], function (_exports, _emberChangeset, _emberChangesetValidations, _quoteProcess) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ADMIN = 'admin';
  var USER = 'user';
  var DRAFT = 'draft';
  var REQUESTTODRAFT = 'request_to_draft';
  var REJECTED = 'rejected';

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    hasErrorsAdmins: false,
    hasErrorsOwner: false,
    hasErrorsReason: false,
    showAcceptModal: false,
    showRejectModal: false,
    statusChangeReason: "",
    fullNameMatcher: function fullNameMatcher(person, term) {
      var fullName = person.get('firstName') + ' ' + person.get('lastName');

      if (fullName.toLocaleLowerCase().match(term.toLocaleLowerCase())) {
        return 1;
      }

      return -1;
    },
    setupChangeset: function setupChangeset() {
      Ember.set(this, 'quoteChangeset', new _emberChangeset.default(this.get('quote'), (0, _emberChangesetValidations.default)(_quoteProcess.default), _quoteProcess.default));
      this.addObserver('userAdminsForQuote', this, 'adminsAddedOrRemoved');
      this.addObserver('userUsersForQuote', this, 'usersAddedOrRemoved');
    },
    adminsAddedOrRemoved: function adminsAddedOrRemoved() {
      var _this = this;

      var newArrray = [];
      this.get('companyUsers').forEach(function (item) {
        if (!_this.get('userAdminsForQuote').contains(item)) {
          newArrray.addObject(item);
        }
      });
      Ember.set(this, 'companyUsersUsers', newArrray);
    },
    usersAddedOrRemoved: function usersAddedOrRemoved() {
      var _this2 = this;

      var newArrray = [];
      this.get('companyUsers').forEach(function (item) {
        if (!_this2.get('userUsersForQuote').contains(item)) {
          newArrray.addObject(item);
        }
      });
      Ember.set(this, 'companyUsersAdmins', newArrray);
    },
    actions: {
      openAcceptModal: function openAcceptModal() {
        this.set('showAcceptModal', true);
      },
      closeAcceptModal: function closeAcceptModal() {
        this.set('showAcceptModal', false);
      },
      openRejectModal: function openRejectModal() {
        this.set('showRejectModal', true);
      },
      closeRejectModal: function closeRejectModal() {
        this.set('showRejectModal', false);
      },
      submitAction: function submitAction() {
        var _this3 = this;

        var changeset = this.get('quoteChangeset');
        var quoteRelatedPromises = [];

        if (this.get('userAdminsForQuote').length == 0) {
          Ember.$('#admins').addClass('has-error');
          this.set('hasErrorsAdmins', true);
        } else {
          Ember.$('#admins').removeClass('has-error');
          this.set('hasErrorsAdmins', false);
        }

        if (this.get('ownerForQuote') === null) {
          Ember.$('#quoteOwner').addClass('has-error');
          this.set('hasErrorsOwner', true);
        } else {
          Ember.$('#quoteOwner').removeClass('has-error');
          this.set('hasErrorsOwner', false);
        }

        if (!this.get('hasErrorsAdmins') && !this.get('hasErrorsOwner')) {
          this.get('userAdminsForQuote').forEach(function (user) {
            var record = _this3.store.createRecord('user-quote-role', {
              quote: _this3.get('quote'),
              user: user,
              role: ADMIN,
              canEvaluate: true
            });

            quoteRelatedPromises.push(record.save());
          });
          this.get('userUsersForQuote').forEach(function (user) {
            var record = _this3.store.createRecord('user-quote-role', {
              quote: _this3.get('quote'),
              user: user,
              role: USER,
              canEvaluate: true
            });

            quoteRelatedPromises.push(record.save());
          });
          Ember.RSVP.all(quoteRelatedPromises).then(function () {
            changeset.set('owner', _this3.get('ownerForQuote'));
            changeset.set('status', REQUESTTODRAFT);
            changeset.save().then(function () {
              _this3.set('showAcceptModal', false);

              _this3.set('showRejectModal', false);

              _this3.transitionToRoute('app.dashboard');
            }).catch(function (err) {
              err.errors.forEach(function (error) {
                if (error.status === "400") {
                  _this3.get('notify').error(_this3.get('intl').t('notifyMessages.noAdminsSet'));
                }
              });
            });
          }).catch(function (err) {
            err.errors.forEach(function (error) {
              if (error.status === "400") {
                _this3.get('notify').error(error.detail);
              }
            });
          });
        } else {
          this.get('notify').error(this.get('intl').t('notifyMessages.resolveExistingError'));
        }
      },
      submitRejection: function submitRejection() {
        var _this4 = this;

        var changeset = this.get('quoteChangeset');
        var reason = this.get('statusChangeReason');

        if (reason === "") {
          Ember.$('#reason').addClass('has-error');
          this.set("hasErrorsReason", true);
        } else {
          Ember.$('#reason').removeClass('has-error');
          this.set("hasErrorsReason", false);
        }

        if (!this.get('hasErrorsReason')) {
          changeset.set('status', REJECTED);
          changeset.set('statusChangeReason', reason);
          changeset.save().then(function () {
            _this4.set('showAcceptModal', false);

            _this4.set('showRejectModal', false);

            _this4.transitionToRoute('app.dashboard');
          }).catch(function (err) {
            err.errors.forEach(function (error) {
              if (error.status === "400") {
                _this4.get('notify').error(_this4.get('intl').t('notifyMessages.errorrOccurredTryAgain'));
              }
            });
          });
        }
      },
      goToSection: function goToSection(section) {
        Ember.$("html, body").animate({
          scrollTop: Ember.$('#' + section).offset().top - 100
        });
      }
    }
  });

  _exports.default = _default;
});