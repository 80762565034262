define("cb-ui/templates/components/ember-table/full-row-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vkzaGJ5I",
    "block": "{\"symbols\":[],\"statements\":[[6,\"td\"],[9,\"class\",\"is-first-column is-fixed-left ember-view\"],[9,\"style\",\"position: sticky;top:0; left: 0;width: 100px!important;\"],[7],[0,\"\\n  \"],[6,\"strong\"],[7],[1,[25,\"t\",[\"app.components.projectEvaluationInfo.groupLabel\"],null],false],[0,\" \"],[1,[20,[\"rowValue\",\"title\"]],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/ember-table/full-row-cell.hbs"
    }
  });

  _exports.default = _default;
});