define("cb-ui/templates/app/project/proposal/question-header-two", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OxIClqmZ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"m-t-md\"],[7],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"font-size-16\"],[7],[0,\"\\n        2\"],[6,\"sup\"],[7],[0,\"nd\"],[8],[0,\" \"],[1,[25,\"t\",[\"app.partials.questionHeaderOne.roundOfQuestions\"],null],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"span\"],[7],[0,\"\\n        - \"],[1,[25,\"t\",[\"app.partials.questionHeaderOne.currentStatus\"],null],false],[0,\"\\n\"],[4,\"if\",[[20,[\"quote\",\"questionsRound2IsNotOpen\"]]],null,{\"statements\":[[0,\"          \"],[6,\"span\"],[9,\"class\",\"label label-info\"],[7],[1,[25,\"t\",[\"app.partials.questionHeaderOne.notOpen\"],null],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[20,[\"quote\",\"questionsRound2IsOpen\"]]],null,{\"statements\":[[0,\"          \"],[6,\"span\"],[9,\"class\",\"label label-success\"],[7],[1,[25,\"t\",[\"app.partials.questionHeaderOne.open\"],null],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[20,[\"quote\",\"questionsRound2IsClosed\"]]],null,{\"statements\":[[0,\"          \"],[6,\"span\"],[9,\"class\",\"label label-danger\"],[7],[1,[25,\"t\",[\"app.partials.questionHeaderOne.closed\"],null],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n    \"],[6,\"span\"],[7],[0,\"\\n        - \"],[1,[25,\"t\",[\"app.partials.questionHeaderOne.questionType\"],null],false],[0,\"\\n\"],[4,\"if\",[[20,[\"quote\",\"publicQuestions\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,\"t\",[\"app.partials.questionHeaderOne.public\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"quote\",\"undisclosedQuestions\"]]],null,{\"statements\":[[0,\"          \"],[1,[25,\"t\",[\"app.partials.questionHeaderOne.nonPublic\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/app/project/proposal/question-header-two.hbs"
    }
  });

  _exports.default = _default;
});