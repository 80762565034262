define("cb-ui/templates/components/models-table/management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JxJXCQAY",
    "block": "{\"symbols\":[],\"statements\":[[2,\"<button class=\\\"btn btn-block btn-mini btn-table disabled\\\" {{action 'send' 'editUser' record}}>\"],[0,\"\\n\"],[2,\"<i class=\\\"glyphicon glyphicon-log-in\\\"></i> Edit user\"],[0,\"\\n\"],[2,\"</button>\"],[0,\"\\n\"],[2,\"TODO: change action for \"],[0,\"\\n\"],[6,\"button\"],[9,\"class\",\"btn btn-primary btn-mini btn-table\"],[3,\"action\",[[19,0,[]],\"send\",\"selectProject\",[20,[\"record\"]]]],[7],[0,\"\\n  \"],[6,\"i\"],[9,\"class\",\"glyphicon glyphicon-log-in\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"app.management.index.editAllocation\"],null],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/models-table/management.hbs"
    }
  });

  _exports.default = _default;
});