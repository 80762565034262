define("cb-ui/templates/wizard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h2kXzHm6",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"id\",\"public\"],[7],[0,\"\\n  \"],[1,[25,\"top-navbar\",null,[[\"logout\",\"changeLocalStorageLanguage\"],[\"invalidateSession\",\"changeLocalStorageLanguage\"]]],false],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",[25,\"unless\",[[25,\"eq\",[[20,[\"currentRouteName\"]],\"index\"],null],\"container\",\"container-wrapper\"],null],null],[7],[0,\"\\n      \"],[1,[18,\"outlet\"],false],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/wizard.hbs"
    }
  });

  _exports.default = _default;
});