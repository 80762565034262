define("cb-ui/templates/components/rfp-timeline-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q+XcUz1b",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"timeline-graph-menu m-b-xs pull-right\"],[7],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"btn btn-sm\"],[3,\"action\",[[19,0,[]],\"zoom\",\"in\"]],[7],[6,\"span\"],[9,\"class\",\"glyphicon glyphicon-zoom-in\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"buttons.zoomIn\"],null],false],[8],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"btn btn-sm\"],[3,\"action\",[[19,0,[]],\"zoom\",\"out\"]],[7],[6,\"span\"],[9,\"class\",\"glyphicon glyphicon-zoom-out\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"buttons.zoomOut\"],null],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"btn btn-sm\"],[3,\"action\",[[19,0,[]],\"move\",\"left\"]],[7],[6,\"span\"],[9,\"class\",\"glyphicon glyphicon-menu-left\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"buttons.moveLeft\"],null],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"btn btn-sm\"],[3,\"action\",[[19,0,[]],\"move\",\"right\"]],[7],[1,[25,\"t\",[\"buttons.moveRight\"],null],false],[0,\" \"],[6,\"span\"],[9,\"class\",\"glyphicon glyphicon-menu-right\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"clearfix\"],[7],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/rfp-timeline-graph.hbs"
    }
  });

  _exports.default = _default;
});