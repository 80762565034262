define("cb-ui/routes/wizard/consultants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(obj, transition) {
      var cbStore = this.get('cbStore');
      return Ember.RSVP.hash({
        services: cbStore.findAllWrapper('serviceStandard', 'mpttOrder'),
        industries: cbStore.findAllWrapper('industryStandard', 'mpttOrder'),
        quote: this.store.find('quote', transition.params.wizard.quote_uuid)
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      controller.set('quote', model.quote);
      var extraCompanies = new Ember.A();
      var extraNewCompanies = new Ember.A();
      var localSelectedCompanies = new Ember.A();
      this.store.query('quote-consultant', {
        quote_id: model.quote.get('id')
      }).then(function (quoteConsultants) {
        controller.set('quoteConsultants', quoteConsultants);
        model.quote.get('companies').then(function (selectedCompanies) {
          var quoteConsultantCompanyPromises = [];
          var quoteConsultantCompanies = [];
          quoteConsultants.forEach(function (consultant) {
            var companyPromise = new Ember.RSVP.Promise(function (resolve) {
              consultant.get('company').then(function (company) {
                quoteConsultantCompanies.push(company);
                resolve();
              });
            });
            quoteConsultantCompanyPromises.push(companyPromise);
          });
          Ember.RSVP.all(quoteConsultantCompanyPromises).then(function () {
            selectedCompanies.forEach(function (company) {
              if (!quoteConsultantCompanies.includes(company)) {
                if (company.get('extraConsultant')) {
                  _this.store.query('extra-company', {
                    company: company.get('id')
                  }).then(function (extraCompanyList) {
                    if (extraCompanyList.get('length')) {
                      extraNewCompanies.addObject(extraCompanyList.get('firstObject'));
                    }
                  });
                } else {
                  extraCompanies.addObject(company);
                }
              } else {
                localSelectedCompanies.addObject(company);
              }
            });
            controller.set('extraConsultants', extraCompanies);
            controller.set('extraNewConsultants', extraNewCompanies);
            controller.set('localSelectedCompanies', localSelectedCompanies);
          });
        });
      });
      controller.set('quote.currentStep', 20);
      controller.set('isShowingModal', false);
      controller.set('showError', false);
      controller.set('showAllSpecialistConsultants', false);
      controller.set('showAllDiversifiedConsultants', false);
      controller.set('showAllGeneralistConsultants', false);
      controller.set('showAddExtraConsultant', true);
      controller.set('showExtraConsultantForm', false);
    }
  });

  _exports.default = _default;
});