define("cb-ui/controllers/app/project/proposal/beauty-contest", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    proposal: null,
    quote: null,
    currentUser: null,
    userProposalRole: null,
    userIsProposalAdmin: Ember.computed.equal('userProposalRole.role', 'admin'),
    notify: Ember.inject.service('notify'),
    actions: {
      navbarTransitionToComponent: function navbarTransitionToComponent(componentId) {
        Ember.run.next(function () {
          Ember.$('html, body').animate({
            scrollTop: Ember.$('#' + componentId).offset().top - 200
          });
        });
      },
      acceptBeautyContestInvitation: function acceptBeautyContestInvitation() {
        var _this = this;

        var proposal = this.get('proposal');

        if (confirm(this.get('intl').t('confirmMessages.acceptBeautyContestInvitation').toString())) {
          proposal.set('beautyContestInvitationStatus', 'accepted');
          proposal.save().then(function (savedProposal) {
            savedProposal.reload();

            _this.get('notify').success(_this.get('intl').t('notifyMessages.beautyContestAccepted').toString());
          }).catch(function (response) {
            if (response.errors && response.errors[0].detail === _this.get('intl').t('beautyContestResponseDenied').toString()) {
              var rfpReleaseDue = (0, _moment.default)(_this.get('quote.rfpReleaseDueDate')).add(1, 'days').tz('Europe/Berlin').format('DD.MM.YYYY');
              var presentationDue = (0, _moment.default)(_this.get('quote.presentationEndDate')).tz('Europe/Berlin').format('DD.MM.YYYY');

              _this.get('notify').error(_this.get('intl').t('notifyMessages.beautyContestResponsePeriod').toString() + rfpReleaseDue + _this.get('intl').t('notifyMessages.and').toString() + presentationDue + ' (CET)');
            }

            proposal.rollbackAttributes();
          });
        }
      },
      declineBeautyContestInvitation: function declineBeautyContestInvitation() {
        var _this2 = this;

        var proposal = this.get('proposal');

        if (confirm(this.get('intl').t('confirmMessages.declineBeautyContestInvitation').toString())) {
          proposal.set('beautyContestInvitationStatus', 'declined');
          proposal.save().then(function (savedProposal) {
            savedProposal.reload();

            _this2.get('notify').success(_this2.get('intl').t('notifyMessages.beautyContestDeclined').toString());
          }).catch(function (response) {
            if (response.errors && response.errors[0].detail === _this2.get('intl').t('beautyContestResponseDenied').toString()) {
              var rfpReleaseDue = (0, _moment.default)(_this2.get('quote.rfpReleaseDueDate')).add(1, 'days').tz('Europe/Berlin').format('DD.MM.YYYY');
              var presentationDue = (0, _moment.default)(_this2.get('quote.presentationEndDate')).tz('Europe/Berlin').format('DD.MM.YYYY');

              _this2.get('notify').error(_this2.get('intl').t('notifyMessages.beautyContestResponsePeriod').toString() + rfpReleaseDue + _this2.get('intl').t('notifyMessages.and').toString() + presentationDue + ' (CET)');
            }

            proposal.rollbackAttributes();
          });
        }
      }
    }
  });

  _exports.default = _default;
});