define("cb-ui/controllers/app/rfp/consultants", ["exports", "cb-ui/mixins/consultants-controller", "cb-ui/mixins/company-studies", "cb-ui/mixins/selected-consultant", "cb-ui/config/environment"], function (_exports, _consultantsController, _companyStudies, _selectedConsultant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import CompanyServicesControllerMixin from '../../../mixins/company-services-controller';
  var _default = Ember.Controller.extend(_consultantsController.default, _companyStudies.default, _selectedConsultant.default, {
    quote: null,
    // selected company
    company: null,
    showUnsavedChangesModal: false,
    targetRoute: 'app.rfp.project-details',
    //used in partials that are common for easy wizard and rfp
    appLanguage: Ember.computed.alias('currentUser.language'),
    showError: false,
    propertyNameService: null,
    propertyNameIndustry: null,
    showAddExtraConsultant: true,
    showExtraConsultantForm: false,
    showAllSpecialistConsultants: false,
    showAllDiversifiedConsultants: false,
    showAllGeneralistConsultants: false,
    blockPrevious: false,
    companies: Ember.computed.alias('model.companies'),
    sortKey: ['-finalScore'],
    hasUnsavedChanges: Ember.computed('extraConsultants.[]', 'extraNewConsultants.[]', 'localSelectedCompanies.[]', function () {
      var localSelectedCompaniesIds = this.get('localSelectedCompanies').map(function (company) {
        return company.get('id');
      });
      var originalLocalSelectedCompaniesIds = [];

      if (this.get('originalLocalSelectedCompanies')) {
        originalLocalSelectedCompaniesIds = this.get('originalLocalSelectedCompanies').map(function (company) {
          return company.get('id');
        });
      }

      var extraConsultantsIds = this.get('extraConsultants').map(function (company) {
        return company.get('id');
      });
      var originalExtraConsultantsIds = [];

      if (this.get('originalExtraConsultants')) {
        originalExtraConsultantsIds = this.get('originalExtraConsultants').map(function (company) {
          return company.get('id');
        });
      }

      var extraNewConsultantsIds = this.get('extraNewConsultants').map(function (company) {
        return new String(company.get('id'));
      });
      var originalExtraNewConsultantsIds = [];

      if (this.get('originalExtraNewConsultants')) {
        originalExtraNewConsultantsIds = this.get('originalExtraNewConsultants').map(function (company) {
          return company.get('id');
        });
      }

      if (localSelectedCompaniesIds.sort().join(',') !== originalLocalSelectedCompaniesIds.sort().join(',') || extraConsultantsIds.sort().join(',') !== originalExtraConsultantsIds.sort().join(',') || extraNewConsultantsIds.sort().join(',') !== originalExtraNewConsultantsIds.sort().join(',')) {
        return true;
      }

      return false;
    }),
    specialistConsultants: Ember.computed.filterBy('quoteConsultants', 'consultantType', 'specialist'),
    sortedSpecialistConsultants: Ember.computed.sort('specialistConsultants', 'sortKey'),
    diversifiedConsultants: Ember.computed.filterBy('quoteConsultants', 'consultantType', 'diversified consultant'),
    sortedDiversifiedConsultants: Ember.computed.sort('diversifiedConsultants', 'sortKey'),
    generalistConsultants: Ember.computed.filterBy('quoteConsultants', 'consultantType', 'generalist'),
    sortedGeneralistConsultants: Ember.computed.sort('generalistConsultants', 'sortKey'),
    consultantModalTitle: Ember.computed('company', function () {
      return this.get('company.companyNameAlias') + ' | COMPANY PROFILE';
    }),
    generalManagementConsultants: Ember.computed.filterBy('company.individuals', 'generalManagement', true),
    globalHeadOffices: Ember.computed.filterBy('company.addresses', 'globalHeadOffice', true),
    sortServicesProperty: Ember.computed('appLanguage', function () {
      return ['service' + this.get('appLanguage')];
    }),
    sortIndustriesProperty: Ember.computed('appLanguage', function () {
      return ['industry' + this.get('appLanguage')];
    }),
    sortedCompanyServices: Ember.computed.sort('company.services', 'sortServicesProperty'),
    sortedCompanyIndustries: Ember.computed.sort('company.industries', 'sortIndustriesProperty'),
    actions: {
      openUnsavedChangesModal: function openUnsavedChangesModal() {
        this.set('showUnsavedChangesModal', true);
      },
      closeUnsavedChangesModal: function closeUnsavedChangesModal() {
        this.set('showUnsavedChangesModal', false);
      },
      close: function close() {
        this.set('isShowingModal', false);
      },
      previous: function previous() {
        if (this.get('blockPrevious')) {
          return;
        }

        this.set('targetRoute', 'app.rfp.index');

        if (this.get('hasUnsavedChanges')) {
          this.send('openUnsavedChangesModal');
        } else {
          this.transitionToRoute(this.get('targetRoute'));
        }
      },
      next: function next() {
        this.set('targetRoute', 'app.rfp.project-details');

        if (this.get('hasUnsavedChanges')) {
          this.send('openUnsavedChangesModal');
        } else {
          this.transitionToRoute(this.get('targetRoute'));
        }
      },
      saveConsultants: function saveConsultants(route) {
        var _this = this;

        this.set('blockPrevious', true);
        this.set('targetRoute', 'app.rfp.project-details');
        var promise = this.saveQuote();
        promise.then(function () {
          _this.set('blockPrevious', false);

          _this.set('showUnsavedChangesModal', false);

          if (route && route.includes(_environment.default.CANONICAL_DOMAIN_NEXT)) {
            window.location = route;
            return;
          }

          if (route) {
            _this.transitionToRoute(route);
          } else {
            _this.transitionToRoute(_this.get('targetRoute'));
          }
        });
      },
      proceed: function proceed(route) {
        this.set('showUnsavedChangesModal', false);
        var quote = this.get('quote');
        quote.rollbackAttributes();
        quote.reload();

        if (route && route.includes(_environment.default.CANONICAL_DOMAIN_NEXT)) {
          window.location = route;
          return;
        }

        this.transitionToRoute(route);
      },
      saveAndProceed: function saveAndProceed(route) {
        this.send('saveConsultants', route);
      }
    }
  });

  _exports.default = _default;
});