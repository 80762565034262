define("cb-ui/routes/new-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      if (!transition.queryParams['recoverToken']) {
        this.transitionTo('index');
      }
    },
    setupControllert: function setupControllert(controller, model) {
      this._super(controller, model);

      controller.set('newPassword', null);
      controller.set('retypePassword', null);
    }
  });

  _exports.default = _default;
});