define("cb-ui/components/em-form-submit", ["exports", "ember-rapid-forms/components/em-form-submit"], function (_exports, _emFormSubmit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import Ember from 'ember';
  var _default = _emFormSubmit.default;
  _exports.default = _default;
});