define("cb-ui/controllers/app/agent-project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    quote: null,
    proposals: null,
    activeIndividual: null,
    showIndividualInfoModal: null,
    companies: new Ember.A(),
    actions: {
      sendInvitation: function sendInvitation(proposal, individualEmail) {
        this.store.createRecord('proposal-invite', {
          proposal: proposal,
          individualEmail: individualEmail
        }).save().then(function () {
          proposal.reload();
        }).catch(function () {
          proposal.reload();
        });
      },
      removeConsultant: function removeConsultant(proposal) {
        var _this = this;

        proposal.status = 'dismissed';
        proposal.isActive = false;
        proposal.save().then(function () {
          _this.get('notify').success('Consultant removed');

          _this.get('proposals').forEach(function (p) {
            p.set('consultants', null);
          });

          _this.set('proposals', null);

          _this.send('refreshRoute');
        }).catch(function (response) {
          if (response && response.errors && response.errors.length) {
            _this.get('notify').error(response.errors[0].detail);
          }
        });
      },
      addConsultant: function addConsultant(company) {
        var quote = this.get('quote');
        var ctrl = this;
        quote.get('companies').then(function (companies) {
          companies.addObject(company);
          quote.save().then(function () {
            ctrl.get('notify').success('Consultant added');
            ctrl.set('selectedCompany', null);
            ctrl.get('proposals').forEach(function (p) {
              p.set('consultants', null);
            });
            ctrl.set('proposals', null);
            ctrl.send('refreshRoute');
          }).catch(function (response) {
            if (response && response.errors && response.errors.length) {
              ctrl.get('notify').error(response.errors[0].detail);
            }
          });
        });
      },
      showIndividualInfo: function showIndividualInfo(individual) {
        this.set('activeIndividual', individual);
        this.set('showIndividualInfoModal', true);
      },
      closeModal: function closeModal() {
        this.set('showIndividualInfoModal', false);
      },
      changeCompanyProposal: function changeCompanyProposal(proposal, oldCompany, newCompany) {
        var _this2 = this;

        this.store.createRecord('agent-new-proposal', {
          proposal: proposal,
          oldCompany: oldCompany,
          newCompany: newCompany
        }).save().then(function () {
          _this2.get('notify').success('The proposal was updated.');

          Ember.run.later(_this2, function () {
            window.location.reload();
          }, 1000);
        }).catch(function (response) {
          if (response && response.errors && response.errors.length) {
            _this2.get('notify').error(response.errors[0].detail);
          }

          window.location.reload();
        });
      },
      selectedCompanyChanged: function selectedCompanyChanged(newCompany) {
        this.set('selectedCompany', newCompany);

        if (newCompany) {
          this.set('showSaveNewCompany', true);
        } else {
          this.set('showSaveNewCompany', true);
        }
      }
    }
  });

  _exports.default = _default;
});