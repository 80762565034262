define("cb-ui/adapters/quote-consultant", ["exports", "cb-ui/config/environment", "ember-simple-auth/mixins/data-adapter-mixin", "active-model-adapter"], function (_exports, _environment, _dataAdapterMixin, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.default.extend(_dataAdapterMixin.default, {
    authorizer: 'authorizer:token',
    namespace: 'api',
    host: _environment.default.backendAPI,
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._super(modelName, id, snapshot, requestType, query);

      if (url[url.length - 1] !== '/') {
        url = url + '/';
      }

      return url;
    }
  });

  _exports.default = _default;
});