define("cb-ui/components/axa-request-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      projectLanguageChanged: function projectLanguageChanged(value) {
        this.set('changeset.projectLanguage', value);

        if (value === 'de') {
          var german = this.get('languages').filter(function (x) {
            return x.id === "2";
          })[0];
          this.set('changeset.proposalLanguage', german);
          var clientProfile = "Der AXA Konzern zählt mit Beitragseinnahmen von 12 Mrd. Euro (2022) und 8.028 Mitarbeitenden zu den größten Erstversicherern in Deutschland. Die AXA Deutschland ist Teil der AXA Gruppe, einem der weltweit führenden Versicherungsunternehmen und Vermögensmanager mit Tätigkeitsschwerpunkten in Europa, Nordamerika und dem asiatisch-pazifischen Raum.";
          this.set('changeset.clientIntroduction', clientProfile);
        }

        if (value === 'en') {
          var english = this.get('languages').filter(function (x) {
            return x.id === "1";
          })[0];
          this.set('changeset.proposalLanguage', english);
          var _clientProfile = "With premium income of 12 billion euros (2022) and 8,028 employees, the AXA Group is one of the largest primary insurers in Germany. AXA Germany is part of the AXA Group, one of the world's leading insurance companies and asset managers with operations in Europe, North America and the Asia-Pacific region.";
          this.set('changeset.clientIntroduction', _clientProfile);
        }
      },
      strategicExecutionBoardChanged: function strategicExecutionBoardChanged(evt) {
        this.set('changeset.strategicExecutionBoard', evt.target.checked);
      },
      engagementChanged: function engagementChanged(value) {
        this.set('changeset.remoteConsulting', value);
      }
    }
  });

  _exports.default = _default;
});