define("cb-ui/controllers/app/org-settings/worker-roles/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    company: null,
    currentUser: null,
    //fields for new worker role modal
    consultantRole: null,
    level: null,
    description: null,
    showNewWorkerRoleModal: null,
    modalEditInstance: null,
    companyName: Ember.computed.alias('company.companyName'),
    columns: Ember.computed(function () {
      return [{
        propertyName: 'id',
        title: 'ID'
      }, {
        propertyName: 'company.companyName',
        title: 'Company'
      }, {
        propertyName: 'level',
        title: 'Level'
      }, {
        propertyName: 'consultantRole',
        title: 'Consultant role'
      }, {
        propertyName: 'description',
        title: 'Description',
        className: 'textarea-pre-line'
      }, {
        title: 'Actions',
        component: 'models-table/worker-roles',
        className: 'models-table__actions-10'
      }];
    }),
    closeModal: function closeModal() {
      this.setProperties({
        level: null,
        consultantRole: null,
        description: null,
        modalEditInstance: null,
        showNewWorkerRoleModal: false
      });
    },
    actions: {
      showModal: function showModal() {
        this.set('showNewWorkerRoleModal', true);
      },
      closeNewWorkerModal: function closeNewWorkerModal() {
        this.closeModal();
      },
      saveNewWorkerRole: function saveNewWorkerRole() {
        var _this = this;

        var consultantRole = this.get('consultantRole');
        var level = this.get('level');
        var description = this.get('description');
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (consultantRole && consultantRole.trim() && level && level.trim() && description && description.trim()) {
            var owr = _this.get('modalEditInstance');

            if (!owr) {
              owr = _this.store.createRecord('organization-worker-role');
            }

            owr.setProperties({
              'consultantRole': consultantRole.trim(),
              'level': level.trim(),
              'description': description.trim(),
              'company': _this.get('company')
            });
            owr.save().then(function () {
              _this.get('notify').success('The consultant role ' + consultantRole.trim() + ' was created');

              _this.closeModal();

              resolve();

              _this.send('refreshRoute');
            }).catch(function () {
              reject();
            });
          } else {
            _this.get('notify').error(_this.get('intl').t('app.orgSettings.workerRoles.index.requiredFields').toString());

            reject();
          }
        });
      },
      editWorkerRole: function editWorkerRole(owr) {
        // this.transitionToRoute('app.management.quotes', owr.get('id'));
        this.setProperties({
          'consultantRole': owr.get('consultantRole'),
          'level': owr.get('level'),
          'description': owr.get('description'),
          'showNewWorkerRoleModal': true,
          'modalEditInstance': owr
        });
      },
      deleteWorkerRole: function deleteWorkerRole(owr) {
        var _this2 = this;

        // this.transitionToRoute('app.management.quotes', owr.get('id'));
        if (confirm(this.get('intl').t('app.orgSettings.workerRoles.index.confirmDeleteWorkerRole'))) {
          owr.destroyRecord().then(function () {
            _this2.get('notify').success('The entry has been deleted');

            _this2.send('refreshRoute');
          }).catch(function () {
            _this2.get('notify').error('Failed to delete entry');
          });
        }
      }
    }
  });

  _exports.default = _default;
});