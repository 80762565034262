define("cb-ui/templates/components/profile-validation-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wh9KpsYO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[20,[\"sessionContext\",\"currentUser\",\"emailValidated\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"alert alert-warning alert-dismissable m-t-xs m-b-xs p-t-xxs p-b-xxs\"],[7],[0,\"\\n        \"],[6,\"button\"],[9,\"class\",\"close\"],[3,\"action\",[[19,0,[]],\"close\"]],[7],[0,\"\\n            \"],[6,\"span\"],[9,\"aria-hidden\",\"true\"],[7],[0,\"×\"],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[1,[25,\"t\",[\"app.partials.profileValidationAlert.emailNotConfirmed\"],null],false],[0,\" \"],[4,\"link-to\",[\"app.account.index\"],null,{\"statements\":[[1,[25,\"t\",[\"app.partials.profileValidationAlert.confirm\"],null],false]],\"parameters\":[]},null],[0,\" \"],[1,[25,\"t\",[\"app.partials.profileValidationAlert.yourEmail\"],null],false],[0,\"\\n\"],[4,\"unless\",[[20,[\"sessionContext\",\"currentUser\",\"tcbValidated\"]]],null,{\"statements\":[[0,\"          \"],[6,\"div\"],[7],[0,\" \"],[1,[25,\"t\",[\"app.partials.profileValidationAlert.tcbNotValidated\"],null],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"unless\",[[20,[\"sessionContext\",\"currentUser\",\"tcbValidated\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"alert alert-warning alert-dismissable m-t-xs m-b-xs p-t-xxs p-b-xxs\"],[7],[0,\"\\n          \"],[6,\"button\"],[9,\"class\",\"close\"],[9,\"data-dismiss\",\"alert\"],[7],[0,\"\\n              \"],[6,\"span\"],[9,\"aria-hidden\",\"true\"],[7],[0,\"×\"],[8],[0,\"\\n          \"],[8],[0,\"\\n        \"],[1,[25,\"t\",[\"app.partials.profileValidationAlert.tcbNotValidated\"],null],false],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/profile-validation-alert.hbs"
    }
  });

  _exports.default = _default;
});