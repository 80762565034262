define("cb-ui/models/quote-timeline-entry", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    isCustom: true,
    name: attr(),
    slug: attr(),
    date: attr('date'),
    minDate: attr('date'),
    maxDate: attr('date'),
    quote: belongsTo('quote', {
      async: true,
      inverse: 'timelineEntries'
    }),
    label: Ember.computed('name', 'intl.locale.[]', function () {
      if (this.get('isCustom')) {
        return this.get('name');
      } else {
        return this.get('intl').t('app.components.rfpTimelineGraph.' + this.get('name'));
      }
    }),
    saveItem: function saveItem() {
      this.save();
    },
    autoSave: Ember.observer('name', 'date', function () {
      if (this.get('quote.status') === 'draft') {
        Ember.run.debounce(this, this.saveItem, 1000);
      }
    })
  });

  _exports.default = _default;
});