define("cb-ui/components/ember-table/company-info-pitch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service('notify'),
    actions: {
      reinitiateProposalEvaluations: function reinitiateProposalEvaluations(proposal) {
        var _this = this;

        if (confirm(this.get('intl').t('confirmMessages.reinitiateProposalEvaluations'))) {
          proposal.reinitiatePitchProposalEvaluations().then(function () {
            _this.get('notify').success(_this.get('intl').t('notifyMessages.reinitiateProposalEvaluations').toString());

            window.location.reload();
          }).catch(function () {
            _this.get('notify').error(_this.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
          });
        }
      }
    }
  });

  _exports.default = _default;
});