define("cb-ui/templates/components/ember-table/evaluation-results-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uq+QKWvr",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"user-proposal-evaluation-result\",null,[[\"criteria\",\"user\",\"proposal\",\"proposalsEvaluations\"],[[20,[\"cellValue\",\"criteria\"]],[20,[\"evaluator\"]],[20,[\"cellValue\",\"proposal\"]],[20,[\"proposalsEvaluations\"]]]]],false],[0,\"\\n\"],[1,[25,\"proposal-evaluation-criteria-comment\",null,[[\"criteriaEvaluation\",\"user\",\"proposalsEvaluations\",\"proposal\"],[[20,[\"cellValue\",\"criteria\"]],[20,[\"evaluator\"]],[20,[\"proposalsEvaluations\"]],[20,[\"cellValue\",\"proposal\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/ember-table/evaluation-results-info.hbs"
    }
  });

  _exports.default = _default;
});