define("cb-ui/helpers/cet-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    var date = params[0];
    var dateFormat = 'DD.MM.YYYY';

    if (params[1]) {
      dateFormat = params[1];
    }

    var showNextDay = params[2];

    if (date) {
      if (showNextDay) {
        return (0, _moment.default)(date).tz('Europe/Berlin').add(1, 'days').format(dateFormat);
      }

      return (0, _moment.default)(date).tz('Europe/Berlin').format(dateFormat);
    } else {
      return (0, _moment.default)().tz('Europe/Berlin').format(dateFormat);
    }
  });

  _exports.default = _default;
});