define("cb-ui/controllers/wizard-language", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['industry', 'service', 'language'],
    industry: null,
    service: null,
    language: null,
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    intl: Ember.inject.service(),
    //default projectLanguage is English
    projectLanguage: null,
    checkQueryParams: function checkQueryParams() {
      var language = this.get('language');

      if (language && this.get('industry') && this.get('service')) {
        this.set('localStorage.language', language);
        this.get('intl').setLocale(language);
        this.set('projectLanguage', language);
        this.send('next');
      } else {
        this.set('projectLanguage', 'en');
      }
    },
    actions: {
      next: function next(callback) {
        var _this = this;

        // 45 represents the ID for EURO currency which is the default
        var promise = this.store.find('currency', 45).then(function (currency) {
          var quote = _this.store.createRecord('quote', {
            currency: currency,
            status: 'draft',
            projectName: '',
            companyName: '',
            ownerName: '',
            personSalutation: '',
            personLastName: '',
            personFirstName: '',
            personRole: '',
            projectManagerFunction: '',
            steeringCommitteeHead: '',
            clientIntroduction: '',
            documents: [],
            projectLanguage: _this.get('projectLanguage')
          });

          _this.set('isLoading', true);

          return quote.save().then(function (quote) {
            _this.transitionToRoute('wizard.index', quote.get('uuid'), {
              queryParams: {
                service: _this.get('service'),
                industry: _this.get('industry')
              }
            });
          });
        });

        if (callback) {
          callback(promise);
        } else {
          return promise;
        }
      },
      cancel: function cancel() {
        window.location = 'https://apadua.com';
      }
    }
  });

  _exports.default = _default;
});