define("cb-ui/helpers/number-abbr", ["exports", "ember-cli-number-abbr/helpers/number-abbr"], function (_exports, _numberAbbr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _numberAbbr.default;
    }
  });
  Object.defineProperty(_exports, "numberAbbr", {
    enumerable: true,
    get: function get() {
      return _numberAbbr.numberAbbr;
    }
  });
});