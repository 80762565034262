define("cb-ui/controllers/app/project/client/questions", ["exports", "cb-ui/utils/download-file", "cb-ui/config/environment"], function (_exports, _downloadFile, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    quote: null,
    questions: null,
    sortedQuestions: Ember.computed.sort('questions', 'sortProperties'),
    sortProperties: ['createdAt:desc'],
    userIsQuoteAdmin: Ember.computed.equal('userQuoteRole.role', 'admin'),
    actions: {
      exportQuestions: function exportQuestions() {
        var jwt = this.get('session.data.authenticated.token');
        var url = _environment.default.backendAPI + '/api/export_questions/' + this.get('quote.id').toString() + '/?auth_token=' + jwt;
        (0, _downloadFile.default)(url);
      },
      addAnswer: function addAnswer(question) {
        var _this = this;

        if (confirm(this.get('intl').t('confirmMessages.questionAnswer').toString())) {
          if (question.get('answer') && question.get('answer').trim()) {
            question.save().then(function (savedQuestion) {
              savedQuestion.reload();

              _this.get('notify').success(_this.get('intl').t('notifyMessages.answerSubmitted').toString());
            }, function () {
              _this.get('notify').error(_this.get('intl').t('notifyMessages.questionAnswerError').toString());
            });
          } else {
            this.get('notify').error(this.get('intl').t('notifyMessages.provideAnswer').toString());
          }
        }
      }
    }
  });

  _exports.default = _default;
});