define("cb-ui/routes/app/consultants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var cbStore = this.get('cbStore');
      return Ember.RSVP.hash({
        companies: cbStore.queryWrapper('company', 'companyName', {
          company_type: 'consultant'
        }),
        services: cbStore.findAllWrapper('serviceStandard', 'mpttOrder'),
        industries: cbStore.findAllWrapper('industryStandard', 'mpttOrder'),
        currentUser: this.get('sessionContext.currentUser')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('companies', model.companies);
      controller.set('showConsultantModal', false);
      controller.set('currentUser', model.currentUser);
    }
  });

  _exports.default = _default;
});