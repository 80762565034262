define("cb-ui/routes/app/dashboard/agent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {},
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('quotes', null);
      controller.setupView();
    }
  });

  _exports.default = _default;
});