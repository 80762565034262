define("cb-ui/templates/app/management/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HzIOjJWQ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"col-md-offset-3 col-md-9 m-b-sm m-t-sm\"],[7],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"col-md-12\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ibox white-bg\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ibox-title p-t-xs\"],[7],[0,\"\\n        \"],[6,\"div\"],[7],[0,\"\\n          \"],[6,\"h5\"],[9,\"class\",\"projects-title\"],[7],[1,[25,\"t\",[\"app.management.index.companyUsers\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"m-l-md pull-left\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"currentUser\",\"isCompanyAdmin\"]]],null,{\"statements\":[[0,\"            \"],[6,\"button\"],[9,\"class\",\"btn btn-primary btn-sm \"],[3,\"action\",[[19,0,[]],\"showMemberModal\"]],[7],[0,\" \"],[2,\"add action \"],[0,\"\\n              \"],[1,[25,\"fa-icon\",[\"plus\"],null],false],[0,\" \"],[1,[25,\"t\",[\"app.management.index.addMember\"],null],false],[0,\"\\n            \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[8],[0,\"\\n\\n      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n        \"],[1,[25,\"projects-table\",null,[[\"items\",\"columns\",\"selectProject\"],[[20,[\"companyUsers\"]],[20,[\"columns\"]],\"editQuotes\"]]],false],[0,\"\\n\"],[0,\"      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\\n\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"showNewMemberModal\"]]],null,{\"statements\":[[0,\"  \"],[12,\"new-user-account-with-company\",[]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "cb-ui/templates/app/management/index.hbs"
    }
  });

  _exports.default = _default;
});