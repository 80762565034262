define("cb-ui/models/quote-lite", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    status: attr('string'),
    ownerName: attr('string'),
    projectName: attr('string'),
    companies: attr(),
    personFirstName: attr('string'),
    personLastName: attr('string'),
    publishedAt: attr('date'),
    projectAwardDate: attr('date'),
    intID: Ember.computed('id', function () {
      return parseInt(this.get('id'), 10);
    }),
    statusAlias: Ember.computed('id', 'intl.locale.[]', 'status', function () {
      var projectStatus = this.get('status');
      return this.get('intl').t('projectStatuses.' + projectStatus).toString();
    }),
    consultantsNames: Ember.computed('companies', function () {
      //let values = '';
      var values = [];
      this.get('companies').forEach(function (element) {
        //values += element + ', ';
        values.push(element);
      }); //values = values.substring(0, values.length - 2);

      return {
        names: values
      };
    })
  });

  _exports.default = _default;
});