define("cb-ui/controllers/app/dashboard/consultant", ["exports", "ember-local-storage", "ember-ajax", "cb-ui/config/environment"], function (_exports, _emberLocalStorage, _emberAjax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var radioOptions = {
    paymentAccepted: 'paymentAccepted',
    paymentDeclined: 'paymentDeclined'
  };

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    payment: null,
    proposal: null,
    quoteId: null,
    showNDA: false,
    showPreviewModal: false,
    isSendingRequest: false,
    showAgentQuestionModal: null,
    termsAndConditionsText: null,
    radioButtonOptions: radioOptions,
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    appLanguage: Ember.computed.alias('localStorage.language'),
    invitedToAnyRfp: Ember.computed('proposals', function () {
      if (this.get('proposals.length') == 0) {
        return false;
      }

      return true;
    }),
    columns: Ember.computed('intl.locale.[]', function () {
      return [{
        propertyName: 'projectId',
        title: 'Project ID',
        classNames: 'table-id',
        sortPrecedence: 0,
        sortDirection: 'desc'
      }, {
        propertyName: 'projectName',
        title: this.get('intl').t("app.dashboardConsultant.projects.projectName").toString()
      }, {
        propertyName: 'companyName',
        title: this.get('intl').t("app.dashboardConsultant.projects.companyName").toString()
      }, {
        propertyName: 'ownerName',
        title: this.get('intl').t("app.dashboardConsultant.projects.projectManager").toString()
      }, {
        propertyName: 'statusAlias',
        title: this.get('intl').t("app.dashboardConsultant.projects.projectStatus").toString(),
        filterWithSelect: true
      }, {
        propertyName: 'industryTitle',
        title: this.get('intl').t("app.dashboardConsultant.projects.industry").toString()
      }, {
        propertyName: 'serviceTitle',
        title: this.get('intl').t("app.dashboardConsultant.projects.businessArea").toString()
      }, {
        title: this.get('intl').t("app.dashboardConsultant.projects.actions").toString(),
        component: 'models-table/preview-response-project'
      }];
    }),
    showConfirmationSelectionButton: Ember.computed('payment', function () {
      return this.get('payment') === 'paymentAccepted';
    }),
    setDefaultValues: function setDefaultValues() {
      this.set('proposal', null);
      this.set('payment', null);
      this.set('showNDA', null);
      this.set('showPreviewModal', null);
      this.set('showFullTnCs', false);
      this.set('cancelPartiticipationModal', false);
    },
    actions: {
      gotoRatecards: function gotoRatecards() {
        window.location = _environment.default.ratecardsURL + '/';
      },
      selectProject: function selectProject(record) {
        var _this = this;

        this.setDefaultValues();

        if (record.get('status') === 'invited') {
          this.set('proposal', record);
          this.set('quoteId', record.get('quote.id')); // load terms and conds
          // ajax call
          // then de la el setezi valoare intro variabila pe controller

          if (!this.get('termsAndConditionsText')) {
            this.get('ajax').request(_environment.default.TERMS_AND_CONDITIONS_URL).then(function (data) {
              _this.set('termsAndConditionsText', data['text']);
            });
          }

          this.set('showNDA', true);
        } else if (record.get('isIncludedInEvaluation') || record.get('isAccepted') || record.get('status') == 'on hold') {
          this.transitionToRoute('app.project.proposal.summary', record.get('quote.id'), record.get('id'));
        } else if (record.get('status') === 'declined') {
          this.get('notify').error(this.get('intl').t('notifyMessages.participationDeclined').toString());
        }
      },
      showPreviewProject: function showPreviewProject(record) {
        this.set('proposal', record);
        this.set('showPreviewModal', true);
      },
      confirmSelection: function confirmSelection() {
        var _this2 = this;

        if (this.get('payment') === 'paymentAccepted') {
          var proposal = this.get('proposal');
          proposal.set('status', 'accepted');
          proposal.save().then(function () {
            _this2.transitionToRoute('app.project.proposal.summary', _this2.get('quoteId'), _this2.get('proposal.id'));

            _this2.setDefaultValues();
          });
        } else {
          this.setDefaultValues();
        }
      },
      closeNDA: function closeNDA() {
        this.setDefaultValues();
      },
      turnOffCancelParticipationModal: function turnOffCancelParticipationModal() {
        this.set('cancelPartiticipationModal', false);
      },
      turnOnCancelParticipationModal: function turnOnCancelParticipationModal() {
        this.set('cancelPartiticipationModal', true);
      },
      sendCancelParticipationReason: function sendCancelParticipationReason() {
        var _this3 = this;

        if (this.get('isSendingRequest')) {
          return;
        }

        this.set('isSendingRequest', true);

        if (this.get('proposal').get('cancelReason') && !Ember.isEmpty($.trim(this.get('proposal').get('cancelReason')))) {
          var proposal = this.get('proposal');

          if (proposal.get('hasDirtyAttributes') && proposal.changedAttributes()['cancelReason'] !== undefined) {
            proposal.set('status', 'declined');
            proposal.save().then(function () {
              _this3.set('cancelPartiticipationModal', false);

              _this3.setDefaultValues();

              _this3.set('isSendingRequest', false);
            }).catch(function () {
              _this3.set('isSendingRequest', false);
            });
          } else {
            this.set('isSendingRequest', false);
          }
        } else {
          this.set('isSendingRequest', false);
          this.get('notify').error(this.get('intl').t('app.modals.cancelParticipation.disabledButtonErrorMessage'));
        }
      },
      expandTnC: function expandTnC() {
        this.set('showFullTnCs', true);
      }
    }
  });

  _exports.default = _default;
});