define("cb-ui/routes/app/rfp/project-details", ["exports", "ember-local-storage", "cb-ui/mixins/scrollbar-reset", "cb-ui/utils/download-file"], function (_exports, _emberLocalStorage, _scrollbarReset, _downloadFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollbarReset.default, {
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    showConfirm: true,
    model: function model() {
      var _this = this;

      var cbStore = this.get('cbStore');
      return new Ember.RSVP.hash({
        languages: cbStore.findAllWrapper('language', 'intId'),
        projectContexts: cbStore.findAllWrapper('projectContext', 'contextEn'),
        projectPhases: cbStore.findAllWrapper('projectPhase', 'id'),
        projectRoles: cbStore.findAllWrapper('projectRole', 'roleEn'),
        currencies: this.store.query('currency', {
          only_project_currency: true
        }),
        salutations: cbStore.findAllWrapper('salutation', 'salutationEn'),
        academicTitles: cbStore.findAllWrapper('academic-title', 'titleEn'),
        quoteDocumentTypes: cbStore.findAllWrapper('quoteDocumentType', 'id'),
        businessTypes: cbStore.findAllWrapper('businessType', 'businessTypeEn'),
        //beautyItems: this.store.query('beautyItem', {
        //  only_default: true
        //}),
        quote: this.modelFor('app.rfp'),
        currentUser: this.get('sessionContext.currentUser'),
        countries: cbStore.findAllWrapper('country', 'commonNameEn')
      }).then(function (model) {
        return new Ember.RSVP.hash({
          languages: model.languages,
          projectContexts: model.projectContexts,
          projectPhases: model.projectPhases,
          projectRoles: model.projectRoles,
          currencies: model.currencies,
          salutations: model.salutations,
          academicTitles: model.academicTitles,
          quoteDocumentTypes: model.quoteDocumentTypes,
          businessTypes: model.businessTypes,
          quote: model.quote,
          currentUser: model.currentUser,
          countries: model.countries,
          contactInformations: _this.store.query('quote-contact', {
            quote_id: model.quote.get('id')
          })
        });
      });
    },
    addTimelineEntriesForQuoteDates: function addTimelineEntriesForQuoteDates(timelineEntries, quote) {
      var standardEntries = quote.getStandardTimelineEntries();
      standardEntries.forEach(function (entry) {
        timelineEntries.addObject(entry);
      });
    },
    setupController: function setupController(controller, model) {
      var _this2 = this;

      controller.set('quoteIsLoaded', false);
      controller.set('contactInformations', model.contactInformations);

      this._super(controller, model);

      var quote = model.quote;
      var self = this; //let quote = this.modelFor('app.rfp');
      //let beautyItems = new A();
      //
      //model.beautyItems.forEach(item => {
      //  beautyItems.addObject({
      //    item: item,
      //    checked: false
      //  });
      //});

      var dataIsLoadFromLocalStorage = false;
      var quoteDocuments = new Ember.A();
      var quoteTimelineEntries = new Ember.A();
      var localQuoteProjectGoals = new Ember.A();
      var localSecondaryLanguages = new Ember.A();
      var localStorage = this.get('localStorage');
      var lsQuoteDocumentsPromises = [];
      var lsQuoteProjectGoalsPromises = [];
      var lsQuoteTimelineEntriesPromises = [];
      var quoteDocumentsPromise = null;
      var quoteTimelineEntriesPromise = null;

      if (false && localStorage.get('quote') && localStorage.get('quote.data') && localStorage.get('quote.data.id') === quote.get('id')) {
        var lsQuote = this.store.normalize('quote', localStorage.get('quote').data); //let lsBeautyItems = localStorage.get('selectedBeautyItems');
        //const selectedBeautyItems = lsBeautyItems.map(beautyItem => {
        //  if (beautyItem.data.id) {
        //    return this.store.push(this.store.normalize('beautyItem', beautyItem.data));
        //  } else {
        //    return this.store.createRecord('beautyItem', beautyItem.data.attributes);
        //  }
        //});

        var lsQuoteDocuments = localStorage.get('quoteDocuments');
        var lsQuoteProjectGoals = localStorage.get('quoteProjectGoals');

        if (lsQuote.data && lsQuote.data.attributes.updatedAt && lsQuote.data.attributes.updatedAt.getTime() === quote.get('updatedAt').getTime()) {
          dataIsLoadFromLocalStorage = true; //TODO: check if this is correct for current ember version

          quote = this.store.push(lsQuote); //beautyItems = this.localBeautyItemsList(selectedBeautyItems, beautyItems);

          lsQuoteDocuments.forEach(function (id) {
            var qdPromise = new Ember.RSVP.Promise(function (resolve) {
              self.store.find('quote-document', id).then(function (currentFile) {
                quoteDocuments.addObject(currentFile);
                resolve();
              });
            });
            lsQuoteDocumentsPromises.push(qdPromise);
          });
          lsQuoteProjectGoals.forEach(function (item) {
            var qpPromise = new Ember.RSVP.Promise(function (resolve) {
              self.store.find('quote-project-goal', item.id).then(function (quoteProjectGoal) {
                quoteProjectGoal.set('description', item.description);
                localQuoteProjectGoals.addObject(quoteProjectGoal);
                resolve();
              });
            });
            lsQuoteProjectGoalsPromises.push(qpPromise);
          });
        } else {
          //  quote.get('beautyItems').then(quoteBeautyItems => {
          //    beautyItems = this.localBeautyItemsList(quoteBeautyItems, beautyItems);
          //  });
          quoteDocumentsPromise = new Ember.RSVP.Promise(function (resolve) {
            quote.get('documents').then(function (documents) {
              documents.forEach(function (document) {
                quoteDocuments.addObject(document);
              });
              resolve();
            });
          });
        }
      } else {
        //  quote.get('beautyItems').then(quoteBeautyItems => {
        //    beautyItems = this.localBeautyItemsList(quoteBeautyItems, beautyItems);
        //  });
        quoteDocumentsPromise = new Ember.RSVP.Promise(function (resolve) {
          quote.get('documents').then(function (documents) {
            documents.forEach(function (document) {
              quoteDocuments.addObject(document);
            });
            resolve();
          });
        });
        quoteTimelineEntriesPromise = new Ember.RSVP.Promise(function (resolve) {
          quote.get('timelineEntries').then(function (timelineEntries) {
            timelineEntries.forEach(function (timelineEntry) {
              timelineEntry.set('isCustom', true);
              quoteTimelineEntries.addObject(timelineEntry);
            });
            resolve();
          });
        });
      }

      controller.set('quote', quote);

      if (lsQuoteDocumentsPromises) {
        Ember.RSVP.all(lsQuoteDocumentsPromises).then(function () {
          controller.set('localQuoteDocuments', quoteDocuments);
        });
      } else {
        quoteDocumentsPromise.then(function () {
          controller.set('localQuoteDocuments', quoteDocuments);
        });
      } // TODO add entries rfp release / proposal due


      if (lsQuoteTimelineEntriesPromises) {
        Ember.RSVP.all(lsQuoteTimelineEntriesPromises).then(function () {
          _this2.addTimelineEntriesForQuoteDates(quoteTimelineEntries, quote);

          controller.set('localQuoteTimelineEntries', quoteTimelineEntries);
        });
      } else {
        quoteTimelineEntriesPromise.then(function () {
          _this2.addTimelineEntriesForQuoteDates(quoteTimelineEntries, quote);

          controller.set('localQuoteTimelineEntries', quoteTimelineEntries);
        });
      }

      if (lsQuoteProjectGoalsPromises) {
        Ember.RSVP.all(lsQuoteProjectGoalsPromises).then(function () {
          controller.set('localQuoteProjectGoals', localQuoteProjectGoals);
        });
      } //controller.set('beautyItems', beautyItems);


      if (quote.get('timeMaterial')) {
        controller.set('financialProposalRenumeration', 'timeMaterial');
      } else if (quote.get('fixedFee')) {
        controller.set('financialProposalRenumeration', 'fixedFee');
      } else if (quote.get('successBased')) {
        controller.set('financialProposalRenumeration', 'successBased');
      } else if (quote.get('hybridModel')) {
        controller.set('financialProposalRenumeration', 'hybridModel');
      }

      controller.set('pitchPresentationGroup', quote.get('pitchSession') ? 1 : 0);

      if (quote.get('publicQuestions')) {
        controller.set('disclosureOfQuestions', 'publicQuestions');
      } else if (quote.get('undisclosedQuestions')) {
        controller.set('disclosureOfQuestions', 'undisclosedQuestions');
      }

      if (quote.get('personalContactInformation')) {
        controller.set('contactInformation', 'personalContactInformation');
      } else if (quote.get('noPersonalContactInformation')) {
        controller.set('contactInformation', 'noPersonalContactInformation');
      }

      controller.set('closedEnvelope', quote.get('closedEnvelope'));

      if (quote.get('useDefaultCompany')) {
        controller.set('companyType', 'default');
      } else {
        controller.set('companyType', 'affiliate');
      } //if (quote.get('meAsPersonContact')) {
      //  controller.set('personalInformation', 'meAsPersonContact');
      //} else if (quote.get('anotherAsPersonContact')) {
      //  controller.set('personalInformation', 'anotherAsPersonContact');
      //}


      controller.set('deadlineTime', quote.get('deadlineTime')); //controller.set('projectContext', quote.get('projectContext.contextEn'));
      //controller.set('projectRole', null);
      //if (quote.get('extraProjectRole')) {
      //  controller.set('projectRole', controller.get('otherProjectRole'));
      //}
      //quote.get('projectRole').then(projectRole=> {
      //  if (projectRole) {
      //    controller.set('projectRole', projectRole.get('roleEn'));
      //  }
      //});

      var projectPhases = model.projectPhases;
      var localProjectPhasesItems = new Ember.A();
      var quoteProjectPhasesPromise = new Ember.RSVP.Promise(function (resolve) {
        if (dataIsLoadFromLocalStorage) {
          var lsSelectedProjectPhases = localStorage.get('quoteProjectPhases');
          projectPhases.forEach(function (projectPhase) {
            localProjectPhasesItems.addObject({
              projectPhase: projectPhase,
              isChecked: lsSelectedProjectPhases.includes(projectPhase.get('id')) ? true : false
            });
          });
          resolve();
        } else {
          quote.get('projectPhases').then(function (quoteProjectPhases) {
            projectPhases.forEach(function (projectPhase) {
              localProjectPhasesItems.addObject({
                projectPhase: projectPhase,
                isChecked: quoteProjectPhases.includes(projectPhase) ? true : false
              });
            });
            resolve();
          });
        }
      });
      var quoteSecondaryLanguagesPromise = new Ember.RSVP.Promise(function (resolve) {
        if (dataIsLoadFromLocalStorage) {
          var lsSecondaryLanguages = localStorage.get('quoteSecondaryLanguages');
          model.languages.forEach(function (language) {
            if (parseInt(language.get('id')) in lsSecondaryLanguages) {
              localSecondaryLanguages.addObject(language);
            }
          });
          controller.set('localSecondaryLanguages', localSecondaryLanguages);
          resolve();
        } else {
          var localLanguages = new Ember.A();
          quote.get('secondaryLanguages').then(function (secondaryLanguages) {
            secondaryLanguages.forEach(function (language) {
              localLanguages.addObject(language);
            });
            controller.set('localSecondaryLanguages', localLanguages);
            resolve();
          });
        }
      });
      var user = model.currentUser; // if (!quote.get('companyName') && quote.get('useDefaultCompany')) {
      //   quote.set('companyName', user.get('companyName'));
      // }

      controller.set('userCompanyName', user.get('companyName'));

      if (!quote.get('ownerName')) {
        quote.set('ownerName', user.get('fullName'));
      }

      if (model.quoteDocumentTypes && model.quoteDocumentTypes.length) {
        controller.set('uploadedFileType', model.quoteDocumentTypes.get('firstObject'));
      } else {
        controller.set('uploadedFileType', null);
      } //if (!quote.get('personRole')) {
      //  if (quote.get('extraProjectRole')) {
      //    quote.set('personRole', quote.get('extraProjectRole'));
      //  } else {
      //    quote.get('projectRole').then(projectRole => {
      //      if (projectRole) {
      //        quote.set('personRole', projectRole.get('roleEn'));
      //      }
      //    });
      //  }
      //}


      quoteProjectPhasesPromise.then(function () {
        controller.set('localProjectPhasesItems', localProjectPhasesItems);
      });
      controller.set('quote.currentStep', 30);
      Ember.RSVP.all([quoteDocumentsPromise, quoteProjectPhasesPromise, quoteSecondaryLanguagesPromise]).then(function () {
        // RSVP.all([quoteDocumentsPromise, quoteProjectGoalsPromise]).then(()=> {
        controller.set('allowSaveToLocalStorage', true);
        controller.saveToLocalStorage();
      });
      this.set('showConfirm', true);
      controller.set('currentUser', model.currentUser);
      controller.set('quoteIsLoaded', true);
      controller.set('changesetList', []);
      controller.set('removedContactInformations', []);
      controller.setupChangesets();
    },
    //localBeautyItemsList(quoteBeautyItems, beautyItems){
    //  if (quoteBeautyItems) {
    //    const quoteDefaultBeautyItems = quoteBeautyItems.filterBy('default', true);
    //    const quoteExtraBeautyItems = quoteBeautyItems.filterBy('default', false);
    //    beautyItems.forEach(beautyItem=> {
    //      if (quoteDefaultBeautyItems.includes(beautyItem.item)) {
    //        beautyItem.checked = true;
    //      }
    //    });
    //    quoteExtraBeautyItems.forEach(item => {
    //      beautyItems.addObject({
    //        item: item,
    //        checked: true,
    //      });
    //    });
    //  }
    //  return beautyItems;
    //},
    actions: {
      setShowConfirm: function setShowConfirm(newValue) {
        this.set('showConfirm', newValue);
      },
      newDocumentToken: function newDocumentToken(id) {
        this.store.findRecord('quote-document', id).then(function (res) {
          (0, _downloadFile.default)(res.data.file, res.get('fileName'));
        });
      }
    }
  });

  _exports.default = _default;
});