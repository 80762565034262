define("cb-ui/components/resize-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    didInsertElement: function didInsertElement() {
      var self = this;
      Ember.run.next(function () {
        Ember.$(window).on('resize.resizeWrapper', function () {
          self.resizeEvent();
        });
        self.resizeEvent();
      });
      Ember.run.later(function () {
        self.resizeEvent();
      }, 1000);
    },
    resizeEvent: function resizeEvent() {
      this.sendAction('resizeTriggered', Ember.$(window).width() < 985);
    },
    willDestroyElement: function willDestroyElement() {
      this.off('resize.resizeWrapper');
    }
  });

  _exports.default = _default;
});