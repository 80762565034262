define("cb-ui/templates/components/ember-table/company-info-technical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1ZmC2ZpV",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"style\",\"height:32px; width:32px; display:inline-block\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"proposal\",\"company\",\"logo\"]]],null,{\"statements\":[[0,\"    \"],[6,\"img\"],[10,\"src\",[26,[[20,[\"proposal\",\"company\",\"logo\"]]]]],[9,\"alt\",\"consultant logo\"],[9,\"height\",\"32\"],[9,\"width\",\"32\"],[9,\"class\",\"m-r-xs\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"proposal\",\"company\",\"companyNameAlias\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[7],[1,[20,[\"proposal\",\"company\",\"companyNameAlias\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[7],[1,[20,[\"proposal\",\"company\",\"companyName\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[20,[\"proposal\",\"isSubmitted\"]]],null,{\"statements\":[[0,\"    \"],[6,\"button\"],[9,\"class\",\"btn btn-xs btn-primary m-t\"],[3,\"action\",[[19,0,[]],\"reinitiateProposalEvaluations\",[20,[\"proposal\"]]]],[7],[0,\"\\n        \"],[1,[25,\"t\",[\"buttons.reinitiateProposalEvaluations\"],null],false],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/ember-table/company-info-technical.hbs"
    }
  });

  _exports.default = _default;
});