define("cb-ui/routes/landing", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    actions: {
      lastQuote: function lastQuote() {
        this.transitionTo('wizard.index', this.get('controller.model.id'));
      },
      newQuote: function newQuote() {
        var _this = this;

        var quote = this.store.createRecord('quote');
        quote.save().then(function (quote) {
          _this.transitionTo('wizard.index', quote.id);
        });
      }
    },
    model: function model(params, transition) {
      var _this2 = this;

      return this.store.findAll('quote').then(function (quotes) {
        var unpublishedQuotes = quotes.filterBy('published', false);

        if (unpublishedQuotes.get('length') === 0) {
          var quote = _this2.store.createRecord('quote');

          return quote.save().then(function (quote) {
            transition.abort();

            _this2.transitionTo('wizard.index', quote.id);
          });
        } else {
          return unpublishedQuotes.get('content')[0];
        }
      });
    }
  });

  _exports.default = _default;
});