define("cb-ui/controllers/login", ["exports", "ember-local-storage", "cb-ui/config/environment"], function (_exports, _emberLocalStorage, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    parentController: Ember.inject.controller('application'),
    classNames: ["application-index"],
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    notify: Ember.inject.service(),
    username: null,
    password: null,
    init: function init() {
      window.location.assign("".concat(_environment.default.CANONICAL_DOMAIN_NEXT, "/login/"));
      $("#progressBar").remove();
    },
    actions: {
      login: function login() {
        var _this = this;

        var loginData = this.getProperties('email', 'password');
        this.get('session').authenticate('authenticator:token', loginData).then(function () {
          _this.get('localStorage').set('userEmail', null);
        }, function () {
          _this.get('notify').error(_this.get('intl').t('notifyMessages.userPasswordIncorrect').toString());
        }).catch(function () {
          alert(_this.get('intl').t('notifyMessages.userPasswordIncorrect').toString());
        });
      }
    }
  });

  _exports.default = _default;
});