define("cb-ui/controllers/new-password", ["exports", "cb-ui/config/environment", "ember-ajax/errors"], function (_exports, _environment, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['recoverToken'],
    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),
    recoverToken: null,
    password: null,
    retypePassword: null,
    actions: {
      changePassword: function changePassword() {
        var _this = this;

        var recoverToken = this.get('recoverToken');
        var ajax = this.get('ajax');

        if (!recoverToken) {
          this.get('notify').error(this.get('intl').t('recoverPassword.info').toString());
        } else {
          var newPassword = this.get('password');
          var retypePassword = this.get('retypePassword');

          if (newPassword === retypePassword) {
            ajax.post(_environment.default.backendAPI + '/api/change-password/', {
              data: {
                token: recoverToken,
                new_password: newPassword,
                retype_password: retypePassword
              }
            }).then(function () {
              _this.set('newPassword', null);

              _this.set('retypePassword', null);

              _this.transitionToRoute('login');

              Ember.run.next(_this, function () {
                _this.get('notify').success(_this.get('intl').t('notifyMessages.passwordChanged').toString());
              });
            }).catch(function (response) {
              if (response instanceof _errors.ForbiddenError) {
                _this.get('notify').error(_this.get('intl').t('notifyMessages.invalidToken').toString());
              } else {
                _this.get('notify').error(_this.get('intl').t('notifyMessages.invalidNewPassword').toString());
              }
            });
          } else {
            this.get('notify').error(this.get('intl').t('notifyMessages.passwordNotMatching').toString());
          }
        }
      }
    }
  });

  _exports.default = _default;
});