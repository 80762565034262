define("cb-ui/models/user", ["exports", "ember-data", "ember-api-actions"], function (_exports, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _DS$Model$extend;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = _emberData.default.Model.extend((_DS$Model$extend = {
    email: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    password: attr('string'),
    language: attr('string', {
      defaultValue: 'en'
    }),
    trackingConsent: attr('boolean', {
      defaultValue: null
    }),
    currentPassword: attr('string'),
    retypePassword: attr('string'),
    jobTitle: attr('string'),
    phone: attr('string'),
    mobilePhone: attr('string'),
    fax: attr('string'),
    isClient: attr('boolean', {
      defaultValue: true
    }),
    isConsultant: attr('boolean', {
      defaultValue: false
    }),
    isExternalEvaluator: attr('boolean', {
      defaultValue: false
    }),
    isActive: attr('boolean'),
    registrationCompleted: attr('boolean'),
    isStaff: attr('boolean'),
    isCompanyAdmin: attr('boolean'),
    isCompanyProjectAdmin: attr('boolean'),
    emailValidated: attr('boolean', {
      defaultValue: false
    }),
    tcbValidated: attr('boolean', {
      defaultValue: false
    }),
    profileCompleted: attr('boolean', {
      defaultValue: false
    }),
    token: attr('string'),
    company: belongsTo('company', {
      async: true,
      inverse: 'companyUsers'
    }),
    proposals: hasMany('proposal', {
      async: true,
      inverse: 'consultantUser'
    }),
    salutation: belongsTo('salutation', {
      async: true
    }),
    academicTitle: belongsTo('academic-title', {
      async: true
    }),
    // Company Info Fields
    newCompany: attr('boolean', {
      defaultValue: false
    }),
    companyName: attr('string'),
    'addressLine-1': _emberData.default.attr('string'),
    'addressLine-2': _emberData.default.attr('string'),
    'addressLine-3': _emberData.default.attr('string'),
    vatNumber: attr('string'),
    postalCode: attr('string'),
    city: attr('string'),
    state: attr('string'),
    country: belongsTo('country', {
      async: true
    }),
    registry: attr('string'),
    registryNumber: attr('string')
  }, _defineProperty(_DS$Model$extend, "vatNumber", attr('string')), _defineProperty(_DS$Model$extend, "dunsId", attr('number')), _defineProperty(_DS$Model$extend, "website", attr('string')), _defineProperty(_DS$Model$extend, "newsletterSubscription", attr('boolean', {
    defaultValue: true
  })), _defineProperty(_DS$Model$extend, "resendEvaluationInvitation", (0, _emberApiActions.memberAction)({
    path: 'resend-evaluation-invitation/',
    type: 'post'
  })), _defineProperty(_DS$Model$extend, "deleteEvaluationInvitation", (0, _emberApiActions.memberAction)({
    path: 'delete-evaluation-invitation/',
    type: 'post'
  })), _defineProperty(_DS$Model$extend, "createCompanyUser", (0, _emberApiActions.memberAction)({
    path: 'create-company-user/',
    type: 'post'
  })), _defineProperty(_DS$Model$extend, "changeLanguage", (0, _emberApiActions.memberAction)({
    path: 'change-language/',
    type: 'post'
  })), _defineProperty(_DS$Model$extend, "fullName", Ember.computed('firstName', 'lastName', function () {
    return this.get('firstName') + ' ' + this.get('lastName');
  })), _defineProperty(_DS$Model$extend, "countryObserver", Ember.observer('country', function () {
    if (this.get('country')) {
      this.get('errors').remove('country');
    }
  })), _defineProperty(_DS$Model$extend, "salutationObserver", Ember.observer('salutation', function () {
    if (this.get('salutation')) {
      this.get('errors').remove('salutation');
    }
  })), _DS$Model$extend));

  _exports.default = _default;
});