define("cb-ui/routes/app/project/client/timeline-configuration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var quote = this.modelFor('app.project');
      return Ember.RSVP.hash({
        quote: quote,
        currentUser: this.get('sessionContext.currentUser'),
        userQuoteRoleList: this.store.query('user-quote-role', {
          quote_id: quote.get('id')
        })
      });
    },
    addTimelineEntriesForQuoteDates: function addTimelineEntriesForQuoteDates(timelineEntries, quote) {
      var standardEntries = quote.getStandardTimelineEntries();
      standardEntries.forEach(function (entry) {
        timelineEntries.addObject(entry);
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      var quote = model.quote;
      controller.set('quote', quote);
      controller.set('quote.currentStep', 30);
      controller.set('userQuoteRole', model.userQuoteRoleList.get('firstObject'));
      controller.set('currentUser', model.currentUser);
      model.currentUser.get('company').then(function (company) {
        controller.set('currentUserCompany', company);
      });
      var quoteTimelineEntries = new Ember.A();
      var lsQuoteTimelineEntriesPromises = [];
      var quoteTimelineEntriesPromise = null;
      quoteTimelineEntriesPromise = new Ember.RSVP.Promise(function (resolve) {
        quote.get('timelineEntries').then(function (timelineEntries) {
          timelineEntries.forEach(function (timelineEntry) {
            timelineEntry.set('isCustom', true);
            quoteTimelineEntries.addObject(timelineEntry);
          });
          resolve();
        });
      });

      if (lsQuoteTimelineEntriesPromises) {
        Ember.RSVP.all(lsQuoteTimelineEntriesPromises).then(function () {
          _this.addTimelineEntriesForQuoteDates(quoteTimelineEntries, quote);

          controller.set('localQuoteTimelineEntries', quoteTimelineEntries);
        });
      } else {
        quoteTimelineEntriesPromise.then(function () {
          _this.addTimelineEntriesForQuoteDates(quoteTimelineEntries, quote);

          controller.set('localQuoteTimelineEntries', quoteTimelineEntries);
        });
      }
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});