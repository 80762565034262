define("cb-ui/controllers/external-evaluation", ["exports", "cb-ui/config/environment", "ember-local-storage"], function (_exports, _environment, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['evaluationType', 'quoteId', 'tcbToken'],
    technicalType: 'technical',
    pitchType: 'pitch',
    notify: Ember.inject.service(),
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    sessionContext: Ember.inject.service(),
    userFullName: Ember.computed('sessionContext.currentUser.firstName', 'sessionContext.currentUser.lastName', function () {
      var user = this.get('sessionContext.currentUser');

      if (user) {
        return (user.get('firstName') + ' ' + user.get('lastName')).trim();
      }
    }),
    currentUser: null,
    actions: {
      changeUserLanguage: function changeUserLanguage(language) {
        var _this = this;

        var localStorage = this.get('localStorage');
        this.get('sessionContext.currentUser').then(function (user) {
          var oldLanguage = user.get('language');

          if (oldLanguage !== language) {
            user.changeLanguage({
              'language': language
            }).then(function () {
              user.set('language', language);

              _this.get('intl').setLocale(language);

              localStorage.set('language', language);
            }).catch(function (error) {
              console.log(error);

              _this.get('notify').error(_this.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});