define("cb-ui/components/financial-evaluation-tables", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filteredProposals: new Ember.A(),
    showDailyRateTable: false,
    showMandaysTable: true,
    showFeePerRoleTable: true,
    showCharts: true,
    quote: null,
    blendedWeightedRateColumns: Ember.computed('intl.locale.[]', 'filteredProposals.length', function () {
      var columns = [];
      var proposals = this.get('filteredProposals');

      if (proposals.length == 0) {
        columns.push({
          heading: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.dailyRate'),
          valuePath: 'title',
          isFixed: 'left',
          width: 420,
          isResizable: true
        });
      } else {
        columns.push({
          heading: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.dailyRate'),
          valuePath: 'title',
          isFixed: 'left',
          width: 420,
          isResizable: false
        });
      }

      proposals.forEach(function (p) {
        columns.push({
          valuePath: p.get('id'),
          company: p.get('company'),
          heading: '',
          headerComponent: 'ember-table/company-info'
        });
      });
      return columns;
    }),
    blendedWeightedRateRows: Ember.computed('int.locale[]', 'filteredProposals.length', 'expandDailyRateTable', function () {
      var proposals = this.get('filteredProposals');
      var roles = [];
      var res = [];
      var levels = {};
      proposals.forEach(function (item) {
        var data = item.get('arithmeticAndWeightedAverageDailyRate');

        for (var key in data) {
          if (key != 'total' && roles.indexOf(key) < 0) {
            roles.push(key);
            levels[key] = data[key]['level'];
          }
        }
      });
      var items = Object.keys(levels).map(function (key) {
        return [key, levels[key]];
      });
      items.sort(function (first, second) {
        return second[1] - first[1];
      });
      items.forEach(function (item) {
        var role = item[0];
        var thisRole = {};
        thisRole['title'] = {
          title: role,
          cellComponent: 'ember-table/proposal-role'
        };
        proposals.forEach(function (item) {
          var r = item.get('arithmeticAndWeightedAverageDailyRate')[role]; // if (item.get('isClosedEnvelope')) {
          //     r = null;
          // }

          var ba, wa;

          if (r) {
            ba = r['arithmetic_average_daily_rate'];
            wa = r['weighted_average_daily_rate'];
          } else {
            ba = 0;
            wa = 0;
          }

          thisRole[item.get('id')] = {
            proposal: item,
            blended: ba,
            weighted: wa,
            cellComponent: 'ember-table/blended-weighted-rate-cell'
          };
        });
        res.push(thisRole);
      });
      return res;
    }),
    mandayColumns: Ember.computed('intl.locale.[]', 'filteredProposals.length', function () {
      var columns = [];
      var proposals = this.get('filteredProposals');

      if (proposals.length == 0) {
        columns.push({
          heading: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.totalMandays'),
          valuePath: 'title',
          isFixed: 'left',
          width: 420,
          isResizable: true
        });
      } else {
        columns.push({
          heading: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.totalMandays'),
          valuePath: 'title',
          isFixed: 'left',
          width: 420,
          isResizable: false
        });
      }

      proposals.forEach(function (p) {
        columns.push({
          valuePath: p.get('id'),
          company: p.get('company'),
          heading: '',
          headerComponent: 'ember-table/company-info'
        });
      });
      return columns;
    }),
    mandayRows: Ember.computed('intl.locale.[]', 'filteredProposals.length', function () {
      var proposals = this.get('filteredProposals');
      var roles = [];
      var res = [];
      var levels = {};
      proposals.forEach(function (item) {
        var data = item.get('arithmeticAndWeightedAverageDailyRate');

        for (var key in data) {
          if (key != 'total' && roles.indexOf(key) < 0) {
            roles.push(key);
            levels[key] = data[key]['level'];
          }
        }
      });
      var items = Object.keys(levels).map(function (key) {
        return [key, levels[key]];
      });
      items.sort(function (first, second) {
        return second[1] - first[1];
      });
      items.forEach(function (item) {
        var role = item[0];
        var thisRole = {};
        thisRole['title'] = {
          title: role,
          level: levels[role],
          cellComponent: 'ember-table/proposal-role-level'
        };
        proposals.forEach(function (item) {
          var r = item.get('arithmeticAndWeightedAverageDailyRate')[role];
          var days;

          if (r) {
            days = r['total_mandays_planned'];
          } else {
            days = 0;
          }

          thisRole[item.get('id')] = {
            proposal: item,
            mandays: days,
            cellComponent: 'ember-table/proposal-role-mandays'
          };
        });
        res.push(thisRole);
      });
      var graph = {};
      var graphicalRepresentationRoles = [];
      var graphicalRepresentationValues = [];
      items.forEach(function (item) {
        graphicalRepresentationRoles.push(item[1] + '. ' + item[0]);
        graphicalRepresentationValues.push({
          x: item[1] + '. ' + item[0],
          y: 0
        });
      });
      graph['title'] = {
        title: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.graphicalRepresentation'),
        roles: graphicalRepresentationRoles,
        datum: [{
          "key": "fee",
          "values": graphicalRepresentationValues
        }],
        cellComponent: 'ember-table/graphical-representation-title'
      };
      proposals.forEach(function (item) {
        var values = [];
        items.forEach(function (itm) {
          var role = itm[0];
          var r = item.get('arithmeticAndWeightedAverageDailyRate')[role];
          var days;

          if (r) {
            days = r['total_mandays_planned'];
          } else {
            days = 0;
          }

          values.push({
            x: '[' + levels[role] + '] - ' + role,
            y: days
          });
        });
        graph[item.get('id')] = {
          proposal: item,
          datum: [{
            'key': 'days',
            'values': values
          }],
          cellComponent: 'ember-table/mandays-graph'
        };
      });
      res.push(graph);
      return res;
    }),
    feePerRoleColumns: Ember.computed('intl.locale.[]', 'filteredProposals.length', function () {
      var columns = [];
      var proposals = this.get('filteredProposals');

      if (proposals.length == 0) {
        columns.push({
          heading: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.feePerRole'),
          valuePath: 'title',
          isFixed: 'left',
          width: 420,
          isResizable: true
        });
      } else {
        columns.push({
          heading: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.feePerRole'),
          valuePath: 'title',
          isFixed: 'left',
          width: 420,
          isResizable: false
        });
      }

      proposals.forEach(function (p) {
        columns.push({
          valuePath: p.get('id'),
          company: p.get('company'),
          heading: '',
          headerComponent: 'ember-table/company-info'
        });
      });
      return columns;
    }),
    feePerRoleRows: Ember.computed('intl.locale.[]', 'filteredProposals.length', function () {
      var proposals = this.get('filteredProposals');
      var roles = [];
      var res = [];
      var levels = {};
      proposals.forEach(function (item) {
        var data = item.get('arithmeticAndWeightedAverageDailyRate');

        for (var key in data) {
          if (key != 'total' && roles.indexOf(key) < 0) {
            roles.push(key);
            levels[key] = data[key]['level'];
          }
        }
      });
      var items = Object.keys(levels).map(function (key) {
        return [key, levels[key]];
      });
      items.sort(function (first, second) {
        return second[1] - first[1];
      });
      items.forEach(function (item) {
        var role = item[0];
        var thisRole = {};
        thisRole['title'] = {
          title: role,
          level: levels[role],
          cellComponent: 'ember-table/proposal-role-level'
        };
        proposals.forEach(function (item) {
          var r = item.get('arithmeticAndWeightedAverageDailyRate')[role];
          var fee;

          if (r) {
            fee = r['total_fee'];
          } else {
            fee = 0;
          }

          thisRole[item.get('id')] = {
            proposal: item,
            rate: fee,
            cellComponent: 'ember-table/proposal-rates-cell'
          };
        });
        res.push(thisRole);
      });
      var graph = {};
      var graphicalRepresentationRoles = [];
      var graphicalRepresentationValues = [];
      items.forEach(function (item) {
        graphicalRepresentationRoles.push(item[1] + '. ' + item[0]);
        graphicalRepresentationValues.push({
          x: item[1] + '. ' + item[0],
          y: 0
        });
      });
      graph['title'] = {
        title: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.graphicalRepresentation'),
        roles: graphicalRepresentationRoles,
        datum: [{
          "key": "fee",
          "values": graphicalRepresentationValues
        }],
        cellComponent: 'ember-table/graphical-representation-title'
      };
      proposals.forEach(function (item) {
        var values = [];
        items.forEach(function (itm) {
          var role = itm[0];
          var r = item.get('arithmeticAndWeightedAverageDailyRate')[role];
          var fee;

          if (r) {
            fee = r['total_fee'];
          } else {
            fee = 0;
          }

          values.push({
            x: '[' + levels[role] + '] - ' + role,
            y: fee
          });
        });
        graph[item.get('id')] = {
          proposal: item,
          datum: [{
            'key': 'fee',
            'values': values
          }],
          cellComponent: 'ember-table/fee-per-role-graph'
        };
      });
      res.push(graph);
      return res;
    }),
    overallChartData: Ember.computed('filteredProposals.[]', function () {
      var tfvalues = [];
      var svalues = [];
      var dvalues = [];
      var tvvalues = [];
      this.get('filteredProposals').forEach(function (item) {
        item.get('company').then(function (c) {
          var data = item.get('financialQuotationEvaluationData');
          var totalVolume = item.get('financialQuotationTotal');
          var tmrebate = item.get('timeMaterialsRebate');
          var ffrebate = item.get('fixedFeeRebate');
          var supplements = data['supplements_fee_value'];
          var total = data['financial_quotation_fee'];

          if (item.get('isClosedEnvelope')) {
            totalVolume = 0;
            tmrebate = 0;
            ffrebate = 0;
            supplements = 0;
            total = 0;
          }

          var rebate = tmrebate + ffrebate;
          var auxvals = [{
            x: c.get('companyNameAlias'),
            y: total
          }, {
            x: c.get('companyNameAlias'),
            y: supplements
          }, {
            x: c.get('companyNameAlias'),
            y: -rebate
          }, {
            x: c.get('companyNameAlias'),
            y: totalVolume
          }];
          tfvalues.push(auxvals[0]);
          svalues.push(auxvals[1]);
          dvalues.push(auxvals[2]);
          tvvalues.push(auxvals[3]);
        });
      });
      return [{
        'key': 'Total Fee',
        'values': tfvalues
      }, {
        'key': 'Other costs',
        'values': svalues
      }, {
        'key': 'Discount',
        'values': dvalues
      }, {
        'key': 'Total Volume',
        'values': tvvalues
      }];
    }),
    dailyRateChartData: Ember.computed('filteredProposals.[]', function () {
      var blendedValues = [];
      var weightedValues = [];
      this.get('filteredProposals').forEach(function (item) {
        item.get('company').then(function (c) {
          var data = item.get('arithmeticAndWeightedAverageDailyRate');
          var total = data['total'];

          if (item.get('isClosedEnvelope')) {
            total['arithmetic_average_daily_rate'] = 0;
            total['total_fee'] = 0;
            total['total_mandays_planned'] = 0;
            total['weighted_average_daily_rate'] = 0;
          }

          var auxvals = [{
            x: c.get('companyNameAlias'),
            y: total['arithmetic_average_daily_rate']
          }, {
            x: c.get('companyNameAlias'),
            y: total['weighted_average_daily_rate']
          }];
          blendedValues.push(auxvals[0]);
          weightedValues.push(auxvals[1]);
        });
      });
      return [{
        'key': 'Blended Average',
        'values': blendedValues
      }, {
        'key': 'Weighted Average',
        'values': weightedValues
      }];
    }),
    totalMandaysChartData: Ember.computed('filteredProposals.[]', function () {
      var values = [];
      var key = "Total Mandays Planned";
      this.get('filteredProposals').forEach(function (item) {
        item.get('company').then(function (c) {
          var total = item.get('totalMandaysPlannedOverall');

          if (item.get('isClosedEnvelope')) {
            total = 0;
          }

          values.push({
            x: c.get('companyNameAlias'),
            y: total
          });
        });
      });
      return [{
        'key': key,
        'values': values
      }];
    }),
    durationInWeeksChartData: Ember.computed('filteredProposals.[]', function () {
      var values = [];
      var key = "Duration In Weeks";
      this.get('filteredProposals').forEach(function (item) {
        item.get('company').then(function (c) {
          var total = item.get('durationInWeeks');

          if (item.get('isClosedEnvelope')) {
            total = 0;
          }

          values.push({
            x: c.get('companyNameAlias'),
            y: total
          });
        });
      });
      return [{
        'key': key,
        'values': values
      }];
    }),
    beforeDiscreteSetup: function beforeDiscreteSetup(svg, chart) {
      chart.valueFormat(d3.format(",.0f")).showValues(true);
      chart.wrapLabels(true);
      chart.color(['#0d3c5a']);
      chart.margin({
        'left': 425
      });
    },
    beforeSetupRate: function beforeSetupRate(svg, chart) {
      chart.color(['#0d3c5a', '#ffc000']);
      chart.margin({
        'left': 425
      });
      chart.showControls(false);
      chart.legend.margin({
        top: 3,
        right: 0,
        left: 0,
        bottom: 10
      });
    },
    beforeSetupOverall: function beforeSetupOverall(svg, chart) {
      chart.color(['#454545', '#ffc000', '#dcdcdc', '#0d3c5a']);
      chart.margin({
        'left': 425
      });
      chart.showControls(false);
      chart.legend.margin({
        top: 3,
        right: 0,
        left: 0,
        bottom: 10
      });
    },
    afterSetupMultiBar: function afterSetupMultiBar(svg, chart) {
      chart.dispatch.on('renderEnd', function () {
        d3.selectAll('.nv-multiBarWithLegend').each(function () {
          var glabels = d3.select(this);
          var cl = 0;
          glabels.selectAll('.labels').remove();
          glabels.append('g').attr('class', 'labels');
          d3.selectAll('.nv-multiBarWithLegend .labels').each(function () {
            var lbls = d3.select(this);
            lbls.selectAll('text').remove();
            var ci = 0;
            d3.selectAll('.nv-multiBarWithLegend .nv-group').each(function (d, i) {
              var g = d3.select(this);

              if (i == 0 || i == 2) {
                ci += 1;
              }

              if (ci == cl + 1) {
                g.selectAll('text').remove();
                g.selectAll('.nv-bar').each(function (bar) {
                  var b = d3.select(this);
                  var barWidth = b.attr('width');
                  lbls.append('text').attr('transform', b.attr('transform').substring(0, b.attr('transform').length - 2).concat('6)')).text(function () {
                    return parseFloat(bar.y).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }).attr('y', function () {
                    return parseFloat(b.attr('y')) - 10;
                  }).attr('x', function () {
                    var width = this.getBBox().width;
                    return parseFloat(b.attr('x')) + parseFloat(barWidth) / 2 - width / 2;
                  }).attr('class', 'bar-values').style("fill", "rgba(70,70,70,1)").style("stroke", "rgba(0,0,0,0)").style("font-weight", "bold");
                });
              }
            });
            cl += 1;
          });
        });
      });
    },
    didInsertElement: function didInsertElement() {
      this.$().find('.summary-section-title').on('click', function () {
        Ember.$(this).find('span:first').toggleClass('glyphicon-triangle-bottom glyphicon-triangle-right');
      });
      this.$().find('.rate-analisys-title').on('click', function () {
        Ember.$(this).find('span:first').toggleClass('glyphicon-triangle-right glyphicon-triangle-bottom');
      });
    }
  });

  _exports.default = _default;
});