define("cb-ui/models/base-criteria-evaluation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    title: attr('string'),
    description: attr('string'),
    isEliminatory: attr('boolean', {
      defaultValue: false
    }),
    order: attr('number', {
      defaultValue: 0
    }),
    weight: attr('number', {
      defaultValue: 0
    }),
    group: belongsTo('base-criteria-evaluation-group', {
      inverse: 'baseCriteriaEvaluations'
    })
  });

  _exports.default = _default;
});