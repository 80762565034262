define("cb-ui/templates/components/proposal-evaluation-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MbuazpqY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"proposal\",\"isIncludedInEvaluation\"]]],null,{\"statements\":[[4,\"if\",[[20,[\"evaluation\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"class\",[26,[\"p-m \",[25,\"if\",[[20,[\"proposal\",\"isSubmitted\"]],\"pointer-cursor\"],null]]]],[3,\"action\",[[19,0,[]],\"changeEvaluationScore\"]],[7],[0,\"\\n\"],[4,\"if\",[[25,\"or\",[[25,\"eq\",[[20,[\"roundedScore\"]],0],null],[25,\"eq\",[[20,[\"roundedScore\"]],null],null]],null]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[10,\"class\",[26,[[18,\"notYetRatedClass\"],[25,\"unless\",[[20,[\"notYetRatedClass\"]],\"red\"],null]]]],[7],[0,\"\\n          \"],[1,[25,\"t\",[\"app.components.proposalEvaluationItem.pleaseRate\"],null],false],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,\"div\"],[7],[0,\"\\n\"],[4,\"if\",[[25,\"eq\",[[20,[\"roundedScore\"]],1],null]],null,{\"statements\":[[0,\"            \"],[1,[25,\"t\",[\"app.components.proposalEvaluationItem.disagree\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,\"eq\",[[20,[\"roundedScore\"]],2],null]],null,{\"statements\":[[0,\"            \"],[1,[25,\"t\",[\"app.components.proposalEvaluationItem.pDisagree\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,\"eq\",[[20,[\"roundedScore\"]],3],null]],null,{\"statements\":[[0,\"            \"],[1,[25,\"t\",[\"app.components.proposalEvaluationItem.pAgree\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,\"eq\",[[20,[\"roundedScore\"]],4],null]],null,{\"statements\":[[0,\"            \"],[1,[25,\"t\",[\"app.components.proposalEvaluationItem.agree\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/proposal-evaluation-item.hbs"
    }
  });

  _exports.default = _default;
});