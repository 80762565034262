define("cb-ui/controllers/app/rfp/management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    quote: null,
    // users from same company
    companyUsers: [],
    //
    newUsers: [],
    // users with permissions for quote
    localUsersQuotePermissions: [],
    loadBackendPermissions: function loadBackendPermissions() {
      var _this = this;

      var localPermissions = Ember.A();
      this.store.query('user-quote-permission', {
        quote_id: this.get('quote.id')
      }).then(function (usersQuotePermissions) {
        usersQuotePermissions.forEach(function (userQuotePerm) {
          var permissions = userQuotePerm.get('permissions');
          localPermissions.addObject({
            user: userQuotePerm.get('user'),
            viewQuote: permissions.includes('view_quote'),
            crudQuote: permissions.includes('crud_quote'),
            updateQuoteDocuments: permissions.includes('update_quote_documents'),
            updateQuoteReview: permissions.includes('update_quote_review')
          });
        });

        _this.set('localUsersQuotePermissions', localPermissions);
      });
    },
    actions: {
      changePermission: function changePermission(userPromise, isSelected, permissionType) {
        var _this2 = this;

        // isSelected is the oldValue, the
        userPromise.then(function (user) {
          _this2.store.find('user-quote-permission', user.get('id').toString() + '-' + _this2.get('quote.id').toString()).then(function (userQuotePermission) {
            var permissions = userQuotePermission.get('permissions');

            if (isSelected) {
              if (permissions.includes(permissionType)) {
                permissions.removeObject(permissionType);
                userQuotePermission.save();
              }
            } else {
              if (!permissions.includes(permissionType)) {
                permissions.addObject(permissionType);
                userQuotePermission.save();
              }
            }
          });
        });
      },
      addNewUser: function addNewUser() {
        var _this3 = this;

        var newUsers = this.get('newUsers');
        newUsers.forEach(function (user) {
          //TODO: Check if user already has quote permissions
          _this3.store.createRecord('user-quote-permission', {
            quote: _this3.get('quote'),
            user: user
          }).save().then(function () {
            _this3.set('newUsers', []); //return;


            _this3.loadBackendPermissions();
          });
        });
      },
      deleteUserQuotePermissions: function deleteUserQuotePermissions(user) {
        var _this4 = this;

        this.store.find('user-quote-permission', user.get('id').toString() + '-' + this.get('quote.id').toString()).then(function (userQuotePermission) {
          userQuotePermission.destroyRecord().then(function () {
            _this4.loadBackendPermissions();
          });
        });
      }
    }
  });

  _exports.default = _default;
});