define("cb-ui/templates/incomplete-proposal-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P6JgOzYu",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"bs-modal\",null,[[\"footer\",\"body\",\"onHidden\",\"size\"],[false,false,[25,\"action\",[[19,0,[]],\"closeIncompleteProposalModal\"],null],\"lg\"]],{\"statements\":[[4,\"component\",[[19,1,[\"body\"]]],null,{\"statements\":[[0,\"      \"],[6,\"h4\"],[7],[1,[25,\"t\",[\"app.modals.incompleteProposal.title\"],null],false],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"p-m\"],[7],[0,\"\\n\"],[4,\"unless\",[[20,[\"proposal\",\"technicalProposalIsCompleted\"]]],null,{\"statements\":[[0,\"            \"],[6,\"div\"],[9,\"class\",\"m-b\"],[7],[0,\"\\n              \"],[1,[25,\"t\",[\"app.modals.incompleteProposal.incompleteTechnicalProposal\"],null],false],[0,\"\\n            \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"unless\",[[20,[\"proposal\",\"financialProposalIsCompleted\"]]],null,{\"statements\":[[0,\"            \"],[6,\"div\"],[9,\"class\",\"m-b\"],[7],[0,\"\\n              \"],[1,[25,\"t\",[\"app.modals.incompleteProposal.incompleteFinancialProposal\"],null],false],[0,\"\\n            \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[8],[0,\"\\n      \"],[6,\"center\"],[7],[0,\"\\n          \"],[6,\"button\"],[9,\"class\",\"btn btn-primary\"],[3,\"action\",[[19,0,[]],\"closeIncompleteProposalModal\"]],[7],[1,[25,\"t\",[\"buttons.close\"],null],false],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/incomplete-proposal-modal.hbs"
    }
  });

  _exports.default = _default;
});