define("cb-ui/routes/app/project/proposal/announcements", ["exports", "cb-ui/mixins/scrollbar-reset", "cb-ui/utils/append-access-token", "cb-ui/utils/get-target-domain"], function (_exports, _scrollbarReset, _appendAccessToken, _getTargetDomain) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollbarReset.default, {
    beforeModel: function beforeModel() {
      var quote = this.modelFor('app.project');
      var quoteId = quote.get('id');
      var proposal = this.modelFor('app.project.proposal');
      var proposalId = proposal.get('id');
      var base64QuoteId = window.btoa(unescape(encodeURIComponent("QuoteNode:".concat(quoteId))));
      var base64ProposalId = window.btoa(unescape(encodeURIComponent("QuoteProposalNode:".concat(proposalId))));
      window.location = (0, _appendAccessToken.default)("".concat((0, _getTargetDomain.default)(), "/dashboard/rfp/").concat(base64QuoteId, "/proposals/").concat(base64ProposalId, "/setup/announcements"), this.get('session.data.authenticated.token'));
    }
  });

  _exports.default = _default;
});