define("cb-ui/templates/models-table/preview-response-project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iSNaCpeC",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n\"],[4,\"if\",[[25,\"or\",[[20,[\"record\",\"isAccepted\"]],[20,[\"record\",\"isIncludedInEvaluation\"]]],null]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"col-xs-12\"],[7],[0,\"\\n        \"],[6,\"button\"],[9,\"class\",\"btn btn-primary btn-block btn-mini btn-table\"],[3,\"action\",[[19,0,[]],\"sendAction\",\"selectProject\",[20,[\"record\"]]]],[7],[6,\"i\"],[9,\"class\",\"glyphicon glyphicon-log-in\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"app.dashboardConsultant.projects.open\"],null],false],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"record\",\"isInvited\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"col-md-6\"],[7],[0,\"\\n            \"],[6,\"button\"],[9,\"class\",\"btn btn-default btn-block btn-mini btn-table\"],[3,\"action\",[[19,0,[]],\"sendAction\",\"showPreviewProject\",[20,[\"record\"]]]],[7],[6,\"i\"],[9,\"class\",\"glyphicon glyphicon-log-in\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"app.dashboardConsultant.projects.preview\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-md-6\"],[7],[0,\"\\n\\n            \"],[6,\"button\"],[9,\"class\",\"btn btn-primary btn-block btn-mini btn-table\"],[3,\"action\",[[19,0,[]],\"sendAction\",\"selectProject\",[20,[\"record\"]]]],[7],[6,\"i\"],[9,\"class\",\"glyphicon glyphicon-log-in\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"app.dashboardConsultant.projects.response\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"col-xs-12\"],[7],[0,\"Not accepted\"],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/models-table/preview-response-project.hbs"
    }
  });

  _exports.default = _default;
});