define("cb-ui/components/section-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    quote: null,
    sectionsSorting: ['order'],
    sortedSections: Ember.computed.sort('sections', 'sectionsSorting'),
    sections: null,
    actions: {
      'delete': function _delete(section) {
        this.attrs.delete(section);
      },
      disable: function disable(section) {
        this.attrs.disable(section);
      },
      enable: function enable(section) {
        this.attrs.enable(section);
      }
    }
  });

  _exports.default = _default;
});