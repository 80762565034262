define("cb-ui/components/ask-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var projectTypes = ['Project Scope', 'Technical Proposal', 'Beauty Contest', 'Team Overview', 'References', 'Other Topics'];

  var _default = Ember.Component.extend({
    newQuestion: null,
    newQuestionType: projectTypes[0],
    questionTypes: projectTypes,
    actions: {
      selectQuestionType: function selectQuestionType(type) {
        this.set('newQuestionType', type);
      },
      addQuestion: function addQuestion() {
        this.sendAction('addQuestion', this.get('newQuestion'), this.get('newQuestionType'));
      }
    }
  });

  _exports.default = _default;
});