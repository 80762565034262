define("cb-ui/routes/app/process", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Apadua = 'apadua';

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super('transition');

      var quoteId = transition.params['app.process'].quote_id;
      this.get('sessionContext.currentUser').then(function (user) {
        if (!user.get('isClient')) {
          _this.transitionTo('app.dashboard');
        }

        _this.store.find('quote', quoteId).then(function (quote) {
          if (quote.get('status') !== 'requested') {
            _this.transitionTo('app.dashboard');
          }
        });
      });
    },
    model: function model(params) {
      var _this2 = this;

      var quoteId = params.quote_id;
      return Ember.RSVP.hash({
        user: this.get('sessionContext.currentUser'),
        quote: this.store.find('quote', quoteId)
      }).then(function (model) {
        return Ember.RSVP.hash({
          user: model.user,
          quote: model.quote,
          companyUsers: _this2.store.query('user', {
            company_id: model.user.get('company').get('id'),
            company_app: Apadua
          }),
          userAdminstForQuote: _this2.store.query('user-quote-role', {
            role: 'admin'
          })
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('quote', model.quote);
      controller.set('companyUsers', model.companyUsers);
      controller.set('companyUsersAdmins', model.companyUsers);
      controller.set('companyUsersUsers', model.companyUsers);
      controller.set('userAdminsForQuote', []);
      controller.set('userUsersForQuote', []);
      controller.set('ownerForQuote', null);
      controller.setupChangeset();
    }
  });

  _exports.default = _default;
});