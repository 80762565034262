define("cb-ui/templates/components/ember-table/result-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0LPHs0aT",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"style\",[25,\"if\",[[20,[\"cellValue\",\"bold\"]],\"font-weight: bold\"],null],null],[7],[0,\"\\n  \"],[1,[25,\"get-result-data\",null,[[\"criteria\",\"proposal\",\"quoteEvaluationResults\",\"criteriaScore\",\"finishedEvaluations\",\"totalScore\"],[[20,[\"cellValue\",\"criteria\"]],[20,[\"cellValue\",\"proposal\"]],[20,[\"quoteEvaluationResults\"]],[20,[\"cellValue\",\"criteriaScore\"]],[20,[\"cellValue\",\"finishedEvaluations\"]],[20,[\"cellValue\",\"totalScore\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/ember-table/result-data.hbs"
    }
  });

  _exports.default = _default;
});