define("cb-ui/routes/app/project/client/review", ["exports", "cb-ui/mixins/scrollbar-reset", "cb-ui/utils/download-file"], function (_exports, _scrollbarReset, _downloadFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollbarReset.default, {
    model: function model() {
      var quote = this.modelFor('app.project');
      return new Ember.RSVP.hash({
        criteriaEvaluations: this.store.findAll('criteriaEvaluation'),
        quoteCompanies: this.modelFor('app.project').get('companies'),
        //TODO: Loaded for rfp-summary component
        consultantsType: this.store.findAll('consultantType'),
        currentUser: this.get('sessionContext.currentUser'),
        criteriaEvaluationGroups: this.store.query('criteria-evaluation-group', {
          include: 'evaluations',
          quote_id: quote.get('id')
        }),
        userQuoteRoleList: this.store.query('user-quote-role', {
          quote_id: quote.get('id')
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var quote = this.modelFor('app.project');
      var technicalQuoteCriteriaGroups = new Ember.A();
      var pitchQuoteCriteriaGroups = new Ember.A();
      controller.set('quote', quote);
      controller.set('userQuoteRole', model.userQuoteRoleList.get('firstObject'));
      controller.set('criteriaEvaluations', model.criteriaEvaluations);
      controller.set('quoteCompanies', model.quoteCompanies);
      controller.set('currentUser', model.currentUser);
      model.criteriaEvaluationGroups.forEach(function (ceg) {
        var groupType = ceg.get('type');

        if (groupType === 'technical_proposal') {
          technicalQuoteCriteriaGroups.addObject(ceg);
        } else if (groupType === 'pitch') {
          pitchQuoteCriteriaGroups.addObject(ceg);
        }
      });
      controller.set('technicalQuoteCriteriaGroups', technicalQuoteCriteriaGroups);
      controller.set('pitchQuoteCriteriaGroups', pitchQuoteCriteriaGroups);
    },
    actions: {
      newDocumentToken: function newDocumentToken(id) {
        this.store.findRecord('quote-document', id).then(function (res) {
          (0, _downloadFile.default)(res.data.file, res.get('fileName'));
        });
      },
      newCoverLetterToken: function newCoverLetterToken(id) {
        this.store.findRecord('quote-cover-letter-document', id).then(function (res) {
          (0, _downloadFile.default)(res.data.file, res.get('fileName'));
        });
      }
    }
  });

  _exports.default = _default;
});