define("cb-ui/routes/app/account/change-password", ["exports", "cb-ui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      window.location.assign("".concat(_environment.default.CANONICAL_DOMAIN_NEXT, "/dashboard/account/security"));

      this._super(controller, model);

      controller.set('user', this.modelFor('app.account'));
    },
    cleanup: Ember.on('deactivate', function () {
      this.controller.set('user.password', null);
      this.controller.set('user.currentPassword', null), this.controller.set('user.retypePassword', null);
    })
  });

  _exports.default = _default;
});