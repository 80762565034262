define("cb-ui/routes/app/org-settings/cover-letter-settings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var _this = this;

      var user = this.modelFor('app.org-settings');
      return user.get('company').then(function (company) {
        return company.get('companySettings').then(function (companySettings) {
          if (companySettings === null) {
            companySettings = _this.get('store').createRecord('company-setting', {
              name: null,
              font: null,
              custom_css: null,
              nav: null,
              navType: null,
              primary: null,
              primaryHover: null,
              default: null,
              success: null,
              danger: null,
              coverLetterLogo: null,
              coverLetterText: null,
              company: company
            });
            return companySettings.save().then(function (companySettings) {
              return Ember.RSVP.hash({
                company: company,
                companySettings: companySettings
              });
            });
          } else {
            return Ember.RSVP.hash({
              company: company,
              companySettings: companySettings
            });
          }
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('company', model.company);
      controller.set('companySettings', model.companySettings);

      if (model.companySettings.get('coverLetterLogo')) {
        controller.set('shownImage', model.companySettings.get('coverLetterLogo'));
        controller.set('shownFooterImage', model.companySettings.get('coverLetterFooterLogo'));
      }
    },
    actions: {
      reloadModel: function reloadModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});