define("cb-ui/templates/components/ember-table/blended-weighted-rate-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mFm8ftIx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"or\",[[20,[\"cellValue\",\"proposal\",\"isIncludedInEvaluation\"]],[25,\"eq\",[[20,[\"cellValue\",\"proposal\",\"status\"]],\"dismissed\"],null]],null]],null,{\"statements\":[[4,\"if\",[[25,\"and\",[[20,[\"cellValue\",\"blended\"]],[20,[\"cellValue\",\"weighted\"]]],null]],null,{\"statements\":[[0,\"  \"],[6,\"h4\"],[7],[1,[25,\"t\",[\"app.projectClient.evaluation.label.financialProposals.blended\"],null],false],[0,\": \"],[1,[25,\"format-number\",[[20,[\"cellValue\",\"blended\"]]],[[\"maximumFractionDigits\"],[0]]],false],[0,\" \"],[1,[20,[\"cellValue\",\"proposal\",\"quote\",\"currency\",\"isoCurrencyCode\"]],false],[8],[0,\"\\n  \"],[6,\"h4\"],[7],[1,[25,\"t\",[\"app.projectClient.evaluation.label.financialProposals.weighted\"],null],false],[0,\": \"],[1,[25,\"format-number\",[[20,[\"cellValue\",\"weighted\"]]],[[\"maximumFractionDigits\"],[0]]],false],[0,\" \"],[1,[20,[\"cellValue\",\"proposal\",\"quote\",\"currency\",\"isoCurrencyCode\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/ember-table/blended-weighted-rate-cell.hbs"
    }
  });

  _exports.default = _default;
});