define("cb-ui/controllers/wizard/consultants", ["exports", "cb-ui/mixins/consultants-controller", "cb-ui/mixins/company-studies", "cb-ui/mixins/selected-consultant", "ember-intl", "ember-local-storage"], function (_exports, _consultantsController, _companyStudies, _selectedConsultant, _emberIntl, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import CompanyServicesControllerMixin from '../../mixins/company-services-controller';
  var _default = Ember.Controller.extend(_consultantsController.default, _companyStudies.default, _selectedConsultant.default, {
    quote: null,
    notify: Ember.inject.service('notify'),
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    appLanguage: Ember.computed.alias('localStorage.language'),
    company: null,
    showError: false,
    showCommentModal: null,
    commentModalText: (0, _emberIntl.translationMacro)('wizard.info.consultants'),
    companies: Ember.computed.alias('model.companies'),
    sortKey: ['-finalScore'],
    specialistConsultants: Ember.computed.filterBy('quoteConsultants', 'consultantType', 'specialist'),
    sortedSpecialistConsultants: Ember.computed.sort('specialistConsultants', 'sortKey'),
    diversifiedConsultants: Ember.computed.filterBy('quoteConsultants', 'consultantType', 'diversified consultant'),
    sortedDiversifiedConsultants: Ember.computed.sort('diversifiedConsultants', 'sortKey'),
    generalistConsultants: Ember.computed.filterBy('quoteConsultants', 'consultantType', 'generalist'),
    sortedGeneralistConsultants: Ember.computed.sort('generalistConsultants', 'sortKey'),
    consultantModalTitle: Ember.computed('company', function () {
      return this.get('company.companyNameAlias') + ' | COMPANY PROFILE';
    }),
    generalManagementConsultants: Ember.computed.filterBy('company.individuals', 'generalManagement', true),
    globalHeadOffices: Ember.computed.filterBy('company.addresses', 'globalHeadOffice', true),
    sortServicesProperty: Ember.computed('appLanguage', function () {
      return ['service' + this.get('appLanguage')];
    }),
    sortIndustriesProperty: Ember.computed('appLanguage', function () {
      return ['industry' + this.get('appLanguage')];
    }),
    sortedCompanyServices: Ember.computed.sort('company.services', 'sortServicesProperty'),
    sortedCompanyIndustries: Ember.computed.sort('company.industries', 'sortIndustriesProperty'),
    actions: {
      close: function close() {
        this.set('isShowingModal', false);
      },
      previous: function previous() {
        this.transitionToRoute('wizard.index');
      },
      next: function next(callback) {
        var _this = this;

        var promise = this.saveQuote();
        promise.then(function () {
          _this.transitionToRoute('wizard.confirm');
        }).catch(function (err) {
          _this.get('notify').error(_this.get('intl').t('notifyMessages.errorOccurredFixErrors').toString());

          throw err;
        });
        callback(promise);
      },
      commentModalShow: function commentModalShow() {
        this.set('showCommentModal', true);
      },
      closeCommentModal: function closeCommentModal() {
        this.set('showCommentModal', false);
      }
    }
  });

  _exports.default = _default;
});