define("cb-ui/services/ie-detection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    usesIe: function usesIe() {
      var agent = window.navigator.userAgent;
      var msie = agent.indexOf('MSIE ');

      if (msie > 0) {
        return true;
      }

      var trident = agent.indexOf('Trident/');

      if (trident > 0) {
        var rv = agent.indexOf('rv:');
        return true;
      }

      return false;
    }
  });

  _exports.default = _default;
});