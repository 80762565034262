define("cb-ui/components/consultant-list-item", ["exports", "cb-ui/components/multiple-select-item"], function (_exports, _multipleSelectItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _multipleSelectItem.default.extend({
    tagName: 'div',
    classNames: ['consultant-list-item'],
    language: 'en',
    isPublic: false,
    company: Ember.computed.alias('item.company'),
    // determine if the consultant is selected or not based on selectedItems and company
    itemIsSelectedObserver: Ember.on('init', Ember.observer('selectedItems.[]', 'company', function () {
      var self = this; // this.set('isSelected', false);

      Ember.run.next(function () {
        var newIsSelected;
        var oldIsSelected = self.get('isSelected');

        if (self) {
          if (self.get('selectedItems.length')) {
            newIsSelected = self.get('selectedItems').includes(self.get('company.content'));

            if (oldIsSelected !== newIsSelected) {
              self.set('isSelected', newIsSelected);
            }
          } else {
            if (oldIsSelected) {
              self.set('isSelected', false);
            }
          }
        }
      });
    })),
    click: function click() {
      return false;
    },
    actions: {
      toggleModal: function toggleModal() {
        this.sendAction('toggleModal', this.get('company.content'), this.get('item.industryScore'), this.get('item.serviceScore'));
      },
      toggleSelected: function toggleSelected() {
        this.sendAction(this.get('isSelected') ? 'removeItem' : 'addItem', this.get('company'));
      }
    }
  });

  _exports.default = _default;
});