define("cb-ui/utils/append-access-token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = appendAccessToken;

  function appendAccessToken(url, token) {
    var refreshToken = window.localStorage.getItem('jwtRefreshToken');

    if (refreshToken) {
      return "".concat(url, "?token=").concat(token, "&refreshToken=").concat(refreshToken);
    }

    return "".concat(url, "?token=").concat(token);
  }
});