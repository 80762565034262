define("cb-ui/templates/missing-cover-letter-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "owomnf4F",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"bs-modal\",null,[[\"footer\",\"body\",\"onHidden\",\"size\"],[false,false,[25,\"action\",[[19,0,[]],\"closeMissingCoverLetterAlert\"],null],\"lg\"]],{\"statements\":[[4,\"component\",[[19,1,[\"body\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"col-md-offset-2 col-md-8 text-center\"],[7],[0,\"\\n              \"],[6,\"div\"],[9,\"class\",\"m-b-md alert alert-danger\"],[7],[6,\"strong\"],[7],[1,[25,\"t\",[\"app.modals.missingCoverLetterAlert.title\"],null],false],[8],[8],[0,\"\\n          \"],[8],[0,\"\\n      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"row\"],[9,\"style\",\"display: flex; justify-content: center\"],[7],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"col-md-4 text-center\"],[7],[0,\"\\n              \"],[6,\"div\"],[9,\"class\",\"m-b\"],[7],[0,\"\\n                  \"],[6,\"button\"],[9,\"data-test-new-default-cl\",\"\"],[9,\"class\",\"btn btn-block btn-primary\"],[3,\"action\",[[19,0,[]],\"confirmNext\"]],[7],[0,\"\\n                        \"],[1,[25,\"t\",[\"app.modals.missingCoverLetterAlert.yes\"],null],false],[0,\"\\n                    \"],[8],[0,\"\\n              \"],[8],[0,\"\\n          \"],[8],[0,\"\\n\\n          \"],[6,\"div\"],[9,\"class\",\"col-md-4 text-center\"],[7],[0,\"\\n              \"],[6,\"div\"],[9,\"class\",\"m-b\"],[7],[0,\"\\n                  \"],[6,\"button\"],[9,\"data-test-new-custom-cl\",\"\"],[9,\"class\",\"btn btn-block btn-primary\"],[3,\"action\",[[19,0,[]],\"closeMissingCoverLetterAlert\"]],[7],[0,\"\\n                    \"],[1,[25,\"t\",[\"app.modals.missingCoverLetterAlert.no\"],null],false],[0,\"\\n                  \"],[8],[0,\"\\n              \"],[8],[0,\"\\n          \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/missing-cover-letter-alert.hbs"
    }
  });

  _exports.default = _default;
});