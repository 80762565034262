define("cb-ui/components/ember-table/full-row-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNames: ['et-tr'],
    rowValue: null
  });

  _exports.default = _default;
});