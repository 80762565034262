define("cb-ui/routes/app/rfp/criteria-evaluation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var quoteId = this.modelFor('app.rfp').get('id');
      return Ember.RSVP.hash({
        templates: this.store.query('template', {
          default_templates: true,
          global_templates: true
        }),
        criteriaEvaluationGroups: this.store.query('criteria-evaluation-group', {
          include: 'evaluations',
          quote_id: quoteId
        }),
        currentUser: this.get('sessionContext.currentUser')
      });
    },
    setupController: function setupController(controller, model, transition) {
      var _this = this;

      this._super(controller, model);

      var quote = this.modelFor('app.rfp');
      var technicalTemplates = new Ember.A();
      var pitchTemplates = new Ember.A();
      var defaultTechnicalTemplates = new Ember.A();
      var companyTechnicalTemplates = new Ember.A();
      var defaultPitchTemplates = new Ember.A();
      var companyPitchTemplates = new Ember.A();
      var technicalQuoteCriteriaGroups = new Ember.A();
      var pitchQuoteCriteriaGroups = new Ember.A();
      controller.set('quote', quote);
      controller.set('quote.currentStep', 40);
      controller.set('showCoverLetterQuestion', false);
      controller.set('technicalTemplate', null);
      controller.set('pitchTemplate', null);
      model.templates.forEach(function (template) {
        var templateType = template.get('type'); //TODO: Template type for technical is technical_proposal

        if (templateType === 'technical_proposal') {
          technicalTemplates.addObject(template);

          if (template.get('company.id')) {
            companyTechnicalTemplates.addObject(template);
          } else {
            defaultTechnicalTemplates.addObject(template);
          }
        } else if (templateType === 'pitch') {
          pitchTemplates.addObject(template);

          if (template.get('company.id')) {
            companyPitchTemplates.addObject(template);
          } else {
            defaultPitchTemplates.addObject(template);
          }
        }
      });
      controller.set('technicalTemplatesOptions', [{
        groupName: this.get('intl').t('app.rfp.criteriaEvaluation.label.companyTemplates').toString(),
        options: companyTechnicalTemplates.sortBy('title')
      }, {
        groupName: this.get('intl').t('app.rfp.criteriaEvaluation.label.defaultTemplates').toString(),
        options: defaultTechnicalTemplates.sortBy('title')
      }]);
      controller.set('pitchTemplatesOptions', [{
        groupName: this.get('intl').t('app.rfp.criteriaEvaluation.label.companyTemplates').toString(),
        options: companyPitchTemplates.sortBy('title')
      }, {
        groupName: this.get('intl').t('app.rfp.criteriaEvaluation.label.defaultTemplates').toString(),
        options: defaultPitchTemplates.sortBy('title')
      }]);
      model.criteriaEvaluationGroups.forEach(function (ceg) {
        var groupType = ceg.get('type');

        if (groupType === 'technical_proposal') {
          technicalQuoteCriteriaGroups.addObject(ceg);
        } else if (groupType === 'pitch') {
          pitchQuoteCriteriaGroups.addObject(ceg);
        }
      });
      controller.set('technicalQuoteCriteriaGroups', technicalQuoteCriteriaGroups);
      controller.set('pitchQuoteCriteriaGroups', pitchQuoteCriteriaGroups);
      controller.set('showTechnicalTemplatesDropdown', !technicalQuoteCriteriaGroups.get('length'));
      controller.set('showPitchTemplatesDropdown', !pitchQuoteCriteriaGroups.get('length')); //remove query params from url

      if (transition.queryParams.technicalTemplateId) {
        var technical = technicalTemplates.findBy('id', transition.queryParams.technicalTemplateId);
        controller.set('technicalTemplate', technical);

        if (technical) {
          this.store.query('base-criteria-evaluation-group', {
            include: 'base_criteria_evaluations',
            template_id: technical.get('id')
          }).then(function (groups) {
            controller.set('technicalTemplateGroups', groups);
            controller.set('technicalTemplateDataLoaded', true);
          }).catch(function () {
            _this.get('notify').error(_this.get('intl').t('notifyMessages.groupsNotLoadedForTechnical').toString());
          });
          controller.set('showTechnicalTemplatesDropdown', true);
        }
      }

      if (transition.queryParams.pitchTemplateId) {
        var pitch = pitchTemplates.findBy('id', transition.queryParams.pitchTemplateId);
        controller.set('pitchTemplate', pitch);

        if (pitch) {
          this.store.query('base-criteria-evaluation-group', {
            include: 'base_criteria_evaluations',
            template_id: pitch.get('id')
          }).then(function (groups) {
            controller.set('pitchTemplateGroups', groups);
            controller.set('pitchTemplateDataLoaded', true);
            controller.set('showPitchTemplatesDropdown', true);
          }).catch(function () {
            _this.get('notify').error(_this.get('intl').t('notifyMessages.groupsNotLoadedForPitch').toString());
          });
        }
      }

      controller.set('currentUser', model.currentUser);
      model.currentUser.get('company').then(function (company) {
        controller.set('currentUserCompany', company);
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (this.get('controller.blockPrevious')) {
          transition.abort();
        } else {
          this._super(transition);
        }
      },
      refreshRoute: function refreshRoute() {
        //TODO: CHECK if ok ??
        this.store.unloadAll('template');
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});