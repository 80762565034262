define("cb-ui/validations/contactInformation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    firstName: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    lastName: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    jobTitle: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    })],
    salutation: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })]
  };
  _exports.default = _default;
});