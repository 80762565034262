define("cb-ui/templates/components/tcb-datepicker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tqXg2FxZ",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"bootstrap-datepicker\",null,[[\"data-test-date-picker\",\"placeholder\",\"class\",\"value\",\"autoclose\",\"todayHighlight\",\"format\",\"readonly\",\"disabled\",\"changeDate\",\"startDate\",\"endDate\",\"weekStart\",\"language\"],[true,[20,[\"placeholder\"]],[25,\"if\",[[20,[\"timelineDatePicker\"]],\"form-control tcb-datepicker-timeline pointer-cursor\",\"form-control pointer-cursor\"],null],[20,[\"datePickerValue\"]],true,true,[20,[\"dateFormat\"]],[20,[\"readonly\"]],[20,[\"disabled\"]],[25,\"action\",[[19,0,[]],\"changeTimelineDate\"],null],[25,\"if\",[[20,[\"dateLtStart\"]],null,[20,[\"startDate\"]]],null],[25,\"if\",[[20,[\"dateGtEndDate\"]],null,[20,[\"endDate\"]]],null],1,[20,[\"intl\",\"locale\",\"firstObject\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/tcb-datepicker.hbs"
    }
  });

  _exports.default = _default;
});