define("cb-ui/templates/components/label-request-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U8UKAwIJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"  \"],[6,\"label\"],[7],[0,\"\\n    \"],[11,1],[0,\"\\n    \"],[1,[18,\"label\"],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"label\"],[10,\"class\",[26,[\"control-label \",[25,\"if\",[[20,[\"invisibleLabel\"]],\"sr-only\"],null],\" \",[18,\"labelClass\"]]]],[10,\"for\",[18,\"formElementId\"],null],[7],[1,[18,\"label\"],false],[0,\"\\n\"],[4,\"if\",[[20,[\"tooltipText\"]]],null,{\"statements\":[[0,\"      \"],[6,\"span\"],[7],[0,\"\\n        \"],[4,\"bs-tooltip\",null,null,{\"statements\":[[1,[18,\"tooltipText\"],false]],\"parameters\":[]},null],[0,\"\\n        \"],[6,\"span\"],[9,\"class\",\"glyphicon glyphicon-info-sign pointer-cursor m-l-xs\"],[7],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/label-request-form.hbs"
    }
  });

  _exports.default = _default;
});