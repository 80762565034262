define("cb-ui/templates/submitted-info-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pBK1Q6za",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"bs-modal\",null,[[\"footer\",\"body\",\"onHidden\",\"size\"],[false,false,[25,\"route-action\",[\"closeSubmittedInfoModal\"],null],\"lg\"]],{\"statements\":[[4,\"component\",[[19,1,[\"body\"]]],null,{\"statements\":[[0,\"      \"],[6,\"h3\"],[9,\"class\",\"text-center\"],[7],[1,[25,\"t\",[\"app.modals.submitProposal.title\"],null],false],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n          \"],[6,\"div\"],[7],[1,[25,\"t\",[\"app.modals.submittedInfo.thankYou\"],null],false],[8],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"m-b\"],[7],[1,[25,\"t\",[\"app.modals.submittedInfo.details\"],null],false],[8],[0,\"\\n          \"],[6,\"button\"],[9,\"class\",\"btn btn-primary\"],[3,\"action\",[[19,0,[]],\"goToDashboard\"]],[7],[1,[25,\"t\",[\"buttons.goToDashboard\"],null],false],[0,\" \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/submitted-info-modal.hbs"
    }
  });

  _exports.default = _default;
});