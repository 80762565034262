define("cb-ui/controllers/app/project/client/announcements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    showAddAnnouncement: false,
    quote: null,
    announcements: null,
    sortedAnnouncements: Ember.computed.sort('announcements', 'sortProperties'),
    sortProperties: ['postedAt:desc'],
    userIsQuoteAdmin: Ember.computed.equal('userQuoteRole.role', 'admin'),
    localDocuments: new Ember.A(),
    loadQuoteAnnouncementData: function loadQuoteAnnouncementData(quoteAnnouncement) {
      var _this = this;

      var quoteAnnouncementDocuments = new Ember.A();
      var dataLoadedPromises = [];
      var documentsPromise = quoteAnnouncement.get('quoteAnnouncementDocuments');
      dataLoadedPromises.push(documentsPromise);
      documentsPromise.then(function (documents) {
        documents.forEach(function (document) {
          quoteAnnouncementDocuments.addObject(document);
        });
      });
      Ember.RSVP.all(dataLoadedPromises).catch(function () {
        _this.get('notify').error('Announcement details couldn\'t be loaded.');
      });
    },
    actions: {
      toggleAddAnnouncement: function toggleAddAnnouncement() {
        this.toggleProperty('showAddAnnouncement');
      },
      addAnnouncement: function addAnnouncement(announcementText) {
        var _this2 = this;

        var announcement = this.store.createRecord('quote-announcement', {
          quote: this.get('quote'),
          content: announcementText,
          user: this.get('currentUser'),
          quoteAnnouncementDocuments: this.get('localDocuments')
        });
        announcement.save().then(function () {
          _this2.set('showAddAnnouncement', false);

          _this2.set('localDocuments', new Ember.A());

          _this2.get('notify').success(_this2.get('intl').t('notifyMessages.announcementPosted').toString());
        }, function () {
          _this2.get('notify').error(_this2.get('intl').t('notifyMessages.errorOccurredVerifyFields').toString());

          announcement.unloadRecord();
        });
      },
      addQuoteAnnouncementDocument: function addQuoteAnnouncementDocument(file) {
        var _this3 = this;

        this.store.find('quote-announcement-document', file.id).then(function (currentFile) {
          var localDocuments = _this3.get('localDocuments');

          localDocuments.addObject(currentFile);

          _this3.get('notify').success('The file was uploaded and added');
        });
      },
      removeQuoteAnnouncementDocument: function removeQuoteAnnouncementDocument(file) {
        this.get('localDocuments').removeObject(file);
      }
    }
  });

  _exports.default = _default;
});