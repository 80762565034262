define("cb-ui/controllers/app/project/client/financial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    quote: null,
    currentUser: null,
    proposals: new Ember.A(),
    activeEvaluation: null,
    activeEvaluationCategory: null,
    awardedProposal: null,
    activeProposal: null,
    showEvaluationModal: null,
    showAwardProjectModal: null,
    isPreview: false,
    showFinancialDetailsModal: null,
    beautyContestItems: new Ember.A(),
    criteriaEvaluations: new Ember.A(),
    notYetRatedClass: '',
    consultantName: '',
    showAllProposals: false,
    showMandaysInfo: true,
    showFeePerRoleInfo: true,
    userIsQuoteAdmin: Ember.computed.equal('userQuoteRole.role', 'admin'),
    filteredProposals: Ember.computed('proposals.[]', 'showAllProposals', function () {
      if (!this.get('showAllProposals')) {
        var statuses = ['awarded', 'lost', 'submitted'];
        return this.get('proposals').filter(function (item) {
          if (statuses.includes(item.get('status'))) {
            return item;
          }
        });
      } else {
        return this.get('proposals');
      }
    }),
    consultantNameObserver: Ember.observer('activeEvaluationCategory', 'activeEvaluation', 'activeEvaluation.proposal', function () {
      var _this = this;

      if (this.get('activeEvaluation')) {
        this.get('activeEvaluation.proposal').then(function (proposal) {
          proposal.get('consultantUser').then(function (consultantUser) {
            _this.set('consultantName', consultantUser.get('fullName'));
          });
        });
      }
    }),
    colspanEmptyColumns: Ember.computed('proposal.length', function () {
      return 5 - this.get('filteredProposals.length');
    }),
    financialEvaluationColumns: Ember.computed('intl.locale.[]', 'filteredProposals.length', function () {
      var columns = [];
      var proposals = this.get('filteredProposals');

      if (proposals.length == 0) {
        columns.push({
          heading: '',
          valuePath: 'title',
          isFixed: 'left',
          width: 420,
          isResizable: true
        });
      } else {
        columns.push({
          heading: '',
          valuePath: 'title',
          isFixed: 'left',
          width: 420,
          isResizable: false
        });
      }

      proposals.forEach(function (p) {
        columns.push({
          valuePath: p.get('id'),
          company: p.get('company'),
          heading: '',
          headerComponent: 'ember-table/company-info'
        });
      });
      return columns;
    }),
    financialEvaluationRows: Ember.computed('intl.locale.[]', 'filteredProposals.length', function () {
      var proposals = this.get('filteredProposals');
      var totalFee = {};
      var otherCosts = {};
      var totalFeeWithOtherCosts = {};
      var discount = {};
      var totalVolume = {};
      var durationInWeeks = {};
      var durationInWorkingDays = {};
      var paymentTerms = {};
      totalFee['title'] = {
        title: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.totalFee'),
        cellComponent: 'ember-table/proposal-role'
      };
      otherCosts['title'] = {
        title: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.otherCosts'),
        cellComponent: 'ember-table/proposal-role'
      };
      totalFeeWithOtherCosts['title'] = {
        title: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.totalFeeWithOtherCosts'),
        cellComponent: 'ember-table/proposal-role'
      };
      discount['title'] = {
        title: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.discount'),
        cellComponent: 'ember-table/proposal-role'
      };
      totalVolume['title'] = {
        title: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.totalVolume'),
        cellComponent: 'ember-table/proposal-role'
      };
      durationInWeeks['title'] = {
        title: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.durationInWeeks'),
        cellComponent: 'ember-table/proposal-role'
      };
      durationInWorkingDays['title'] = {
        title: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.durationInWorkingDays'),
        cellComponent: 'ember-table/proposal-role'
      };
      var showPaymentTerms = false;
      proposals.forEach(function (p) {
        if (p.get('fixedFeePaymentTerms') != "") showPaymentTerms = true;
      });

      if (showPaymentTerms) {
        paymentTerms['title'] = {
          title: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.paymentTerms'),
          cellComponent: 'ember-table/proposal-role'
        };
      }

      proposals.forEach(function (p) {
        totalFee[p.get('id')] = {
          proposal: p,
          rate: p.get('financialQuotationEvaluationData')['financial_quotation_fee'],
          cellComponent: 'ember-table/proposal-rates-cell'
        };
        otherCosts[p.get('id')] = {
          proposal: p,
          rate: p.get('financialQuotationEvaluationData')['supplements_fee_value'],
          cellComponent: 'ember-table/proposal-rates-cell'
        };
        totalFeeWithOtherCosts[p.get('id')] = {
          proposal: p,
          rate: p.get('financialQuotationEvaluationData')['financial_quotation_fee'] + p.get('financialQuotationEvaluationData')['supplements_fee_value'],
          cellComponent: 'ember-table/proposal-rates-cell'
        };

        if (isNaN(p.get('timeMaterialsRebate')) && isNaN(p.get('fixedFeeRebate'))) {
          discount[p.get('id')] = {
            proposal: p,
            rate: 0,
            cellComponent: 'ember-table/proposal-rates-cell'
          };
        } else {
          discount[p.get('id')] = {
            proposal: p,
            rate: p.get('timeMaterialsRebate') + p.get('fixedFeeRebate'),
            cellComponent: 'ember-table/proposal-rates-cell'
          };
        }

        totalVolume[p.get('id')] = {
          proposal: p,
          rate: p.get('financialQuotationTotal'),
          cellComponent: 'ember-table/proposal-rates-cell'
        };
        durationInWeeks[p.get('id')] = {
          proposal: p,
          mandays: 1 * p.get('durationInWeeks'),
          cellComponent: 'ember-table/proposal-role-mandays'
        };
        durationInWorkingDays[p.get('id')] = {
          proposal: p,
          mandays: 5 * p.get('durationInWeeks'),
          cellComponent: 'ember-table/proposal-role-mandays'
        };

        if (showPaymentTerms) {
          paymentTerms[p.get('id')] = {
            proposal: p,
            mandays: p.get('fixedFeePaymentTerms'),
            cellComponent: 'ember-table/proposal-role-mandays'
          };
        }
      });

      if (showPaymentTerms) {
        return [totalFee, otherCosts, totalFeeWithOtherCosts, discount, totalVolume, durationInWeeks, durationInWorkingDays, paymentTerms];
      } else {
        return [totalFee, otherCosts, totalFeeWithOtherCosts, discount, totalVolume, durationInWeeks, durationInWorkingDays];
      }
    }),
    blendedWeightedRateColumns: Ember.computed('intl.locale.[]', 'filteredProposals.length', function () {
      var columns = [];
      var proposals = this.get('filteredProposals');

      if (proposals.length == 0) {
        columns.push({
          heading: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.dailyRate'),
          valuePath: 'title',
          isFixed: 'left',
          width: 420,
          isResizable: true
        });
      } else {
        columns.push({
          heading: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.dailyRate'),
          valuePath: 'title',
          isFixed: 'left',
          width: 420,
          isResizable: false
        });
      }

      proposals.forEach(function (p) {
        columns.push({
          valuePath: p.get('id'),
          company: p.get('company'),
          heading: '',
          headerComponent: 'ember-table/company-info'
        });
      });
      return columns;
    }),
    blendedWeightedRateRows: Ember.computed('intl.locale.[]', 'filteredProposals.length', function () {
      var proposals = this.get('filteredProposals');
      var roles = [];
      var res = [];
      proposals.forEach(function (item) {
        var data = item.get('arithmeticAndWeightedAverageDailyRate');

        for (var key in data) {
          if (key != 'total' && roles.indexOf(key) < 0) {
            roles.push(key);
          }
        }
      });
      var blendedAverage = {};
      var weightedAverage = {};
      blendedAverage['title'] = {
        title: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.blendedAverageDailyRate'),
        cellComponent: 'ember-table/proposal-role'
      };
      weightedAverage['title'] = {
        title: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.weightedAverageDailyRate'),
        cellComponent: 'ember-table/proposal-role'
      };
      proposals.forEach(function (p) {
        var total = p.get('arithmeticAndWeightedAverageDailyRate')['total'];
        var ba, wa;

        if (total) {
          ba = total['arithmetic_average_daily_rate'];
          wa = total['weighted_average_daily_rate'];
        } else {
          ba = 0;
          wa = 0;
        }

        blendedAverage[p.get('id')] = {
          proposal: p,
          rate: ba,
          cellComponent: 'ember-table/proposal-rates-cell'
        };
        weightedAverage[p.get('id')] = {
          proposal: p,
          rate: wa,
          cellComponent: 'ember-table/proposal-rates-cell'
        };
      });
      res.push(blendedAverage);
      res.push(weightedAverage);
      return res;
    }),
    columns: Ember.computed('intl.locale.[]', 'filteredProposals.length', function () {
      var columns = [];
      var proposals = this.get('filteredProposals');

      if (proposals.length == 0) {
        columns.push({
          heading: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.details'),
          valuePath: 'title',
          isFixed: 'left',
          width: 420,
          isResizable: true
        });
      } else {
        columns.push({
          heading: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.details'),
          valuePath: 'title',
          isFixed: 'left',
          width: 420,
          isResizable: false
        });
      }

      proposals.forEach(function (p) {
        columns.push({
          valuePath: p.get('id'),
          company: p.get('company'),
          heading: '',
          headerComponent: 'ember-table/company-info'
        });
      });
      return columns;
    }),
    rows: Ember.computed('intl.locale.[]', 'filteredProposals.length', function () {
      var quote = this.get('quote');
      var proposals = this.get('filteredProposals');
      var averageDailyData = {};
      var supplementsData = {};
      var totalProjectData = {};
      var projectVolumeAtAwardingData = {};
      var finalEvaluationData = {};
      var rankData = {};

      if (quote.get('timeMaterial')) {
        averageDailyData['title'] = {
          title: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.averageDailyRate'),
          description: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.averageDailyRateDetails'),
          cellComponent: 'ember-table/criteria-info'
        };
      }

      supplementsData['title'] = {
        title: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.supplements'),
        description: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.supplementsDetails'),
        cellComponent: 'ember-table/criteria-info'
      };
      totalProjectData['title'] = {
        title: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.totalProject'),
        description: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.totalProjectDetails'),
        cellComponent: 'ember-table/criteria-info'
      };
      projectVolumeAtAwardingData['title'] = {
        title: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.totalProjectAtAwarding'),
        description: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.totalProjectAtAwardingDetails'),
        cellComponent: 'ember-table/criteria-info'
      };
      finalEvaluationData['title'] = {
        title: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.finalEvaluationFinancialProposal'),
        description: this.get('intl').t('app.projectClient.evaluation.label.financialProposals.finalEvaluationFinancialProposalDetails'),
        cellComponent: 'ember-table/criteria-info'
      };
      rankData['title'] = this.get('intl').t('app.projectClient.evaluation.label.rank');
      proposals.forEach(function (p) {
        var transitions = p.get('finalProposalTotalHistory') ? JSON.parse(p.get('finalProposalTotalHistory').replaceAll("'", "\"")) : {
          transitions: []
        };

        if (quote.get('timeMaterial')) {
          averageDailyData[p.get('id')] = {
            proposal: p,
            cellComponent: 'ember-table/timematerial-average-daily-cell'
          };
        }

        supplementsData[p.get('id')] = {
          proposal: p,
          cellComponent: 'ember-table/financial-supplements-cell'
        };
        totalProjectData[p.get('id')] = {
          proposal: p,
          transitions: transitions,
          cellComponent: 'ember-table/financial-total-project-cell'
        };
        projectVolumeAtAwardingData[p.get('id')] = {
          proposal: p,
          timestamp: transitions.transitions.length > 0 ? transitions.transitions[transitions.transitions.length - 1].timestamp : null,
          changedBy: transitions.transitions.length > 0 ? transitions.transitions[transitions.transitions.length - 1].changed_by : null,
          cellComponent: 'ember-table/financial-total-proposal-cell'
        };
        finalEvaluationData[p.get('id')] = {
          proposal: p,
          cellComponent: 'ember-table/financial-final-evaluation-cell'
        };
        rankData[p.get('id')] = {
          proposal: p,
          cellComponent: 'ember-table/financial-rank-cell'
        };
      });

      if (this.get('userIsQuoteAdmin')) {
        return [averageDailyData, supplementsData, totalProjectData, projectVolumeAtAwardingData, finalEvaluationData, rankData];
      }

      return [averageDailyData, supplementsData, totalProjectData, finalEvaluationData, rankData];
    }),
    actions: {
      openFinancialDetailsModal: function openFinancialDetailsModal(proposal) {
        this.set('activeProposal', proposal);
        this.set('showFinancialDetailsModal', true);
      },
      closeFinancialDetailsModal: function closeFinancialDetailsModal() {
        this.set('showFinancialDetailsModal', false);
        this.set('activeProposal', null);
      }
    }
  });

  _exports.default = _default;
});