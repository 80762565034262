define("cb-ui/components/form-group", ["exports", "ember-rapid-forms/components/form-group"], function (_exports, _formGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import Ember from 'ember';
  var _default = _formGroup.default;
  _exports.default = _default;
});