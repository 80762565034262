define("cb-ui/models/quote", ["exports", "cb-ui/config/environment", "ember-data", "moment", "ember-api-actions"], function (_exports, _environment, _emberData, _moment, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;

  var _default = _emberData.default.Model.extend({
    //visual document editor attrs
    session: Ember.inject.service(),
    sections: _emberData.default.hasMany('document-section', {
      async: true
    }),
    rootSections: Ember.computed.filterBy('sections', 'parent.id', undefined),
    //end visual document editor
    proposals: hasMany('proposal', {
      async: true
    }),
    stepCompletion: attr(),
    published: _emberData.default.attr('boolean'),
    createdAt: attr('date'),
    requestedAt: attr('date'),
    publishedAt: attr('date'),
    completedAt: attr('date'),
    uuid: _emberData.default.attr(),
    updatedAt: _emberData.default.attr('date'),
    status: attr('string'),
    statusChangeReason: attr('string'),
    //quote language
    projectLanguage: attr('string', {
      defaultValue: 'en'
    }),
    country: _emberData.default.belongsTo('country', {
      async: true
    }),
    city: _emberData.default.attr('string'),
    businessType: _emberData.default.belongsTo('business-type', {
      async: true
    }),
    industryStandard: _emberData.default.belongsTo('industry-standard', {
      async: true
    }),
    serviceStandard: _emberData.default.belongsTo('service-standard', {
      async: true
    }),
    projectGoals: _emberData.default.hasMany('project-goal', {
      async: true
    }),
    projectGoalsOrder: attr(),
    budgetEstimate: attr(),
    budgetEstimateCurrency: attr(),
    budgetApprovalStatus: attr(),
    additionalInformation: attr('string'),
    strategicExecutionBoard: attr(),
    quoteProjectGoals: _emberData.default.hasMany('quote-project-goal', {
      async: true
    }),
    quoteServiceProviders: _emberData.default.hasMany('quote-service-provider', {
      async: true
    }),
    quoteContacts: _emberData.default.hasMany('quote-contact', {
      async: true,
      inverse: 'quote'
    }),
    quoteCompany: belongsTo('company', {
      async: true,
      inverse: 'companyQuote'
    }),
    companySizeTurnover: _emberData.default.belongsTo('company-size-turnover', {
      async: true
    }),
    companySizeEmployees: _emberData.default.belongsTo('company-size-employee', {
      async: true
    }),
    mainLocation: _emberData.default.belongsTo('country', {
      async: true
    }),
    secondaryLocations: _emberData.default.hasMany('country', {
      async: true
    }),
    mainLanguage: _emberData.default.belongsTo('language', {
      async: true
    }),
    proposalLanguage: _emberData.default.belongsTo('language', {
      async: true
    }),
    secondaryLanguages: _emberData.default.hasMany('language', {
      async: true
    }),
    projectContext: _emberData.default.belongsTo('project-context', {
      async: true
    }),
    startDate: _emberData.default.attr('date'),
    endDate: _emberData.default.attr('date'),
    projectPhases: _emberData.default.hasMany('project-phase', {
      async: true
    }),
    // company details regarding quote
    companyName: _emberData.default.attr('string'),
    useDefaultCompany: attr('boolean', {
      defaultValue: true
    }),
    companyAddressLineOne: attr('string'),
    companyAddressLineTwo: attr('string'),
    companyAddressLineThree: attr('string'),
    companyPostalCode: attr('string'),
    companyCity: attr('string'),
    companyWebsite: attr('string'),
    companyCountry: belongsTo('country', {
      async: true
    }),
    ownerName: _emberData.default.attr('string'),
    owner: belongsTo('user', {
      async: true
    }),
    projectName: attr('string'),
    projectRole: _emberData.default.belongsTo('project-role', {
      async: true
    }),
    extraProjectRole: _emberData.default.attr('string'),
    companies: _emberData.default.hasMany('company', {
      async: true
    }),
    currentStep: _emberData.default.attr('number'),
    saveAsDraft: _emberData.default.attr('boolean'),
    // Project-details - RfP Content attributes
    // financialProposal and attributes
    financialProposal: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    timeMaterial: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    fixedFee: _emberData.default.attr('boolean'),
    successBased: _emberData.default.attr('boolean'),
    hybridModel: _emberData.default.attr('boolean'),
    // technicalProposal and attributes
    technicalProposal: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    approachPresentation: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    projectReferences: _emberData.default.attr('boolean'),
    outlineProjectPhases: _emberData.default.attr('boolean'),
    companyPortrait: _emberData.default.attr('boolean'),
    //teamOverview and attributes
    teamOverview: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    standardProfile: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    projectsWorkedOn: _emberData.default.attr('boolean'),
    //beautyContest and attributes
    beautyContest: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    beautyItems: hasMany('beauty-item', {
      async: true
    }),
    blockNotifications: _emberData.default.attr('boolean'),
    // Project-details - Questions & Documents attributes
    questionRounds: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    publicQuestions: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    undisclosedQuestions: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    documents: _emberData.default.hasMany('quote-document', {
      async: true,
      inverse: 'quote'
    }),
    timelineEntries: _emberData.default.hasMany('quote-timeline-entries', {
      async: true,
      inverse: 'quote'
    }),
    uploadedCoverLetter: attr('boolean', {
      defaultValue: false
    }),
    coverLetterTypeWasChosen: attr('boolean', {
      defaultValue: false
    }),
    coverLetterDocument: belongsTo('quote-cover-letter-document', {
      async: true,
      inverse: 'quote'
    }),
    // Project-details - RfP Timeline attributes
    rfpReleaseDueDate: attr('date'),
    proposalDueDate: attr('date'),
    projectAwardDate: attr('date'),
    presentationStartDate: attr('date'),
    presentationEndDate: attr('date'),
    deadlineTime: attr('string', {
      defaultValue: 'end-of-day'
    }),
    proposalValidityWeeks: attr('number', {
      defaultValue: 8
    }),
    // Project-details - Contact Information attributes
    personalContactInformation: attr('boolean'),
    noPersonalContactInformation: attr('boolean', {
      defaultValue: true
    }),
    meAsPersonContact: attr('boolean'),
    anotherAsPersonContact: attr('boolean'),
    //project administrator info
    personSalutation: belongsTo('salutation', {
      async: true
    }),
    personRole: attr('string'),
    personFirstName: attr('string'),
    personLastName: attr('string'),
    projectAdministratorTitle: belongsTo('academic-title', {
      async: true
    }),
    projectAdministratorJobTitle: attr('string'),
    projectAdministratorEmail: attr('string'),
    projectAdministratorPhone: attr('string'),
    //project manager info
    projectManagerSalutation: belongsTo('salutation', {
      async: true
    }),
    projectManagerTitle: belongsTo('academic-title', {
      async: true
    }),
    projectManagerFirstName: attr('string'),
    projectManagerLastName: attr('string'),
    projectManagerJobTitle: attr('string'),
    projectManagerEmail: attr('string'),
    projectManagerPhone: attr('string'),
    //project sponsor info
    projectSponsorSalutation: belongsTo('salutation', {
      async: true
    }),
    projectSponsorTitle: belongsTo('academic-title', {
      async: true
    }),
    projectSponsorFirstName: attr('string'),
    projectSponsorLastName: attr('string'),
    projectSponsorJobTitle: attr('string'),
    projectSponsorEmail: attr('string'),
    projectSponsorPhone: attr('string'),
    // Contact Information end
    questions: _emberData.default.hasMany('question', {
      async: true
    }),
    quoteAnnouncements: _emberData.default.hasMany('quote-announcement', {
      async: true
    }),
    sectionKeys: attr(),
    currency: belongsTo('currency', {
      async: true
    }),
    //Placeholders
    projectManagerFunction: attr('string'),
    steeringCommitteeHead: attr('string'),
    clientIntroduction: attr('string'),
    initialSituation: attr('string'),
    //project steps completion
    projectFirstStepCompleted: attr('boolean', {
      defaultValue: false
    }),
    projectSecondStepCompleted: attr('boolean', {
      defaultValue: false
    }),
    projectThirdStepCompleted: attr('boolean', {
      defaultValue: false
    }),
    // Service Line
    serviceType: belongsTo('service-type', {
      async: true
    }),
    pitchSession: attr('boolean', {
      defaultValue: false
    }),
    // criteria evaluation percentages
    financialWeight: attr('number', {
      defaultValue: 50
    }),
    technicalWeight: attr('number', {
      defaultValue: 50
    }),
    pitchWeight: attr('number', {
      defaultValue: 0
    }),
    //remote evaluation flag
    remoteConsulting: attr(),
    // evaluation status for each type
    technicalEvaluationIsActive: attr('boolean'),
    pitchEvaluationIsActive: attr('boolean'),
    // closed envelope
    closedEnvelope: attr('boolean'),
    companyAlias: attr(),
    otherMentions: attr('string'),
    organizationProjectReference: attr('string'),
    getEvaluationResults: (0, _emberApiActions.memberAction)({
      path: 'evaluation-results/',
      type: 'post'
    }),
    quoteProposals: (0, _emberApiActions.memberAction)({
      path: 'quote-proposals/',
      type: 'post'
    }),
    isAxa: Ember.computed('owner.id', function () {
      if (this.get('owner').get('id') == _environment.default.axaUserId) {
        return true;
      }

      return false;
    }),
    isSiemens: Ember.computed('owner.id', function () {
      if (this.get('owner').get('id') == _environment.default.siemensUserId) {
        return true;
      }

      return false;
    }),
    isSwisscard: Ember.computed('owner.id', function () {
      if (this.get('owner').get('id') == _environment.default.swisscardUserId) {
        return true;
      }

      return false;
    }),
    isAldi: Ember.computed('ownder.id', function () {
      if (this.get('owner').get('id') == _environment.default.aldiUserId) {
        return true;
      }

      return false;
    }),
    isGea: Ember.computed('ownder.id', function () {
      if (this.get('owner').get('id') == _environment.default.geaUserId) {
        return true;
      }

      return false;
    }),
    isCovestro: Ember.computed('ownder.id', function () {
      if (this.get('owner').get('id') == _environment.default.covestroUserId) {
        return true;
      }

      return false;
    }),
    allTimelineEntries: Ember.computed('pitchSession', 'pitchEvaluationIsActive', 'timelineEntries.@each.name', 'timelineEntries.@each.date', 'rfpReleaseDueDate', 'proposalDueDate', 'projectAwardDate', 'presentationStartDate', 'presentationEndDate', function () {
      var allEntries = [];
      allEntries = allEntries.concat(this.getStandardTimelineEntries());
      this.get('timelineEntries').forEach(function (entry) {
        allEntries.addObject(entry);
      });
      allEntries = allEntries.sortBy('date');
      return allEntries;
    }),
    getStandardTimelineEntries: function getStandardTimelineEntries() {
      var entries = [];
      var fields = ['rfpReleaseDueDate', 'proposalDueDate', 'projectAwardDate', 'startDate', 'endDate'];

      if (this.get('pitchSession')) {
        fields = fields.concat(['presentationStartDate', 'presentationEndDate']);
      }

      for (var i = 0; i < fields.length; i++) {
        var newEntry = this.store.createRecord('quote-timeline-entry', {
          name: fields[i],
          date: this.get(fields[i]),
          isCustom: false
        });
        entries.addObject(newEntry);
      }

      return entries;
    },
    intID: Ember.computed('id', function () {
      return parseInt(this.get('id'), 10);
    }),
    statusAlias: Ember.computed('id', 'intl.locale.[]', 'status', function () {
      var projectStatus = this.get('status');
      return this.get('intl').t('projectStatuses.' + projectStatus).toString();
    }),
    monetaryProjectGoals: Ember.computed.filterBy('projectGoals', 'isMonetary', true),
    nonMonetaryProjectGoals: Ember.computed.filterBy('projectGoals', 'isMonetary', false),
    consultantsNames: Ember.computed('companies', function () {
      return _emberData.default.PromiseObject.create({
        promise: this.get('companies').then(function (companies) {
          return {
            names: companies.mapBy('companyName').join()
          };
        })
      });
    }),
    questionsRound1IsNotOpen: Ember.computed('rfpReleaseDueDate', function () {
      if (this.get('rfpReleaseDueDate')) {
        return (0, _moment.default)() < (0, _moment.default)(this.get('rfpReleaseDueDate')).add({
          hours: 23,
          minutes: 59,
          seconds: 59
        });
      } else {
        return false;
      }
    }),
    questionsRound1IsOpen: Ember.computed('rfpReleaseDueDate', 'firstQuestionsDueDate', function () {
      if (this.get('rfpReleaseDueDate') && this.get('firstQuestionsDueDate')) {
        return (0, _moment.default)() >= (0, _moment.default)(this.get('rfpReleaseDueDate')).add({
          hours: 23,
          minutes: 59,
          seconds: 59
        }) && (0, _moment.default)() <= (0, _moment.default)(this.get('firstQuestionsDueDate')).add({
          hours: 23,
          minutes: 59,
          seconds: 59
        });
      } else {
        return false;
      }
    }),
    questionsRound1IsClosed: Ember.computed('firstQuestionsDueDate', function () {
      if (this.get('firstQuestionsDueDate')) {
        return (0, _moment.default)() > (0, _moment.default)(this.get('firstQuestionsDueDate')).add({
          hours: 23,
          minutes: 59,
          seconds: 59
        });
      } else {
        return false;
      }
    }),
    questionsRound2IsNotOpen: Ember.computed('firstQuestionsDueDate', function () {
      if (this.get('firstQuestionsDueDate')) {
        return (0, _moment.default)() < (0, _moment.default)(this.get('firstQuestionsDueDate')).add({
          hours: 23,
          minutes: 59,
          seconds: 59
        });
      } else {
        return false;
      }
    }),
    questionsRound2IsOpen: Ember.computed('firstQuestionsDueDate', 'secondQuestionsDueDate', function () {
      if (this.get('secondQuestionsDueDate') && this.get('firstQuestionsDueDate')) {
        return (0, _moment.default)() <= (0, _moment.default)(this.get('secondQuestionsDueDate')).add({
          hours: 23,
          minutes: 59,
          seconds: 59
        }) && (0, _moment.default)() > (0, _moment.default)(this.get('firstQuestionsDueDate')).add({
          hours: 23,
          minutes: 59,
          seconds: 59
        });
      } else {
        return false;
      }
    }),
    questionsRound2IsClosed: Ember.computed('secondQuestionsDueDate', function () {
      if (this.get('secondQuestionsDueDate')) {
        return (0, _moment.default)() > (0, _moment.default)(this.get('secondQuestionsDueDate')).add({
          hours: 23,
          minutes: 59,
          seconds: 59
        });
      } else {
        return false;
      }
    }),
    // Reset errors for belongsTo Relations
    industryStandardObserver: Ember.observer('industryStandard', function () {
      this.get('errors').remove('industryStandard');
    }),
    serviceStandardObserver: Ember.observer('serviceStandard', function () {
      this.get('errors').remove('serviceStandard');
    }),
    mainLanguageObserver: Ember.observer('mainLanguage', function () {
      this.get('errors').remove('mainLanguage');
    }),
    projectContextObserver: Ember.observer('projectContext', function () {
      this.get('errors').remove('projectContext');
    }),
    projectPhasesObserver: Ember.observer('projectPhases', function () {
      this.get('errors').remove('projectPhases');
    }),
    projectRoleObserver: Ember.observer('projectRole', function () {
      this.get('errors').remove('projectRole');
    }),
    companyObserver: Ember.observer('company', function () {
      this.get('errors').remove('company');
    }),
    countryObserver: Ember.observer('country', function () {
      this.get('errors').remove('country');
    }),
    businessTypeObserver: Ember.observer('businessType', function () {
      this.get('errors').remove('businessType');
    }),
    mainLocationObserver: Ember.observer('mainLocation', function () {
      this.get('errors').remove('mainLocation');
    }),
    projectGoalsObserver: Ember.observer('projectGoals', function () {
      this.get('errors').remove('projectGoals');
    }),
    companySizeObserver: Ember.observer('companySizeTurnover', 'companySizeEmployees', function () {
      this.get('errors').remove('companySizeTurnover');
      this.get('errors').remove('companySizeEmployees');
    }),
    personSalutationObserver: Ember.observer('personSalutation', function () {
      this.get('errors').remove('personSalutation');
    }),
    serviceTypeObserver: Ember.observer('serviceType', function () {
      this.get('errors').remove('serviceType');
    }),
    companyCountryObserver: Ember.observer('companyCountry', function () {
      this.get('errors').remove('companyCountry');
    }),
    downloadRfpPDFUrl: Ember.computed('id', 'session.data.authenticated.token', function () {
      var jwt = this.get('session.data.authenticated.token');
      return _environment.default.backendAPI + '/api/download_rfp_pdf/' + this.get('id').toString() + '/?auth_token=' + jwt;
    })
  });

  _exports.default = _default;
});