define("cb-ui/models/salutation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    salutationEn: _emberData.default.attr('string'),
    salutationDe: _emberData.default.attr('string'),
    published: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    quoteContacts: _emberData.default.hasMany('quote-contact', {
      async: true,
      inverse: 'salutation'
    })
  });

  _exports.default = _default;
});