define("cb-ui/routes/app/project/client/proposals", ["exports", "cb-ui/mixins/scrollbar-reset", "cb-ui/utils/download-file"], function (_exports, _scrollbarReset, _downloadFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollbarReset.default, {
    model: function model() {
      var quote = this.modelFor('app.project');
      return Ember.RSVP.hash({
        quote: quote,
        proposals: this.store.query('proposal', {
          quote_id: quote.get('id'),
          inactive: true
        }),
        currentUser: this.get('sessionContext.currentUser'),
        userQuoteRoleList: this.store.query('user-quote-role', {
          quote_id: quote.get('id')
        }) //workerRoles: this.store.findAll('organization-worker-role')

      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('quote', model.quote);
      controller.set('userQuoteRole', model.userQuoteRoleList.get('firstObject'));
      controller.set('proposals', model.proposals); //controller.set('workerRoles', model.workerRoles);

      controller.set('currentUser', model.currentUser);
      var atLeastOneActive = false;

      if (model.proposals.get('length') > 0) {
        model.proposals.forEach(function (proposal) {
          if (proposal.get('isActive') && !atLeastOneActive) {
            atLeastOneActive = true;
            controller.set('selectedProposal', proposal);
            controller.set('selectedCompany', proposal.get('company.content'));
          }
        });

        if (!atLeastOneActive) {
          controller.set('selectedProposal', model.proposals.get('firstObject'));
          controller.set('selectedCompany', model.proposals.get('firstObject').get('company.content'));
        }

        var qs = new Ember.A();
        var cqs = new Ember.A();
        model.proposals.forEach(function (proposal) {
          if (proposal.get('company.content') === model.proposals.get('firstObject').get('company.content')) {
            qs.insertAt(0, proposal);
          }

          if (!cqs.includes(proposal.get('company.content'))) {
            cqs.push(proposal.get('company.content'));
          }
        });
        controller.set('selectedProposals', qs);
        controller.set('companies', cqs);
      } else {
        controller.set('selectedProposal', null);
        controller.set('selectedCompany', null);
      }
    },
    actions: {
      newDocumentToken: function newDocumentToken(id) {
        this.store.findRecord('proposal-document', id).then(function (res) {
          (0, _downloadFile.default)(res.data.file, res.get('fileName'));
        });
      },
      willTransition: function willTransition(transition) {
        var store = this.store;
        store.peekAll('proposal').map(function (model) {
          if (!model.get('isActive')) {
            store.unloadRecord(model);
          }
        });

        this._super(transition);
      }
    }
  });

  _exports.default = _default;
});