define("cb-ui/controllers/app/management-consultant/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    company: null,
    companyUsers: new Ember.A(),
    currentUser: null,
    //fields for new user account modal
    email: null,
    firstName: null,
    lastName: null,
    isCompanyAdmin: null,
    showNewMemberModal: null,
    companyName: Ember.computed.alias('company.companyName'),
    columns: Ember.computed(function () {
      return [{
        propertyName: 'id',
        title: 'ID'
      }, {
        propertyName: 'email',
        title: 'Email'
      }, {
        propertyName: 'firstName',
        title: 'First name '
      }, {
        propertyName: 'lastName',
        title: 'Last name '
      }, {
        propertyName: 'isCompanyAdmin',
        title: 'Is company admin'
      }, {
        title: 'Actions',
        component: 'models-table/management',
        className: 'models-table__actions-10'
      }];
    }),
    validateEmail: function validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    closeModal: function closeModal() {
      this.setProperties({
        email: null,
        firstName: null,
        lastName: null,
        isCompanyAdmin: null,
        showNewMemberModal: false
      });
    },
    actions: {
      showMemberModal: function showMemberModal() {
        this.set('showNewMemberModal', true);
      },
      closeMemberModal: function closeMemberModal() {
        this.closeModal();
      },
      saveNewMember: function saveNewMember() {
        var _this = this;

        var currentUser = this.get('currentUser');
        var firstName = this.get('firstName');
        var lastName = this.get('lastName');
        var email = this.get('email');
        var isCompanyAdmin = this.get('isCompanyAdmin');
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (firstName && firstName.trim() && lastName && lastName.trim() && email && email.trim()) {
            if (_this.validateEmail(email)) {
              return currentUser.createCompanyUser({
                first_name: firstName,
                last_name: lastName,
                email: email,
                is_company_admin: isCompanyAdmin
              }).then(function () {
                _this.get('notify').success('The account with email ' + email.trim() + ' was created');

                _this.closeModal();

                resolve();

                _this.send('refreshRoute');
              }).catch(function () {
                _this.get('notify').error(_this.get('intl').t('notifyMessages.userAlreadyExists').toString());

                reject();
              });
            } else {
              _this.get('notify').error(_this.get('intl').t('notifyMessages.provideValidEmail').toString());

              reject();
            }
          } else {
            _this.get('notify').error(_this.get('intl').t('app.management.index.requiredFields').toString());

            reject();
          }
        });
      },
      editProposals: function editProposals(user) {
        this.transitionToRoute('app.managementConsultant.proposals', user.get('id'));
      }
    }
  });

  _exports.default = _default;
});