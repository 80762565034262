define("cb-ui/controllers/app/account/change-password", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    user: null,
    password: null,
    newPassword: null,
    retypeNewPassword: null,
    passwordsMatch: true,
    errors: Ember.computed(function () {
      return _emberData.default.Errors.create();
    }),
    passwordMatchingObserver: Ember.observer('user.password', 'user.retypePassword', function () {
      var user = this.get('user');
      var newPassword = user.get('password');
      var retypePassword = user.get('retypePassword');

      if (newPassword && retypePassword !== newPassword) {
        user.get('errors').add('retypePassword', 'Values don\'t match. Please correct the password.');
      }

      if (this.get('newPassword') !== this.get('retypeNewPassword')) {
        this.set('passwordsMatch', false);
      } else {
        this.set('passwordsMatch', true);
      }
    }),
    actions: {
      changePassword: function changePassword() {
        var _this = this;

        var currentPassword = this.get('user.currentPassword');

        if (currentPassword) {
          if (this.get('passwordsMatch')) {
            var user = this.get('user');
            user.set('currentPassword', currentPassword);
            user.save({
              adapterOptions: {
                change_password: true
              }
            }).then(function () {
              user.set('currentPassword', null);

              _this.transitionToRoute('app.account');

              Ember.run.next(_this, function () {
                _this.get('notify').success(_this.get('intl').t('notifyMessages.passwordChanged').toString());
              });
            }).catch(function () {
              _this.get('notify').error(_this.get('intl').t('notifyMessages.errorOccurredVerifyFields').toString());
            });
          } else {
            this.get('notify').error(this.get('intl').t('notifyMessages.correctNewPassword').toString());
          }
        } else {
          this.get('notify').error(this.get('intl').t('notifyMessages.fillInCurrentPassword').toString());
        }
      }
    }
  });

  _exports.default = _default;
});