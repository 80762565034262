define("cb-ui/components/ember-table/evaluation-award-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    cellValue: null,
    proposal: Ember.computed.alias('cellValue.proposal'),
    actions: {
      openAwardProjectModal: function openAwardProjectModal(proposal) {
        this.sendAction('openAwardProjectModal', proposal);
      },
      openDismissProjectModal: function openDismissProjectModal(proposal) {
        this.sendAction('openDismissProjectModal', proposal);
      }
    }
  });

  _exports.default = _default;
});