define("cb-ui/controllers/app/consultants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    companies: new Ember.A(),
    company: null,
    showConsultantModal: null,
    currentUser: null,
    appLanguage: Ember.computed.alias('currentUser.language'),
    sortServicesProperty: Ember.computed('appLanguage', function () {
      return ['service' + this.get('appLanguage')];
    }),
    sortIndustriesProperty: Ember.computed('appLanguage', function () {
      return ['industry' + this.get('appLanguage')];
    }),
    sortedCompanyServices: Ember.computed.sort('company.services', 'sortServicesProperty'),
    sortedCompanyIndustries: Ember.computed.sort('company.industries', 'sortIndustriesProperty'),
    columns: Ember.computed(function () {
      return [{
        propertyName: 'companyName',
        title: 'Consultant Firm'
      }, {
        propertyName: 'globalAddress.country.commonNameEn',
        title: 'Country'
      }, {
        propertyName: 'globalAddress.city',
        title: 'City '
      }, {
        propertyName: 'consultantType.consultantTypeEn',
        title: 'Type'
      }, {
        propertyName: 'industriesNames.names',
        title: 'Industries'
      }, {
        propertyName: 'specializationsNames.names',
        title: 'Specializations'
      }, {
        title: 'Actions',
        template: 'models-table/select-company'
      }];
    }),
    actions: {
      showConsultantInfoModal: function showConsultantInfoModal(consultant) {
        this.set('company', consultant);
        this.set('showConsultantModal', consultant);
      },
      close: function close() {
        this.set('showConsultantModal', false);
      }
    }
  });

  _exports.default = _default;
});