define("cb-ui/routes/app/dashboard/consultant", ["exports", "cb-ui/utils/append-access-token", "cb-ui/utils/get-target-domain"], function (_exports, _appendAccessToken, _getTargetDomain) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.findAll('proposal');
    },
    beforeModel: function beforeModel() {
      window.location = (0, _appendAccessToken.default)("".concat((0, _getTargetDomain.default)(), "/dashboard/rfp"), this.get('session.data.authenticated.token'));
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('quotes', this.modelFor('app.dashboard'));
      controller.set('proposals', model);
      controller.set('showPreviewModal', false);
      controller.set('showFullTnCs', false);
      controller.set('proposal', null);
      controller.set('showNDA', false);
    }
  });

  _exports.default = _default;
});