define("cb-ui/controllers/app/templates/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['quoteId', 'technicalTemplateId', 'pitchTemplateId', 'projectConfiguration'],
    notify: Ember.inject.service(),
    template: null,
    groups: new Ember.A(),
    groupTitle: null,
    isLoading: null,
    totalUsedWeight: Ember.computed('groups.@each.baseCriteriaEvaluations', function () {
      var groups = this.get('groups');
      var weight = 0;
      groups.forEach(function (group) {
        group.get('baseCriteriaEvaluations').forEach(function (bce) {
          weight += bce.get('weight') || 0;
        });
      });
      return weight;
    }),
    totalRemainingWeight: Ember.computed('totalUsedWeight', function () {
      return 100 - this.get('totalUsedWeight');
    }),
    validateData: function validateData(forSubmit) {
      var _this = this;

      var template = this.get('template');
      var templateTitle = template.get('title');
      var groups = this.get('groups');
      var dataIsValid = true;
      var bceAreValid = true;
      template.get('errors').remove('title');

      if (!templateTitle || !templateTitle.trim()) {
        template.get('errors').add('title', this.get('intl').t('requiredField').toString());
        dataIsValid = false;
      }

      groups.forEach(function (group) {
        var criteriaEvaluations = group.get('baseCriteriaEvaluations');
        var title = group.get('title');
        group.get('errors').remove('title');

        if (!title || !title.trim()) {
          group.get('errors').add('title', _this.get('intl').t('requiredField').toString());
          dataIsValid = false;
        }

        if (forSubmit && !criteriaEvaluations.get('length')) {
          bceAreValid = false;
          dataIsValid = false;
        }

        criteriaEvaluations.forEach(function (bce) {
          bce.get('errors').remove('title');
          bce.get('errors').remove('description');
          var title = bce.get('title');
          var description = bce.get('description');

          if (!title || !title.trim()) {
            bce.get('errors').add('title', _this.get('intl').t('requiredField').toString());
            dataIsValid = false;
          }

          if (!description || !description.trim()) {
            bce.get('errors').add('description', _this.get('intl').t('requiredField').toString());
            dataIsValid = false;
          }

          if (forSubmit) {
            var weight = bce.get('weight');

            if (!weight || weight > 100) {
              _this.get('notify').error(bce.get('title') + ': ' + _this.get('intl').t('invalidTemplateWeight').toString());

              return false;
            }
          }
        });
      });

      if (!bceAreValid) {
        this.get('notify').error('All groups should have at least one criteria evaluation added');
      }

      if (forSubmit && this.get('totalUsedWeight') !== 100) {
        dataIsValid = false;
        this.get('notify').error(this.get('intl').t('invalidTotalTemplateWeight')).toString();
      }

      return dataIsValid;
    },
    serializeData: function serializeData(template) {
      var dataJSON = {
        template: template.get('id'),
        template_title: template.get('title'),
        is_draft: template.get('isDraft'),
        groups: []
      };
      var groups = this.get('groups');
      var groupsJSON = [];
      groups.forEach(function (group) {
        var criteriaEvaluations = group.hasMany('baseCriteriaEvaluations').value();
        var criteriaEvaluationsJSON = [];
        criteriaEvaluations.forEach(function (ca) {
          var attributes = {};

          var snapshotAttributes = ca._createSnapshot()._attributes;

          for (var key in snapshotAttributes) {
            attributes[key.underscore()] = snapshotAttributes[key];
          }

          attributes['id'] = ca.get('id');
          criteriaEvaluationsJSON.push(attributes);
        });
        groupsJSON.push({
          id: group.get('id'),
          title: group.get('title'),
          order: group.get('order'),
          template: group.get('template.id'),
          'base_criteria_evaluations': criteriaEvaluationsJSON
        });
      });
      dataJSON.groups = groupsJSON;
      return dataJSON;
    },
    actions: {
      addGroup: function addGroup() {
        var groups = this.get('groups');
        var groupsLength = groups.get('length');
        groups.pushObject(this.store.createRecord('base-criteria-evaluation-group', {
          title: this.get('groupTitle'),
          template: this.get('template'),
          order: groupsLength + 1
        }));
      },
      addCriteriaEvaluation: function addCriteriaEvaluation(group) {
        var criteriaEvaluations = group.get('baseCriteriaEvaluations');
        var criteriaLength = criteriaEvaluations.get('length');
        var remainingWeight = this.get('totalRemainingWeight');
        var weight = remainingWeight > 9 ? 10 : remainingWeight;
        criteriaEvaluations.pushObject(this.store.createRecord('base-criteria-evaluation', {
          title: null,
          description: null,
          isEliminatory: false,
          order: criteriaLength + 1,
          weight: weight
        }));
      },
      removeCriteriaEvaluation: function removeCriteriaEvaluation(criteriaEvaluation, group) {
        var criteriaEvaluations = group.get('baseCriteriaEvaluations');
        criteriaEvaluations.removeObject(criteriaEvaluation);
        criteriaEvaluations.forEach(function (ce, index) {
          ce.set('order', index + 1);
        });
        criteriaEvaluation.deleteRecord();
        this.get('groups').arrayContentDidChange(); // notify that the array content did change, so the computed property updates
      },
      removeGroup: function removeGroup(group) {
        var groups = this.get('groups');
        groups.removeObject(group);
        groups.forEach(function (group, index) {
          group.set('order', index + 1);
        });
        group.deleteRecord();
      },
      save: function save() {
        var _this2 = this;

        var template = this.get('template');
        var dataIsValid = this.validateData();
        template.set('isDraft', true);
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (dataIsValid) {
            template.bulkSave(_this2.serializeData(template)).then(function () {
              resolve();

              _this2.store.peekAll('base-criteria-evaluation').content.forEach(function (bce) {
                if (bce.id == null) {
                  bce.deleteRecord();
                }
              });

              _this2.store.peekAll('base-criteria-evaluation-group').content.forEach(function (bceg) {
                if (bceg.id == null) {
                  bceg.deleteRecord();
                }
              });

              _this2.send('refreshRoute');

              var quoteId = _this2.get('quoteId');

              var projectConfiguration = _this2.get('projectConfiguration');

              if (quoteId && projectConfiguration) {
                _this2.transitionToRoute('app.project.client.configuration', quoteId);
              } else if (quoteId) {
                _this2.transitionToRoute('app.rfp.criteria-evaluation', quoteId, {
                  queryParams: {
                    templateId: template.get('id')
                  }
                });
              }
            }).catch(function () {
              reject();

              _this2.get('notify').error(_this2.get('intl').t('notifyMessages.errorOccurredVerifyFields').toString());
            });
          } else {
            reject();

            _this2.get('notify').error(_this2.get('intl').t('notifyMessages.errorOccurredFixErrors').toString());
          }
        });
      },
      saveSubmit: function saveSubmit() {
        var _this3 = this;

        var template = this.get('template');
        template.set('isDraft', false);
        var dataIsValid = this.validateData(true);
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (dataIsValid) {
            template.bulkSave(_this3.serializeData(template)).then(function () {
              resolve();

              _this3.store.peekAll('base-criteria-evaluation').content.forEach(function (bce) {
                if (bce.id == null) {
                  bce.deleteRecord();
                }
              });

              _this3.store.peekAll('base-criteria-evaluation-group').content.forEach(function (bceg) {
                if (bceg.id == null) {
                  bceg.deleteRecord();
                }
              });

              _this3.send('refreshRoute');

              var quoteId = _this3.get('quoteId');

              var projectConfiguration = _this3.get('projectConfiguration');

              if (quoteId && projectConfiguration) {
                _this3.transitionToRoute('app.project.client.configuration', quoteId);
              } else if (quoteId) {
                _this3.transitionToRoute('app.rfp.criteria-evaluation', quoteId, {
                  queryParams: {
                    templateId: template.get('id')
                  }
                });
              } else {
                _this3.transitionToRoute('app.templates.index');
              }
            }).catch(function () {
              reject();

              _this3.get('notify').error(_this3.get('intl').t('notifyMessages.errorOccurredVerifyFields').toString());
            });
          } else {
            reject();

            _this3.get('notify').error(_this3.get('intl').t('notifyMessages.errorOccurredFixErrors').toString());
          }
        });
      },
      dragEndAction: function dragEndAction(_ref) {
        var sourceList = _ref.sourceList,
            sourceIndex = _ref.sourceIndex,
            targetList = _ref.targetList,
            targetIndex = _ref.targetIndex;

        if (sourceList === targetList && sourceIndex === targetIndex) {
          return;
        }

        var item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item); // set order fields for items

        targetList.forEach(function (item, index) {
          item.set('order', index + 1);
        });
        sourceList.forEach(function (item, index) {
          item.set('order', index + 1);
        });
      },
      setBceWeight: function setBceWeight(bce, oldWeight, newWeight) {
        var remainingWeight = this.get('totalRemainingWeight');
        var newValue;

        if (newWeight <= oldWeight || newWeight <= remainingWeight) {
          newValue = newWeight;
        } else {
          newValue = oldWeight + remainingWeight;
        }

        bce.slider.set(newValue);
        bce.set('weight', newValue);
        this.notifyPropertyChange('totalUsedWeight');
      }
    }
  });

  _exports.default = _default;
});