define("cb-ui/models/quote-service-provider", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    quote: belongsTo('quote', {
      async: true,
      inverse: 'quoteServiceProviders'
    }),
    providerName: attr('string'),
    providerWebsite: attr('string'),
    contactPersonName: attr('string'),
    email: attr('string'),
    phoneNumber: attr('string'),
    saveItem: function saveItem() {
      this.save();
    },
    autoSave: Ember.observer('providerName', 'contactPersonName', 'email', 'phoneNumber', function () {
      Ember.run.debounce(this, this.saveItem, 1000);
    })
  });

  _exports.default = _default;
});