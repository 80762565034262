define("cb-ui/mixins/company-services-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    finalCompanyServices: Ember.A(),
    sortedServices: Ember.A(),
    projectServices: [],
    leafProjectServices: Ember.A(),
    finalCompanyIndustries: Ember.A(),
    sortedIndustries: Ember.A(),
    projectIndustries: [],
    leafProjectIndustries: Ember.A(),
    servicesIndustriesCompanyObserver: Ember.observer('company', 'company.services.[]', 'company.industries.[]', function () {
      if (this.get('quote')) {
        this.initializeServicesIndustries();
      }
    }),
    finalCompanyServicesObserver: Ember.observer('company', 'company.services.[]', 'company.consultants.[]', 'projectServices.[]', 'leafProjectServices.[]', function () {
      var _this = this;

      if (this.get('company')) {
        this.get('company.services').then(function (companyServices) {
          var leafProjectServices = _this.get('leafProjectServices');

          var projectServicesList = _this.get('projectServices');

          if (leafProjectServices) {
            leafProjectServices.forEach(function (leaf) {
              if (!companyServices.includes(leaf)) {
                _this.deleteLeafService(projectServicesList, leaf.get('id'));
              }
            });
          }

          projectServicesList.forEach(function (projectService) {
            var nodeDeletedFlag = true;

            while (nodeDeletedFlag) {
              nodeDeletedFlag = _this.deleteParentWithoutChildren(projectService);
            }
          });
          projectServicesList = projectServicesList.filter(function (service) {
            return !(service.children && !service.children.length);
          });
          var newServicesList = Ember.A();

          _this.createFinalList(projectServicesList, newServicesList, 0);

          _this.set('finalCompanyServices', newServicesList);
        });
      }
    }),
    finalCompanyIndustriesObserver: Ember.observer('company', 'company.consultants.[]', 'projectIndustries.[]', 'leafProjectIndustries.[]', function () {
      var _this2 = this;

      if (this.get('company')) {
        var companyIndustries = this.get('company.industries');
        var leafProjectIndustries = this.get('leafProjectIndustries');
        var projectIndustriesList = this.get('projectIndustries');

        if (leafProjectIndustries) {
          leafProjectIndustries.forEach(function (leaf) {
            if (!companyIndustries.includes(leaf)) {
              _this2.deleteLeafService(projectIndustriesList, leaf.get('id'));
            }
          });
        }

        projectIndustriesList.forEach(function (projectIndustry) {
          var nodeDeletedFlag = true;

          while (nodeDeletedFlag) {
            nodeDeletedFlag = _this2.deleteParentWithoutChildren(projectIndustry);
          }
        });
        projectIndustriesList = projectIndustriesList.filter(function (industry) {
          return !(industry.children && !industry.children.length);
        });
        var newIndustriesList = Ember.A();
        this.createFinalList(projectIndustriesList, newIndustriesList, 0);
        this.set('finalCompanyIndustries', newIndustriesList);
      }
    }),
    deleteLeafService: function deleteLeafService(servicesList, nodeId) {
      var _this3 = this;

      servicesList.forEach(function (node) {
        if (!node.children) {
          if (nodeId.toString() === node.id.toString()) {
            servicesList.removeObject(node);
          }
        } else {
          _this3.deleteLeafService(node.children, nodeId);
        }
      });
    },
    createFinalList: function createFinalList(objectsList, newList, offset) {
      var _this4 = this;

      objectsList.forEach(function (obj) {
        var name = obj.name;

        if (offset < 20 && obj.children) {
          name = '<strong>' + name + '</strong>';
        }

        newList.addObject({
          name: name,
          offset: offset
        });

        if (obj.children) {
          _this4.createFinalList(obj.children, newList, offset + 10);
        }
      });
    },
    nodeIsLeaf: function nodeIsLeaf(nodeId) {
      return this.get('leafProjectServices').filterBy('id', nodeId).get('length') > 0;
    },
    deleteParentWithoutChildren: function deleteParentWithoutChildren(node) {
      var _this5 = this;

      var nodesToDelete = [];
      var deleteFlag = false;

      if (node.children) {
        node.children.forEach(function (child) {
          if (child.children && !child.children.length && !_this5.nodeIsLeaf(child.id)) {
            nodesToDelete.push(child);
            deleteFlag = true;
          } else {
            deleteFlag |= _this5.deleteParentWithoutChildren(child);
          }

          nodesToDelete.forEach(function (child) {
            node.children.pop(child);
          });
          return deleteFlag;
        });
      }
    },
    initializeServicesIndustries: function initializeServicesIndustries() {
      var _this6 = this;

      var projectServices = [];
      var leafProjectServices = Ember.A();
      var projectIndustries = [];
      var leafProjectIndustries = Ember.A();
      var sortedServices = this.get('sortedServices');
      var sortedIndustries = this.get('sortedIndustries');
      sortedServices.forEach(function (service) {
        if (!service.get('parent.id')) {
          var children = _this6.generateChildrenNameList(service, sortedServices, _this6.get('propertyNameService'));

          projectServices.push(children);
        }

        if (!service.get('children.length')) {
          leafProjectServices.addObject(service);
        }
      });
      this.set('projectServices', projectServices);
      this.set('leafProjectServices', leafProjectServices);
      sortedIndustries.forEach(function (industry) {
        if (!industry.get('parent.id')) {
          var children = _this6.generateChildrenNameList(industry, sortedIndustries, _this6.get('propertyNameIndustry'));

          projectIndustries.push(children);
        }

        if (!industry.get('children.length')) {
          leafProjectIndustries.addObject(industry);
        }
      });
      this.set('projectIndustries', projectIndustries);
      this.set('leafProjectIndustries', leafProjectIndustries);
    },
    generateChildrenNameList: function generateChildrenNameList(parentNode, nodesList, propertyName) {
      var _this7 = this;

      var children = nodesList.filterBy('parent.id', parentNode.get('id'));

      if (!children.get('length')) {
        return {
          id: parentNode.get('id'),
          name: parentNode.get(propertyName)
        };
      } else {
        return {
          id: parentNode.get('id'),
          name: parentNode.get(propertyName),
          children: children.map(function (child) {
            return _this7.generateChildrenNameList(child, nodesList, propertyName);
          })
        };
      }
    }
  });

  _exports.default = _default;
});