define("cb-ui/components/ember-table/pitch-presentation-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    cellValue: null,
    proposal: Ember.computed.alias('cellValue.proposal'),
    actions: {
      openBeautyContestModal: function openBeautyContestModal(proposal) {
        this.sendAction('openBeautyContestModal', proposal);
      },
      removeInvitation: function removeInvitation(proposal) {
        this.sendAction('removeInvitation', proposal);
      }
    }
  });

  _exports.default = _default;
});