define("cb-ui/models/criteria-evaluation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    titleEn: attr('string'),
    titleDe: attr('string'),
    descriptionEn: attr('string'),
    descriptionDe: attr('string'),
    type: attr('string'),
    hide: attr('boolean')
  });

  _exports.default = _default;
});