define("cb-ui/routes/app/project/client", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super(transition);

      this.get('sessionContext.currentUser').then(function (user) {
        if (!user.get('isClient')) {
          _this.transitionTo('app.dashboard');
        }
      });
    }
  });

  _exports.default = _default;
});