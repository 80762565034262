define("cb-ui/models/service-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    serviceTypeEn: attr('string'),
    serviceTypeDe: attr('string'),
    published: attr('boolean', {
      defaultValue: true
    }),
    enabled: attr('boolean', {
      defaultValue: true
    }),
    allowedForEasyWizard: attr('boolean', {
      defaultValue: true
    })
  });

  _exports.default = _default;
});