define("cb-ui/app", ["exports", "cb-ui/resolver", "ember-load-initializers", "cb-ui/config/environment", "moment"], function (_exports, _resolver, _emberLoadInitializers, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Ember from 'ember';
  // Ember.MODEL_FACTORY_INJECTIONS = true;
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  /**
   * moment.js plugin: additional methods
   * businessDiff (mStartDate)
   * businessAdd (numberOfDays)
   * businessSubtract (numberOfDays)
   * business ([false])
   */

  _exports.default = _default;

  _moment.default.fn.businessDiff = function (start) {
    var a,
        b,
        c,
        iDiff = 0,
        unit = 'day';

    if (this.isSame(start)) {
      return iDiff;
    }

    if (this.isBefore(start)) {
      a = start.clone();
      b = this.clone();
      c = -1;
    } else {
      a = this.clone();
      b = start.clone();
      c = 1;
    }

    do {
      var iDay = b.day();

      if (iDay > 0 && iDay < 6) {
        iDiff++;
      }

      b.add(unit, 1);
    } while (a.diff(b, unit) > 0);

    return iDiff * c;
  };

  _moment.default.fn.businessAdd = function (days) {
    var i = 0;

    while (i < days) {
      this.add('day', 1);

      if (this.day() > 0 && this.day() < 6) {
        i++;
      }
    }

    return this;
  };

  _moment.default.fn.businessSubtract = function (days) {
    var i = 0;

    while (i < days) {
      this.subtract('day', 1);

      if (this.day() > 0 && this.day() < 6) {
        i++;
      }
    }

    return this;
  };

  _moment.default.fn.business = function (backwards) {
    while (this.day() === 0 || this.day() === 6) {
      this[!!backwards ? 'businessSubtract' : 'businessAdd'](1);
    }

    return this;
  };
});