define("cb-ui/templates/components/ember-table/evaluation-score-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mhQDRK0Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"eq\",[[20,[\"cellValue\",\"section\"]],\"total\"],null]],null,{\"statements\":[[0,\"  \"],[6,\"strong\"],[7],[0,\"\\n    \"],[1,[25,\"format-number\",[[25,\"get\",[[25,\"get\",[[25,\"get\",[[20,[\"cellValue\",\"quoteEvaluationResults\"]],[20,[\"cellValue\",\"section\"]]],null],\"proposals\"],null],[20,[\"cellValue\",\"proposal\",\"id\"]]],null]],[[\"maximumFractionDigits\"],[2]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"format-number\",[[25,\"get\",[[25,\"get\",[[25,\"get\",[[20,[\"cellValue\",\"quoteEvaluationResults\"]],[20,[\"cellValue\",\"section\"]]],null],\"proposals\"],null],[20,[\"cellValue\",\"proposal\",\"id\"]]],null]],[[\"maximumFractionDigits\"],[2]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/ember-table/evaluation-score-cell.hbs"
    }
  });

  _exports.default = _default;
});