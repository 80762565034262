define("cb-ui/validations/quote", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    personSalutation: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    personFirstName: [(0, _validators.validateLength)({
      max: 128
    }), (0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    personLastName: [(0, _validators.validateLength)({
      max: 128
    }), (0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    })],
    projectAdministratorJobTitle: [(0, _validators.validateLength)({
      max: 128
    }), (0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    projectAdministratorEmail: [(0, _validators.validateLength)({
      max: 254
    }), (0, _validators.validateFormat)({
      type: 'email'
    }), (0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    projectAdministratorPhone: [(0, _validators.validateLength)({
      max: 32
    }), (0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],

    /*companyName: [
      validateLength({ max: 100 }),
      validatePresence({ presence: true, allowBlank: false }),
    ],
    companyAddressLineOne: [
      validateLength({ max: 256 }),
      validatePresence({ presence: true, allowBlank: false }),
    ],
    companyAddressLineTwo: [
      validateLength({ max: 256 }),
    ],
    companyAddressLineThree: [
      validateLength({ max: 256 }),
    ],
    companyPostalCode: [
      validateLength({ max: 32 }),
      validatePresence({ presence: true, allowBlank: false }),
    ],
    companyCity: [
      validateLength({ max: 128 }),
      validatePresence({ presence: true, allowBlank: false }),
    ],
    companyCountry: [
      validatePresence({ presence: true, allowBlank: false }),
    ],*/
    projectName: [(0, _validators.validateLength)({
      max: 256
    }), (0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    industryStandard: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],

    /*
    companySizeTurnover: [
      validatePresence({ presence: true, allowBlank: false }),
    ],
    companySizeEmployees: [
      validatePresence({ presence: true, allowBlank: false }),
    ],*/
    serviceType: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    serviceStandard: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    mainLocation: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    clientIntroduction: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    initialSituation: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    mainLanguage: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    proposalLanguage: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    startDate: [(0, _validators.validatePresence)({
      presence: true
    })],
    endDate: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })] // projectPhases: [
    //   validatePresence({ presence: true, allowBlank: false }),
    // ],

  };
  _exports.default = _default;
});