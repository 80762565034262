define("cb-ui/templates/components/ember-table/financial-supplements-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qBhQbV14",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"or\",[[20,[\"cellValue\",\"proposal\",\"isIncludedInEvaluation\"]],[25,\"eq\",[[20,[\"cellValue\",\"proposal\",\"status\"]],\"dismissed\"],null]],null]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"m-t m-b \"],[7],[0,\"\\n        \"],[6,\"h3\"],[7],[1,[25,\"format-number\",[[20,[\"cellValue\",\"proposal\",\"financialQuotationData\",\"supplementsValue\"]]],[[\"format\"],[\"0,0.00\"]]],false],[0,\"%\"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/ember-table/financial-supplements-cell.hbs"
    }
  });

  _exports.default = _default;
});