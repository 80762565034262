define("cb-ui/components/rfp-fixed-menu", ["exports", "cb-ui/utils/append-access-token", "cb-ui/utils/get-target-domain", "cb-ui/config/environment"], function (_exports, _appendAccessToken, _getTargetDomain, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    width: 10,
    activeStep: 0,
    quote: null,
    showUnsavedChangesModal: false,
    targetRoute: null,
    containerWidth: Ember.computed('width', function () {
      var widthColumns = this.get('width');
      return "width: " + (widthColumns * 8.333333337).toString() + '%';
    }),
    domain: Ember.computed('window.location.hostname', function () {
      return _environment.default.CANONICAL_DOMAIN_NEXT;
    }),
    base64Id: Ember.computed('quote', function () {
      return window.btoa(unescape(encodeURIComponent("QuoteNode:".concat(this.get('quote.id')))));
    }),
    userManagementUrl: Ember.computed('session.data.authenticated.token', function () {
      return "".concat((0, _getTargetDomain.default)(), "/project/").concat(this.get('quote.id'), "/user-management");
    }),
    projectInfoUrl: Ember.computed('window.location.hostname', 'quote', function () {
      return "".concat((0, _getTargetDomain.default)(), "/project/").concat(this.get('quote.id'));
    }),
    consultingFirmUrl: Ember.computed('window.location.hostname', 'quote', function () {
      return "".concat((0, _getTargetDomain.default)(), "/project/").concat(this.get('quote.id'), "/vendors");
    }),
    rfpDetailsUrl: Ember.computed('window.location.hostname', 'quote', function () {
      return "".concat((0, _getTargetDomain.default)(), "/project/").concat(this.get('quote.id'), "/rfp-details");
    }),
    reviewAndSubmitUrl: Ember.computed('window.location.hostname', 'quote', function () {
      return "".concat((0, _getTargetDomain.default)(), "/project/").concat(this.get('quote.id'), "/review-and-submit");
    }),
    projectDetailsUrl: Ember.computed('window.location.hostname', 'quote', function () {
      return "".concat((0, _getTargetDomain.default)(), "/project/").concat(this.get('quote.id'), "/rfp-details");
    }),
    internalDocsUrl: Ember.computed('session.data.authenticated.token', function () {
      return "".concat((0, _getTargetDomain.default)(), "/project/").concat(this.get('quote.id'), "/documents");
    }),
    actions: {
      openUnsavedChangesModal: function openUnsavedChangesModal(route) {
        this.set('showUnsavedChangesModal', true);
        this.set('targetRoute', route);
      },
      closeUnsavedChangesModal: function closeUnsavedChangesModal() {
        this.set('showUnsavedChangesModal', false);
        this.set('targetRoute', null);
      },
      proceed: function proceed(route) {
        var parentProceed = this.get('proceed');
        parentProceed(route);
      },
      saveAndProceed: function saveAndProceed(route) {
        var parentProceed = this.get('saveAndProceed');
        parentProceed(route);
      }
    }
  });

  _exports.default = _default;
});