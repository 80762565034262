define("cb-ui/templates/components/projects-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nvLaunEI",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-md-12\"],[7],[0,\"\\n        \"],[1,[25,\"models-table\",null,[[\"data\",\"columns\",\"useNumericPagination\",\"filteringIgnoreCase\",\"selectProject\",\"showPreviewProject\",\"customClasses\",\"customMessages\",\"columnsAreUpdateable\",\"columnFieldsToCheckUpdate\",\"useFilteringByColumns\"],[[20,[\"items\"]],[20,[\"columns\"]],true,true,\"selectProject\",\"showPreviewProject\",[20,[\"customCssClasses\"]],[20,[\"customMessages\"]],true,[20,[\"columnsFieldsToUpdate\"]],false]]],false],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/projects-table.hbs"
    }
  });

  _exports.default = _default;
});