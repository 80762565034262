define("cb-ui/templates/components/multiple-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J0CaweT7",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,\"h5\"],[7],[0,\"\\n  \"],[1,[18,\"label\"],false],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[6,\"div\"],[9,\"class\",\"options-wrap\"],[7],[0,\"\\n    \"],[6,\"ul\"],[9,\"class\",\"item-list option-list\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"items\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,\"multiple-select-item\",null,[[\"selectedItems\",\"item\",\"addItem\",\"removeItem\",\"language\"],[[20,[\"selectedItems\"]],[19,1,[]],\"addItem\",\"removeItem\",[20,[\"language\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/multiple-select.hbs"
    }
  });

  _exports.default = _default;
});