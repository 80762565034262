define("cb-ui/templates/components/ember-table/evaluation-feedback-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pOlSMcAw",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,\"or\",[[25,\"eq\",[[20,[\"proposal\",\"status\"]],\"submitted\"],null],[25,\"eq\",[[20,[\"proposal\",\"status\"]],\"accepted\"],null]],null]],null,{\"statements\":[[4,\"if\",[[20,[\"userIsQuoteAdmin\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"m-t\"],[7],[0,\"\\n        \"],[1,[25,\"checkbox-item\",null,[[\"item\",\"isChecked\",\"label\",\"addItem\",\"removeItem\"],[[20,[\"proposa\",\"dontSendFeedback\"]],[20,[\"proposal\",\"dontSendFeedback\"]],[25,\"t\",[\"app.projectClient.evaluation.label.feedback.dontSendFeedback\"],null],\"removeFeedback\",\"addFeedback\"]]],false],[0,\"\\n      \"],[8],[0,\"\\n      \"],[1,[25,\"textarea\",null,[[\"data-test-project-goal-description\",\"cols\",\"rows\",\"value\",\"placeholder\",\"class\",\"disabled\"],[true,\"50\",\"6\",[20,[\"proposal\",\"feedback\"]],[25,\"t\",[\"app.projectClient.evaluation.label.feedback.placeholder\"],null],\"form-control border-radius-0 rfp-textarea\",[20,[\"proposal\",\"dontSendFeedback\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"m-t\"],[7],[0,\"\\n      \"],[1,[25,\"textarea\",null,[[\"data-test-project-goal-description\",\"cols\",\"rows\",\"value\",\"class\",\"disabled\"],[true,\"50\",\"6\",[20,[\"proposal\",\"feedback\"]],\"form-control border-radius-0 rfp-textarea\",true]]],false],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/ember-table/evaluation-feedback-cell.hbs"
    }
  });

  _exports.default = _default;
});