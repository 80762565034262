define("cb-ui/routes/wizard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    userLead: null,
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var userLead = this.store.createRecord('user-lead', {
        firstName: '',
        lastName: '',
        email: '',
        companyName: ''
      });
      controller.set('userLead', userLead);
    }
  });

  _exports.default = _default;
});