define("cb-ui/routes/app/project/proposal/result", ["exports", "cb-ui/mixins/scrollbar-reset"], function (_exports, _scrollbarReset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollbarReset.default, {
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var proposal = model;
      var currentUser = this.get('sessionContext.currentUser');
      this.store.query('user-proposal-role', {
        proposal_id: proposal.get('id'),
        user_id: currentUser.get('id')
      }).then(function (userProposalRoleList) {
        controller.set('userProposalRole', userProposalRoleList.get('firstObject'));
      });
      controller.set('currentUser', currentUser);
      controller.set('proposal', proposal);
      controller.set('quote', this.modelFor('app.project'));
    }
  });

  _exports.default = _default;
});