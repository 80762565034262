define("cb-ui/components/score-rank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    proposal: null,
    proposals: new Ember.A(),
    isTechnical: false,
    isBeautyContest: false,
    isFinancial: false,
    rank: null,
    technicalScores: Ember.computed('proposals.@each.intermediateScoreTechnicalProposal', function () {
      var validProposals = this.get('proposals').filter(function (p) {
        var technicalScore = p.get('intermediateScoreTechnicalProposal');

        if (technicalScore && technicalScore > 0) {
          return true;
        }
      });
      return validProposals.map(function (item) {
        return item.get('intermediateScoreTechnicalProposal');
      });
    }),
    beautyContestScores: Ember.computed('proposals.@each.intermediateScoreBeautyContest', function () {
      var validProposals = this.get('proposals').filter(function (p) {
        var score = p.get('intermediateScoreBeautyContest');

        if (score && score > 0) {
          return true;
        }
      });
      return validProposals.map(function (item) {
        return item.get('intermediateScoreBeautyContest');
      });
    }),
    financialScores: Ember.computed('proposale.@each.financialQuotationData', function () {
      var validProposals = this.get('proposals').filter(function (p) {
        var score = p.get('financialQuotationData.intermediateScore');

        if (score && score > 0) {
          return true;
        }
      });
      return validProposals.map(function (item) {
        return item.get('financialQuotationData.intermediateScore');
      });
    }),
    rankObserver: Ember.on('init', Ember.observer('proposal', 'proposals.@each.intermediateScoreTechnicalProposal', 'proposals.@each.intermediateScoreBeautyContest', 'proposals.@each.financialQuotationData', function () {
      var currentScore = null;
      var proposalsScores = [];

      if (this.get('isTechnical')) {
        currentScore = this.get('proposal.intermediateScoreTechnicalProposal');
        proposalsScores = this.get('technicalScores');
      } else if (this.get('isBeautyContest')) {
        currentScore = this.get('proposal.intermediateScoreBeautyContest');
        proposalsScores = this.get('beautyContestScores');
      } else if (this.get('isFinancial')) {
        currentScore = this.get('proposal.financialQuotationData.intermediateScore');
        proposalsScores = this.get('financialScores');
      }

      if (currentScore) {
        proposalsScores.sort(function (a, b) {
          return a - b;
        }).reverse();
        var rank = proposalsScores.indexOf(currentScore);

        if (rank >= 0) {
          this.set('rank', rank + 1);
        }
      }
    }))
  });

  _exports.default = _default;
});