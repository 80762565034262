define("cb-ui/templates/components/checkbox-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yvXsMIdZ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"checkbox m-t-none m-b-xs\"],[7],[0,\"\\n    \"],[6,\"label\"],[9,\"class\",\"pointer-cursor\"],[7],[0,\"\\n      \"],[1,[25,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[20,[\"isChecked\"]]]]],false],[0,\" \"],[1,[18,\"label\"],false],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/checkbox-item.hbs"
    }
  });

  _exports.default = _default;
});