define("cb-ui/routes/app/rfp/confirm", ["exports", "cb-ui/mixins/scrollbar-reset", "cb-ui/utils/download-file"], function (_exports, _scrollbarReset, _downloadFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollbarReset.default, {
    model: function model() {
      var quote = this.modelFor('app.rfp');
      return Ember.RSVP.hash({
        criteriaEvaluations: this.store.findAll('criteriaEvaluation'),
        user: this.get('sessionContext.currentUser'),
        quoteCompanies: this.modelFor('app.rfp').get('companies'),
        //TODO: Loaded for rfp-summary component
        consultantType: this.store.findAll('consultantType'),
        quoteCompaniesContacts: this.store.query('quote-company-contact', {
          quote: quote.get('id')
        }),
        salutations: this.store.findAll('salutation'),
        criteriaEvaluationGroups: this.store.query('criteria-evaluation-group', {
          include: 'evaluations',
          quote_id: quote.get('id')
        }),
        userQuoteRoleList: this.store.query('user-quote-role', {
          quote_id: quote.get('id')
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var quote = this.modelFor('app.rfp');
      controller.set('quote', quote);
      controller.set('userQuoteRole', model.userQuoteRoleList.get('firstObject'));
      controller.set('criteriaEvaluations', model.criteriaEvaluations);
      controller.set('showEditCoverLetterModal', false);
      controller.set('showCreateNewCoverLetterModal', false);
      controller.set('quote.currentStep', 60);
      controller.set('currentUser', model.user);
      controller.set('userStatus', null);
      controller.set('showUserStatusModal', false);
      quote.get('owner').then(function (quoteOwner) {
        var admins = model.userQuoteRoleList.filter(function (uqr) {
          return uqr.get('user.id') == model.user.get('id') && uqr.get('role') == 'admin';
        });
        controller.set('isQuoteOwner', admins.length > 0 || quoteOwner.get('id') === model.user.get('id'));
      });
      controller.set('quoteCompanies', model.quoteCompanies);
      controller.set('showQuoteCompanyContactsModal', false);
      controller.set('emailSent', false);
      controller.createQuoteCompanyContactsList(model.quoteCompaniesContacts);
      var technicalQuoteCriteriaGroups = new Ember.A();
      var pitchQuoteCriteriaGroups = new Ember.A();
      model.criteriaEvaluationGroups.forEach(function (ceg) {
        var groupType = ceg.get('type');

        if (groupType === 'technical_proposal') {
          technicalQuoteCriteriaGroups.addObject(ceg);
        } else if (groupType === 'pitch') {
          pitchQuoteCriteriaGroups.addObject(ceg);
        }
      });
      controller.set('technicalQuoteCriteriaGroups', technicalQuoteCriteriaGroups);
      controller.set('pitchQuoteCriteriaGroups', pitchQuoteCriteriaGroups);
    },
    actions: {
      newDocumentToken: function newDocumentToken(id) {
        this.store.findRecord('quote-document', id).then(function (res) {
          (0, _downloadFile.default)(res.data.file, res.get('fileName'));
        });
      },
      newCoverLetterToken: function newCoverLetterToken(id) {
        this.store.findRecord('quote-cover-letter-document', id).then(function (res) {
          (0, _downloadFile.default)(res.data.file, res.get('fileName'));
        });
      },
      closeSubmitRFPModal: function closeSubmitRFPModal() {
        this.controller.set('showConfirmModal', false);
      }
    }
  });

  _exports.default = _default;
});