define("cb-ui/templates/components/document-toc-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UJCA4wql",
    "block": "{\"symbols\":[\"section\"],\"statements\":[[4,\"each\",[[20,[\"sortedSections\"]]],null,{\"statements\":[[0,\"    \"],[6,\"li\"],[9,\"class\",\"dd-item dd3-item\"],[10,\"data-id\",[26,[[19,1,[\"uid\"]]]]],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"data-test-section-handle-toc\",\"\"],[9,\"class\",\"dd-handle dd3-handle\"],[7],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"dd3-content\"],[7],[0,\"\\n            \"],[6,\"span\"],[9,\"class\",\"section-toc-item\"],[3,\"action\",[[19,0,[]],\"goTo\",[19,1,[]]]],[7],[0,\"\\n\"],[4,\"if\",[[19,1,[\"disabled\"]]],null,{\"statements\":[[0,\"                    \"],[6,\"span\"],[9,\"class\",\"section-toc-item--disabled\"],[9,\"title\",\"This section is disabled\"],[7],[0,\"\\n                    \"],[6,\"i\"],[9,\"class\",\"glyphicon glyphicon-eye-close\"],[7],[8],[0,\"\\n                        \"],[1,[19,1,[\"title\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                    \"],[1,[19,1,[\"number\"]],false],[0,\" \"],[1,[19,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"            \"],[8],[0,\"\\n\"],[4,\"unless\",[[19,1,[\"notDeletable\"]]],null,{\"statements\":[[0,\"                \"],[6,\"i\"],[9,\"data-test-delete-section-toc\",\"\"],[9,\"class\",\"glyphicon glyphicon-trash section-toc-item--delete\"],[9,\"title\",\"Delete this section\"],[3,\"action\",[[19,0,[]],\"delete\",[19,1,[]]]],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[8],[0,\"\\n\"],[4,\"if\",[[19,1,[\"children\"]]],null,{\"statements\":[[0,\"            \"],[1,[25,\"document-toc-list\",null,[[\"sections\",\"delete\"],[[19,1,[\"children\"]],[25,\"action\",[[19,0,[]],\"delete\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/document-toc-list.hbs"
    }
  });

  _exports.default = _default;
});