define("cb-ui/components/ember-table/evaluation-feedback-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    cellValue: null,
    proposal: Ember.computed.alias('cellValue.proposal'),
    actions: {
      addFeedback: function addFeedback() {
        this.set('proposal.dontSendFeedback', false);
      },
      removeFeedback: function removeFeedback() {
        this.set('proposal.dontSendFeedback', true);
        this.set('proposal.feedback', '');
      }
    }
  });

  _exports.default = _default;
});