define("cb-ui/helpers/get-result-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params, namedArgs) {
      var criteriaScore = namedArgs.criteriaScore;
      var finishedEvaluations = namedArgs.finishedEvaluations;
      var totalScore = namedArgs.totalScore;
      var quoteEvaluationResults = namedArgs.quoteEvaluationResults;
      var proposal = namedArgs.proposal;

      if (proposal && quoteEvaluationResults) {
        var proposalData = quoteEvaluationResults.proposals[proposal.get('id')];

        if (criteriaScore) {
          var criteria = namedArgs.criteria;
          var score = proposalData.results_per_criteria[criteria.get('id')];

          if (proposalData.finished_evaluations) {
            return score.toFixed(2);
          } else {
            return this.get('intl').t('app.helpers.getResultData.notAvailable').toString();
          }
        } else if (finishedEvaluations) {
          return proposalData.finished_evaluations.toString() + ' / ' + quoteEvaluationResults.total_evaluations.toString();
        } else if (totalScore) {
          if (proposalData.finished_evaluations) {
            return proposalData.total_score.toFixed(2);
          } else {
            return this.get('intl').t('app.helpers.getResultData.notAvailable').toString();
          }
        }
      } else {
        return this.get('intl').t('app.helpers.getResultData.invalidData').toString();
      }
    }
  });

  _exports.default = _default;
});