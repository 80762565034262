define("cb-ui/mixins/company-studies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    company: null,
    companyStudies: new Ember.A(),
    sortedCompanyStudies: new Ember.A(),
    years: new Ember.A(),
    companyStudiesObserver: Ember.observer('company', function () {
      var _this = this;

      var company = this.get('company');

      if (company) {
        company.get('studies').then(function (studies) {
          var sortedStudies = studies.sortBy('year').reverse();

          _this.set('years', sortedStudies.mapBy('year').uniq());

          _this.set('sortedCompanyStudies', sortedStudies);
        });
      }
    })
  });

  _exports.default = _default;
});