define("cb-ui/components/em-select", ["exports", "ember-rapid-forms/components/em-select"], function (_exports, _emSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import Ember from 'ember';
  var _default = _emSelect.default;
  _exports.default = _default;
});