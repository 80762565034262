define("cb-ui/routes/app/templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        templates: this.store.findAll('template'),
        currentUser: this.get('sessionContext.currentUser')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('templates', model.templates);
    }
  });

  _exports.default = _default;
});