define("cb-ui/controllers/app/project/proposal", ["exports", "moment", "ember-local-storage"], function (_exports, _moment, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    proposal: null,
    quote: null,
    showSubmittedInfoModal: null,
    daysUntilProposalDueDate: 0,
    isForConsultant: true,
    //fields for callBackClient Modal
    user: null,
    question: null,
    userPhone: null,
    userCompany: null,
    userFullName: null,
    userEmail: null,
    projectID: Ember.computed.alias('quote.id'),
    projectName: Ember.computed.alias('quote.projectName'),
    userProposalRole: null,
    callBackButtonDisabled: false,
    feeConditions: Ember.computed('proposal.feeConditions', function () {
      return this.get('proposal.feeConditions');
    }),
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    appLanguage: Ember.computed.alias('localStorage.language'),
    theconsultingbaseFee: Ember.computed('proposal.financialQuotationTotal', function () {
      var proposal = this.get('proposal');

      if (proposal.get('financialQuotationTotal')) {
        return proposal.get('financialQuotationTotal') / 100;
      }

      return 0;
    }),
    vatFee: Ember.computed('theconsultingbaseFee', function () {
      var theconsultingbaseFee = this.get('theconsultingbaseFee');

      if (theconsultingbaseFee) {
        return theconsultingbaseFee * 0.19;
      }

      return 0;
    }),
    totalAmountFee: Ember.computed('theconsultingbaseFee', 'vatFee', function () {
      var theconsultingbaseFee = this.get('theconsultingbaseFee');
      var vatFee = this.get('vatFee');
      var totalAmount = 0;

      if (!isNaN(theconsultingbaseFee)) {
        totalAmount += theconsultingbaseFee;
      }

      if (!isNaN(vatFee)) {
        totalAmount += vatFee;
      }

      return totalAmount;
    }),
    participationFee: Ember.computed('proposal.financialQuotationTotal', 'proposal.feePercentageUponParticipation', 'proposal.feeFlatForParticipation', function () {
      var proposalValue = this.get('proposal.financialQuotationTotal');
      var fixedFee = this.get('proposal.feeFlatForParticipation');
      var partPercent = this.get('proposal.feePercentageUponParticipation');
      var total = 0;

      if (partPercent && proposalValue) {
        total += partPercent * proposalValue / 100;
      }

      if (fixedFee) {
        total += fixedFee;
      }

      return total;
    }),
    successFee: Ember.computed('proposal.financialQuotationTotal', 'proposal.feePercentageUponWinning', 'proposal.feeCap', function () {
      var proposalValue = this.get('proposal.financialQuotationTotal');
      var winPercent = this.get('proposal.feePercentageUponWinning');
      var feeCap = this.get('proposal.feeCap');
      var total = 0;

      if (winPercent && proposalValue) {
        total += winPercent * proposalValue / 100;
      }

      if (total > feeCap) {
        total = feeCap;
      }

      return total;
    }),
    daysUntilProposalDueDateObserver: Ember.observer('quote.proposalDueDate', function () {
      var quote = this.get('quote');
      var proposalDueDate = quote.get('proposalDueDate');

      if (proposalDueDate) {
        var days = (0, _moment.default)(proposalDueDate).diff((0, _moment.default)(), 'days');
        this.set('daysUntilProposalDueDate', days);
      }
    })
  });

  _exports.default = _default;
});