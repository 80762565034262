define("cb-ui/components/subheader-client-project", ["exports", "cb-ui/utils/append-access-token", "cb-ui/utils/get-target-domain", "cb-ui/config/environment"], function (_exports, _appendAccessToken, _getTargetDomain, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    quote: null,
    width: 10,
    containerWidth: Ember.computed('width', function () {
      var widthColumns = this.get('width');
      return "width: " + (widthColumns * 8.333333337).toString() + '%';
    }),
    domain: Ember.computed('window.location.hostname', function () {
      return _environment.default.CANONICAL_DOMAIN_NEXT;
    }),
    base64Id: Ember.computed('quote', function () {
      return window.btoa(unescape(encodeURIComponent("QuoteNode:".concat(this.get('quote.id')))));
    }),
    userManagementUrl: Ember.computed('session.data.authenticated.token', function () {
      return (0, _appendAccessToken.default)("".concat((0, _getTargetDomain.default)(), "/dashboard/rfp/").concat(this.get('base64Id'), "/evaluation/users"), this.get('session.data.authenticated.token'));
    }),
    announcementstUrl: Ember.computed('session.data.authenticated.token', function () {
      return (0, _appendAccessToken.default)("".concat((0, _getTargetDomain.default)(), "/dashboard/rfp/").concat(this.get('base64Id'), "/evaluation/announcements"), this.get('session.data.authenticated.token'));
    }),
    internalDocsUrl: Ember.computed('session.data.authenticated.token', function () {
      return (0, _appendAccessToken.default)("".concat((0, _getTargetDomain.default)(), "/dashboard/rfp/").concat(this.get('base64Id'), "/evaluation/documents"), this.get('session.data.authenticated.token'));
    })
  });

  _exports.default = _default;
});