define("cb-ui/utils/translate-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = translateProperty;

  function translateProperty(enKey, deKey) {
    return Ember.computed(enKey, deKey, 'intl.locale', function () {
      var enProperty = this.get(enKey);
      var deProperty = this.get(deKey);
      var locale = this.get('intl.locale')[0];

      if (locale === 'de') {
        return deProperty || enProperty;
      }

      return enProperty;
    });
  }
});