define("cb-ui/templates/app/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HVysFQ7J",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"app-top-navbar\",null,[[\"logout\"],[\"invalidateSession\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/app/index.hbs"
    }
  });

  _exports.default = _default;
});