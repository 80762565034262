define("cb-ui/templates/app/consultants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yu93KIr0",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"app-top-navbar\",null,[[\"logout\",\"changeUserLanguage\",\"contactTCB\"],[\"invalidateSession\",\"changeUserLanguage\",\"contactTCBAgent\"]]],false],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-md-12 m-t-lg\"],[7],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-md-12\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"ibox\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"ibox-title\"],[7],[0,\"\\n                \"],[1,[25,\"t\",[\"app.rfp.consultants.title\"],null],false],[0,\"\\n            \"],[8],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n                \"],[1,[25,\"projects-table\",null,[[\"items\",\"columns\",\"selectProject\"],[[20,[\"companies\"]],[20,[\"columns\"]],\"showConsultantInfoModal\"]]],false],[0,\"\\n\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"showConsultantModal\"]]],null,{\"statements\":[[0,\"    \"],[12,\"consultant-modal\",[]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "cb-ui/templates/app/consultants.hbs"
    }
  });

  _exports.default = _default;
});