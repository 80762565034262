define("cb-ui/utils/get-target-domain", ["exports", "cb-ui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getTargetDomain;

  function getTargetDomain() {
    var domain = _environment.default.CANONICAL_DOMAIN_NEXT;

    if (domain.endsWith('/')) {
      return domain.slice(0, -1);
    }

    return domain;
  }
});