define("cb-ui/models/question", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    user: belongsTo('user', {
      async: true
    }),
    quote: belongsTo('quote', {
      async: true
    }),
    proposal: belongsTo('proposal', {
      async: true
    }),
    question: attr(),
    type: attr(),
    answer: attr(),
    round: attr(),
    createdAt: attr('date'),
    answeredAt: attr('date'),
    hasAnswer: Ember.computed.notEmpty('answer'),
    isAnswered: attr('boolean'),
    questionNotificationSent: attr('boolean', {
      defaultValue: false
    }),
    answerNotificationSent: attr('boolean', {
      defaultValue: false
    }) // by default this property is false so that the flag will be persisted as flase in the db.

  });

  _exports.default = _default;
});