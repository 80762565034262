define("cb-ui/templates/components/ember-table/mandays-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l3i5zS1Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"or\",[[20,[\"cellValue\",\"proposal\",\"isIncludedInEvaluation\"]],[25,\"eq\",[[20,[\"cellValue\",\"proposal\",\"status\"]],\"dismissed\"],null]],null]],null,{\"statements\":[[1,[25,\"nvd3-chart\",null,[[\"type\",\"datum\",\"beforeSetup\"],[\"multiBarHorizontalChart\",[20,[\"cellValue\",\"datum\"]],[20,[\"beforeGraphSetup\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/ember-table/mandays-graph.hbs"
    }
  });

  _exports.default = _default;
});