define("cb-ui/templates/components/ember-table/financial-rank-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/ayz6tJO",
    "block": "{\"symbols\":[],\"statements\":[[6,\"h2\"],[7],[0,\"\\n  \"],[6,\"strong\"],[7],[0,\"\\n    \"],[1,[25,\"score-rank\",null,[[\"proposals\",\"proposal\",\"isFinancial\"],[[20,[\"proposals\"]],[20,[\"cellValue\",\"proposal\"]],true]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cb-ui/templates/components/ember-table/financial-rank-cell.hbs"
    }
  });

  _exports.default = _default;
});