define("cb-ui/models/proposal-document-type", ["exports", "ember-data", "cb-ui/utils/translate-property"], function (_exports, _emberData, _translateProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    documentTypeEn: attr('string'),
    documentTypeDe: attr('string'),
    documentType: (0, _translateProperty.default)('documentTypeEn', 'documentTypeDe'),
    published: attr('boolean', {
      defaultValue: true
    }),
    //flag for 'Other document type' proposal document type
    isCustom: attr('boolean', {
      defaultValue: false
    }),
    //flag for 'Approach presentation' proposal document type
    isApproachPresentation: attr('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});