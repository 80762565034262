define("cb-ui/controllers/app/org-settings/cover-letter-settings/index", ["exports", "ember-intl"], function (_exports, _emberIntl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    successSavedMessage: (0, _emberIntl.translationMacro)('app.orgSettings.coverLetterSettings.actions.successSaved'),
    errorMessage: (0, _emberIntl.translationMacro)('app.orgSettings.coverLetterSettings.actions.error'),
    coverLetterLogoChangedSuccessMessage: (0, _emberIntl.translationMacro)('app.orgSettings.coverLetterSettings.actions.coverLetterLogoChangedSuccess'),
    shownImage: null,
    shownFooterImage: null,
    actions: {
      saveCoverLetterTextChanges: function saveCoverLetterTextChanges() {
        var _this = this;

        var editor = this.get('editor');
        var companySettings = this.companySettings;
        var coverLetterText = companySettings.get('coverLetterText');
        var editorText = editor.getContent();

        if (Ember.isEmpty(editorText)) {
          companySettings.set('coverLetterText', null);
        } else if (editorText !== coverLetterText) {
          companySettings.set('coverLetterText', editorText);
        }

        if (companySettings.hasDirtyAttributes) {
          companySettings.save().then(function () {
            _this.get('notify').success(_this.get('successSavedMessage'));
          }).catch(function () {
            _this.get('notify').error(_this.get('errorMessage'));
          });
        }
      },
      addCoverLetterTextLogo: function addCoverLetterTextLogo() {
        var _this2 = this;

        this.get('companySettings').reload().then(function () {
          _this2.set('shownImage', _this2.companySettings.get('coverLetterLogo'));

          _this2.get('notify').success(_this2.get('coverLetterLogoChangedSuccessMessage'));
        });
      },
      addCoverLetterTextFooterLogo: function addCoverLetterTextFooterLogo() {
        var _this3 = this;

        this.get('companySettings').reload().then(function () {
          _this3.set('shownFooterImage', _this3.companySettings.get('coverLetterFooterLogo'));

          _this3.get('notify').success(_this3.get('coverLetterLogoChangedSuccessMessage'));
        });
      }
    }
  });

  _exports.default = _default;
});