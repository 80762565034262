define("cb-ui/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-local-storage", "cb-ui/mixins/loading-slider", "cb-ui/utils/get-target-domain", "cb-ui/utils/session-manager"], function (_exports, _applicationRouteMixin, _emberLocalStorage, _loadingSlider, _getTargetDomain, _sessionManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _loadingSlider.default, {
    tcbToken: null,
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    sessionContext: Ember.inject.service(),
    moment: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      this._super(transition);

      this.get('intl').setLocale('en');
      this.get('moment').setLocale('en');

      if ('tcbToken' in transition.queryParams) {
        // If the session is not already authenticated the transition is aborted and retried by ember-simple-auth
        // once the authentication is successful. To avoid an infinite loop the auth token is stored and further
        // calls to beforeModel will skip auth with identical tokens.
        var currentTcbToken = this.get('tcbToken');

        if (transition.queryParams.tcbToken !== currentTcbToken) {
          _sessionManager.default.loadFromQueryParams(transition.queryParams);

          this.set('tcbToken', transition.queryParams.tcbToken);

          if (!this.get('session.isAuthenticated')) {
            // attemptedTransition is used by the sessionAuthenticationSucceeded hook to redirect the user after login.
            // The hook is not triggered at the end of session.authenticate if the session is already authenticated.
            this.get('session').set('attemptedTransition', transition);
            transition.abort();
          }

          return this.get('session').authenticate('authenticator:token');
        }
      }
    },
    afterModel: function afterModel() {
      if (this.get('localStorage.language')) {
        this.get('intl').setLocale(this.get('localStorage.language'));
        this.get('moment').setLocale(this.get('localStorage.language'));
      }
    },
    sessionAuthenticated: function sessionAuthenticated() {
      var _this = this;

      var quoteUuid = this.get('localStorage.quoteUuid');
      this.set('localStorage.language', null);

      if (quoteUuid) {
        this.store.find('quote', quoteUuid).then(function (quote) {
          quote.save().then(function (savedQuote) {
            _this.get('sessionContext.currentUser').then(function (user) {
              _this.set('localStorage.quoteUuid', null);

              savedQuote.set('ownerName', user.get('fullName'));

              _this.transitionTo('app.rfp.project-details', savedQuote.get('id'));
            });
          }).catch(function () {
            _this.get('notify').error(_this.get('intl').t('notifyMessages.errorOccurredFixErrors').toString());
          });
        }).catch(function () {
          _this.set('localStorage.quoteUuid', null);

          _this.transitionTo('app.dashboard');
        });
      } else {
        this._super();
      }
    },
    sessionInvalidated: function sessionInvalidated() {
      if (!this.get('session.skipRedirect')) {
        window.location = "".concat((0, _getTargetDomain.default)(), "/login?logoutBefore=true");
      }
    },
    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },
      changeLocalStorageLanguage: function changeLocalStorageLanguage(newLanguage) {
        this.set('localStorage.language', newLanguage); // this.set('intl.locale', newLanguage);

        this.get('intl').setLocale(newLanguage);
        this.get('moment').setLocale(newLanguage);
      }
    }
  });

  _exports.default = _default;
});