define("cb-ui/models/quote-contact", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    quote: belongsTo('quote', {
      async: true,
      inverse: 'quoteContacts'
    }),
    firstName: attr('string'),
    lastName: attr('string'),
    jobTitle: attr('string'),
    salutation: belongsTo('salutation', {
      async: true
    })
  });

  _exports.default = _default;
});